<!-- Map container -->
<div [class]="mapMode()" id="leaflet"></div>

<!-- Browse & search feature -->
<div *ngIf="mapMode() === mapModes.SHOW_SKOGSKADER" class="custom-control-container filter-tools">
  <div class="control-toggles">
    <!-- Report filter -->
    <button
      (click)="isReportFilterVisible = !isReportFilterVisible"
      (keyup.enter)="isReportFilterVisible = !isReportFilterVisible"
      [title]="isReportFilterVisible ? 'Skjul rapportfilter' : 'Vis rapportfilter'"
      class="custom-control report-filter-toggle"
    >
      <i
        [class.bi-chevron-double-left]="isReportFilterVisible"
        [class.bi-chevron-double-right]="!isReportFilterVisible"
        class="custom-control-icon bi"
      ></i>
    </button>
    <!-- /END Report filter -->

    <!-- Search place -->
    <button
      (click)="isReportFilterVisible = !isReportFilterVisible"
      (keyup.enter)="isReportFilterVisible = !isReportFilterVisible"
      class="custom-control search-place-toggle"
      title="Søk i kart"
    >
      <i class="custom-control-icon bi bi-search"></i>
    </button>
    <!-- /END Search place -->
  </div>

  <div class="control-content">
    <app-report-filter
      *ngIf="isReportFilterVisible"
      (filtersChanged)="updateFilterValues($event)"
      [emitFiltersOnInit]="true"
      [loading$]="loadingReports$"
    ></app-report-filter>
    <app-search-place *ngIf="!isReportFilterVisible"></app-search-place>
  </div>
</div>
<!-- /END Browse & search feature -->

<!-- Draw on map feature -->
<div *ngIf="mapMode() === mapModes.DRAW" class="custom-control-container draw-tools">
  <div class="control-content">
    <app-search-place></app-search-place>
    <app-leaflet-geoman [map]="map"></app-leaflet-geoman>
  </div>
</div>
<!-- /END Draw on map feature -->
