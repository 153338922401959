// From node_modules:
require('leaflet');
//require('leaflet.wms');
//require('leaflet-draw');

// From local ajs folder:
//require('@ajs-npm/leaflet.js');
require('@ajs-npm/leaflet.wms.js');
require('@ajs-npm/leaflet.draw.js');
require('@ajs/js/map/leaflet.draw.local.js');
window.type = ''; // https://github.com/Leaflet/Leaflet.draw/issues/1026
require('@ajs-npm/L.Control.Locate.min.js');
require('@ajs-npm/Bing.js');
require('@ajs/js/map/angular-leaflet-directive.min.js');
require('@ajs/js/modified_extras/leaflet-pip/geojson-utils.js');
require('@ajs/js/modified_extras/leaflet-pip/leaflet-pip.js');
const { MapModesEnum } = require('@enums/map-modes.enum');
const { AppConfig } = require("@core/app.config");


(function (skogskadeControllers) {
  'use strict';

  skogskadeControllers.controller('mapController', mapControllerFn);

  mapControllerFn.$inject = [
    '$scope',
    '$http',
    '$routeParams',
    '$rootScope',
    'dateHelper',
    'leafletData',
    'legendService',
    'mapDataService',
    'mapModeService',
    'mapService',
    'messageService',
    'ng2MapObjectsService',
    'ng2Router',
    'reportFilterService',
    'reportListService',
    'requestCounterService',
    'skogskadeGlobalOptions',
  ];

  function mapControllerFn(
    $scope,
    $http,
    $routeParams,
    $rootScope,
    dateHelper,
    leafletData,
    legendService,
    mapDataService,
    mapModeService,
    mapService,
    messageService,
    ng2MapObjectsService,
    ng2Router,
    reportFilterService,
    reportListService,
    requestCounterService,
    skogskadeGlobalOptions
  ) {
    define(['leaflet.wms'], function(wms) {
      L.WMS = wms;
    });

    //
    // Initializing
    //
    $http.defaults.useXDomain = true;
    $scope.use_filter = true;
    $scope.reportIDs = null;
    // Careful with this variable as it is something
    $scope.filterValues = reportFilterService.getFilterValues();
    $scope.dateHelper = dateHelper;
    $scope.requests = requestCounterService.getRequestCount;
    var searchMarkerStyle = {
      color: '#136aec',
      fillColor: '#2a93ee',
      fillOpacity: 0.7,
      weight: 2,
      opacity: 0.9,
      radius: 5,
    };
    $scope.showSearchField = false;
    $scope.simplemap = null;
    $scope.tearingDownController = false;

    $scope.filterParam = { showSearchFilter: true };
    $scope.numFeatures = mapService.getNumGeoFiguresWithText($scope.mapMode);
    $scope.showFilterWarning = true;
    $scope.skogskadeLayer = null;

    // A counter used to give unique ids to drawn items
    $scope.skogskadeIdCounter = 0;
    $scope.generateIdentifier = function(layer) {
      layer.options.skogskadeId = ++$scope.skogskadeIdCounter;
    }

    // If ajs mapService has no geo, try to load from ng2 service
    $scope.featureCount = mapService.getNumGeoFigures($scope.mapMode);
    if ($scope.featureCount === 0 || $scope.featureCount === '0') {
      var ng2data = ng2MapObjectsService.getSavedAsFeatureGroup(MapModesEnum.DRAW);
      if (ng2data) {
        ng2data.eachLayer((l) => {
          // Update counter of MapController
          if (l.options.skogskadeId) {
            var idInt = parseInt(l.options.skogskadeId);
            if (idInt > $scope.skogskadeIdCounter) {
              $scope.skogskadeIdCounter = idInt;
            }
          }

          // Set findSite for all
          l.options[AppConfig.MAP_FEATURE_FINDSITE] = true;
        });

        // Make sure every layer has skogskadeId set.
        ng2data.eachLayer((l) => {
          if (!l.options?.skogskadeId) {
            $scope.generateIdentifier(l);
          }
        });
        mapService.setFeatureGroup(ng2data, $scope.mapMode);
      }
    }

    // Make sure all restoredFeatures are marked as findSites
    $scope.restoredFeatures = mapService.getFeatureGroup($scope.mapMode);
    var restoreCount = $scope.restoredFeatures?.getLayers().length;
    var flagCount = 0;
    if ($scope.restoredFeatures && $scope.restoredFeatures.getLayers()) {
      $scope.restoredFeatures.eachLayer((l) => {
        l.options[AppConfig.MAP_FEATURE_FINDSITE] = true;
        flagCount++;
      });
//      console.log('flagged ' + flagCount + '/' + restoreCount + ' restored');
    }

    /**
     * This saves any drawings marked for use as find sites
     * to ajs and ng2 map services and
     * then redirects back to the Form
     */
    $scope.goToSkadeForm = function () {
      var drawContr = null;
      $scope.tearingDownController = true;

      //Find drawControl
      angular.forEach(customControls, function (value) {
        if (value.options.draw) {
          drawContr = value;
        }
      });

      //var editing = drawContr.options.edit.featureGroup.getLayers()[0].editing._enabled;

      if (drawContr) {
        var selOptions = {
          dashArray: '10, 10',
          fill: true,
          fillColor: '#fe57a1',
          fillOpacity: 0.1,
          maintainColor: false,
        };

        leafletData.getMap().then(function (map) {
          // Hack for å lagre editering eller sletting
          var edit1 = new L.EditToolbar.Edit(map, {
            featureGroup: drawContr.options.edit.featureGroup,
            selectedPathOptions: selOptions,
          });
          edit1.save(); // Save Edits
          edit1.disable();
          var delete1 = new L.EditToolbar.Delete(map, {
            featureGroup: drawContr.options.edit.featureGroup,
            selectedPathOptions: selOptions,
          });
          delete1.save(); //Save deletes
          delete1.disable();
        });
      }

      // Start with "$scope.restoredFeatures", then iterate over current "drawnItems" to combine them, to make
      // sure we have all layers available to check and save
      var isOnlyFlaggedLayers = true;
      var newFindSites = $scope.restoredFeatures || new L.FeatureGroup();
      drawnItems.eachLayer((layer) => {
        var hasMatch = $scope.isLayerInGroup(newFindSites, layer);
        if (!newFindSites.getLayers().includes(layer) && !hasMatch) {
          newFindSites.addLayer(layer);
          isOnlyFlaggedLayers = false;
        }
      });

      $scope.saveDrawnItemsToNg2(newFindSites, isOnlyFlaggedLayers);
      haveSaveDraw = true;
      mapModeService.setMapMode('showSkogskader');
      ng2MapObjectsService.mapMode = 'showSkogskader';
      ng2Router.navigate(['meld-skade'], { fragment: 'area' });
    }; // end goToSkadeForm

    $scope.isLayerInGroup = function(layerGroup, layer) {
      return !!layerGroup.getLayers().find((existing) => {
        // Try to find match via skogskadeId
        if (
            Object.hasOwn(existing.options, 'skogskadeId') &&
            Object.hasOwn(layer.options, 'skogskadeId') &&
            existing.options.skogskadeId === layer.options.skogskadeId
        ) {
          return true;
        }


        // Try to find match using numbered place
        if (existing?.properties?.stedsnummer?.length > 0 && existing?.properties?.stedsnummer === layer?.properties?.stedsnummer) {
          return true;
        }

        // Match a single lat long, like a point/icon/marker
        if (Object.hasOwn(layer,'_latlng') && Object.hasOwn(existing, '_latlng')) {
          if (Object.hasOwn(layer['_latlng'],'lat') && Object.hasOwn(existing['_latlng'], 'lat')) {
            if (existing['_latlng']['lat'] === layer['_latlng']['lat'] && existing['_latlng']['lng'] === layer['_latlng']['lng']) {
              return true;
            }
          }
        }

        // Match multiple lat longs, like polylines, polygons and rectangles.
        // This is a crude check that only matches using the very first lat/lng pair
        if (Object.hasOwn(layer,'_latlngs') && Object.hasOwn(existing, '_latlngs')) {
          if (layer['_latlngs'].length > 0 && existing['_latlngs'].length > 0) {
            var matchLat = false;
            var matchLng = false;
            if (Object.hasOwn(layer['_latlngs'][0],'lat') && Object.hasOwn(existing['_latlngs'][0], 'lat')) {
              if (existing['_latlngs'][0]['lat'] === layer['_latlngs'][0]['lat']) {
                matchLat = true;
              }
            }
            if (Object.hasOwn(layer['_latlngs'][0],'lng') && Object.hasOwn(existing['_latlngs'][0], 'lng')) {
              if (existing['_latlng'][0]['lng'] === layer['_latlng'][0]['lng']) {
                matchLng = true;
              }
            }

            if (matchLat && matchLng) {
              return true;
            }
          }
        }

        // No match found
        return false;
      });
    }

    $scope.saveDrawnItemsToNg2 = function(drawnItems, excludeNonFlagged = false) {
      var removeCount = 0;
      var saveCount = 0;
      drawnItems.eachLayer(function (layer) {
        // console.log(`checking candidate for save leaflet id`, layer['_leaflet_id'], layer['options']);
        if (excludeNonFlagged && !layer.options[AppConfig.MAP_FEATURE_FINDSITE] || layer.options[AppConfig.MAP_FEATURE_FINDSITE] !== true) {
          drawnItems.removeLayer(L.stamp(layer));
          removeCount++;
          // console.log(`falsy flag`, layer.options, layer.options[AppConfig.MAP_FEATURE_FINDSITE], excludeNonFlagged);
        } else {
          saveCount++;
        }
      });
      // console.log('removed ' + removeCount + ', saving ' + saveCount + ' findSites to ng2. DrawnItems length:', drawnItems.getLayers().length);
      ng2MapObjectsService.saveObjects(drawnItems, $scope.mapMode, excludeNonFlagged);
    }

    //Lagre tegnede objekter
    leafletData.getMap().then(function (map) {
      if (drawnItems) {
        map.addLayer(drawnItems);
      }
      map.on('draw:created', function (e) {
        var layer = e.layer;
        $scope.generateIdentifier(layer);
        layer.options.type = e.layerType;
        layer.options[AppConfig.MAP_FEATURE_FINDSITE] = true;
        drawnItems.addLayer(layer);
        mapService.setFeatureGroup(drawnItems);
        $scope.drawnObjects = true;
        $scope.numFeatures = mapService.getNumGeoFiguresWithText($scope.mapMode);
      });

      map.on('draw:edited', function (e) {
        e.layers.eachLayer(function (layer) {
          if (layer['feature'] && layer.feature['id'] && layer.feature['id'].includes('n2000_komm_flate')) {
            delete layer.feature.properties.komid;
          }
          layer.options[AppConfig.MAP_FEATURE_FINDSITE] = true;
        });
        mapService.setFeatureGroup(drawnItems);
      });

      map.on('draw:deleted', function (e) {
        // remove the deleted from drawnItems:
        e.layers?.eachLayer((deleted) => {
          drawnItems.removeLayer(L.stamp(deleted));
        });

        // update other caches
        $scope.restoredFeatures = drawnItems;
        drawnItems.eachLayer((l) => {
          l.options[AppConfig.MAP_FEATURE_FINDSITE] = true;
        })

        // The deleted item might already be cached in ng2, so update:
        if (!$scope.tearingDownController) {
          $scope.saveDrawnItemsToNg2(drawnItems, true);
        }

        // update counters in map
        var ant = drawnItems.getLayers().length;
        if (ant > 0) {
          mapService.setFeatureGroup(drawnItems);
          $scope.numFeatures = mapService.getNumGeoFiguresWithText($scope.mapMode);
        } else {
          mapService.setFeatureGroup(null);
          $scope.drawnObjects = false;
        }
      });
    });

    $scope.toggleSearchFilter = function () {
      //If search filed already is activ set it to hidden
      if ($scope.filterParam.showSearchFilter === true) {
        $scope.filterParam.showSearchFilter = false;
        $('#searchInFilter').removeClass('bi-chevron-double-left').addClass('bi-chevron-double-right');
        $('#searchInFilterParent').prop('title', 'Vis rapportfilter');
      } else {
        $scope.filterParam.showSearchFilter = true;
        $('#searchInFilter').removeClass('bi-chevron-double-right').addClass('bi-chevron-double-left');
        $('#searchInFilterParent').prop('title', 'Skjul rapportfilter');
        // Hide the searchField field, in order to not have bothe open at the same time
        if ($scope.showSearchField === true) {
          $scope.showSearchField = false;
          $('#searchInMap').css('border', 'none');
        }
      }
      $scope.$apply;
    };

    $scope.toggleSearchField = function () {
      // Hide search field if already active, else hide filters and show search
      if ($scope.showSearchField === true) {
        $scope.showSearchField = false;
      } else {
        $scope.showSearchField = true;
        $scope.filterParam.showSearchFilter = false;
        $('#searchInFilter').removeClass('bi-chevron-double-left').addClass('bi-chevron-double-right');
        $('#searchInFilterParent').prop('title', 'Vis rapportfilter');
        setTimeout(function() {
          $('#scrollable-dropdown-menu').find('input').focus();
        }, 100);
      }
      $scope.$apply;
    };

    var searchMarker = null;
    var myMap = null;
    var lc = null;
    var info2popup = '';
    var report_ids = [];
    $scope.wmsInitialized = false;
    var haveSaveDraw = false;

    $rootScope.$on('addFeatureAsSearchSite', function (event, value) {
      var antall = mapService.getNumGeoFigures($scope.mapMode);

      if (antall >= 1) {
        $scope.drawnObjects = true;
        $scope.numFeatures = mapService.getNumGeoFiguresWithText($scope.mapMode);
      }
    });
    //
    // Start doing stuff
    //

    //clean array with active layers since we dont't store checked overlay
    mapService.resetActiveLayerList();
    $scope.filteredReports = { filtered: [] };
    leafletData.getMap().then(function (map) {
      myMap = map;
      if (mapService.getSearchMarker() !== null) {
        // restore marker point from mapservice
        searchMarker = L.circleMarker(mapService.getSearchMarker(), searchMarkerStyle);
        searchMarker.addTo(myMap);
      }
      myMap.on('overlayadd', function (e) {
        mapService.setActiveLayer(e);
      });
      myMap.on('overlayremove', function (e) {
        mapService.deleteActiveLayer(e);
      });
      L.control.scale({ imperial: false }).addTo(map);
      if (!$scope.wmsInitialized && $scope.mapMode !== 'drawOnMap') {
        addWmsLayersTo(myMap, reportFilterService.getFilterValues());
        $scope.wmsInitialized = true;
      }

      lc = L.control.locate().addTo(map);
      lc._container.hidden = true;
      map.on('locationfound', function () {
        // console.log('locationFound --> lc.stop()');
        lc.stop();
      });
    });

    if ($routeParams.reports) {
      // Show a list of reports in map
      $scope.reports = $routeParams.reports;
    }
    if ($routeParams.mode) {
      $scope.mapMode = $routeParams.mode;
      mapModeService.setMapMode($routeParams.mode);
    } else {
      $scope.mapMode = mapModeService.getMapMode(); // Henter fra service hvilket modus kartet skal ha
    }

    $scope.hideDrawMessage = false;
    $scope.drawnObjects = false;

    var bounds = null;

    $scope.destroyMap = function() {
      var mapContainer = document.getElementById('mapContainer');

      if($scope.simplemap)
      {
        $scope.simplemap = null;
      }
      if (mapContainer) {
        mapContainer.innerHTML = '';
      }
    }

    $scope.setMunicipality = function (fylke, kommune, aust, nord) {
      var komNR = mapService.getMunicipalityNR(fylke, kommune, aust, nord);
      if (!komNR) {
        alert('Fant ikke kommune');
      } else {
        $scope.showMunicipality(komNR, aust, nord);
      }
    };

    $scope.showMunicipality = function (komNR, aust, nord) {
      var geoserverUrl =
        skogskadeGlobalOptions.serviceUrl +
        '/geoserver/ows?srsname=EPSG:4258&format_options=decimals:1' +
        '&service=WFS&version=1.0.0&outputFormat=json&request=GetFeature' +
        '&typeName=sl:n2000_komm_flate&cql_filter=komid=%27' +
        komNR +
        '%27&';
      $http
        .get(geoserverUrl)
        .error(function (data) {
          alert('Fant ikke kommunegrense');
        })
        .success(function (komdata, status) {
          if (komdata.totalFeatures === 0) {
            alert('Fant ikke kommunegrense, viser punkt');
            $scope.setMarker(aust, nord);
          } else {
            if ($scope.mapMode === 'drawOnMap') {
              require('@ajs/js/map/leaflet.draw.local.js');
              var komLayerArr = L.geoJson(komdata).getLayers();
              angular.forEach(komLayerArr, function (value, key) {
                //keep properties from kommunegrenser because Backend use this if
                //border not is changed
                //delete value.feature.properties;
                delete value.feature.geometry_name;
                value.options[AppConfig.MAP_FEATURE_FINDSITE] = false;
                value.addTo(drawnItems);
              });
              myMap.fitBounds(drawnItems.getBounds());
              mapService.setFeatureGroup(drawnItems);
            } else {
              // Not drawMode
              $scope.removeSearchMarker();
              var kommuneStyle = {
                color: '#00f',
                fillOpacity: 0,
                weight: 5,
                opacity: 0.8,
              };
              var kommuneLayer = L.geoJson(null, { style: kommuneStyle });
              kommuneLayer.addData(komdata);

              searchMarker = kommuneLayer;
              myMap.fitBounds(kommuneLayer.getBounds());
              L.featureGroup([kommuneLayer])
                //.bindPopup('Hello world!')
                /*
                 .on('click', function (e) {
                 skogskadeClick(null,e);
                 })*/
                .addTo(myMap);
            }
          }
        });
    };

    $scope.setMarker = function (east, north) {
      var latitude = Number(north);
      var longitude = Number(east);
      var point = [latitude, longitude];
      var customOption = {
        [AppConfig.MAP_FEATURE_FINDSITE]: false,
        skogskadeId: ++$scope.skogskadeIdCounter,
      };
      if ($scope.mapMode === 'drawOnMap') {
        L.marker(point, customOption).addTo(drawnItems);
        mapService.setFeatureGroup(drawnItems);
        $scope.$apply(function () {
          $scope.center = {
            lat: latitude,
            lng: longitude,
            zoom: 12,
          };
        });
        //$scope.zoomToFeature(layer);
      } else {
        //first remove previous Marker:
        $scope.removeSearchMarker();
        searchMarker = L.circleMarker(point, searchMarkerStyle);
        searchMarker.addTo(myMap);
        mapService.setSearchMarker(point);
        $scope.$apply(function () {
          $scope.center = {
            lat: latitude,
            lng: longitude,
            zoom: 12,
          };
        });
      }
    };

    $scope.removeSearchMarker = function (east, north) {
      if (searchMarker !== null) {
        myMap.removeLayer(searchMarker);
        mapService.setSearchMarker(null);
      }
    };

    if (localStorage) {
      $scope.hideDrawMessage = localStorage.getItem('hideDrawMessage');
      if (($scope.hideDrawMessage === 'false' || $scope.hideDrawMessage === null) && $scope.mapMode === 'drawOnMap') {
        $('#mapModal').modal('show');
      }
    }

    L.Control.skogskadeLocate = L.Control.Locate.extend({
      _drawMarker: function (map) {
        var blueIcon = L.icon({
          iconUrl: 'ajs/img/marker_blue_circle.png',
          iconSize: [16, 16],
          iconAnchor: [8, 8],
          popupAnchor: [0, 0],
        });

        if (this._event.accuracy === undefined) {
          this._event.accuracy = 0;
        }
        var radius = this._event.accuracy;
        if (this._locateOnNextLocationFound) {
          if (this._isOutsideMapBounds()) {
            this.options.onLocationOutsideMapBounds(this);
          } else {
            map.fitBounds(this._event.bounds, {
              padding: this.options.circlePadding,
              maxZoom: this.options.keepCurrentZoomLevel ? map.getZoom() : this.options.locateOptions.maxZoom,
            });
          }
          this._locateOnNextLocationFound = false;
        }
        // circle with the radius of the location's accuracy
        var style, o;
        if (this.options.drawCircle) {
          if (this._following) {
            style = this.options.followCircleStyle;
          } else {
            style = this.options.circleStyle;
          }
          if (!this._circle) {
            this._circle = L.circle(this._event.latlng, radius, style).addTo(this._layer);
          } else {
            this._circle.setLatLng(this._event.latlng).setRadius(radius);
            for (o in style) {
              this._circle.options[o] = style[o];
            }
          }
        }
        var distance, unit;
        if (this.options.metric) {
          distance = radius.toFixed(0);
          unit = 'meters';
        } else {
          distance = (radius * 3.2808399).toFixed(0);
          unit = 'feet';
        }
        // small inner marker
        var mStyle;
        if (this._following) {
          mStyle = this.options.followMarkerStyle;
        } else {
          mStyle = this.options.markerStyle;
        }

        if (!this._marker) {
          // Her legges det til et punkt i kartet ala tegning

          if ($scope.mapMode === 'drawOnMap') {
            drawnItems.eachLayer(function (layer) {
              if (layer.options.geoloc) {
                // Remove other geoloc objects
                drawnItems.removeLayer(layer);
              }
            });
            var newMarker = L.marker(this._event.latlng);
            newMarker.options.geoloc = true;
            newMarker.addTo(drawnItems);
            //var markergeoJson = JSON.stringify(L.marker(this._event.latlng).toGeoJSON());
            mapService.setFeatureGroup(drawnItems);
            $scope.saveDrawnItemsToNg2(drawnItems);
            $scope.drawnObjects = true;
            $scope.numFeatures = mapService.getNumGeoFiguresWithText($scope.mapMode);
            this._marker = newMarker;
          } else {
            this._marker = L.marker(this._event.latlng, mStyle).addTo(this._layer);
          }
        } else {
          this._marker = L.marker(this._event.latlng, mStyle);
        }
        this._marker.setIcon(blueIcon);
        var t = this.options.strings.popup;
        if (this.options.showPopup && t) {
          if (this._marker) {
            this._marker
              .bindPopup(
                L.Util.template(t, {
                  distance: distance,
                  unit: unit,
                }),
              )
              ._popup.setLatLng(this._event.latlng);
          }
        }
        //  this._toggleContainerStyle();
      },
    });

    var locateControl = new L.Control.skogskadeLocate({
      position: 'topright',
      icon: 'leaflet-icon glyphicon glyphicon-record',
      // locateOptions: {
      //   enableHighAccuracy: true
      // },
      strings: {
        title: 'Vis meg hvor jeg er', // title of the locate control
        popup: 'Du er innenfor {distance} {unit} fra dette punktet', // text to appear if user clicks on circle
        outsideMapBoundsMsg: 'Ser ut til at du er utenfor grensene for dette kartet', // default message for onLocationOutsideMapBounds
      },
    });

    /*
     Put a hooks on L.WMS.Source its getFeatureInfo, we can then add new functionaliy
     */
    if (L.WMS) {
      L.WMS.Source = L.WMS.Source.extend({
        getFeatureInfo: function (point, latlng, layers, callback) {
          var params = this.getFeatureInfoParams(point, layers);
          var infoUrl = this._url + L.Util.getParamString(params, this._url);
          this.showWaiting();
          this.ajax(infoUrl, done);

          function done(result) {
            this.hideWaiting();
            var text = this.parseFeatureInfo(result, url);
            // start section for new code
            var parser, xmlDoc;
            var url = skogskadeGlobalOptions.reportURL;
            if (result) {
              parser = new DOMParser();
              xmlDoc = parser.parseFromString(result, 'text/xml');
              var checkXml =
                xmlDoc.documentElement.nodeName == 'parsererror'
                  ? 'error while parsing'
                  : xmlDoc.documentElement.nodeName;
              if (
                checkXml != 'ServiceExceptionReport' &&
                checkXml &&
                xmlDoc.getElementsByTagName(checkXml)[0].childNodes.length > 1
              ) {
                var nodes = 0;
                // only one vil have value except polygon and polygoncenter, but we handled this
                // se next comment
                var antallNoderPolygon = xmlDoc.getElementsByTagName('overview_polygon_feature') || [];
                var antallNoderPoint = xmlDoc.getElementsByTagName('overview_point_feature') || [];
                var antallNoderLine = xmlDoc.getElementsByTagName('overview_line_feature') || [];

                nodes += antallNoderPolygon.length >= 1 ? antallNoderPolygon.length : 0;
                nodes += antallNoderPoint.length >= 1 ? antallNoderPoint.length : 0;
                nodes += antallNoderLine.length >= 1 ? antallNoderLine.length : 0;
                if (nodes === 0) {
                  return;
                }

                for (var i = 0; i < nodes; i++) {
                  var diagnoses = xmlDoc.getElementsByTagName('diagnosis_list')[i].childNodes[0].nodeValue;
                  var report_id = xmlDoc.getElementsByTagName('report_id')[i].childNodes[0].nodeValue;
                  if ($.inArray(report_id, report_ids) === -1) {
                    info2popup +=
                      '<p><b>' +
                      diagnoses +
                      '</b><br>' +
                      '<a href=' +
                      url +
                      report_id +
                      '>Se rapport nr. ' +
                      report_id +
                      '</a></p>';
                    report_ids.push(report_id);
                  }
                }
              } else {
                if (info2popup === '') {
                  info2popup = 'Ingen treff';
                }
              }
              if (info2popup.length > 20) {
                var substring = 'Ingen treff';
                if (info2popup.indexOf(substring) !== -1) {
                  info2popup = info2popup.replace(substring, '');
                }
              }
            }
            // End section for new code
            callback.call(this, latlng, info2popup);
          }
        },
      });
    }
    var ownLegends = legendService.getLegendConfig();

    function addWmsLayersTo(map, filterValues) {
      var before = 0;
      map.eachLayer(function () {
        before += 1;
      });
      if ($scope.wmsInitialized) {
        $scope.skogskadeSource.removeSubLayer('overview_reports');
        $scope.skogskadeLayer.remove();
        map.removeLayer($scope.skogskadeLayer);
      }

      var diagnosis = filterValues.textSearch;
      var fromDate = dateHelper.dateToString(filterValues.fromDate);
      var toDate = dateHelper.dateToString(filterValues.toDate);
      var place = filterValues.placeSearch;
      var wmsUrl = skogskadeGlobalOptions.serviceUrl + skogskadeGlobalOptions.serviceUrlProxy;
      var wmsConfig = {
        format: 'image/png',
        transparent: 'true',
        attribution: 'NIBIO',
        info_format: 'application/vnd.ogc.gml',
        FROMDATE: fromDate,
        TODATE: toDate,
        KOMMUNE: place,
        DIAGNOSIS: diagnosis,
        tiled: false,
        FEATURE_COUNT: 1000,
        maxZoom: 21,
        opacity: 0.75,
      };

      define(['leaflet.wms'], function (wms) {
        var reportsSource = wms.source(wmsUrl, wmsConfig);

        var reportsLayer = reportsSource.getLayer('overview_reports');
        reportsLayer.addTo(map);
        $scope.skogskadeLayer = reportsLayer;
        $scope.skogskadeSource = reportsSource;
        map.on('click', function (e) {
          info2popup = '';
          report_ids = [];
        });
      });
    }

    function getLengendContent() {
      var legendTable = legendService.getLegendTable();
      var overlays = $scope.layers.overlays;
      for (var layer in overlays) {
        if (overlays[layer].hasOwnProperty('legendUrl') && mapService.checkLayerIsActive(overlays[layer].name)) {
          var l = overlays[layer];
          legendTable += '<div>';
          legendTable += '<div class="legendsImgHeading">' + l.name + '</div>';
          if (layer === 'vern') {
            legendTable += '<div> <img  class="legendImgVern" src="' + l.legendUrl + '" /></div>';
          } else {
            legendTable += '<div> <img  class="legendImg" src="' + l.legendUrl + '" /></div>';
          }

          legendTable += '</div>';
        }
      }
      return legendTable;
    }

    // Configuration for  legends that is created by this application and not available from
    // GetLegendGraphic requests to wms-servers

    var legendButtonHtml =
      '<div class="legend"><span alt="Tegnforklaring" class="legendicon glyphicon glyphicon-info-sign"></span></div>';

    var skLegend = L.control({ position: 'topright' });

    var legendElement = L.DomUtil.create('div', 'legend-container');

    legendElement.innerHTML += legendButtonHtml;

    skLegend.onAdd = function (map) {
      var onmouseenterHandler = function (e) {
        // Change the inner html to be legend for the maps

        legendElement.innerHTML = '<div class="legend-popup" alt="Tegnforklaring">' + getLengendContent() + '</div>';
        // remove the onmousenterhandler, the clikc event is needed in order to work on touchdevice
        L.DomEvent.removeListener(legendElement, 'mouseenter', onmouseenterHandler);
        L.DomEvent.removeListener(legendElement, 'click', onmouseenterHandler);

        // add the mouseleavehandler, the clikc event is needed in order to work on touchdevice
        L.DomEvent.addListener(legendElement, 'mouseleave', onmouseleaveHandler);
        L.DomEvent.addListener(legendElement, 'click', onmouseleaveHandler);
        L.DomEvent.stopPropagation(e);
      };

      var onmouseleaveHandler = function (e) {
        // set inner html back to button look
        legendElement.innerHTML = legendButtonHtml;
        // swap handlers again
        L.DomEvent.removeListener(legendElement, 'mouseleave', onmouseleaveHandler);
        L.DomEvent.removeListener(legendElement, 'click', onmouseleaveHandler);

        L.DomEvent.addListener(legendElement, 'mouseenter', onmouseenterHandler);
        L.DomEvent.addListener(legendElement, 'click', onmouseenterHandler);
        L.DomEvent.stopPropagation(e);
      };
      // Add handlers to call on mousenter and click (need click touchdevice)
      L.DomEvent.addListener(legendElement, 'mouseenter', onmouseenterHandler);
      L.DomEvent.addListener(legendElement, 'click', onmouseenterHandler);
      return legendElement;
    }; // end skLegend.onAdd

    //add zoom control with your options
    var zoomControl = new L.Control.Zoom({ zoomInTitle: 'Zoom inn', zoomOutTitle: 'Zoom ut', position: 'topright' });

    var customControls = [zoomControl, locateControl, skLegend];

    /* Se dokumentasjon her https://github.com/michaelguild13/Leaflet.draw */
    if ($scope.mapMode === 'drawOnMap') {
      $scope.use_filter = false;
      var drawnItems = new L.FeatureGroup();
      var options = {
        edit: {
          featureGroup: drawnItems,
        },
        draw: {
          circle: false,
          circlemarker: false,
        },
      };
      var drawControl = new L.Control.Draw(options);
      customControls = [drawControl, zoomControl, locateControl, skLegend];
    }

    // http://wms01utv.ad.skogoglandskap.no/cgi-bin/skogskader?FROMDATE=08.09.1986&TODATE=10.09.2017&KOMMUNE=andebu&&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&BBOX=502079.0559379605111,6529217.736477248371,636287.9387609034311,6617516.914028517902&CRS=EPSG:32632&WIDTH=916&HEIGHT=602&LAYERS=overview_line,overview_point,overview_polygon&STYLES=,,&FORMAT=image/png&DPI=96&MAP_RESOLUTION=96&FORMAT_OPTIONS=dpi:96&TRANSPARENT=TRUE
    var mapConfig = mapService.getMapConfig(customControls);
    angular.extend($scope, mapConfig);
    //ta vare på kartutsnittet for ulike kartmodus
    if (mapService.getCenterForMode($scope.mapMode)) {
      $scope.center = mapService.getCenterForMode($scope.mapMode);
    }

    $scope.$watch('center', function () {
      mapService.setCenterForMode($scope.mapMode, $scope.center);
    });

    if ($scope.mapMode === 'drawOnMap') {
      //Hente inn objekter fra mapservice når kartfane lastes
      leafletData.getMap().then(function (map) {
        var prevObjects = mapService.getFeatureGroup($scope.mapMode);
        // If AngularJs MapService has no objects, try to get them from ng2MapObjectsService:
        if (prevObjects === null || prevObjects?.length < 1) {
          prevObjects = ng2MapObjectsService.getSavedAsFeatureGroup($scope.mapMode);
        }

        if (prevObjects) {
          var layerArr = prevObjects.getLayers();
          angular.forEach(layerArr, function (value, key) {
            if (value instanceof L.Marker) {
              var prevLayer = value.toGeoJSON();
              drawnItems.addLayer(L.GeoJSON.geometryToLayer(prevLayer));
            } else {
              drawnItems.addLayer(value);
            }
          });
          map.addLayer(drawnItems);
          $scope.drawnObjects = true;
          $scope.numFeatures = mapService.getNumGeoFiguresWithText($scope.mapMode);
        }
      });
    }

    $scope.$watch('center', function () {
      mapService.setCenterForMode($scope.mapMode, $scope.center);
    });

    $scope.zoomToFeature = function (layer) {
      leafletData.getMap().then(function (map) {
        if (layer.getBounds()) {
          map.fitBounds(layer.getBounds());
        } else {
          map.panTo(layer._latlng);
        }
        map.fitBounds(layer.getBounds());
      });
    };

    $scope.hideDrawMessageFunc = function () {
      //console.log('hideDrawMessageFunc:'+ $scope.hideDrawMessage);
      $scope.hideDrawMessage = true;
      localStorage.setItem('hideDrawMessage', true);
      $('#mapModal').modal('hide');
    };

    $scope.$on('$viewContentLoaded', function () {
      // Setter størrelsen på kartet etter at kartsiden er lastet.
      /*Do this because delete white space in bottom on phone and avoid scrolling*/
      var navbar = mapService.getHeaderSize($scope.mapMode);
      var mapHeight = jQuery(window).height() - navbar;
      jQuery('.angular-leaflet-map').height(mapHeight);
      jQuery('.angular-leaflet-map').width(jQuery(document.body).width());
    });

    jQuery(window).resize(function () {
      var navbar = mapService.getHeaderSize($scope.mapMode);
      var mapHeight = jQuery(window).height() - navbar;
      jQuery('.angular-leaflet-map').height(mapHeight);
      jQuery('.angular-leaflet-map').width(jQuery(document.body).width());
    });

    // Set the watchers on filterService if we are using filter
    if ($scope.use_filter && $scope.mapMode !== 'drawOnMap') {
      $scope.$watch(
        function () {
          return reportFilterService.getFilterValues().changes;
        },
        function (newVal, oldVal) {
          if (!$scope.wmsInitialized || (!angular.equals(newVal, oldVal) && newVal !== null)) {
            if (myMap) {
              addWmsLayersTo(myMap, reportFilterService.getFilterValues());
              if (!$scope.wmsInitialized) {
                $scope.wmsInitialized = true;
              }
            }
          }
        },
      );
    }

    /*
     Have to clean up if backbutton or switch of tab have been used
     */
    $scope.$on('$destroy', function () {
      if (!haveSaveDraw) {
        $scope.drawnObjects = false;
        mapService.setFeatureGroup(null, $scope.mapMode);
        // Trigger a new save which should filter out any searched but not added as findSite.
      }

      leafletData.getMap().then(function (map) {
        map.off('draw:deleted');
      });


      $scope.destroyMap(event);
    }); // end $scope.$on

    /**
     * Currently unused code we might need later has been moved beneath this line
     */

    //
    //  function pointInPoint(latlng, layerslist) {
    //    var resultsPoint = [];
    //    $.each(layerslist, function (index, value) {
    //      if (
    //        value.feature.geometry.type === 'Point' &&
    //        value._latlng.lat === latlng.lat &&
    //        value._latlng.lng === latlng.lng
    //      ) {
    //        resultsPoint.push(value);
    //      }
    //    });
    //    return resultsPoint;
    //  }
    //
    //  function fillUpPopupHtml(results) {
    //    var popupHtml = '<div class="report_popup_list">';
    //    $.each(results, function (index, feature) {
    //      popupHtml +=
    //        '<p>' +
    //        feature.feature.properties.diagnosis +
    //        '<br /><a href="' +
    //        skogskadeGlobalOptions.reportURL +
    //        feature.feature.properties.report_id +
    //        '">Se rapport nr. ' +
    //        feature.feature.properties.report_id +
    //        '</a></p>';
    //    });
    //    return popupHtml + '</div>';
    //  }

    //    function skogskadeClick(event) {
    //      var layer = event.layer || event.target;
    //      var popup = '';
    //      var popupHtml = '';
    //      var pipLayer = L.geoJson($scope.geojson.data, {});
    //      var results = leafletPip.pointInLayer(event.latlng, pipLayer, false);
    //      var layersFromPipLayer = pipLayer._layers;
    //      var feature = event.target.feature;
    //      if (!feature) {
    //        feature = event.layer.feature;
    //      }
    //
    //      if (feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiPolygon') {
    //        //Check if leafletPip.pointIn Layer have found any match, if not I use
    //        //event.target.feature to secure that popup is not emty.
    //
    //        if (results.length > 0) {
    //          popupHtml = fillUpPopupHtml(results);
    //        } else {
    //          popupHtml =
    //            '<p>' +
    //            feature.properties.diagnosis +
    //            '<br /><a href="' +
    //            skogskadeGlobalOptions.reportURL +
    //            feature.properties.report_id +
    //            '">Se rapport nr. ' +
    //            feature.properties.report_id +
    //            '</a></p>';
    //        }
    //
    //        if (feature) {
    //          // Else there is a kommunepolygon
    //          layer.setStyle({
    //            weight: 2,
    //            color: '#666',
    //            fillColor: 'blue',
    //          });
    //        }
    //
    //        popup = L.popup().setLatLng(event.latlng).setContent(popupHtml).openOn(event.target._map);
    //      } else if (feature.geometry.type === 'Point' || feature.geometry.type === 'LineString') {
    //        var resultsPoint = pointInPoint(event.latlng, layersFromPipLayer);
    //        popupHtml = fillUpPopupHtml(resultsPoint);
    //        popup = L.popup().setLatLng(event.latlng).setContent(popupHtml).openOn(event.target._map);
    //      }
    //    }
    //
    //    function skogskadeMouseover(leafletEvent) {
    //      var layer = leafletEvent.layer || leafletEvent.target;
    //      if (layer.feature.geometry.type === 'Polygon') {
    //        layer.setStyle({
    //          weight: 2,
    //          color: '#666',
    //          fillColor: 'white',
    //        });
    //        layer.bringToFront();
    //      }
    //    }
    //
    //    function skogskadeMouseout(leafletEvent) {
    //      var layer = leafletEvent.layer || leafletEvent.target;
    //      if (layer.feature.geometry.type === 'Polygon') {
    //        layer.setStyle({
    //          weight: 2,
    //          color: '#666',
    //          fillColor: 'red',
    //        });
    //        layer.bringToFront();
    //      }
    //    }



    //    function zoomToGeoFeatures(feature, layer) {
    //      if ($scope.mapMode !== 'drawOnMap') {
    //        layer.on({
    //          click: skogskadeClick,
    //        });
    //        layer.on({
    //          mouseover: skogskadeMouseover,
    //        });
    //        layer.on({
    //          mouseout: skogskadeMouseout,
    //        });
    //      }
    //      if ($scope.mapMode === 'showReport') {
    //        if (feature.geometry.type === 'Point') {
    //          var pointbounds = new L.LatLngBounds(
    //            [feature.geometry.coordinates[1] + 0.1, feature.geometry.coordinates[0] + 0.1],
    //            [feature.geometry.coordinates[1] - 0.1, feature.geometry.coordinates[0] - 0.1],
    //          );
    //
    //          if (bounds !== null) {
    //            bounds.extend(pointbounds);
    //          } else {
    //            bounds = pointbounds;
    //          }
    //        } else if (feature.geometry.type === 'Polygon' || feature.geometry.type === 'LineString') {
    //          if (bounds !== null) {
    //            bounds.extend(layer.getBounds());
    //          } else {
    //            bounds = layer.getBounds();
    //          }
    //          // zoom a bit out from extent of  polygons
    //          var newBounds = new L.LatLngBounds(
    //            [bounds.getSouth() + 0.01, bounds.getWest() + 0.01],
    //            [bounds.getNorth() - 0.01, bounds.getEast() - 0.01],
    //          );
    //          bounds = newBounds;
    //        }
    //        leafletData.getMap().then(function (map) {
    //          map.fitBounds(bounds);
    //        });
    //      }
    //    }

    //    function createLayer(title, id, geoJSON, drawnItems) {
    //      L.geoJson(geoJSON, {
    //        pointToLayer: function(feature, latlng) {
    //          if (feature.properties.radius) {
    //            return new L.Circle(latlng, feature.properties.radius);
    //          }
    //          return;
    //        }
    //      }).eachLayer(function(layer) {
    //        layer.bindPopup(title);
    //        layer.options.color = "#3c8dbc";
    //        layer.options.weight = 2;
    //        layer.addTo(drawnItems);
    //      });
    //    }


    // /* Se dokumentasjon her https://github.com/domoritz/leaflet-locatecontrol*/
    // var locateControl = new L.Control.skogskadeLocate({
    //     position: 'topright',
    //     icon: 'leaflet-icon',
    //     iconLoading: 'leaflet-icon',
    //     createButtonCallback: function (container, options) {
    //       console.log('creating btn', container, options);
    //       var link = L.DomUtil.create(
    //         'a', 'leaflet-bar-part leaflet-bar-part-single', container
    //       );
    //       link.title = options.strings.title;
    //       var icon = L.DomUtil.create(options.iconElementTag, options.icon, link);
    //       return { link: link, icon: icon };
    //     },
    //     locateOptions: {
    //       // timeout: 3000,
    //       // maximumAge: 3000,
    //       enableHighAccuracy: true
    //     },
    //     onLocationError: function(err) {alert(err.message)},
    //     strings: {
    //         title: "Vis meg hvor jeg er",  // title of the locate control
    //         popup: "Du er innenfor {distance} {unit} fra dette punktet",  // text to appear if user clicks on circle
    //         outsideMapBoundsMsg: "Ser ut til at du er utenfor grensene for dette kartet" // default message for onLocationOutsideMapBounds
    //     }
    //
    // });
  }
})(angular.module('skogskadeControllers'));
