import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { BreadcrumbType } from '@apptypes/breadcrumb.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterLink],
  selector: 'app-breadcrumbs',
  standalone: true,
  styleUrls: ['./breadcrumbs.component.scss'],
  templateUrl: './breadcrumbs.component.html',
})
export class BreadcrumbsComponent {
  @Input()
  breadcrumbs: BreadcrumbType[] = [];

  @HostBinding('class.show')
  get shouldShow(): boolean {
    return this.breadcrumbs?.length > 0;
  }
}
