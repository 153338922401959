import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  selector: 'app-error404',
  standalone: true,
  styleUrls: ['./error404.component.scss'],
  templateUrl: './error404.component.html',
})
export default class Error404Component {}
