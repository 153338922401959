import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { ArrayHelper } from '@core/helpers/array.helper';
import { ObjectHelper } from '@core/helpers/object.helper';
import { SelectCollectionItemsOptionsType } from '@modals/select-collection-items/select-collection-items-options.type';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { auditTime, BehaviorSubject, distinctUntilChanged, Observable, Subject, tap } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FormsModule],
  selector: 'app-select-collection-items-modal',
  standalone: true,
  styleUrls: ['./select-collection-items-modal.component.scss'],
  templateUrl: './select-collection-items-modal.component.html',
})
export class SelectCollectionItemsModalComponent<ItemType extends object> implements OnInit {
  @Input()
  collection: ItemType[] = [];

  @Input()
  selected: ItemType[] = [];

  @Input()
  set options(data: SelectCollectionItemsOptionsType<ItemType>) {
    this.config = ObjectHelper.nestedAssign(this.config, data);
    this.sortBy = [
      this.config.fields.orderBy1?.toString() || this._orderBy1,
      this.config.fields.orderBy2?.toString() || this._orderBy2,
    ];
  }

  private readonly _destroyRef = inject(DestroyRef);
  private readonly _orderBy1 = 'order_by';
  private readonly _orderBy2 = 'label';

  collectionFiltered$!: Observable<ItemType[]>;
  config: SelectCollectionItemsOptionsType<ItemType> = {
    fields: {
      children: 'children',
      comparison: 'id',
      expanded: 'expand',
      label: 'label',
      orderBy1: this._orderBy1,
      orderBy2: this._orderBy2,
      search: 'label',
    },
    labels: {
      item: 'oppføring',
      itemPlural: 'oppføringer',
      title: 'Velg oppføringer',
    },
  };
  filterInput = '';
  sortBy = [this.config.fields.orderBy1?.toString() as string, this.config.fields.orderBy2?.toString() as string];

  private readonly _collectionSubject$ = new BehaviorSubject<ItemType[]>([]);

  private _searchCriteriaSubject$ = new Subject<string>();

  constructor(
    public modal: NgbActiveModal,
    private readonly _cdr: ChangeDetectorRef,
  ) {}

  filter(): void {
    this._searchCriteriaSubject$.next(this.filterInput);
  }

  ngOnInit(): void {
    this.collectionFiltered$ = this._collectionSubject$.asObservable();
    this._collectionSubject$.next(ArrayHelper.nestedSort(this.collection, 'children', this.sortBy));

    this._searchCriteriaSubject$
      .pipe(
        auditTime(200),
        distinctUntilChanged(),
        takeUntilDestroyed(this._destroyRef),
        tap(term => {
          let filtered = this.collection;
          if (term?.length > 0) {
            filtered = ArrayHelper.findRecursive<ItemType>(
              term,
              this.collection,
              'includesString',
              this.config.fields.search.toString(),
              'children',
            );
          }
          this._collectionSubject$.next(ArrayHelper.nestedSort(filtered, 'children', this.sortBy));
        }),
      )
      .subscribe();

    setTimeout(() => {
      document.getElementById('collection-tree-filter')?.focus();
    }, 1);
  }

  toggleSelected(item: ItemType): void {
    const idx = this.selected.findIndex(
      ex => ex[this.config.fields.comparison.toString()] === item[this.config.fields.comparison.toString()],
    );
    if (idx > -1) {
      this.selected.splice(idx, 1);
    } else {
      this.selected.push(item);
    }
    this._cdr.detectChanges();
  }
}
