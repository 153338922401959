<!-- Modal: Terms of use -->
<div class="modal-header">
  <h1 class="modal-title fs-5" id="modalHeaderTitle">Vilkår</h1>
  <button (click)="modal.dismiss('Cross click')" aria-label="Lukk" class="btn-close" type="button"></button>
</div>
<div class="modal-body">
  <h2 class="fs-6">
    Standardvilkår for rapport og bilder lastet inn på skogskader.no, Norsk Institutt for BIOøkonomi (NIBIO)
  </h2>

  <h2>Opplasting av rapport</h2>
  <p>
    Navn på personen som har observert skaden (observatør), e-post og ev. telefonnummer vil bli lagret hos Norsk
    institutt for bioøkonomi (NIBIO). Vi trenger observatørens e-postadresse og ev. telefonnummer for eventuelt å kunne
    innhente flere opplysninger om den innrapporterte skaden. E-post og telefonnummer vil ikke offentliggjøres på våre
    nettsider og lagres kun internt i databasen. Det er ikke obligatorisk å angi telefonnummer.<br /><br />
    Kommentarfeltet kan brukes til en nærmere beskrivelse av skaden, observerte symptomer, utbredelse i
    bestandet/kommunen, skogens/treets alder, del av treet, etc. En utførlig beskrivelse vil lette
    skadeidentifikasjonen.<br /><br />
    Det er ikke obligatorisk å fylle ut kommentarfeltet.
  </p>

  <h2>Opplasting av bilder</h2>
  <ol>
    <li>
      NIBIO får en ubegrenset rett til å bruke bildene som legges inn i
      <a href="https://skogskader.nibio.no">skogskader.nibio.no</a>.
    </li>
    <li>NIBIO har rett til å beskjære/endre bildene.</li>
    <li>
      NIBIO kan gi tredjemann rett til å bruke bildene, herunder å beskjære/endre dem, evt. på de vilkår NIBIO måtte
      ønske å sette.
    </li>
    <li>
      NIBIO sine rettigheter begrenser ikke fotografens rett til selv å bruke bildene sine, eller å la tredjemann bruke
      dem, så lenge bruken ikke kan være til skade/ulempe for NIBIO sin virksomhet.
    </li>
    <li>NIBIO har eiendomsretten til de bildefilene som legges inn i skogskader.no.</li>
    <li>
      Ved all bruk av bildene i skogskader.no skal bildene merkes slik: <br />
      «©: Fotografens navn/ NIBIO» i umiddelbar nærhet til bildet.
    </li>
  </ol>

  <h2>Nettstatistikk</h2>
  <p>
    NIBIO samler inn anonymiserte opplysninger om besøkende på skogskader.nibio.no. Formålet med dette er å utarbeide
    statistikk som vi bruker til å forbedre og videreutvikle informasjonstilbudet på nettstedet. Eksempler på hva
    statistikken gir svar på, er hvor mange som besøker ulike sider, hvor lenge besøket varer, hvilke nettsteder
    brukerne kommer fra og hvilke nettlesere som benyttes. Opplysningene behandles i anonymisert form. Med anonymisert
    menes at vi ikke kan spore opplysningene vi samler inn tilbake til den enkelte bruker. Vi samler inn hele
    IP-adressen, men IP-adressen anonymiseres slik at bare de tre første gruppene i adressen brukes til å generere
    statistikk. Det vil si at dersom IP-adressen består av numrene 195.159.103.82, brukes bare 195.159.103.xx.
  </p>

  <h2>Informasjonskapsler</h2>
  <p>
    Informasjonskapsler (cookies) er små tekstfiler som plasseres på din datamaskin når du laster ned en nettside. Dette
    medfører ingen sikkerhetsrisiko for deg,
    <a href="https://nettvett.no/slik-administrer-du-informasjonskapsler/">
      men du kan når som helst avslå å samtykke i lagring av informasjonskapsler</a
    >.<br /><br />
    Følgende informasjonskapsler brukes på <a href="https://skogskader.nibio.no">skogskader.nibio.no</a>:
    <br />
    JSESSIONID brukes til sesjonshåndtering av komponentene nettstedet skogskader.nibio.no består av, slettes når
    nettleserøkten avsluttes<br />
    _ga er Tredjeparts informasjonskapsel som brukes av Google Analytics for å skille på brukere, slettes etter 2 år.<br />
    _gid er Tredjeparts informasjonskapsel som brukes av Google Analytics for å skille på brukere, slettes etter 1 døgn.
  </p>
</div>
<div class="modal-footer">
  <button (click)="modal.close('Close click')" class="btn btn-secondary" type="button">Lukk</button>
</div>
