import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  NgForm,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { DamageType } from '@apptypes/damage.type';
import { HostType } from '@apptypes/host.type';
import { ImageAttachmentType } from '@apptypes/image-attachment.type';
import { AppConfig } from '@core/app.config';
import { ArrayHelper } from '@core/helpers/array.helper';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { ReactiveFormsHelper } from '@core/helpers/reactive-forms.helper';
import { ApiService } from '@core/services/api.service';
import { DiagnosisService } from '@core/services/diagnosis.service';
import { ErrorService } from '@core/services/error.service';
import { FormService } from '@core/services/form.service';
import { MapObjectsService } from '@core/services/map/map-objects.service';
import { MapService } from '@core/services/map/map.service';
import { ToastService } from '@core/services/toast.service';
import { UploadService } from '@core/services/upload.service';
import { MapObjectsEnum } from '@enums/map-objects.enum';
import { SkogskadeWsEndpointsEnum } from '@environments/apis/skogskade.api';
import {
  DamageFormFieldsType,
  DamageFormImagesType,
  DamageFormStructuredDataType,
  DamageFormType,
} from '@features/damage-form/damage-form.type';
import { GenericTextModalOptionsType } from '@modals/generic-text/generic-text-modal-options.type';
import { GenericTextModalComponent } from '@modals/generic-text/generic-text-modal.component';
import { SelectCollectionItemsModalComponent } from '@modals/select-collection-items/select-collection-items-modal.component';
import { SelectCollectionItemsOptionsType } from '@modals/select-collection-items/select-collection-items-options.type';
import { TermsOfUseModalComponent } from '@modals/terms-of-use/terms-of-use-modal.component';
import { NgbCalendar, NgbDatepicker, NgbDateStruct, NgbInputDatepicker, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormFieldErrorsComponent } from '@shared/components/form-field-errors/form-field-errors.component';
import { isFeatureCollection } from '@shared/guards/geojson/feature-collection.guard';
import { FileSizePipe } from '@shared/pipes/file-size.pipe';
import { FeatureCollectionValidator } from '@shared/validators/feature-collection.validator';
import { BBox, FeatureCollection } from 'geojson';
import { EMPTY, Subject, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FileSizePipe,
    FormFieldErrorsComponent,
    FormsModule,
    NgOptimizedImage,
    NgbDatepicker,
    NgbInputDatepicker,
    ReactiveFormsModule,
    RouterLink,
  ],
  providers: [FileSizePipe],
  selector: 'app-report-new-damage',
  standalone: true,
  styleUrls: ['./damage-form.component.scss'],
  templateUrl: './damage-form.component.html',
})
export default class DamageFormComponent implements OnInit {
  private readonly _defaultFeatureCollection = {
    bbox: undefined,
    features: [],
    type: 'FeatureCollection',
  } as FeatureCollection;
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _apiService = inject(ApiService);
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _diagnosisService = inject(DiagnosisService);
  private readonly _errorService = inject(ErrorService);
  private readonly _fb = inject(NonNullableFormBuilder);
  private readonly _fileSizePipe = inject(FileSizePipe);
  private readonly _formService = inject(FormService);
  private readonly _mapObjectsService = inject(MapObjectsService);
  private readonly _mapService = inject(MapService);
  private readonly _ngbCalendar = inject(NgbCalendar);
  private readonly _ngbModal = inject(NgbModal);
  private readonly _toastService = inject(ToastService);
  private readonly _uploadService = inject(UploadService);

  @ViewChild('imageSelectRef')
  imageSelectRef!: ElementRef<HTMLInputElement>;

  @ViewChild('form')
  ngForm!: NgForm;

  allowedFilesAsCsv: string = AppConfig.FORM_ATTACHMENT_EXTENSIONS.map(ext => '.' + ext).join(', ');
  dataCtrl!: DamageFormStructuredDataType;
  dateToday: NgbDateStruct = this._ngbCalendar.getToday();
  fieldsCtrl!: DamageFormFieldsType;
  formImages!: FormArray<FormGroup<DamageFormImagesType>>;
  frm!: FormGroup<DamageFormType>;
  maxFileCount = AppConfig.FORM_ATTACHMENTS_MAX_COUNT;
  maxFileSize = AppConfig.FORM_ATTACHMENT_MAX_SIZE;
  numGeoFigures = signal<number>(0);
  rfh = ReactiveFormsHelper;
  submitClicked = false;
  submitInProgress = false;

  validationMessages = {
    damageHosts: {
      minlength: 'Obligatorisk felt, legg til minst én vertsplante.',
      required: 'Obligatorisk felt, legg til minst én vertsplante.',
    },
    damageTypes: {
      minlength: 'Obligatorisk felt, legg til minst én skadetype.',
      required: 'Obligatorisk felt, legg til minst én skadetype.',
    },
    email: {
      email: 'Den angitte adressen ser ikke ut til å være en gyldig e-postadresse.',
      required: 'Obligatorisk felt, oppgi en e-postadresse den som har observert eller rapporter skaden kan nås på.',
    },
    images: {
      IMAGE_FILE_EXTENSION: 'Ugyldig filtype.',
      IMAGE_FILE_SIZE_MAX: 'For stort fil/bilde',
    },
    observationArea: {
      FEATURE_COLLECTION_MIN_FEATURES: 'Obligatorisk felt, angi minst ett skadeområde.',
    },
    phone: {
      pattern: 'Telefonnummer må angis med kun tall, minimum 8 siffer',
    },
    terms: {
      required: 'Du må akseptere betingelsene for å kunne sende.',
    },
  };

  ngOnInit(): void {
    this._buildForm();
    this._calculateNumFigures();
    this._restoreCachedFormValues();

    // Setup listener to cache values on every valueChange:
    this._formService.cacheValuesInit<DamageFormType>(AppConfig.CACHE_KEY_DAMAGE_FORM, this.frm, this._destroyRef);

    // Now that listener is running, populate from other sources such as URL
    this._populateForm();
  }

  createImageSrc(value: File): string {
    return URL.createObjectURL(value);
  }

  filesSelected(): void {
    const files = this.imageSelectRef.nativeElement.files;
    if (!files) {
      this._clearFormImages();
    } else {
      for (let i = 0; i < files.length; i++) {
        if (files.item(i) !== null) {
          const attachment = {
            captionText: '',
            file: files.item(i) as File,
            photographer: '',
          } as ImageAttachmentType;
          if (this._validateImageAttachment(attachment)) {
            this._addImageToForm(attachment);
            this._uploadService.addUploadToQueue(AppConfig.CACHE_KEY_DAMAGE_FORM + attachment.file.name, attachment);
          }
        }
      }
    }
    this._cdr.detectChanges();
  }

  openDamagesModal() {
    const modalRef = this._ngbModal.open(SelectCollectionItemsModalComponent, {
      ariaLabelledBy: 'modalHeaderTitle',
      fullscreen: 'sm',
      scrollable: true,
      size: 'xl',
    });

    modalRef.componentInstance.collection = this._diagnosisService.cachedData.damages;
    modalRef.componentInstance.selected = this.dataCtrl.damageTypes.value;
    modalRef.componentInstance.options = {
      fields: {
        comparison: 'id',
        label: 'label',
        orderBy1: 'order_by',
        orderBy2: 'label',
      },
      labels: {
        item: 'skadetype',
        itemPlural: 'skadetyper',
        title: 'Velg skadetyper',
      },
    } as SelectCollectionItemsOptionsType<HostType>;

    modalRef.result.then(
      // Callback if modal save button clicked
      result => {
        this.dataCtrl.damageTypes.setValue(result);
        this.dataCtrl.damageTypes.markAsTouched();
        this._cdr.detectChanges();
      },
      // Callback if modal closed without save
      () => {
        this.dataCtrl.damageTypes.markAsTouched();
        this._cdr.detectChanges();
      },
    );
  }

  openHostsModal() {
    const modalRef = this._ngbModal.open(SelectCollectionItemsModalComponent, {
      ariaLabelledBy: 'modalHeaderTitle',
      fullscreen: 'sm',
      scrollable: true,
      size: 'xl',
    });

    modalRef.componentInstance.collection = this._diagnosisService.cachedData.hosts;
    modalRef.componentInstance.selected = this.dataCtrl.damageHosts.value;
    modalRef.componentInstance.options = {
      fields: {
        comparison: 'id',
        label: 'label',
      },
      labels: {
        item: 'vertsplante',
        itemPlural: 'vertsplanter',
        title: 'Velg vertsplanter',
      },
    } as SelectCollectionItemsOptionsType<HostType>;

    modalRef.result.then(
      // Callback if modal save button clicked
      result => {
        this.dataCtrl.damageHosts.setValue(result);
        this.dataCtrl.damageHosts.markAsTouched();
        this._cdr.detectChanges();
      },
      // Callback if modal closed without save
      () => {
        this.dataCtrl.damageHosts.markAsTouched();
        this._cdr.detectChanges();
      },
    );
  }

  openSubmittedModal() {
    const modalRef = this._ngbModal.open(GenericTextModalComponent, {
      ariaLabelledBy: 'modalHeaderTitle',
      backdrop: 'static',
      fullscreen: 'sm',
      scrollable: true,
      size: 'lg',
    });

    modalRef.componentInstance.title = 'Innsending';
    modalRef.componentInstance.bodyHtml =
      'Takk for innsendt rapport. En bekreftelse på mottatt rapport sendes til den oppgitte e-postadressen.';
    modalRef.componentInstance.options = {
      showFooter: true,
      showHeader: true,
    } as GenericTextModalOptionsType;
  }

  openTermsModal() {
    this._ngbModal.open(TermsOfUseModalComponent, {
      ariaLabelledBy: 'modalHeaderTitle',
      fullscreen: 'sm',
      scrollable: true,
      size: 'xl',
    });
  }

  removeFromCollectionField<T>(item: T, formControl: FormControl<T[]>): void {
    const values = formControl.value as T[];
    const idx = values.findIndex(ex => item['id'] === ex['id']);

    if (idx > -1) {
      values.splice(idx, 1);
      formControl.setValue(values);
      this._cdr.detectChanges();
    }
  }

  removeImageAttachment(name: string, idx: number): void {
    this.formImages.removeAt(idx);
    this._uploadService.queue.delete(AppConfig.CACHE_KEY_DAMAGE_FORM + name);
  }

  submitForm(): boolean {
    if (this.submitInProgress) {
      return false;
    }

    this.submitClicked = true;
    this.frm.markAllAsTouched();
    this._cdr.detectChanges();

    // Start with the map drawings as GeoJSON FeatureCollection
    // Fetch from local service storage
    const geoJsonToBackend = this._mapObjectsService.getSavedAsFeatureGroup(MapObjectsEnum.DRAWINGS)?.toGeoJSON();
    if (!geoJsonToBackend || !isFeatureCollection(geoJsonToBackend)) {
      this._scrollToFirstInvalid();
      return false;
    }

    // Decorate as GeoJson FeatureCollection and add bbox
    geoJsonToBackend.type = 'FeatureCollection';
    const bounds = this._mapService.getMap().getBounds();
    if (bounds) {
      geoJsonToBackend.bbox = [
        bounds.getSouthWest().lng,
        bounds.getSouthWest().lat,
        bounds.getNorthEast().lng,
        bounds.getNorthEast().lat,
      ] as BBox;
    }

    // Get the values of the 'fields' part of the form
    const fieldsData = this.frm.get('fields')?.getRawValue();
    delete fieldsData['submit'];
    delete fieldsData['terms'];

    // Add any metadata supplied for the images
    if (this.formImages.value?.length) {
      fieldsData.imageData = [];
      this.formImages.value.forEach(fi => {
        fieldsData.imageData.push({
          name: fi.file?.name,
          photographer: fi.photographer,
          text: fi.captionText,
        });
      });
      // Present in the old one, but needed?
      fieldsData.image = '';
    }

    // Add date field in correct syntax
    const dateVal = this.dataCtrl.observationDate.value;
    fieldsData.date = [dateVal.day, dateVal.month, dateVal.year].join('.');

    // Add details on dmg and host
    fieldsData.diagnosis = this.dataCtrl.damageTypes.value.map(dt => dt.id);
    fieldsData.hosts = this.dataCtrl.damageHosts.value.map(dh => dh.id);

    // Add user inputted values as 'commondata' which is the syntax the backend expects
    geoJsonToBackend['commondata'] = fieldsData;
    const payloadBlob = JSON.stringify({
      formDataObject: geoJsonToBackend,
    });
    // Form the payload structure to align with what backend expects
    const payload = new FormData();
    payload.set('data', payloadBlob);

    if (this.formImages.value?.length) {
      this.dataCtrl.images.value.forEach(img => {
        payload.append('file', img.file as File);
      });
    }

    this.submitInProgress = true;
    this.frm.updateValueAndValidity();
    this._cdr.detectChanges();

    if (!this.frm.valid) {
      this.submitInProgress = false;
      this._cdr.detectChanges();

      this._scrollToFirstInvalid();
      return false;
    }

    if (this.frm.valid) {
      const endpoint = EnvironmentHelper.getSkogskadePaths().ws + SkogskadeWsEndpointsEnum.POST_REPORT;
      const headers = new HttpHeaders();
      headers.set('Accept', 'text/plain');
      headers.set('Content-Type', 'multipart/form-data');

      const submitStream$ = new Subject<void>();
      submitStream$
        .pipe(
          switchMap(() =>
            this._apiService.post('skogskade', endpoint, payload, {}, headers).pipe(
              catchError(err => {
                let handleAsSuccess = false;

                if (err instanceof HttpErrorResponse && err.status.toString() === '200') {
                  handleAsSuccess = true;
                } else if (err.message === 'OK') {
                  handleAsSuccess = true;
                }

                if (handleAsSuccess) {
                  this._completeSubmissionAndCleanup();
                  return EMPTY;
                }

                console.warn(err);
                this._errorService.showCustomError('Noe uventet inntraff ved sending.', err);
                this.submitInProgress = false;
                this._cdr.detectChanges();
                return EMPTY;
              }),
            ),
          ),
        )
        .subscribe({
          next: () => {
            this._completeSubmissionAndCleanup();
          },
        });

      submitStream$.next();
    }
    return false;
  }

  private _addDiagnosisById(id: string) {
    const results: DamageType[] = ArrayHelper.findRecursive(
      id,
      this._diagnosisService.cachedData.damages,
      'identicalString',
      'id',
    );
    if (results?.length > 0) {
      this.dataCtrl.damageTypes.setValue([results[0]], {
        emitEvent: true,
        emitModelToViewChange: true,
        emitViewToModelChange: true,
        onlySelf: false,
      });
    }
  }

  private _addImageToForm(image: ImageAttachmentType) {
    this.formImages.push(
      this._fb.group<DamageFormImagesType>({
        captionText: this._fb.control<string>(image.captionText),
        file: this._fb.control<File>(image.file),
        photographer: this._fb.control<string>(image.photographer || this.fieldsCtrl.observer.value),
      }),
    );
  }

  private _buildForm(): void {
    this.frm = this._fb.group<DamageFormType>({
      data: this._fb.group({
        damageHosts: this._fb.control<HostType[]>([], { validators: [Validators.required, Validators.minLength(1)] }),
        damageTypes: this._fb.control<DamageType[]>([], { validators: [Validators.required, Validators.minLength(1)] }),
        images: this._fb.array<FormGroup<DamageFormImagesType>>([]),
        observationArea: this._fb.control<FeatureCollection>(this._getSavedObjects(), {
          validators: [Validators.required, FeatureCollectionValidator.minFeatures(1)],
        }),
        observationDate: this._fb.control<NgbDateStruct>(this.dateToday, {
          validators: Validators.required,
        }),
      }),
      fields: this._fb.group({
        comment: this._fb.control<string>(''),
        email: this._fb.control<string>('', { validators: [Validators.required, Validators.email] }),
        observer: this._fb.control<string>('', { validators: Validators.required }),
        phone: this._fb.control<string>('', { validators: Validators.pattern(/^\d{8,}$/) }),
        submit: this._fb.control<boolean>(false, { validators: Validators.required }),
        terms: this._fb.control<boolean>(false, { validators: [Validators.requiredTrue] }),
      }),
    });

    this.fieldsCtrl = this.frm.controls.fields.controls;
    this.dataCtrl = this.frm.controls.data.controls;

    this.formImages = this.dataCtrl.images;
  }

  private _calculateNumFigures(): void {
    this.numGeoFigures.set(this.dataCtrl.observationArea.value.features.length || 0);
    this._cdr.detectChanges();
  }

  private _clearFormImages(): void {
    this.formImages.clear();
    this._uploadService.queue.clear();
  }

  private _completeSubmissionAndCleanup(): void {
    this.openSubmittedModal();

    // Store temporarily to refill after form clear
    const submitter = {
      email: this.frm.controls.fields.controls.email.value,
      name: this.frm.controls.fields.controls.observer.value,
      phone: this.frm.controls.fields.controls.phone.value,
    };

    // Clear values and caches
    this._formService.cacheValuesClear(AppConfig.CACHE_KEY_DAMAGE_FORM);
    this._mapObjectsService.savedObjects.set(MapObjectsEnum.DRAWINGS, undefined);
    this._mapObjectsService.savedObjects.set(MapObjectsEnum.SEARCHES, undefined);
    this._mapService.clearMap();
    this._uploadService.queue.clear();

    // Reset form values and status
    this.submitClicked = false;
    this.submitInProgress = false;
    this.dataCtrl.damageHosts.setValue([]);
    this.dataCtrl.damageTypes.setValue([]);
    this.ngForm.resetForm(); // This resets validators and submission status
    this.frm.reset(); // This resets values

    // Reset special counters and fields
    this._clearFormImages();
    this.numGeoFigures.set(0);

    // Re-assign observers personal details as they likely didn't change
    this.frm.controls.fields.controls.email.setValue(submitter.email);
    this.frm.controls.fields.controls.observer.setValue(submitter.name);
    this.frm.controls.fields.controls.phone.setValue(submitter.phone);
    this.frm.updateValueAndValidity();
    this._cdr.detectChanges();
  }

  private _getSavedObjects(): FeatureCollection {
    let savedDrawings = this._mapObjectsService.getFeatureCollection(MapObjectsEnum.DRAWINGS);
    if (!savedDrawings.features.length) {
      savedDrawings = this._defaultFeatureCollection;
    }

    return savedDrawings;
  }

  private _populateForm(): void {
    // Drawn items is likely to have changed outside this component, and the (unserialized) value from MapService is better data
    if (
      (!this.dataCtrl.observationArea.value || this.dataCtrl.observationArea.value.features?.length < 1) &&
      this._mapObjectsService.getFeatureCollection(MapObjectsEnum.DRAWINGS)?.features.length
    ) {
      this.dataCtrl.observationArea.setValue(this._getSavedObjects());
    }

    // Try restoring photos from uploadService storage
    if (this._uploadService.queue.size) {
      this._uploadService.queue.forEach(upload => {
        this._addImageToForm(upload.imageAttachment);
      });
    }

    // Try populating from URL param
    if (this._activatedRoute.snapshot.queryParams) {
      const diagnosisParam = this._activatedRoute.snapshot.queryParams['diagnosisId'];
      if (diagnosisParam?.toString()?.length > 0) {
        this._addDiagnosisById(diagnosisParam);
      }
    }

    this._cdr.detectChanges();
  }

  /**
   * Populate form with data if exists in (local)Storage:
   */
  private _restoreCachedFormValues() {
    this._formService.cacheValuesRestore<DamageFormType>(AppConfig.CACHE_KEY_DAMAGE_FORM, this.frm);
  }

  /**
   * Scroll to first invalid form-group
   */
  private _scrollToFirstInvalid(): void {
    document
      .getElementsByClassName('form-group invalid')
      .item(0)
      ?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  }

  private _validateImageAttachment(attachment: ImageAttachmentType): boolean {
    if (!attachment.file) {
      return false;
    }

    // Check max attachments count
    if (this._uploadService.queue.size >= AppConfig.FORM_ATTACHMENTS_MAX_COUNT) {
      this._toastService.show(
        `Filen «${attachment.file.name}» ble avvist fordi rapporten allerede har maksimalt antall (${AppConfig.FORM_ATTACHMENTS_MAX_COUNT}) bilder vedlagt`,
        'For mange bilder',
        false,
        'text-bg-warning',
      );
      return false;
    }

    // Check file extension (if no dot in file name, leave up to mime type check)
    const fileNameSegments = attachment.file.name.split('.');
    const fileExt = fileNameSegments[fileNameSegments.length - 1];
    if (fileNameSegments?.length > 1 && !AppConfig.FORM_ATTACHMENT_EXTENSIONS.includes(fileExt)) {
      this._toastService.show(
        `Filen «${attachment.file.name}» ble avvist fordi dens
        benevnelse (${fileExt}) ikke er blandt de godkjente filtypene (${AppConfig.FORM_ATTACHMENT_EXTENSIONS.join(
          ', ',
        )}).`,
        'Ikke godkjent filtype/benevnelse',
        false,
        'text-bg-warning',
      );
      return false;
    }

    // Check file MIME type
    if (!AppConfig.FORM_ATTACHMENT_MIME_TYPES.includes(attachment.file.type)) {
      this._toastService.show(
        `Filen «${attachment.file.name}» ble avvist fordi dens filtype (${attachment.file.type})
        ikke er blandt de godkjente variantene (${AppConfig.FORM_ATTACHMENT_MIME_TYPES.join(', ')}).`,
        'Ikke godkjent filtype',
        false,
        'text-bg-warning',
      );
      return false;
    }

    // Check file size
    if (attachment.file.size > AppConfig.FORM_ATTACHMENT_MAX_SIZE) {
      const attachmentSize = this._fileSizePipe.transform(attachment.file.size);
      const maxAttachmentSize = this._fileSizePipe.transform(AppConfig.FORM_ATTACHMENT_MAX_SIZE);
      this._toastService.show(
        `Filen «${attachment.file.name}» på ${attachmentSize} ble avvist fordi den overskrider maks filstørrelse ${maxAttachmentSize}.`,
        'Filstørrelse over maks',
        false,
        'text-bg-warning',
      );
      return false;
    }

    // Check filename unique (required by API)
    if (this._uploadService.queue.has(AppConfig.CACHE_KEY_DAMAGE_FORM + attachment.file.name)) {
      this._toastService.show(
        `En fil med navnet «${attachment.file.name}» er allerede lagt til.`,
        'Duplikat filnavn',
        false,
        'text-bg-warning',
      );
      return false;
    }

    return true;
  }
}
