(function (skogskadeControllers) {
  'use strict';

  skogskadeControllers.controller('formController', formController);

  formController.$inject = [
    '$scope',
    '$http',
    '$location',
    '$upload',
    '$filter',
    'formInitDataService',
    'mapModeService',
    'mapService',
    'SkadeReport',
    '$uibModal'
  ];

  function formController(
    $scope,
    $http,
    $location,
    $upload,
    $filter,
    formInitDataService,
    mapModeService,
    mapService,
    SkadeReport,
    $uibModal
  ) {
    $scope.date = new Date();
    $scope.geolocation = '';
    $scope.geolocationPristine = true;
    $scope.selectedDiagnosis = [];
    $scope.diagnosisPristine = true;
    $scope.selectedHosts = [];
    $scope.hostsPristine = true;
    $scope.comment = '';
    $scope.image = '';
    $scope.observer = '';
    $scope.email = '';
    $scope.phone = '';
    // structure used for turning validation off in inputfields while user types
    $scope.doValidation = {
      email: false,
      phone: false,
    };
    $scope.standMultiSelectData = [];
    $scope.phoneNumberPattern = /^(\+\d{1,3} *)?\d ?\d ?\d ?\d ?\d ?\d ?\d ?\d ?\d*$/;
    $scope.modalTitle = 'Vennligst vent, sender skaderapport.....';
    $scope.modalText = '';
    $scope.imageModalTitle = 'Last opp bilder';
    $scope.imageModalText = 'Gyldige formater er jpeg/jpg, maks bildestørrelse 6 mb og maks antall bilder 20.';
    $scope.imageList = formInitDataService.getImageList();
    $scope.imageDataList = formInitDataService.getImageDataList();
    var diagnosisId = $location.search().diagnosisId;
    $scope.diag = '';
    $scope.hostsList = [];
    $scope.allowReport = formInitDataService.getReadTermsReport();
    $scope.showCloseButtonOnReportSave = true;

    $scope.userDiagnosisStatus = {
      isopen: false,
    };

    $scope.clearTextSearch = function () {
      if ($scope.diag.trim() !== '') {
        $scope.diag = '';
        unsetSearchState();
      }
    };

    $scope.selectDiagnosis = function (diagnose) {
      var i = 0;
      for (i = 0; i < $scope.selectedDiagnosis.length; i++) {
        if ($scope.selectedDiagnosis[i].id === diagnose.id) {
          //Already selected
          return;
        }
      }
      $scope.selectedDiagnosis.push({ id: diagnose.id, label: diagnose.label });
    };

    function setTickForDiagnosis(id, value) {
      var i,
        j = 0;
      for (i = 0; i < $scope.diagnosisList.length; i++) {
        if ($scope.diagnosisList[i].id === id) {
          $scope.diagnosisList[i].ticked = value;
          break;
        }
        for (j = 0; j < $scope.diagnosisList[i].diagnosis.length; j++) {
          if ($scope.diagnosisList[i].diagnosis[j].id === id) {
            $scope.diagnosisList[i].diagnosis[j].ticked = value;
            break;
          }
        }
      }
    }

    function setTickForHosts(id, value, list) {
      var i,
        j = 0;
      for (i = 0; i < list.length; i++) {
        if (list[i].id === id) {
          list[i].ticked = value;
          break;
        }
        for (j = 0; j < list[i].hosts.length; j++) {
          if (list[i].hosts[j].id === id) {
            list[i].hosts[j].ticked = value;
            break;
          }
        }
      }
    }

    function unsetSearchState() {
      var i,
        j = 0;
      for (i = 0; i < $scope.diagnosisList.length; i++) {
        // ticked skal være false, slik at vi bare ser hovedkotegoriene
        var folder = $scope.diagnosisList[i];
        folder.aktive = false;
        folder.filteredOut = false;
        // Gå igjennom alle skader og set filteredOut = false;
        for (j = 0; j < folder.diagnosis.length; j++) {
          folder.diagnosis[j].filteredOut = false;
        }
      }
    }

    function unsetSearchForList(list, property) {
      var i,
        j = 0;
      for (i = 0; i < list.length; i++) {
        // ticked skal være false, slik at vi bare ser hovedkotegoriene
        var folder = list[i];
        folder.ticked = false;
        folder.filteredOut = false;
        // Gå igjennom alle skader og set filteredOut = false;
        for (j = 0; j < folder[property].length; j++) {
          folder[property][j].filteredOut = false;
        }
      }
    }

    $scope.unSelectDiagnosis = function (diagnose) {
      var index = $scope.selectedDiagnosis.indexOf(diagnose);
      if (index !== -1) {
        $scope.selectedDiagnosis.splice(index, 1);
      }

      // unset thet ticked property of the diagnosisList
      setTickForDiagnosis(diagnose.id, false);

      // update the formService
      formInitDataService.removeDiagnosis(diagnose.id);
    };

    $scope.removeImage = function (index) {
      $scope.imageList.splice(index, 1);
      var imageData = formInitDataService.getImageDataList();
      imageData.splice(index, 1);
    };

    $scope.showTerms = function () {
      var win = window.open('termsOfUse.html', '_blank');
      win.focus();
    };

    $scope.cleanImageModal = function () {
      formInitDataService.setImageList([]);
      formInitDataService.setImageDataList([]);
      $scope.imageDataList = [];
      $scope.imageList = [];
      formInitDataService.setReadTerms(false);
    };
    $scope.tmpSaveImageData = function () {
      formInitDataService.setImageDataList($scope.imageDataList);
    };
    $scope.onMultiFileSelect = function ($files) {
      //validate data
      //sjekk om bilde er lastet opp før

      for (var i = 0; i < $files.length; i++) {
        var check = formInitDataService.checkNewImage($files[i]);
        if (check) {
          var fsizeByte = $files[i].size;
          var ftype = $files[i].type;
          if (fsizeByte > '6000000') {
            alert('Fil størrelsen er for stor, grensen er 6mb');
            $files.splice(i, 1);
            break;
          }
          switch (ftype) {
            case 'image/jpg':
            case 'image/jpeg':
              break;
            default:
              alert('Støtter ikke denne filtypen!');
              $files.splice(i, 1);
          }
          if ($files.length > 0) {
            var objectToImageDataList = [];
            objectToImageDataList = {
              name: $files[i].name,
              text: '',
              photographer: $scope.observer,
            };
            if (formInitDataService.getImageList().length > 19) {
              alert('Maks antall bilder for opplasting er 20');
            } else {
              formInitDataService.addImageDataToList(objectToImageDataList);
              formInitDataService.addImageToList($files[i]);
            }
          }
        } else {
          alert('Bildet ' + $files[i].name + ' er allerede lastet opp');
          $files.splice(i, 1);
          i--;
        }
      }
      $scope.imageList = formInitDataService.getImageList();
      $scope.imageDataList = formInitDataService.getImageDataList();
    };

    $scope.geoPristine = function () {
      return $scope.geolocationPristine;
    };

    $scope.disableValidation = function (field) {
      $scope.doValidation[field] = false;
    };

    $scope.enableValidation = function (field) {
      $scope.doValidation[field] = true;
    };

    function preselect(itemlist, selected) {
      var i = 0;
      while (i < selected.length) {
        var itm = selected[i];
        for (var j = 0; j < itemlist.length; j++) {
          if (itm.id === itemlist[j].id) {
            itemlist[j].ticked = true;
            break;
          }
        }
        i++;
      }
      return itemlist;
    }

    function preselectDiagnosis(selected) {
      var i = 0;
      while (i < selected.length) {
        setTickForDiagnosis(selected[i], true);
        i++;
      }
    }

    function preselectHosts(selected) {
      var i = 0;
      while (i < selected.length) {
        setTickForHosts(selected[i], true, $scope.hostsList);
        i++;
      }
    }

    function resetPreselect(itemlist) {
      var i = 0;
      for (i = 0; i < itemlist.length; i++) {
        itemlist[i].ticked = false;
      }
      return itemlist;
    }

    function initForm() {
      if (formInitDataService.date() !== '') {
        $scope.date = formInitDataService.date();
      }
      $scope.geolocation = mapService.getGeoJson('drawOnMap');
      $scope.comment = formInitDataService.comment();
      $scope.image = formInitDataService.image();
      $scope.observer = formInitDataService.observer();
      $scope.email = formInitDataService.email();
      $scope.phone = formInitDataService.phone();
      $scope.geolocationPristine = formInitDataService.geoPristine();
    }

    initForm();

    formInitDataService.getFormInitData().then(function (callbackdata) {
      // Set up the watchers for selected selected diagnosis and selected hosts
      $scope.diagnosisList = formInitDataService.getDiagnosisList();
      $scope.selectedDiagnosis = formInitDataService.diagnosis();
      preselectDiagnosis(formInitDataService.diagnosis());

      $scope.hostsList = formInitDataService.getHostsList('');

      $scope.selectedHosts = formInitDataService.hosts();
      preselectHosts(formInitDataService.getHostsIds());
      if (diagnosisId) {
        var diagnosis = findDiagnosisWithID(parseInt(diagnosisId, 10));
        if (diagnosis !== null) {
          $scope.selectDiagnosis(diagnosis);
        }
      }
    });

    $scope.$watch('diag', function (newVal, oldVal) {
      if (angular.equals(newVal, oldVal)) {
        return;
      }

      if (newVal.trim() === '') {
        unsetSearchState();
      } else if (newVal !== oldVal) {
        formInitDataService.filterItem($scope.diagnosisList, newVal, 'diagnosis');
      }
    });

    /*
     Find and return diagnosis with the corrsponding id.
     Returns null if id is not found
     */
    function findDiagnosisWithID(id) {
      var i,
        j = 0;
      for (i = 0; i < $scope.diagnosisList.length; i++) {
        // CHeck if id is in the top level diagnosis structure
        if ($scope.diagnosisList[i].id === id) {
          return $scope.diagnosisList[i];
        }
        // CHeck if id is in substructure
        for (j = 0; j < $scope.diagnosisList[i].diagnosis.length; j++) {
          if ($scope.diagnosisList[i].diagnosis[j].id === id) {
            return $scope.diagnosisList[i].diagnosis[j];
          }
        }
      }
      return null;
    }

    $scope.toggleSubList = function (index) {
      $scope.subDiagnosis[index] = !$scope.subDiagnosis[index];
    };

    $scope.$watch('geolocation', function (newVal) {
      if ($scope.geolocationPristine && mapService.geoIsSet()) {
        $scope.geolocationPristine = false;
        formInitDataService.setGeoPristine(false);
      }
    });

    $scope.updateDate = function (newVal) {
      formInitDataService.setDate(newVal);
    };

    $scope.updateComment = function (newVal) {
      formInitDataService.setComment(newVal);
    };

    $scope.updateObserver = function (newVal) {
      formInitDataService.setObserver(newVal);
    };
    $scope.updateEmail = function (newVal) {
      formInitDataService.setEmail(newVal);
    };

    $scope.updatePhone = function (newVal) {
      formInitDataService.setPhone(newVal);
    };

    $scope.goToMap = function () {
      //mapModeService.setMapMode("drawOnMap");
      $location.path('/map/drawOnMap');
    };

    $scope.diagnosisValid = function () {
      if (
        $scope.selectedDiagnosis === null ||
        typeof $scope.selectedDiagnosis === 'undefined' ||
        $scope.selectedDiagnosis === '' ||
        $scope.selectedDiagnosis.length === 0
      ) {
        return false; //not validated
      } else {
        return true; //validated
      }
    };

    $scope.hostsValid = function () {
      if (
        $scope.selectedHosts === null ||
        typeof $scope.selectedHosts === 'undefined' ||
        $scope.selectedHosts === '' ||
        $scope.selectedHosts.length === 0
      ) {
        return false; //not validated
      } else {
        return true; //validated
      }
    };

    $scope.selectsValid = function () {
      return $scope.hostsValid() && $scope.diagnosisValid();
    };

    $scope.geolocationValid = function () {
      return mapService.geoIsSet();
    };

    $scope.numGeoFigures = function () {
      return mapService.getNumGeoFigures();
    };

    $scope.numImagesUploded = function () {
      var images = formInitDataService.getImageList();
      if (images === null) return 0;
      return images.length;
    };

    // function to submit the form after all validation has occurred
    $scope.submitForm = function (isValid) {
      var geoJsonToBackend = mapService.getGeoJson('drawOnMap');
      var formData = formInitDataService.getFormValues();
      var imageData = formInitDataService.getImageDataList();
      var images = formInitDataService.getImageList();
      var diagnosisIds = formInitDataService.getDiagnosisIds();
      var hostsIds = formInitDataService.getHostsIds();
      /*
       check i the date is emty, then fill it out with to day date and formate
       */
      if (formData.date === '') {
        formData.date = new Date();
      }
      formInitDataService.setReadTermsReport($scope.allowReport);
      /*
       update formdata and geoJsonToBackend
       */
      formData.diagnosis = diagnosisIds;
      formData.hosts = hostsIds;
      formData.date = formInitDataService.formateDate(formData.date);
      formData.imageData = imageData;
      geoJsonToBackend.commondata = formData;
      if ($scope.diagnosisValid() && isValid) {
        //var files = formInitDataService.getImageList();
        var res = SkadeReport.save(geoJsonToBackend, images);
        res
          .progress(function (evt) {
            $scope.showCloseButtonOnReportSave = false;
            //  var progressPercentage = parseInt(100.0 * evt.loaded / evt.total,10);
            //  $scope.modalText = 'Progress: ' + progressPercentage + '% ';
          })
          .success(function (data, status, headers, config) {
            if (data === 'OK') {
              $scope.modalTitle = 'rapporten er sendt - du vil nå få en bekreftelse på e-post';
              cleanFormAfterSave();
            } else {
              $scope.modalTitle = 'Rapporten ble ikke sendt';
              //var message = data.split(":");
              $scope.showCloseButtonOnReportSave = true;
              $scope.modalText = data;
            }
          });
        res.error(function (data, status, headers, config) {
          $scope.modalTitle = 'Rapporten er ikke sendt';
          $scope.showCloseButtonOnReportSave = true;
        });

        $('#reportModal').modal('show');
      } else {
        alert('Beklager, du har ikke fyllt ut alle de obligatoriske feltene..');
      }
    };

    function cleanFormAfterSave() {
      mapService.setFeatureGroup(null);
      $scope.date = new Date();
      $scope.selectedDiagnosis = [];
      $scope.selectedHosts = [];
      formInitDataService.clearFormData();
      formInitDataService.setGeoPristine(true);
      $scope.standMultiSelectData = resetPreselect($scope.standMultiSelectData);
      unsetSearchState();
      $scope.diagnosisPristine = true;
      $scope.hostsPristine = true;
      $scope.reportForm.$setPristine();
      $scope.imageDataList = [];
      $scope.imageList = [];
      formInitDataService.setReadTerms(false);
      $scope.showCloseButtonOnReportSave = true;
      initForm();
    }

    // Datepicker stuff goes here. See http://angular-ui.github.io/bootstrap/ for documentation on settings:

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    today = yyyy + '-' + mm + '-' + dd;
    $scope.maxtoday = today;

    $scope.today = function () {
      $scope.diagnosisDate = new Date();
    };
    $scope.today();

    $scope.clear = function () {
      $scope.date = null;
    };

    $scope.toggleMin = function () {
      $scope.minDate = $scope.minDate ? null : new Date('1900-01-01');
    };
    $scope.toggleMin();

    $scope.open = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.opened = true;
    };

    $scope.dateOptions = {
      formatYear: 'yyyy',
      startingDay: 1,
    };

    $scope.initDate = new Date();
    $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    $scope.format = $scope.formats[2];

    // End of datepicker stuff

    var selectHostInstance = null;
    $scope.openSelectHostModal = function () {
      unsetSearchForList($scope.hostsList, 'hosts');
      $scope.hostInstance = {
        hostsList: $scope.hostsList,
        selectedHosts: formInitDataService.hosts(),
        hostsPristine: $scope.hostsPristine,
        selectHost: $scope.selectHost,
        unSelectHost: $scope.unSelectHost,
        setTickForHosts: setTickForHosts,
        unsetSearchForList: unsetSearchForList,
        title: 'Legg til en vertsplante',
      };
      selectHostInstance = $uibModal.open({
        templateUrl: 'ajs/partials/select_host_modal.html',
        controller: 'selectModalHostController',
        resolve: {
          hostInstance: function () {
            return $scope.hostInstance;
          },
        },
      });
      selectHostInstance.result.then(
        function (selectedHosts) {},
        function () {}
      );
    };

    $scope.selectHost = function (host, list) {
      var i = 0;
      for (i = 0; i < list.length; i++) {
        if (list[i].id === host.id) {
          //Already selected
          return;
        }
      }
      list.push({ id: host.id, label: host.label });
      formInitDataService.setHosts(list);
    };

    $scope.unSelectHost = function (host, selected, all) {
      var index = selected.indexOf(host);
      if (index !== -1) {
        selected.splice(index, 1);
      }

      // update the formService
      formInitDataService.setHosts(selected);
      setTickForHosts(host.id, false, all);
    };

    $scope.selectedHosts = [];
    $scope.hostInstance = {};
  }
})(angular.module('skogskadeControllers'));
