import { Injectable, signal } from '@angular/core';
import { AppConfig } from '@core/app.config';
import { MapModesEnum } from '@enums/map-modes.enum';
import { MapObjectsEnum } from '@enums/map-objects.enum';
import { FeatureCollection } from 'geojson';
import { FeatureGroup, Layer } from 'leaflet';

@Injectable({
  providedIn: 'root',
})
export class MapObjectsService {
  mapMode: MapModesEnum = MapModesEnum.SHOW_SKOGSKADER;
  savedDrawingsCount = signal<number>(0);
  savedSearchesCount = signal<number>(0);
  savedObjects = new Map<MapObjectsEnum, FeatureGroup | undefined>();

  private readonly _cacheValuesKey: string = 'mapObjects_';

  clearSavedObjects(type: MapObjectsEnum = MapObjectsEnum.DRAWINGS) {
    this.savedObjects.set(type, undefined);
    localStorage.removeItem(this._cacheValuesKey + type);
    if (type === MapObjectsEnum.DRAWINGS) {
      this.savedDrawingsCount.set(0);
    }
    if (type === MapObjectsEnum.SEARCHES) {
      this.savedSearchesCount.set(0);
    }
  }

  getFeatureCollection(type: MapObjectsEnum = MapObjectsEnum.DRAWINGS): FeatureCollection {
    // Fetch from local service storage
    const data: FeatureGroup | undefined = this.getSavedAsFeatureGroup(type);

    // Early return if no data
    if (!data) {
      return { features: [], type: 'FeatureCollection' } as FeatureCollection;
    }

    return data.toGeoJSON() as FeatureCollection;
  }

  getSavedAsFeatureGroup(type: MapObjectsEnum = MapObjectsEnum.DRAWINGS): FeatureGroup | undefined {
    const group = this.savedObjects.get(type);
    const count = group?.getLayers().length || 0;
    if (type === MapObjectsEnum.DRAWINGS) {
      this.savedDrawingsCount.set(count);
    }
    if (type === MapObjectsEnum.SEARCHES) {
      this.savedSearchesCount.set(count);
    }
    return group;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveObjects(
    group: FeatureGroup | any,
    type: MapObjectsEnum = MapObjectsEnum.DRAWINGS,
    excludeNonFlagged: boolean = false,
  ) {
    if (!group) {
      this.clearSavedObjects(type);
      return;
    }

    if (excludeNonFlagged) {
      // We're only interested in features marked to be used as FindSite.
      group.eachLayer(function (layer: Layer) {
        if (!layer.options[AppConfig.MAP_FEATURE_FINDSITE] || layer.options[AppConfig.MAP_FEATURE_FINDSITE] !== true) {
          group.removeLayer(layer);
        }
      });
    }

    const nrEligbleDrawings = group.getLayers()?.length;
    if (!nrEligbleDrawings) {
      console.log(`no data length, abort`);
      this.clearSavedObjects(type);
      return;
    }

    const saveData = new FeatureGroup();
    Object.assign(saveData, group);

    this.savedObjects.set(type, saveData);
    // this.savedDrawingsCount.set(nrEligbleDrawings);
    if (type === MapObjectsEnum.DRAWINGS) {
      this.savedDrawingsCount.set(nrEligbleDrawings);
    }
    if (type === MapObjectsEnum.SEARCHES) {
      this.savedSearchesCount.set(nrEligbleDrawings);
    }

    /**
     * Saving data between page loads:
     * https://www.npmjs.com/package/vault-storage
     * https://www.npmjs.com/package/client-web-storage
     * https://levelup.gitconnected.com/sorry-localstorage-now-im-in-love-with-localforage-2767d38cb81c
     *
     * This old example uses localstorage. It is insufficient, as the geojson details
     * do not survive the de/serialization process:
     * const payload = stringify(saveData);
     * console.log(`localstorage payload`, payload?.length, payload);
     * localStorage.setItem(this._cacheValuesKey + type, payload);
     */
  }

  saveSearchesAsDrawings(): void {
    const drawings = this.savedObjects.get(MapObjectsEnum.DRAWINGS);
    const searches = this.savedObjects.get(MapObjectsEnum.SEARCHES);

    const combined = new FeatureGroup(drawings?.getLayers() || []);

    if (searches?.getLayers()?.length) {
      searches.getLayers().forEach(s => {
        combined.addLayer(s);
      });

      this.saveObjects(combined, MapObjectsEnum.DRAWINGS);
    }

    this.clearSavedObjects(MapObjectsEnum.SEARCHES);
  }
}
