import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostBinding,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl } from '@angular/forms';
import { ObjectHelper } from '@core/helpers/object.helper';
import { ValidationPipe } from '@shared/pipes/validation.pipe';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ValidationPipe],
  selector: 'app-form-field-errors',
  standalone: true,
  styleUrls: ['./form-field-errors.component.scss'],
  templateUrl: './form-field-errors.component.html',
})
export class FormFieldErrorsComponent implements OnInit {
  @Input()
  control!: FormControl | AbstractControl;

  @Input()
  messages?: FieldError;

  @Input()
  showErrors?: boolean;

  @HostBinding('class.form-validation')
  classValidation = true;

  @HostBinding('class.show')
  get showValidation(): boolean {
    return !!this.showErrors && this.control.invalid;
  }

  allMessages!: FieldError;
  defaults: FieldError = {
    required: 'Obligatorisk felt.',
  };

  private readonly _destroyRef = inject(DestroyRef);

  constructor(private readonly _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.allMessages = ObjectHelper.nestedAssign(this.defaults, { ...this.messages });
    this.control?.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe({
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      error: () => {},
      next: () => {
        this._cdr.detectChanges();
      },
    });
  }
}

export type FieldError = {
  [key: string]: string | object;
};
