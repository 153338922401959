(function () {
  angular.module('skogskadeConfigs').config(routeConfig);

  routeConfig.$inject = [
    '$routeProvider',
    '$locationProvider',
    'skogskadeGlobalOptions',
  ];

  function routeConfig(
    $routeProvider,
    $locationProvider,
    skogskadeGlobalOptions
  ) {
//    $locationProvider.html5Mode(true);
    $routeProvider
      .when('/skadebeskrivelse', {
        templateUrl: 'ajs/partials/skade-list.html',
        controller: 'damageListController',
        meta: {
          //Sets 'Home Page' as the title when /home is open
          title: skogskadeGlobalOptions.title,
          description: skogskadeGlobalOptions.descriptionSkadebeskrivelse,
        },
      })
      .when('/skader/:skadeId', {
        templateUrl: 'ajs/partials/skade-detail.html',
        controller: 'damageDetailController',
        meta: {
          //Sets 'Home Page' as the title when /home is open
          title: skogskadeGlobalOptions.title,
          description: skogskadeGlobalOptions.descriptionSkadeDetail,
        },
      })
      //      .when('/help', {
      //      templateUrl: 'ajs/partials/help.html',
      //      meta:        {
      //        //Sets 'Home Page' as the title when /home is open
      //        title:       skogskadeGlobalOptions.title,
      //        description: skogskadeGlobalOptions.descriptionHelp
      //      }
      //    })
      .when('/map/:reportId/:mode', {
        // TODO: Believe this route is WIP. Nothing seems to be fully implemented in Controller. It might be to mask admin functionality on public facing site.
        // Triggered by this? https://skogskader.nibio.no/#/map/22847/showReport
        // Triggered by this? https://skogskader.nibio.no/#/map/22847/showSkogskader
        // Triggered by this? https://skogskader.nibio.no/#/map/22847/reports
        templateUrl: 'ajs/partials/map.html',
        controller: 'mapController', // In use by public site
        meta: {
          //Sets 'Home Page' as the title when /home is open
          title: skogskadeGlobalOptions.title,
          description: skogskadeGlobalOptions.descriptionMap,
        },
      })
      .when('/map/:reportId/editReport', {
        // Meant to be used from https://appl8test.int.nibio.no/skogskade/#/map/30264/editReport
        // If used from public /#/map/22847/editReport it will show but attempting to save will
        // yield 404 error on the POST request to /skogskade_admin_ws since it is only available on appl8*
        templateUrl: 'ajs/partials/editReport.html',
        controller: 'mapEditReportController', // Only in use by Appl8
        meta: {
          //Sets 'Home Page' as the title when /home is open
          title: skogskadeGlobalOptions.title,
          description: skogskadeGlobalOptions.descriptionReportDetail,
        },
      })
      .when('/map/:mode', {
        templateUrl: 'ajs/partials/map.html',
        controller: 'mapController',
        meta: {
          //Sets 'Home Page' as the title when /home is open
          title: skogskadeGlobalOptions.title,
          description: skogskadeGlobalOptions.descriptionMap,
        },
      })
      .when('/map', {
        templateUrl: 'ajs/partials/map.html',
        controller: 'mapController',
        meta: {
          //Sets 'Home Page' as the title when /home is open
          title: skogskadeGlobalOptions.title,
          description: skogskadeGlobalOptions.descriptionMap,
        },
      })
      .when('/kart', {
        redirectTo: '/map/showSkogskader'
      })
      .when('/skadeform', {
        templateUrl: 'ajs/partials/skadeform.html',
        // meta: {
        //Sets 'Home Page' as the title when /home is open
        //        title:       skogskadeGlobalOptions.title,
        //        description: skogskadeGlobalOptions.descriptionSkadeForm
        // },
      })
      .when('/rapporter', {
        templateUrl: 'ajs/partials/report-list.html',
        controller: 'reportListController',
        meta: {
          //Sets 'Home Page' as the title when /home is open
          title: skogskadeGlobalOptions.title,
          description: skogskadeGlobalOptions.descriptionReport,
        },
      })
      .when('/rapporter/:reportId', {
        templateUrl: 'ajs/partials/report-detail.html',
        controller: 'reportDetailController',
        meta: {
          //Sets 'Home Page' as the title when /home is open
          title: skogskadeGlobalOptions.title,
          description: skogskadeGlobalOptions.descriptionReportDetail,
        },
      })
      .otherwise({
        template: '', // recommended by upgrade guide, see last NOTE: https://angular.io/guide/upgrade#create-a-service-to-lazy-load-angularjs
      });
    // .otherwise({
    //   redirectTo: '/skadeform',
    // });

    //    $locationProvider.html5Mode(skogskadeGlobalOptions4html5Mode.html5Mode);
    // $locationProvider.html5Mode({ enabled: true, requireBase: false });
  }
})();
