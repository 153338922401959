import { environment } from '@environments/environment';

(function (skogskadeControllers) {
  'use strict';

  skogskadeControllers.controller('damageListController', damageListController);

  damageListController.$inject = [
    '$scope',
    '$http',
    '$injector',
    '$location',
    '$uibModal',
    '$window',
    'Skadebeskrivelse',
    'stateListModel',
    '$routeParams',
    'formInitDataService',
  ];

  function damageListController(
    $scope,
    $http,
    $injector,
    $location,
    $uibModal,
    $window,
    Skadebeskrivelse,
    stateListModel,
    $routeParams,
    formInitDataService,
  ) {
    $http.defaults.useXDomain = true;
    // START code for "Find host"
    var defaultTitle = stateListModel.getTitle();
    var hostsList = stateListModel.getHostList();
    var hostInstance = {};
    $scope.hostSearchText = stateListModel.getHostSearchText().text || '';
    $scope.hostSelected = stateListModel.getHostSearchText().id;
    $scope.hostsButtonTitle = stateListModel.getHostSearchText().text || defaultTitle;
    // check if we already have hostList
    if (Object.keys(hostsList).length === 0) {
      formInitDataService.getFormInitData().then(function (val) {
        hostsList = formInitDataService.getHostsList(val.host);
        stateListModel.setHostList(hostsList);
      });
    }

    var selectHostInstance = null;
    $scope.openSelectHostModal = function () {
      $scope.search = '';
      unsetSearchForList(hostsList, 'hosts');
      // var uibModalStack = $injector.get('$uibModalStack');
      // uibModalStack.openSelectHostModal();
      hostInstance = {
        hostsList: hostsList,
        selectHost: $scope.selectHost,
        setTickForHosts: setTickForHosts,
        title: defaultTitle,
        unsetSearchForList: unsetSearchForList,
      };

      var uibModal = $injector.get('$uibModal');
      selectHostInstance = uibModal.open({
        controller: 'selectModalHostController',
        resolve: {
          hostInstance: function () {
            return hostInstance;
          },
        },
        templateUrl: 'ajs/partials/select_host_modal.html',
      });
    };

    /*if user have select an host, this function will be called
     */

    $scope.selectHost = function (host) {
      $scope.hostsButtonTitle = host.label;
      selectHostInstance.dismiss('cancel');
      if (host.treeid && host.treeid !== '') {
        filterSearch(host.treeid, true);
        $scope.searchInProgress = true;
      }
      stateListModel.setHostSearchText($scope.hostsButtonTitle, host.treeid);
    };

    /* listen for changes in input search field for hosts
     */
    $scope.$watch('hostSearchText', function (newVal, oldVal) {
      if (angular.equals(newVal, oldVal)) {
        return;
      } else if (newVal !== oldVal) {
        formInitDataService.filterItem(hostsList, newVal, 'hosts');
      }
    });

    /* function who remember last state in hosttree, after we
     * have look on a diagnose or have been on another tab
     */
    $scope.$watch('hostSelected', function (newVal) {
      if (newVal && newVal !== '') {
        $scope.search = '';
        $scope.searchInProgress = true;
        filterSearch(newVal, true);
      }
    });
    /*
     * if user push X clean Input textarea,
     * or search diagnoses is in use
     */
    $scope.clearHostSearch = function (all) {
      $scope.hostsButtonTitle = defaultTitle;
      unsetSearchForList(hostsList, 'hosts');
      stateListModel.setHostSearchText('', '');
      if (all) {
        $scope.searchInProgress = false;
      }
    };

    /* No need for this function, but select_host_modal require
     *this function, and modal is in use of 2 controllere (formcontroller and this controller)
     */

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function setTickForHosts(id, value, list) {}

    /*
     * Everytime we want to selct a host, we start on scratch
     */
    function unsetSearchForList(list, property) {
      var i,
        j = 0;
      for (i = 0; i < list.length; i++) {
        // ticked skal være false, slik at vi bare ser hovedkotegoriene
        var folder = list[i];
        folder.ticked = false;
        folder.filteredOut = false;
        // Gå igjennom alle skader og set filteredOut = false;
        for (j = 0; j < folder[property].length; j++) {
          folder[property][j].filteredOut = false;
          folder[property][j].ticked = false;
        }
      }
    }

    // END code for "Find host"

    stateListModel.tree.$promise.then(function (res) {
      $scope.tree = res;
      if ($scope.paramsShowAllSkade) {
        /*Google search use this functionaliy to get a site for search crawl.
         */
        $scope.toggleShowAll();
      }

      $scope.orderProp = stateListModel.orderProp;
      $scope.search = stateListModel.search;
      $scope.showAll = stateListModel.showAll;
      $scope.expandText = $scope.showAll ? 'Lukk' : 'Utvid';
      $scope.searchInProgress = false;
      $scope.paramsShowAllSkade = $routeParams.showAllSkade || false;
      if ($scope.search.trim() !== '') {
        $scope.searchInProgress = true;
        filterSearch($scope.search, false);
      }
    });

    /*
       Will set the cliked property for nodes in $scope.tree
       Will also change icon accordingly
       Uses depth first to traverse tree.
       */
    function setClickedInTree(value) {
      var newIcon = value ? 'glyphicon-minus-sign' : 'glyphicon-plus-sign';
      var stack = [
        {
          element: $scope.tree,
        },
      ];
      var current;
      var children, i, len;
      while (stack.length > 0) {
        current = stack.pop();
        //get the arguments
        current = current.element;
        children = current.children;
        for (i = 0, len = children.length; i < len; i++) {
          // Add extra properties to tree
          if (children[i].category === 'folder') {
            children[i].clicked = value;
            children[i].icon = newIcon;
            stack.push({
              //pass args via object or array
              element: children[i],
            });
          }
        }
      }
    }

    /*
       Requirement:
       * element must be part of node tree and have a parent property,
       and the same must be true for all ancestors etxcept for the root.
       At the root, parent must not be defined,
       * element can not be part of a tree with cycles,

       Will update the property 'prop' in each ancestor with value
       */

    function setPropInAncestors(element, prop, value) {
      if (!Object.hasOwn(element, 'parent')) {
        return;
      }
      var stillAncestors = true;
      var looper = element.parent;
      while (stillAncestors) {
        looper[prop] = value;
        if (typeof looper.parent !== 'undefined') {
          looper = looper.parent;
        } else {
          stillAncestors = false;
        }
      }
    }

    /*
       Will set the searchHit property for nodes in $scope.tree
       Uses depth first to traverse tree.
       */

    function setSearchHitInTree(value) {
      var stack = [
        {
          element: $scope.tree,
        },
      ];
      var current;
      var children, i, len;
      while (stack.length > 0) {
        current = stack.pop();
        //get the arguments
        current = current.element;
        children = current.children;
        for (i = 0, len = children.length; i < len; i++) {
          // Add extra properties to tree
          children[i].searchHit = value;

          if (children[i].category === 'folder') {
            stack.push({
              //pass args via object or array
              element: children[i],
            });
          }
        }
      }
    }

    function filterSearch(search, host) {
      setSearchHitInTree(false);
      var matchThis = search.toLowerCase();
      var stack = [
        {
          element: $scope.tree,
          parent: null,
        },
      ];
      var current;
      var children, i, len;
      var stackItem;
      var search2array;
      if (host) {
        search2array = search.split('.');
      }
      while (stack.length > 0) {
        stackItem = stack.pop();
        //get the arguments
        current = stackItem.element;
        children = current.children;
        for (i = 0, len = children.length; i < len; i++) {
          if (host) {
            if (children[i].hosts.length >= 1) {
              var j, len2;
              for (j = 0, len2 = children[i].hosts.length; j < len2; j++) {
                var hostsId2array;
                if (children[i].hosts[j].treeid) {
                  hostsId2array = children[i].hosts[j].treeid.split('.');
                }
                var firstPart = hostsId2array[0];
                var firstAndSecondPart = hostsId2array[0] + '.' + hostsId2array[1];
                var firstSecondAndThirdPart = hostsId2array[0] + '.' + hostsId2array[1] + '.' + hostsId2array[2];
                //check hostid, if f.eks 104 is case 1, if 104.106 is case 2 and 104.106.103 is case 3.
                //because we want all hits under a hostgroup
                switch (search2array.length) {
                  case 1:
                    if (firstPart === matchThis) {
                      setSearchHit(children[i]);
                    }
                    break;
                  case 2:
                    if (firstAndSecondPart === matchThis) {
                      setSearchHit(children[i]);
                    }
                    break;
                  case 3:
                    if (firstSecondAndThirdPart === matchThis) {
                      setSearchHit(children[i]);
                    }
                    break;
                }
              }
            }
            if (children[i].category === 'folder') {
              stack.push({
                //pass args via object or array
                element: children[i],
                parent: current,
              });
            }
          } else {
            if (children[i].label.toLowerCase().indexOf(matchThis) > -1) {
              setSearchHit(children[i]);
            }
            if (children[i].category === 'folder') {
              stack.push({
                //pass args via object or array
                element: children[i],
                parent: current,
              });
            }
          }
        }
      }
    }

    function setSearchHit(children) {
      // There is a string match. Set searchHit in ancestors
      children.searchHit = true;
      setPropInAncestors(children, 'searchHit', true);
    }

    $scope.toggleIcon = function (diagnosis) {
      if (diagnosis.icon === 'glyphicon-plus-sign') {
        diagnosis.icon = 'glyphicon-minus-sign';
      } else if (diagnosis.icon === 'glyphicon-minus-sign') {
        diagnosis.icon = 'glyphicon-plus-sign';
      }
    };

    $scope.toggleShowAll = function () {
      $scope.showAll = !$scope.showAll;
      stateListModel.setShowAll($scope.showAll);
      setClickedInTree($scope.showAll);
      $scope.expandText = $scope.showAll ? 'Lukk' : 'Utvid';
    };

    $scope.$watch('search', function (newVal, oldVal) {
      var all = false;
      if (angular.equals(newVal, oldVal)) {
        return;
      }
      if (newVal.trim() === '') {
        $scope.searchInProgress = false;
        //unsetSearchState();
      } else if (newVal !== oldVal) {
        $scope.searchInProgress = true;
        $scope.clearHostSearch(all);
        filterSearch(newVal, false);
      }

      stateListModel.setSearch(newVal);
    });

    $scope.goNext = function (hash) {
      $window.location.href = environment.frontend.baseUrl + environment.frontend.baseHref + hash;
    };

    $scope.changeIcon = function (e) {
      var inputElem = angular.element(e.target);
      var elem;
      if (inputElem.get(0).id === 'minus_toggle') {
        // Click on pluss-icon
        elem = inputElem;
      } else {
        elem = inputElem.find('#minus_toggle');
      }
      elem.toggleClass('glyphicon glyphicon glyphicon-minus-sign glyphicon-plus-sign');
    };
    //window._scope = $scope; // Just for debugging
  }
})(angular.module('skogskadeControllers'));
