import { EnvironmentTargetInterface } from '@environments/assets/environment-target.interface';

export enum SkogskadeProxyEndpointsEnum {
  GET_MUNICIPALITY_BORDER = '/kommunegrense',
}

export const nibioProxyApi: EnvironmentTargetInterface = {
  develop: {
    baseUrl: 'https://proxy1utv.int.nibio.no',
    paths: {
      municipalityWs: '/municipality_cache_ws',
    },
  },
  production: {
    baseUrl: 'https://proxy1.nibio.no',
    paths: {
      municipalityWs: '/municipality_cache_ws',
    },
  },
  test: {
    baseUrl: 'https://proxy1test.nibio.no',
    paths: {
      municipalityWs: '/municipality_cache_ws',
    },
  },
};
