require('leaflet');
require('leaflet.wms');
const { environment } = require("@environments/environment");

(function (skogskadeControllers) {
  'use strict';

  skogskadeControllers.controller('damageDetailController', damageDetailController);

  damageDetailController.$inject = [
    '$scope',
    '$http',
    '$routeParams',
    // '$sce',
    // '$location',
    '$timeout',
    '$uibModal',
    // '$uibModalStack',
    'formInitDataService',
    'SkadeDetail',
    'SkadeBilder',
    'sortDetailService',
    'stateListModel',
    'skogskadeGlobalOptions',
    'mapConf',
    'legendService'
  ];

  function damageDetailController(
    $scope,
    $http,
    $routeParams,
    // $sce,
    // $location,
    $timeout,
    $uibModal,
    // $uibModalStack,
    formInitDataService,
    SkadeDetail,
    SkadeBilder,
    sortDetailService,
    stateListModel,
    skogskadeGlobalOptions,
    mapConf,
    legendService
  ) {
    $http.defaults.useXDomain = true;
    $scope.skadeId = $routeParams.skadeId;
    $scope.mapId = 'diagnosisMap';
    $scope.map = null;
    $scope.diagnosisReportsShown = false;
    $scope.serviceUrl = skogskadeGlobalOptions.serviceUrl;
    var diagnosisList = stateListModel.getDiagnosisList();
    var info2popup = '';
    var report_ids = [];
    // var config = {
    //   attribution: 'NIBIO',
    //   layers: 'diagnosis_reports',
    //   format: 'image/png',
    //   transparent: true,
    //   opacity: 0.7,
    //   diagnosisid: $scope.skadeId,
    // };

    define(['leaflet.wms'], function(wms) {
      L.WMS = wms;
    });

    $scope.destroyMap = function() {
      if($scope.map) {
        $scope.map.off();
        $scope.map = null;
      }
      var mapContainer = document.getElementById('mapContainer');
      if (mapContainer) {
        mapContainer.innerHTML = '';
      }
    }

    $scope.initMap = function () {
      var wmsConfig = {
        format: 'image/png',
        transparent: 'true',
        attribution: 'NIBIO',
        info_format: 'application/vnd.ogc.gml',
        FEATURE_COUNT: 1000,
        diagnosisid: $scope.skadeId,
        tiled: false,
        maxZoom: 21,
        opacity: 1,
      };

      if ($scope.map) {
        $scope.map = null;
        $scope.mapContainer.innerHTML = '<div id="' + $scope.mapId + '" style="height: 600px; width: calc(100% - 44px)"></div>';
      }

      $scope.map = L.map($scope.mapId).setView([65, 10], 5);

      var config = mapConf.getSimpleMapConfig('');
      // START: make legend
      var legend = new L.Control({ position: 'topright' });
      legend.onAdd = function () {
        return legendService.getLegendElement();
      };
      legend.addTo($scope.map);
      // END: make legend
      L.tileLayer(config.layers.skmap.url, {
        attribution: 'Kartverket',
        maxZoom: 19,
        id: 'baselayer',
      }).addTo($scope.map);

      var wmsUrl = skogskadeGlobalOptions.serviceUrl + skogskadeGlobalOptions.serviceUrlProxy;

      var sourcePoint = L.WMS.source(wmsUrl, wmsConfig);

      var sourceLine = L.WMS.source(wmsUrl, wmsConfig);
      var sourcePolygonCenter = L.WMS.source(wmsUrl, wmsConfig);
      wmsConfig.opacity = 0.75;
      var sourcePolygon = L.WMS.source(wmsUrl, wmsConfig);

      var layerPoint = sourcePoint.getLayer('diagnosis_reports_point');
      var layerLine = sourceLine.getLayer('diagnosis_reports_line');
      var layerPolygon = sourcePolygon.getLayer('diagnosis_reports_polygon');
      var layerCenter = sourcePolygonCenter.getLayer('diagnosis_reports_center');
      layerCenter.addTo($scope.map);
      layerPolygon.addTo($scope.map);
      layerPoint.addTo($scope.map);
      layerLine.addTo($scope.map);

      $scope.layers = [layerCenter, layerPolygon, layerPoint, layerLine];
      $scope.map.on('click', function () {
        info2popup = '';
        report_ids = [];
      });
    };

    /*
     Put a hooks on L.WMS.Source its getFeatureInfo, we can then add new functionaliy
     */
    L.WMS.Source = L.WMS.Source.extend({
      'getFeatureInfo': function(point, latlng, layers, callback) {
        var params = this.getFeatureInfoParams(point, layers);
        var url = this._url + L.Util.getParamString(params, this._url);
        this.showWaiting();
        this.ajax(url, done);

        function done(result) {
          this.hideWaiting();
          var text = this.parseFeatureInfo(result, url);

          // start section for new code
          var parser, xmlDoc;
          var url = environment.frontend.baseUrl + environment.frontend.baseHref + '/rapporter/';

          if (result) {
            parser = new DOMParser();
            xmlDoc = parser.parseFromString(result, "text/xml");
            var checkXml = xmlDoc.documentElement.nodeName == "parsererror" ? "error while parsing" : xmlDoc.documentElement.nodeName;

            if (checkXml != "ServiceExceptionReport" && checkXml && xmlDoc.getElementsByTagName(checkXml)[0].childNodes.length > 1) {
              var nodes;

              // only one vil have value except polygon and polygoncenter, but we handled this
              // se next comment
              var antallNoderPolygon = xmlDoc.getElementsByTagName("diagnosis_reports_polygon_feature") || [];
              var antallNoderPoint = xmlDoc.getElementsByTagName("diagnosis_reports_point_feature") || [];
              var antallNoderLine = xmlDoc.getElementsByTagName("diagnosis_reports_line_feature") || [];
              var antallNoderPolygonCenter = xmlDoc.getElementsByTagName("diagnosis_reports_center_feature") || [];

              // if antallNoderPolygon have value, we vil not check antallNoderPolygonCenter
              if (antallNoderPolygon.length >= 1) {
                nodes = antallNoderPolygon.length
              } else
              if (antallNoderPoint.length >= 1) {
                nodes = antallNoderPoint.length
              } else
              if (antallNoderLine.length >= 1) {
                nodes = antallNoderLine.length
              } else
              if (antallNoderPolygonCenter.length >= 1) {
                nodes = antallNoderPolygonCenter.length
              } else
                return;


              for (var i = 0; i < nodes; i++) {
                var diagnoses = xmlDoc.getElementsByTagName("reports_diagnosis_list")[i].childNodes[0].nodeValue;
                var report_id = xmlDoc.getElementsByTagName("report_id")[i].childNodes[0].nodeValue;
                if($.inArray(report_id, report_ids) === -1)
                {
                  info2popup += "<p><b>" + diagnoses + "</b><br>" + "<a href=" + url + report_id + ">Se rapport nr. " + report_id + "</a></p>";
                  report_ids.push(report_id);
                }

              }
            } else {
              if (info2popup === "") {
                info2popup = "Ingen treff"
              }
            }
            if (info2popup.length > 20) {

              var substring = "Ingen treff";
              if (info2popup.indexOf(substring) !== -1) {
                info2popup = info2popup.replace(substring, "")
              }
            }

          }
          // End section for new code
          callback.call(this, latlng, info2popup)
        }

      },
    });

    if (!diagnosisList || Object.keys(diagnosisList).length === 0) {
      formInitDataService.getFormInitData().then(function (val) {
        stateListModel.setDiagnosisList(val.diagnosis);
        var temp = stateListModel.getBreadcrumb($scope.skadeId);
        $scope.breadcrumb = temp ? temp.reverse() : temp;
      });
    } else {
      var temp = stateListModel.getBreadcrumb($scope.skadeId);
      $scope.breadcrumb = temp ? temp.reverse() : temp;
    }

    $scope.skadeName = '';

    $scope.visible = false;

    $scope.detailsSkader = SkadeDetail.query({
      skadeId: $scope.skadeId,
    }).$promise.then(function (data) {
      var sortSkade = [
        'diagnosis',
        'latin',
        'english',
        'host',
        'occurence',
        'symptom',
        'biology',
        'description',
        'sign',
        'videos',
        'links',
        'confuse',
        'author',
      ];
      $scope.availableDiagnosis = data.visible;
      // Only show reports if this diagnosis is active
      if ($scope.availableDiagnosis) {
        sortSkade.push('report');
      }
      $scope.detailsSkaderSorted = sortDetailService.sortFunction(data.info, sortSkade);
      //$scope.detailsSkaderSorted = sortDetailService.sortFunction(data, sortSkade);
      $scope.skadeName = data.info.diagnosis.text;
    });

    $scope.detailsBilder = SkadeBilder.query({
      skadeId: $scope.skadeId,
    });

    // Reset Leaflet instance on navigation
    $scope.$on('$destroy', function( event ) {
      $scope.destroyMap(event);
    });

    $scope.$on('$routeChangeStart', function (event) {
      var currentModal = $scope.imgModal;
      if (angular.isDefined(currentModal)) {
        $scope.imgModal.modal('hide');
        $scope.imgModal.modal('removeBackdrop');
      }
      if (angular.isDefined($scope.diagnosisReportsModal)) {
        $scope.diagnosisReportsModal.modal('hide');
        $scope.diagnosisReportsModal.modal('removeBackdrop');
      }
    });

    $scope.openDiagnosisReports = function () {
      $uibModal.open({
        templateUrl: 'ajs/partials/report-table-modal.html',
        size: 'lg',
        scope: $scope,
        controller: [
          '$scope',
          '$uibModalInstance',
          function ($scope, $uibModalInstance) {
            $scope.ok = function () {
              $uibModalInstance.close();
            };
          },
        ],
      });
    };

    $scope.openModal = function () {
      $scope.imgModal = $('#detailImageModal');
      $scope.imgModal.modal('show');
    };

    var slides = $scope.detailsBilder;
    $scope.getActiveSlide = function () {
      return slides.filter(function (s) {
        return s.active;
      })[0];
    };

    // Wait for directive to render its template and add mapcontainer to DOM, then add map
    $scope.mapContainer = document.getElementById('mapContainer');
    if (!$scope.map) {
      $scope.map = document.getElementById('diagnosisMap');
    }

    $timeout(function () {
      if($scope.mapContainer && $scope.map != null && !$scope.map.hasChildNodes()) {
        $scope.initMap();
      } else {
        $timeout(function() {
          // Assign again
          $scope.mapContainer = document.getElementById('mapContainer');
          if (!$scope.map) {
            $scope.map = document.getElementById('diagnosisMap');
          }
          if ($scope.mapContainer && $scope.map != null) {
            $scope.initMap();
          }
        }, 200);
      }
    }, 200);
  }
})(angular.module('skogskadeControllers'));
