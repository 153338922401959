import { DateFormatterHelper } from '@core/helpers/date-formatter.helper';
import { environment } from '@environments/environment';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';

export class DateHelper {
  /**
   * Use defaults for time when we instantiate Date()s and only care about days
   */
  static readonly DEFAULT_HOURS = 12;
  static readonly DEFAULT_MINUTES = 34;
  static readonly DEFAULT_SECONDS = 56;
  static readonly DEFAULT_MILLISECONDS = 78;

  static getDateFormat(): string {
    return environment.formats.date;
  }

  static getTimeAndDateFormat(): string {
    return environment.formats.dateTime;
  }

  /**
   * Regex for format DD.MM.YYYY
   * Requires DD to be 01-31
   * Requires MM to be 01-12
   * Requires YYYY to be any 4 digits
   *
   * @type {RegExp}
   */
  static readonly REGEX_DATE_NOR_SHORT = /^([0-2]?[1-9]|10|20|3[0-1])\.(0[1-9]|1[0-2])\.\d{4}$/;

  /**
   * Return Date from input "DD.MM.YYYY"
   */
  static parseDateStringShortNor(dateStr: string): Date {
    const segments = dateStr.split('.');
    return new Date(segments[2] + '-' + segments[1] + '-' + segments[0]);
  }

  /**
   * Parses into Date from either of these formats:
   * 19.03.1976
   * 27.02.2021
   * 27/02/2021
   * 27 02 2021
   * 2021-02-27
   */
  static parseNorwegianDate(dateString: string | undefined): Date {
    if (!dateString) {
      throw new EmptyInputError('Could not parse date from string ' + dateString, { cause: 'EmptyInput' });
    }

    const match: RegExpMatchArray | null = dateString.match(
      /^(\d{1,2})\.(\d{1,2})\.(\d{4})$|^(\d{2}|\d{4})-(\d{1,2})-(\d{1,2})$/,
    );
    if (match == null) {
      throw new RegexNoMatchError('Could not parse date from string ' + dateString, { cause: 'RegexNoMatch' });
    }

    // TODO: Check date max 28-30 for the shorter months (29 for leap)

    let year;
    let month;
    let day;
    if (match[1]) {
      // dd mm YYYY
      day = parseInt(match[1], 10);
      month = parseInt(match[2], 10);
      year = parseInt(match[3], 10);
    } else if (match[4]) {
      // YYYY-mm-dd
      year = parseInt(match[4], 10);
      month = parseInt(match[5], 10);
      day = parseInt(match[6], 10);
    }

    return new Date(year, month - 1, day, DateHelper.DEFAULT_HOURS);
  }

  static subtractFromDate(date: Date, years?: number, months?: number, days?: number): Date {
    if (years !== undefined) {
      date.setFullYear(date.getFullYear() - years);
    }
    if (months !== undefined) {
      date.setMonth(date.getMonth() - months);
    }
    if (days !== undefined) {
      date.setDate(date.getDate() - days);
    }
    return date;
  }

  static toJSDate(ngbDate: NgbDate | NgbDateStruct): Date {
    const asNorStr = DateFormatterHelper.toNorString(ngbDate);
    return DateHelper.parseNorwegianDate(asNorStr);
  }

  static toNgbDate(jsDate: Date) {
    return new NgbDate(jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate());
  }

  /**
   * Return date in format "DD.MM.YYYY"
   */
  static toShortNorString(date: Date): string {
    return [
      date.getDate().toString().padStart(2, '0'),
      (date.getMonth() + 1).toString().padStart(2, '0'), // Use 1-index and prefix '0' if needed
      date.getFullYear(),
    ].join('.');
  }

  static validateNgbDateStruct(struct: NgbDateStruct): boolean {
    return struct && struct.year !== undefined && struct.month !== undefined && struct.day !== undefined;
  }
}

export class EmptyInputError extends Error {
  override name = 'EmptyInputError';
}

export class RegexNoMatchError extends Error {
  override name = 'RegexNoMatchError';
}
