import { AbstractControl } from '@angular/forms';

export class ValidatorHelper {
  static ctrlHasValue(control: AbstractControl) {
    return control.value && control.value.length;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static isEmptyInputValue(value: any): boolean {
    return value === null || value.length === 0;
  }
}
