import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
  SecurityContext,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ActivatedRoute,
  ActivationStart,
  Event as NavEvent,
  NavigationStart,
  Router,
  RouterLink,
} from '@angular/router';
import { BreadcrumbType } from '@apptypes/breadcrumb.type';
import {
  DiagnosisDetailNestedType,
  DiagnosisDetailResponseType,
  DiagnosisDetailType,
} from '@apptypes/responses/diagnosis-detail-response.type';
import { StringHelper } from '@core/helpers/string.helper';
import { DiagnosisService } from '@core/services/diagnosis.service';
import { LeafletEmbedComponent } from '@features/leaflet-embed/leaflet-embed.component';
import { ReportsModalComponent } from '@modals/reports-modal/reports-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbsComponent } from '@shared/components/breadcrumbs/breadcrumbs.component';
import { CarouselItemType } from '@shared/components/carousel/carousel-item.type';
import { CarouselComponent } from '@shared/components/carousel/carousel.component';
import { filter, tap } from 'rxjs';
import { concatMap, finalize, map } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, CarouselComponent, LeafletEmbedComponent, BreadcrumbsComponent, RouterLink],
  selector: 'app-damage-detail',
  standalone: true,
  styleUrls: ['./damage-detail.component.scss'],
  templateUrl: './damage-detail.component.html',
})
export default class DamageDetailComponent implements OnInit {
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _diagnosisService = inject(DiagnosisService);
  private readonly _domSanitizer = inject(DomSanitizer);
  private readonly _ngbModal = inject(NgbModal);
  private readonly _router = inject(Router);

  private readonly _detailFilter = [
    'diagnosis',
    'latin',
    'english',
    'host',
    'occurence',
    'symptom',
    'biology',
    'description',
    'sign',
    'videos',
    'links',
    'confuse',
    'author',
    'report',
  ];

  private _modalRef!: NgbModalRef;

  attemptedFetch: boolean = false;
  breadcrumbs: BreadcrumbType[] = [];
  carouselItems: CarouselItemType[] = [];
  details: (DiagnosisDetailType | DiagnosisDetailNestedType)[] = [];
  damage!: DiagnosisDetailResponseType | undefined;
  damageId!: string;

  ngOnInit(): void {
    this._fetchOnRouteParamChanges();
    this._closeModalOnNavigate();
  }

  openReportsModal(evt?: MouseEvent | Event) {
    evt?.stopPropagation();
    evt?.stopImmediatePropagation();
    evt?.preventDefault();

    if (!this.damageId) {
      return;
    }

    this._modalRef = this._ngbModal.open(ReportsModalComponent, {
      ariaLabelledBy: 'modalHeaderTitle',
      backdrop: 'static',
      fullscreen: 'lg',
      scrollable: true,
      size: 'xl',
    });

    this._modalRef.componentInstance.damageId = this.damageId;
    this._modalRef.componentInstance.title = 'Rapporter om skadetype';
    if (this.damage?.info.diagnosis?.text) {
      this._modalRef.componentInstance.title = 'Rapporter om «' + this.damage.info.diagnosis.text + '»';
    }
  }

  private _buildCarousel(response: DiagnosisDetailResponseType): void {
    this.carouselItems = [];
    if (response.photos?.length) {
      response.photos.forEach(photo => {
        this.carouselItems.push({
          attribution: photo.author,
          caption: photo.header,
          imgPath: photo.path,
          imgPathThumbnail: photo.pathSmall,
        });
      });
    }
  }

  private _buildDetails(damage: DiagnosisDetailResponseType): void {
    this._detailFilter.forEach(key => {
      if (damage.info[key]) {
        const detail: DiagnosisDetailType | DiagnosisDetailNestedType = {
          id: damage.info[key].id,
          label: damage.info[key].label,
          text: this._sanitizeAndFormat(damage.info[key].text),
        };

        if (Object.hasOwn(damage.info[key], 'children')) {
          (detail as DiagnosisDetailNestedType).children = [];

          damage.info[key]['children'].forEach(child => {
            (detail as DiagnosisDetailNestedType).children.push({
              id: child.id,
              label: child.label,
              text: this._sanitizeAndFormat(child.text),
            });
          });
        }

        this.details.push(detail);
      }
    });
  }

  private _closeModalOnNavigate(): void {
    // Make sure to close modal when navigating away from page
    this._router.events
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter(
          (event: NavEvent): event is NavigationStart =>
            event instanceof NavigationStart || event instanceof ActivationStart,
        ),
      )
      .subscribe({
        next: () => {
          if (this._modalRef) {
            this._modalRef.close();
          }
        },
      });
  }

  private _fetchOnRouteParamChanges(): void {
    this._activatedRoute.params
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        // Find damage ID from url
        map(routeParams => {
          this._purgeData();
          this.damageId = routeParams['diagnoseId'];
          return this.damageId;
        }),
        // Fetch breadcrums for this damage ID
        concatMap(dmgId => {
          return this._diagnosisService.getBreadcrumbs(dmgId).pipe(
            map(crumbs => {
              this.breadcrumbs = crumbs;
              return dmgId;
            }),
          );
        }),
        // Fetch all detail for this damage ID
        concatMap(dmgId => {
          this.attemptedFetch = false;
          return this._diagnosisService.getDetailsForDiagnosis(dmgId).pipe(
            tap(() => {
              this.attemptedFetch = true;
            }),
          );
        }),
        finalize(() => {
          this.attemptedFetch = true;
        }),
      )
      .subscribe({
        next: response => {
          if (Object.keys(response).length === 0) {
            this._purgeData(); // Clear any previous, this is a live param subscription
            this._cdr.detectChanges();
            return;
          }

          this.damage = response;
          this._buildCarousel(this.damage);
          this._buildDetails(this.damage);
          this._cdr.detectChanges();
        },
      });
  }

  private _purgeData(): void {
    this.breadcrumbs = [];
    this.damage = undefined;
    this.details = [];
  }

  private _sanitizeAndFormat(str?: string): string {
    if (str) {
      const cleaned = this._domSanitizer.sanitize(SecurityContext.HTML, str);
      if (cleaned?.length) {
        return StringHelper.ucFirst(cleaned);
      }
    }
    return '';
  }
}
