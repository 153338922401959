import { inject, Injectable } from '@angular/core';
import { CountiesMunicipalitiesDataType } from '@apptypes/counties-municipalities-data.type';
import { IdNameTuple } from '@apptypes/id-name-tuple.type';
import { OwsResponseType } from '@apptypes/responses/ows-response.type';
import { AppConfig } from '@core/app.config';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { ApiService } from '@core/services/api.service';
import { SkogskadeGeoserverEndpointsEnum, SkogskadeWsEndpointsEnum } from '@environments/apis/skogskade.api';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MunicipalityService {
  private readonly _apiService = inject(ApiService);
  private readonly _munisSubject$ = new ReplaySubject<IdNameTuple[]>(1);

  cachedCounties: IdNameTuple[] = [];
  cachedMunicipalities: IdNameTuple[] = [];
  cachedData: IdNameTuple[] = [];
  municipalities$ = this._munisSubject$.asObservable();
  stampDataFetchedAt!: number;

  fetchMunicipalityFeature(muniNumber: number) {
    const path = EnvironmentHelper.getSkogskadePaths().geoserver + SkogskadeGeoserverEndpointsEnum.GET_OWS;
    const queryParams = {
      cql_filter: 'komid=%27' + muniNumber + '%27',
      format_options: 'decimals:1',
      outputFormat: 'json',
      request: 'GetFeature',
      service: 'WFS',
      srsname: 'EPSG:4258',
      typeName: 'sl:n2000_komm_flate',
      version: '1.0.0',
    };

    return this._apiService.get<OwsResponseType>('skogskade', path, {}, queryParams).pipe(take(1));
  }

  fetchMunicipalities() {
    const path = EnvironmentHelper.getSkogskadePaths().ws + SkogskadeWsEndpointsEnum.GET_MUNICIPALITIES_AND_COUNTIES;

    return this._apiService.get<CountiesMunicipalitiesDataType>('skogskade', path).pipe(
      tap(response => {
        // Recall when we fetched this
        this.stampDataFetchedAt = Date.now();

        // Assign individual data
        this.cachedCounties = Object.keys(response.counties).map(key => {
          return { id: key, name: response.counties[key] + ' fylke' } as IdNameTuple;
        });
        this.cachedMunicipalities = Object.keys(response.municipalities).map(key => {
          return { id: key, name: response.municipalities[key] } as IdNameTuple;
        });

        this.cachedData = { ...this.cachedMunicipalities, ...this.cachedCounties };
        this._munisSubject$.next(this.cachedData);
      }),
    );
  }

  getMunicipalities(): Observable<IdNameTuple[]> {
    if (!this.cachedData || !Object.keys(this.cachedData)?.length || !this._isCacheValid(Date.now())) {
      this.fetchMunicipalities();
    }

    return this.municipalities$;
  }

  private _isCacheValid(timestamp: number): boolean {
    const maxAge = AppConfig.MUNICIPALITY_LIST_CACHE_TTL;
    const age = (timestamp - this.stampDataFetchedAt) / 1000;
    return age > maxAge;
  }
}
