import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, ParamMap, Params, Router, RouterLink } from '@angular/router';
import { FilterValuesType } from '@apptypes/filter-values.type';
import { ReportListFiltersType } from '@apptypes/report-list-filters.type';
import { DateHelper } from '@core/helpers/date.helper';
import { ReportFilterService } from '@core/services/report-filter.service';
import { ReportListService } from '@core/services/report-list.service';
import { environment } from '@environments/environment';
import { NgbHighlight, NgbPagination, NgbPaginationPages } from '@ng-bootstrap/ng-bootstrap';
import { ReportFilterComponent } from '@shared/components/report-filter/report-filter.component';
import { ReportsTableComponent } from '@shared/components/reports-table/reports-table.component';
import { SortableReportListDirective } from '@shared/directives/sortable.directive';
import { tap, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SortableReportListDirective,
    NgbPagination,
    FormsModule,
    NgbPaginationPages,
    NgbHighlight,
    RouterLink,
    ReportFilterComponent,
    ReportsTableComponent,
  ],
  selector: 'app-report-list',
  standalone: true,
  styleUrls: ['./report-list.component.scss'],
  templateUrl: './report-list.component.html',
})
export default class ReportListComponent implements OnInit {
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _dateToday = new Date();
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _reportFilterService = inject(ReportFilterService);
  private readonly _reportListService = inject(ReportListService);
  private readonly _router = inject(Router);

  @ViewChild('damageTypeRef', { static: true })
  damageTypeRef!: ElementRef;

  @ViewChildren(SortableReportListDirective)
  headers!: QueryList<SortableReportListDirective>;

  dateLastYear = DateHelper.subtractFromDate(new Date(), 1);
  errorLoading: boolean = false;
  processing: boolean = false;
  reportFilterServiceFiltersLoaded = false;
  reports$ = this._reportListService.reports$.pipe(
    tap(() => {
      this.errorLoading = false;
    }),
    catchError((e: HttpErrorResponse) => {
      this.errorLoading = true;
      return throwError(() => e);
    }),
  );
  service = this._reportListService;

  defaultCollectionSize = 20;
  defaultFrom = DateHelper.toShortNorString(this.dateLastYear);
  defaultSort = 'date_observed,desc';
  defaultTo = DateHelper.toShortNorString(this._dateToday);

  filters: Required<ReportListFiltersType> = {
    dateEnd: this.defaultTo,
    dateStart: this.defaultFrom,
    id: '',
    page: 1,
    perPage: this.defaultCollectionSize,
    queryDamage: '',
    queryPlace: '',
    sort: this.defaultSort,
  };

  get angularQueryParams(): Params {
    const result: { [key: string]: string } = {} as Params;

    if (this.filters.dateEnd?.length && this.filters.dateEnd !== this.defaultTo) {
      result['date_end'] = this.filters.dateEnd;
    }
    if (this.filters.dateStart?.length && this.filters.dateStart !== this.defaultFrom) {
      result['date_start'] = this.filters.dateStart;
    }
    if (this.filters.queryDamage?.length) {
      result['damage_type'] = this.filters.queryDamage;
    }
    if (this.filters.queryPlace?.length) {
      result['damage_place'] = this.filters.queryPlace;
    }
    if (this.filters.page && this.filters.page !== 1) {
      result['page'] = this.filters.page.toString();
    }
    if (this.filters.perPage && this.filters.perPage !== this.defaultCollectionSize) {
      result['per_page'] = this.filters.perPage.toString();
    }
    if (this.filters.sort?.length && this.filters.sort !== this.defaultSort) {
      result['sort'] = this.filters.sort;
    }

    return result;
  }

  ngOnInit(): void {
    this._activatedRoute.queryParamMap.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(qpMap => {
      this._parseQueryParams(qpMap);

      if (!this.reportFilterServiceFiltersLoaded && !qpMap.keys.length) {
        // Apply values from service
        this.setSearchTerms(this._reportFilterService.filterCurrentValues);
        this.reportFilterServiceFiltersLoaded = true;
        this._updateUrl();
      } else {
        // Let qparams or defaults rule
        this.setSearchTerms();
        this._updateUrl();
        this._updateReportFilters();
      }

      this._cdr.detectChanges();
    });

    this._initSubscriptions();
  }

  downloadCsv() {
    this._reportListService
      .getCsvPath({
        dateEnd: this.filters.dateEnd,
        dateStart: this.filters.dateStart,
        id: this.filters.id,
        page: this.filters.page,
        perPage: this.filters.perPage,
        queryDamage: this.filters.queryDamage,
        queryPlace: this.filters.queryPlace,
        sort: this.filters.sort,
      })
      .pipe(take(1))
      .subscribe({
        next: response => {
          if (response.csvPath?.length) {
            window.location.href = environment.frontend.serviceUrl + '/' + response.csvPath;
          }
        },
      });
  }

  pageChanged() {
    this.filters.page = this._reportListService.page;
    this._updateUrl();
  }

  perPageChanged() {
    this.filters.perPage = this._reportListService.perPage;
    this._updateUrl();
  }

  selectPage(page: string) {
    this._reportListService.page = parseInt(page, 10) || 1;
  }

  setSearchTerms(values?: FilterValuesType): void {
    if (values) {
      // Update local this.filters
      this.filters.queryPlace = values.placeSearch;
      this.filters.queryDamage = values.diagnosisSearch;
      if (values.toDate) {
        this.filters.dateEnd = DateHelper.toShortNorString(values.toDate);
      }
      if (values.fromDate) {
        this.filters.dateStart = DateHelper.toShortNorString(values.fromDate);
      }
      this._updateUrl();
    }

    // Send to Service
    this._reportListService.searchTerms = this.filters;
  }

  private _initSubscriptions(): void {
    // Receive changes to Reports pagination
    this._reportListService.reportsMeta$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe({
      next: meta => {
        this.filters.page = meta.page || 1;
        this.filters.perPage = meta.perPage || this.defaultCollectionSize;

        this._updateUrl();
      },
    });
  }

  private _parseQueryParams(qpMap: ParamMap): void {
    const qDateStart = qpMap.get('date_start');
    if (qDateStart?.length && DateHelper.parseDateStringShortNor(qDateStart) instanceof Date) {
      this.filters.dateStart = qDateStart;
    } else {
      this.filters.dateStart = this.defaultFrom;
    }
    const qDateEnd = qpMap.get('date_end');
    if (qDateEnd?.length && DateHelper.parseDateStringShortNor(qDateEnd) instanceof Date) {
      this.filters.dateEnd = qDateEnd;
    } else {
      this.filters.dateEnd = this.defaultTo;
    }
    const qDmgPlace = qpMap.get('damage_place');
    this.filters.queryPlace = qDmgPlace || '';
    const qDmgType = qpMap.get('damage_type');
    this.filters.queryDamage = qDmgType || '';
    const qPerPage = qpMap.get('per_page');
    if (qPerPage) {
      const intPerPage = parseInt(qPerPage);
      this.filters.perPage = intPerPage || this._reportListService.perPage;
    }
    const qPage = qpMap.get('page');
    if (qPage) {
      const intPage = parseInt(qPage);
      this.filters.page = intPage || this._reportListService.page;
    }
    const qSort = qpMap.get('sort');
    if (qSort) {
      this.filters.sort = qSort || this._reportListService.sort;
    }
  }

  private _updateReportFilters(): void {
    this._reportFilterService.updateValues({
      diagnosisSearch: this.filters.queryDamage,
      fromDate: DateHelper.parseDateStringShortNor(this.filters.dateStart),
      placeSearch: this.filters.queryPlace,
      toDate: DateHelper.parseDateStringShortNor(this.filters.dateEnd),
    });
  }

  /**
   * Navigate to same page with updated query params
   */
  private _updateUrl(): void {
    void this._router.navigate(['.'], {
      queryParams: this.angularQueryParams,
      relativeTo: this._activatedRoute,
    });
  }
}
