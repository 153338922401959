import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { SearchPlaceResponseType } from '@apptypes/responses/search-place-response.type';
import { SearchPlaceItemType } from '@apptypes/search-place-item.type';
import { AppConfig } from '@core/app.config';
import { CoordinateHelper } from '@core/helpers/coordinate.helper';
import { GeonorgeService } from '@core/services/geonorge.service';
import { MapSearchService } from '@core/services/map/map-search.service';
import { MapService } from '@core/services/map/map.service';
import { MapModesEnum } from '@enums/map-modes.enum';
import { NgbTypeaheadModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { isSearchPlaceType } from '@shared/guards/search-place-type.guard';
import * as L from 'leaflet';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  merge,
  Observable,
  of,
  OperatorFunction,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FormsModule, NgbTypeaheadModule, ReactiveFormsModule],
  selector: 'app-search-place',
  standalone: true,
  styleUrls: ['./search-place.component.scss'],
  templateUrl: './search-place.component.html',
})
export class SearchPlaceComponent implements AfterViewInit, OnInit {
  @ViewChild('searchInput', { read: ElementRef })
  searchInput!: ElementRef<HTMLInputElement>;

  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _fb = inject(NonNullableFormBuilder);
  private readonly _geonorgeService = inject(GeonorgeService);
  private readonly _mapSearchService = inject(MapSearchService);
  private readonly _mapService = inject(MapService);

  readonly FILTER_EXCLUDE_PLACE_TYPES = ['Fylke', 'Nasjon', 'Navnegard'];

  frm!: FormGroup<{ search: FormControl<string> }>;
  isValidCoordinate: boolean = false;
  isWithinBoundsCoordinate: boolean = false;
  mainlandBounds = L.latLngBounds(
    L.latLng(AppConfig.APP_GEO_EXTENT[1], AppConfig.APP_GEO_EXTENT[0]),
    L.latLng(AppConfig.APP_GEO_EXTENT[3], AppConfig.APP_GEO_EXTENT[2]),
  );
  mapMode = this._mapService.mapMode;
  mapModes = MapModesEnum;
  qtyFindSiteCandidates = this._mapSearchService.qtyCandidateFindSites;
  searchCtrl!: FormControl<string>;
  searchFailed = false;
  searchFocus$ = new Subject<string>();
  searching = false;
  showCoordinateSearchHint: boolean = false;

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    this.searchInput.nativeElement.focus();
    this.showCoordinateSearchHint = false;
    this._mapSearchService.removeSearchMarker();
    this._mapSearchService.clearSearches();
  }

  formatter = (x: { name: string }) => x.name;

  handleKeyboardEnter(): void {
    const val = this.searchCtrl.value;

    if (CoordinateHelper.isCoordinate(val)) {
      const segments = val.split(',');
      this._mapSearchService.setMarker(
        parseFloat(segments[1]),
        parseFloat(segments[0]),
        AppConfig.MAP_ID_SEARCHED_PLACE,
      );
      this.showCoordinateSearchHint = false;
      this._cdr.detectChanges();
    }

    if (isSearchPlaceType(val)) {
      this._processSelectedPlace(val);
    }
  }

  handleMouseSelectItem(event: NgbTypeaheadSelectItemEvent<SearchPlaceItemType>): void {
    if (event.item && isSearchPlaceType(event.item)) {
      this._processSelectedPlace(event.item);
    }
  }

  markSearchedAsFindSites(): void {
    this._mapSearchService.saveFindSiteCandidates();
  }

  ngAfterViewInit(): void {
    // Clear any leftover search history from last time page was shown
    this.clearSearch();

    // Whenever value is changed, check if we should be showing any search hints
    this.searchCtrl.valueChanges
      .pipe(
        tap((val: string | SearchPlaceItemType) => {
          if (typeof val === 'string') {
            this.isValidCoordinate = CoordinateHelper.isCoordinate(val);
            this.isWithinBoundsCoordinate =
              this.isValidCoordinate && CoordinateHelper.isWithinBounds(val, this.mainlandBounds);
            this.showCoordinateSearchHint = CoordinateHelper.isCoordinateLike(val);
          }
          this._cdr.detectChanges();
        }),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.frm = this._fb.group<{ search: FormControl<string> }>({
      search: this._fb.control<string>(''),
    });

    this.searchCtrl = this.frm.get('search') as FormControl;
  }

  search: OperatorFunction<string, SearchPlaceItemType[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(300), distinctUntilChanged());
    const inputFocus$ = this.searchFocus$;

    return merge(debouncedText$, inputFocus$).pipe(
      filter(term => term.length > 1),
      tap(() => (this.searching = true)),
      switchMap(term =>
        this._geonorgeService.fetchPlaces(encodeURIComponent(term)).pipe(
          map(response => {
            return this._parseSearchPlaceResponse(response);
          }),
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }),
        ),
      ),
      tap(() => (this.searching = false)),
    );
  };

  private _parseSearchPlaceResponse(response: SearchPlaceResponseType) {
    const parsed: SearchPlaceItemType[] = [];
    const places = response.navn;

    if (response.metadata.totaltAntallTreff > 0) {
      places
        .filter(
          place =>
            (place.stedstatus === 'aktiv' || place.stedstatus === 'Vedtatt') &&
            !this.FILTER_EXCLUDE_PLACE_TYPES.includes(place.navneobjekttype) &&
            place.kommuner?.length &&
            place.stedsnavn?.length,
        )
        .forEach(place => {
          const parsedPlace = {} as SearchPlaceItemType;
          parsedPlace.countyName = place.fylker[0].fylkesnavn;
          parsedPlace.municipalityId = place.kommuner[0].kommunenummer;
          parsedPlace.municipalityName = place.kommuner[0].kommunenavn;
          parsedPlace.name =
            place.stedsnavn[0]['skrivemåte'] + ', ' + place.navneobjekttype + ', ' + place.kommuner[0].kommunenavn;
          parsedPlace.posEast = place.representasjonspunkt['øst'];
          parsedPlace.posNorth = place.representasjonspunkt.nord;
          parsedPlace.type = place.navneobjekttype;
          parsed.push(parsedPlace);
        });
    }
    return parsed;
  }

  private _processSelectedPlace(place: SearchPlaceItemType): void {
    if (place.type === 'Kommune') {
      this._mapSearchService.setMunicipality(place);
    } else {
      this._mapSearchService.setMarker(place.posEast, place.posNorth, AppConfig.MAP_ID_SEARCHED_PLACE, place.name);
    }
  }
}
