import { AppConfig } from '@core/app.config';

(function (skogskadeControllers) {
  'use strict';

  skogskadeControllers.controller('searchController', searchControllerFn);

  searchControllerFn.$inject = ['$rootScope', '$scope', 'mapModeService', 'mapService'];

  function searchControllerFn($rootScope, $scope, mapModeService, mapService) {
    $scope.mode = mapModeService.getMapMode();
    $scope.showSaveFindSite =
      mapModeService.getMapMode() === 'drawOnMap' ? true : mapModeService.getMapMode() === 'editReport' ? true : false;
    $scope.removeSearch = function () {
      $scope.query = '';
      // using removeSearchMarker in parent scope
      $scope.removeSearchMarker();
    };

    /*
     If user have push button for "Bruk søk som funnsted" vi vill
     change layers option to true, for send this features to backend later
     */
    $scope.addSearchAsFindSite = function () {
      var drawnItems = mapService.getFeatureGroup($scope.mode);
      if (drawnItems) {
        drawnItems.eachLayer(function (layer) {
          layer.options[AppConfig.MAP_FEATURE_FINDSITE] = true;
          $rootScope.$broadcast('addFeatureAsSearchSite', $scope.mode);
        });
      }
    };

    $scope.geoExtent = [4.5, 58, 31, 70.1];

    /*
     Will extract nord and aust coordinates from string
     Format should be <numeric>,<numeric>
     returns object with nord and aust set null if coordinate format is not met
     */
    $scope.findNordAndAust = function (matchThis) {
      if (typeof matchThis === 'string') {
        var numbers = matchThis.split(',');
        if (numbers.length === 2) {
          if ($.isNumeric(numbers[0]) && $.isNumeric(numbers[1])) {
            return { nord: numbers[0].trim(), aust: numbers[1].trim() };
          }
        }
      }
      return null;
    };

    /*
     function used for matching coordinates
     */

    $scope.coordMatcher = function (query) {
      return function (q, cb) {
        var matches = [];
        var substringRegex;
        // an array that will be populated with substring matches
        //matches = , {label:'dolly'}];

        var coord = $scope.findNordAndAust(q);
        if (coord !== null) {
          if (isWithinExtent(coord.nord, coord.aust, $scope.geoExtent)) {
            coord.label = coord.nord + ',' + coord.aust;
            matches.push(coord);
          }
        }

        cb(matches);
      };
    };

    /*
     Check if nord and aust coordinates are within extent
     returns true if within and false otherwise
     */
    function isWithinExtent(nord, aust, extent) {
      // ekstent på dette formatet: [austMin, nordMin, austMax, nordMax]
      var austMin = extent[0],
        austMax = extent[2];
      var nordMin = extent[1],
        nordMax = extent[3];
      //console.log("Undersøker nord:" + nord + " og aust:" + aust );
      if (nord < nordMin || nord > nordMax) {
        //console.log("Nord koordinat " + nord +" utenfor range " + nordMin + "-" + nordMax);
        return false;
      }
      if (aust < austMin || aust > austMax) {
        //console.log("Aust koordinat " + aust +" utenfor range " + austMin + "-" + austMax);
        return false;
      }
      //console.log("Nord koordinat " + nord+ " Aust koordinat " + aust + "innenfor extent", extent);
      return true;
    }

    /*
     checks wehter query is a possible start of input for desimal coordinate
     Returns true query has potetial.
     Returns false if query in no way matches coordinate format or is already valid
     coordinate format
     */
    $scope.isPossibleDesimalCoord = function (query) {
      if (typeof query === 'string') {
        var numbers = query.split(',');
        if (numbers.length === 1) {
          // this is possibly a desimal coordinate if this is a number
          return $.isNumeric(numbers[0]);
        }
        if (numbers.length === 2) {
          if ($.isNumeric(numbers[0]) && $.isNumeric(numbers[1])) {
            // if both parts are numbers, this is not possible a coordinate but
            // a coordinate for sure, so return false
            var withinExtent = isWithinExtent(numbers[0], numbers[1], $scope.geoExtent);
            return !withinExtent;
          }
          // query can be some combo of  <blank><comma><blank>'ish input. We are in good mood and
          // acknowlege that this can be a coordinate'ish string
          if (numbers[0].trim() === '' || numbers[1].trim() === '') {
            return true;
          }
        }
      }
      return false;
    };
  }
})(angular.module('skogskadeControllers'));
