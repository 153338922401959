import { environment } from '@environments/environment';

(function (skogskadeControllers) {
  'use strict';

  skogskadeControllers.controller('reportListController', reportListControllerFn);

  reportListControllerFn.$inject = [
    '$window',
    '$scope',
    '$http',
    '$location',
    '$routeParams',
    'reportListService',
    'reportFilterService',
    'formInitDataService',
    'messageService',
    'skogskadeGlobalOptions',
    'dateHelper',
  ];

  function reportListControllerFn(
    $window,
    $scope,
    $http,
    $location,
    $routeParams,
    reportListService,
    reportFilterService,
    formInitDataService,
    messageService,
    skogskadeGlobalOptions,
    dateHelper,
  ) {
    var filter = reportFilterService;

    $scope.currentPage = 1;
    $scope.totalItems = 0;
    $scope.pageCount = 0;
    $scope.reportsPerPage = 50;
    $scope.sort = {
      column: 'date_observed',
      descending: true,
    };

    $scope.getPagedReports = function () {
      var sort = $scope.sort.column + ',';
      sort += $scope.sort.descending ? 'desc' : 'asc';
      var searchParams = {
        page: $scope.currentPage,
        size: $scope.reportsPerPage,
        sort: sort,
        to: $scope.toDate(),
        from: $scope.fromDate(),
        diagnosis: filter.getFilterValues().textSearch,
        place: filter.getFilterValues().placeSearch,
      };

      reportListService.getReportsPaged(searchParams).then(
        function (callbackdata) {
          $scope.filteredReports.filtered = callbackdata.records;
          $scope.totalItems = callbackdata.metadata.totalCount;
          $scope.pageCount = callbackdata.metadata.pageCount;
        },
        function (response) {
          var errorMessage = {
            title:
              'Klarte ikke å hente rapporter fra ' +
              dateHelper.dateToString(scope.fromDate) +
              ' til ' +
              dateHelper.dateToString(scope.toDate) +
              '.',
            text: 'Vennligst prøv igjen senere.',
          };
          messageService.setMessage(errorMessage);
          messageService.showMessage(true);
        },
      );
    };

    $scope.$watch('currentPage', function (newVal, oldVal) {
      if (newVal !== oldVal) {
        $scope.getPagedReports();
      }
    });
    // Watch the filter, and collect data on changes
    $scope.$watch(
      function () {
        return reportFilterService.getFilterValues().changes;
      },
      function (newVal, oldVal) {
        if (!angular.equals(newVal, oldVal) && newVal !== null) {
          $scope.currentPage = 1;
          $scope.getPagedReports();
        }
      },
    );

    $scope.downloadCsv = function () {
      var messageText1 = 'Nedlasting av data feilet, vennligst prøv igjen.';
      var messageText2 = 'Ingen rapporter er valgt.';

      var to = $scope.toDate();
      var from = $scope.fromDate();
      var diagnosis = filter.getFilterValues().textSearch;
      var place = filter.getFilterValues().placeSearch;
      if ($scope.filteredReports.filtered.length >= 1) {
        var reportsAsString = reportListService.computeReportIds($scope.filteredReports.filtered);
        var params = {
          to: $scope.toDate(),
          from: $scope.fromDate(),
          diagnosis: filter.getFilterValues().textSearch,
          place: filter.getFilterValues().placeSearch,
        };
        reportListService.downloadCSV(params).then(function (callbackdata) {
          if (callbackdata.data.csvPath) {
            //$window.open(callbackdata.data.csvPath, "_blank");
            $window.location.href = skogskadeGlobalOptions.serviceUrl + '/' + callbackdata.data.csvPath;
          } else {
            var errorMessage = {
              title: 'Noe gikk galt',
              text: messageText1,
            };
            messageService.setMessage(errorMessage);
            messageService.showMessage(true);
          }
        });
      } else {
        var errorMessage = {
          title: messageText2,
        };
        messageService.setMessage(errorMessage);
        messageService.showMessage(true);
      }
    };

    $scope.setFilteredValues = function (filteredReports) {
      var report_list = '';
      for (var i = 0; i < filteredReports.length; i++) {
        if (i !== 0) {
          report_list = report_list + ',';
        }

        report_list = report_list + filteredReports[i].id;
      }
    };

    $scope.goNext = function (hash) {
      $window.location.href = environment.frontend.baseUrl + environment.frontend.baseHref + hash;
    };

    $scope.changeSorting = function (column) {
      var sort = $scope.sort;

      if (sort.column == column) {
        sort.descending = !sort.descending;
      } else {
        sort.column = column;
        sort.descending = false;
      }
      $scope.getPagedReports();
    };

    $scope.validDate = function (jQueryId) {
      var inputText = $(jQueryId).val();
      if (inputText.length !== 10) {
        return false;
      }
      return true;
    };

    $scope.fromDate = function () {
      var d = filter.getFilterValue('fromDate');
      return dateHelper.dateToString(d);
    };

    $scope.toDate = function () {
      var d = filter.getFilterValue('toDate');
      return dateHelper.dateToString(d);
    };

    function init() {
      $scope.filteredReports = { filtered: [] };
      $scope.filterParam = { showSearchFilter: true };
      $scope.getPagedReports();
    }

    init();
  }
})(angular.module('skogskadeControllers'));
