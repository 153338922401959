import * as L from 'leaflet';
declare const angular: angular.IAngularStatic;

(function (skogskadeConstants) {
  'use strict';

  skogskadeConstants.constant('leafletStyles', {
    blueIcon: L.icon({
      iconSize: [16, 16],
      iconUrl: 'ajs/img/marker_blue_circle.png',
    }),
    diagnosisStyle: {
      color: 'red',
      fillColor: 'red',
      fillOpacity: 0.7,
      opacity: 1,
      weight: 3,
    },
    kommSenterIcon: L.icon({
      iconSize: [16, 16],
      iconUrl: 'ajs/img/marker_red_circle.png',
    }),
    landskogIcon: L.icon({
      iconSize: [16, 16],
      iconUrl: 'ajs/img/marker_green_circle.png',
    }),
  });
})(angular.module('skogskadeConstants'));
