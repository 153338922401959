<div class="modal-header">
  <h1 class="modal-title fs-5" id="modalHeaderTitle">{{ title }}</h1>
  <button (click)="modal.dismiss('Cross click')" aria-label="Lukk" class="btn-close" type="button"></button>
</div>
<div class="modal-body">
  <app-reports-table
    (pageChanged)="pageChanged()"
    (perPageChanged)="perPageChanged()"
    (sortChanged)="sortChanged()"
    [filters]="filters"
    [reports$]="reports$"
  ></app-reports-table>
</div>
<div class="modal-footer">
  <button (click)="modal.close('Close click')" class="btn btn-secondary" type="button">Lukk</button>
</div>
