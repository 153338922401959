import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterLink } from '@angular/router';
import {
  ReportDetailDataTupleType,
  ReportDetailDiagnosisListChildrenType,
  ReportDetailResponseType,
} from '@apptypes/responses/report-detail-response.type';
import { StringHelper } from '@core/helpers/string.helper';
import { ReportService } from '@core/services/report.service';
import { LeafletEmbedComponent } from '@features/leaflet-embed/leaflet-embed.component';
import { CarouselItemType } from '@shared/components/carousel/carousel-item.type';
import { CarouselComponent } from '@shared/components/carousel/carousel.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CarouselComponent, CommonModule, LeafletEmbedComponent, RouterLink],
  selector: 'app-report-detail',
  standalone: true,
  styleUrls: ['./report-detail.component.scss'],
  templateUrl: './report-detail.component.html',
})
export default class ReportDetailComponent implements OnInit {
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _reportService = inject(ReportService);

  private readonly _detailFilter = [
    'hostsList',
    'municipalityList',
    'date_observed',
    'observer',
    'comments',
    'feedback',
    'report_id',
    'location',
    'altitude',
    'age',
    'municipe',
    'stande',
    'treepart',
    'vegetation',
    'soil',
    'report_type',
    'date_start',
    'date_end',
  ];

  attemptedFetch: boolean = false;
  carouselItems: CarouselItemType[] = [];
  details: ReportDetailDataTupleType[] = [];
  links: { route: string; text: string }[] = [];
  report: ReportDetailResponseType | undefined = undefined;
  reportId: string | undefined;

  ngOnInit(): void {
    this._activatedRoute.paramMap.pipe(takeUntilDestroyed(this._destroyRef)).subscribe({
      next: paramMap => {
        if (paramMap.get('id')?.length) {
          this.reportId = paramMap.get('id') || undefined;
          if (this.reportId) {
            this._purgeData();
            this._reportService.getReport(this.reportId).subscribe({
              error: () => {
                this.attemptedFetch = true;
                this.report = undefined;
                this._cdr.detectChanges();
              },
              next: response => {
                this.report = response;
                this.attemptedFetch = true;
                this._buildCarousel(this.report);
                if (this.report.diagnosisList) {
                  this._buildDiagnosisLinks(this.report.diagnosisList.children);
                }
                this._buildDetails(this.report);
                this._cdr.detectChanges();
              },
            });
          }
        }
      },
    });
  }

  private _buildCarousel(report: ReportDetailResponseType): void {
    this.carouselItems = [];
    if (report.photos?.length) {
      report.photos.forEach(photo => {
        this.carouselItems.push({
          attribution: photo.author,
          caption: photo.header,
          imgPath: photo.path,
          imgPathThumbnail: photo.pathSmall,
        });
      });
    }
  }

  private _buildDiagnosisLinks(diagnosisList: ReportDetailDiagnosisListChildrenType[]): void {
    diagnosisList.forEach(diag => {
      if (diag.id?.length) {
        this.links.push({
          route: '/skader/' + diag.id,
          text: diag.text,
        });
      }
    });
  }

  private _buildDetails(report: ReportDetailResponseType): void {
    this._detailFilter.forEach(key => {
      if (report[key]) {
        this.details.push({
          label: report[key].label,
          text: StringHelper.ucFirst(report[key].text),
        });
      }
    });
  }

  private _purgeData(): void {
    this.attemptedFetch = false;
    this.details = [];
    this.links = [];
  }
}
