(function (skogskadeControllers) {
  'use strict';

  skogskadeControllers.controller('modalInstanceController', modalInstanceCtrlFn);

  modalInstanceCtrlFn.$inject = ['$scope', '$uibModalInstance', 'message', 'messageService'];

  function modalInstanceCtrlFn($scope, $uibModalInstance, message, messageService) {

  $scope.message = message;

  $scope.close = function () {
    messageService.showMessage(false);
    $uibModalInstance.close();
    messageService.callCloseFunction();
  };

  $scope.ok = function () {
    $uibModalInstance.close();
  };

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
}

})(angular.module('skogskadeControllers'));
