import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'validation',
  standalone: true,
})
export class ValidationPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any, errorObj: ValidationErrors | null): unknown {
    if (!errorObj || !value[Object.keys(errorObj)[0]]) {
      return null;
    }
    const errorKey = Object.keys(errorObj)[0];
    const current = value[errorKey];

    // Handle errors created by NgBootstrap:
    // If what we have is an obj, traverse 1 level deeper.
    if (typeof current === 'object') {
      const firstKey = Object.keys(current)[0];
      if (current[firstKey] !== undefined) {
        return current[firstKey];
      }
    }

    return current;
  }
}
