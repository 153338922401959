import { FeatureCollection } from 'geojson';

/**
 * Verify obj matches:
 * {
 *   type: 'FeatureCollection',
 *   features: []
 * }
 */
export function isFeatureCollection(obj: object): obj is FeatureCollection {
  return (
    obj !== undefined &&
    Object.hasOwn(obj, 'type') &&
    obj['type'] === 'FeatureCollection' &&
    Object.hasOwn(obj, 'features') &&
    Array.isArray(obj['features'])
  );
}
