<div class="container-fluid mb-5 mt-5">
  <div class="row justify-content-md-center">
    <div *ngIf="attemptedFetch && !report?.report_id" class="col-sm-10">
      <h1>Feil</h1>
      Feil: Fant ingen rapport med den angitte ID-en {{ reportId }}.
    </div>

    <ng-container *ngIf="report && report.report_id">
      <div class="col-sm-12 col-md-10">
        <section class="data">
          <div class="details">
            <table class="table">
              <tbody>
                <tr *ngIf="report.diagnosisList">
                  <td class="descriptor">Skadetype:</td>
                  <td class="data">
                    <ng-container *ngFor="let link of links; last as isLast">
                      <a [routerLink]="link.route">{{ link.text }}</a>
                      <span *ngIf="!isLast">, </span>
                    </ng-container>
                  </td>
                </tr>
                <ng-container *ngFor="let detail of details">
                  <tr *ngIf="detail.text?.length">
                    <td class="descriptor">{{ detail.label }}</td>
                    <td class="text">{{ detail.text }}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>

          <div *ngIf="carouselItems?.length" class="photos">
            <app-carousel [items]="carouselItems"></app-carousel>
          </div>
        </section>

        <section class="map mt-2">
          <app-leaflet-embed [reportId]="reportId">
            <div class="leaflet-embed"></div>
          </app-leaflet-embed>
        </section>
      </div>
    </ng-container>
  </div>
</div>
