import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { StringHelper } from '@core/helpers/string.helper';
import { environment } from '@environments/environment';
import { GenericTextModalComponent } from '@modals/generic-text/generic-text-modal.component';
import { NgbCarousel, NgbModal, NgbSlide } from '@ng-bootstrap/ng-bootstrap';
import { CarouselItemType } from '@shared/components/carousel/carousel-item.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NgbCarousel, NgbSlide],
  selector: 'app-carousel',
  standalone: true,
  styleUrls: ['./carousel.component.scss'],
  templateUrl: './carousel.component.html',
})
export class CarouselComponent {
  @Input({ required: true })
  items: CarouselItemType[] = [];

  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _ngbModal = inject(NgbModal);

  protected readonly photoBase = environment.frontend.serviceUrl + '/';
  protected readonly strHelper = StringHelper;

  openModal(item: CarouselItemType) {
    const modalRef = this._ngbModal.open(GenericTextModalComponent, {
      ariaLabelledBy: 'modalHeaderTitle',
      fullscreen: 'sm',
      scrollable: true,
      size: 'xl',
    });

    modalRef.componentInstance.bodyHtml = `<img alt="${item.caption || ''}" src="${this.photoBase + item.imgPath}" />`;
    modalRef.componentInstance.title = item.caption;
  }
}
