import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ObjectHelper } from '@core/helpers/object.helper';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  selector: 'app-draw-introduction-modal',
  standalone: true,
  styleUrls: ['./draw-introduction-modal.component.css'],
  templateUrl: './draw-introduction-modal.component.html',
})
export class DrawIntroductionModalComponent {
  static readonly INTRO_OPTOUT_KEY = 'drawIntroductionModal';

  @Input()
  set options(data: object) {
    this.config = ObjectHelper.nestedAssign(this.config, data);
  }

  config = {};

  constructor(public modal: NgbActiveModal) {}

  optOutIntro(): void {
    if (localStorage) {
      localStorage.setItem(DrawIntroductionModalComponent.INTRO_OPTOUT_KEY, 'hide');
    }
    this.modal.close();
  }
}
