(function (skogskadeServices) {
  'use strict';

  skogskadeServices.factory('formInitDataService', formInitDataFn);

  var imageList = [];
  var imageDataList = [];
  var readTerms = false;
  var readTermsReport = false;

  formInitDataFn.$inject = ['$resource', '$http', '$log', '$q', '$filter', 'skogskadeGlobalOptions'];

  function formInitDataFn($resource, $http, $log, $q, $filter, skogskadeGlobalOptions) {
    var formInitDataUrl = skogskadeGlobalOptions.serviceUrl + '/skogskade_ws/rest/services/report/initform';
    var formData = null;
    var date = '';
    var diagnosis = [];
    var hosts = [];
    var comment = '';
    var image = '';
    var observer = '';
    var email = '';
    var phone = '';
    var geoPristine = true;

    function clearFormData() {
      date = '';
      diagnosis = [];
      hosts = [];
      comment = '';
      image = '';
      observer = '';
      email = '';
      phone = '';
      imageList = [];
      imageDataList = [];
    }

    /**
     Recursively calls itself, converting  the structure of the tree into a flat list
     (One array without any sublevels)

     parent - JSON structure containing an children array, where each element in the array
     must contain an 'label'-item, 'id'-item and category-item and may contain a
     new children-array
     level - the level of the element and some prefix to generate
     resultlist - An array that children of current parent will be appended to
     sortFunction - function passed to Array.sort that will sort each level before flattening it

     Function is modifying the resultList in-parameter
     **/
    function flattenParentChildJSON(parent, level, resultList, sortFunction) {
      var parentIndex = 0;
      // the first codition is neccessary to make code execute with top level
      if (parent.category === undefined || parent.category === 'folder') {
        var childIdx = 0;

        // Before we begin, we order the children list according to sort function
        // provided
        parent.children.sort(sortFunction);
        while (childIdx < parent.children.length) {
          var child = parent.children[childIdx];
          var prepad = '';
          // Uncomment to add different level of spacing
          for (var i = 0; i < level; i++) {
            prepad += '&nbsp&nbsp&nbsp';
          }

          resultList.push({
            label: child.label,
            id: child.id,
            level: level,
            ticked: false,
            disabled: false,
            filteredOut: false,
            treeid: child.treeid,
            category: child.category,
            indent: prepad,
            order: child.order_by,
          });
          flattenParentChildJSON(child, level + 1, resultList, sortFunction);
          childIdx++;
        }
      }
    }

    function compareLabelLocale(a, b) {
      return a.label.localeCompare(b.label);
    }

    /*
     Will build an array of objects where all diagnosis are presented in a sorted list
     Each sub-level in the formData.diagnosis structure is sorted alphabetically. An indent-
     attribute is added to each diagnose with a concatenated indent that can give tructure to
     the list if prepended to label.

     */
    function buildSelectModelDiagnosis() {
      var leveledDiagnosisList = [];
      var diagnosis = formData.diagnosis;
      var childIdx = 0;
      // First level of diagnosis we need to order according to
      // predefined order
      var d = [];
      while (childIdx < diagnosis.children.length) {
        var a = [];
        // order is defined in first four folders in json
        var order = diagnosis.children[childIdx].order_by;
        flattenParentChildJSON(diagnosis.children[childIdx], 0, a, compareLabelLocale);

        //a.sort( compareFunc );
        d.push({
          id: diagnosis.children[childIdx].id,
          order: order,
          aktive: false,
          ticked: false,
          filteredOut: false,
          diagnosis: a,
          label: diagnosis.children[childIdx].label,
          category: diagnosis.children[childIdx].category,
        });
        childIdx++;
      }
      d.sort(function (a, b) {
        return a.order - b.order;
      });
      leveledDiagnosisList = leveledDiagnosisList.concat(d);
      return leveledDiagnosisList;
    }

    function increasingOrder(a, b) {
      return a.order - b.order;
    }

    function increasingOrderBy(a, b) {
      return a.order_by - b.order_by;
    }

    function filterItem(items, field, property) {
      if (typeof field === 'undefined' || field.trim() === '') {
        return items;
      }
      for (var i = 0; i < items.length; i++) {
        var dlist = items[i][property];
        var filteredGroup = $filter('filter')(dlist, field, false);

        if (filteredGroup.length > 0) {
          items[i].aktive = true;
          items[i].filteredOut = false;
          for (var s = 0; s < dlist.length; s++) {
            var filteredOut = true;
            for (var t = 0; t < filteredGroup.length; t++) {
              if (dlist[s].id === filteredGroup[t].id) {
                filteredOut = false;
                break;
              }
            }
            dlist[s].filteredOut = filteredOut;
          }
        } else {
          items[i].ticked = false;
          items[i].filteredOut = true;
        }
      }
      return items;
    }

    /*
     Function is used by FormController and controller.
     Formcontroller without params and controller with params
     */

    function buildSelectModelHosts(hostsParam) {
      var leveledHostsList = [];
      var hosts = '';
      if (!hostsParam) {
        hosts = formData.host;
      } else {
        hosts = hostsParam;
      }
      var childIdx = 0;
      var d = [];
      while (childIdx < hosts.children.length) {
        var a = [];
        // order is defined in first four folders in json
        var order = hosts.children[childIdx].order_by;
        flattenParentChildJSON(hosts.children[childIdx], 0, a, increasingOrderBy);
        //a.sort(increasingOrder);
        var groupLabel = hosts.children[childIdx].label || '';
        groupLabel = groupLabel.charAt(0).toUpperCase() + groupLabel.slice(1);
        var id = hosts.children[childIdx].id;
        var treeid = hosts.children[childIdx].treeid;
        a = fixLabels(a);
        d.push({
          filteredOut: false,
          aktive: false,
          ticked: false,
          order: order,
          hosts: a,
          label: groupLabel,
          id: id,
          treeid: treeid,
          category: 'folder',
        });
        childIdx++;
      }
      d.sort(increasingOrder);
      for (var i = 0; i < d.length; i++) {
        leveledHostsList.push(d[i]);
      }
      return leveledHostsList;
    }

    function fixLabels(labeledList) {
      var i = 0;
      while (i < labeledList.length) {
        var label = labeledList[i].label || '';
        var findScientificName = label.substring(label.indexOf('(') + 1, label.indexOf(')'));
        if (findScientificName !== '') {
          label = label.replace(findScientificName, findScientificName.italics());
        }
        var parts = label.split(',');
        if (parts.length > 1) {
          label = parts[0].trim() + ' (' + parts.splice(1, 4).join('').trim() + ')';
        }
        // Capitalize first letter of stand name
        label = label.charAt(0).toUpperCase() + label.slice(1);
        labeledList[i].label = label;
        i++;
      }
      return labeledList;
    }

    /*
     Find and return diagnosis with the corrsponding id.
     Returns null if id is not found
     Uses a "Iterative breadth first backwards"-approach
     for searching the diagnosis structure.
     http://codereview.stackexchange.com/questions/47932/recursion-vs-iteration-of-tree-structure

     */
    var findDiagnosisWithID = function (id) {
      var stack = [
        {
          depth: 0,
          element: formData.diagnosis,
        },
      ];
      var current;
      var children, i, len;
      var depth;
      while (stack.length > 0) {
        current = stack.pop();
        //get the arguments
        depth = current.depth;
        current = current.element;
        children = current.children;
        for (i = 0, len = children.length; i < len; i++) {
          if (children[i].id == id) {
            return children[i];
          }
          if (children[i].category === 'folder') {
            stack.push({
              //pass args via object or array
              element: children[i],
              depth: depth + 1,
            });
          }
        }
      }
      return null;
    };

    var getReadTerms = function () {
      return readTerms;
    };
    var getReadTermsReport = function () {
      return readTermsReport;
    };

    var getFormInitData = function () {
      if (formData === null) {
        return $http.get(formInitDataUrl, { isArray: false }).then(function (response) {
          formData = response.data;
          return formData;
        });
      } else {
        return $q.when(formData);
      }
    };

    var getImageList = function () {
      return imageList;
    };
    var getImageDataList = function () {
      return imageDataList;
    };

    var getDiagnosisList = function () {
      return buildSelectModelDiagnosis();
    };

    var getHostsList = function (hosts) {
      return buildSelectModelHosts(hosts);
    };

    var getFormValues = function () {
      return {
        date: date,
        diagnosis: diagnosis,
        hosts: hosts,
        comment: comment,
        image: image,
        observer: observer,
        email: email,
        phone: phone,
      };
    };

    /*
     get the ids for the diagnosis
     */
    var getDiagnosisIds = function () {
      var diagnosisIds = [];
      for (var i = 0; i < diagnosis.length; i++) {
        diagnosisIds.push(diagnosis[i].id);
      }
      return diagnosisIds;
    };
    /*
     get the ids for the hosts
     */
    var getHostsIds = function () {
      var hostsIds = [];
      if (hosts !== null && typeof hosts !== 'undefined') {
        for (var i = 0; i < hosts.length; i++) {
          hostsIds.push(hosts[i].id);
        }
      }
      return hostsIds;
    };
    var formateDate = function (dateIn) {
      var yyyy = dateIn.getFullYear().toString();
      var mm = (dateIn.getMonth() + 1).toString(); // getMonth() is zero-based
      var dd = dateIn.getDate().toString();
      return (dd[1] ? dd : '0' + dd[0]) + '.' + (mm[1] ? mm : '0' + mm[0]) + '.' + yyyy;
    };
    var getDate = function () {
      return date;
    };
    var getHosts = function () {
      return hosts;
    };

    var getDiagnosis = function () {
      return diagnosis;
    };

    var getComment = function () {
      return comment;
    };
    var getImage = function () {
      return image;
    };
    var getObserver = function () {
      return observer;
    };
    var getEmail = function () {
      return email;
    };
    var getPhone = function () {
      return phone;
    };

    var getGeoPristine = function () {
      return geoPristine;
    };
    var setReadTerms = function (value) {
      readTerms = value;
    };
    var setReadTermsReport = function (value) {
      readTermsReport = value;
    };
    var addImageToList = function (value) {
      imageList.push(value);
    };
    var setImageDataList = function (value) {
      imageDataList = value;
    };
    var setImageList = function (value) {
      imageList = value;
    };
    var addImageDataToList = function (value) {
      imageDataList.push(value);
    };

    var checkNewImage = function (value) {
      if (imageDataList.length > 0) {
        for (var i = 0; i < imageDataList.length; i++) {
          if (imageDataList[i].name === value.name) {
            return false;
          }
        }
        return true;
      } else return true;
    };
    var setDate = function (value) {
      date = value;
    };

    var addDiagnosis = function (id) {
      diagnosis.push(id);
    };

    var removeDiagnosis = function (id) {
      var index = diagnosis.indexOf(id);
      if (index !== -1) {
        diagnosis.splice(index, 1);
      }
    };

    var setHosts = function (value) {
      hosts = value;
    };
    var setComment = function (value) {
      comment = value;
    };
    var setImage = function (value) {
      image = value;
    };
    var setObserver = function (value) {
      observer = value;
    };
    var setEmail = function (value) {
      email = value;
    };
    var setPhone = function (value) {
      phone = value;
    };
    var setGeoPristine = function (value) {
      geoPristine = value;
    };
    var interfaces = {
      addImageDataToList: addImageDataToList,
      addImageToList: addImageToList,
      filterItem: filterItem,
      findDiagnosisWithID: findDiagnosisWithID,
      getReadTerms: getReadTerms,
      getReadTermsReport: getReadTermsReport,
      getImageList: getImageList,
      getImageDataList: getImageDataList,
      getDiagnosisList: getDiagnosisList,
      getHostsList: getHostsList,
      getFormInitData: getFormInitData,
      getFormValues: getFormValues,
      getDiagnosisIds: getDiagnosisIds,
      getHostsIds: getHostsIds,
      setReadTerms: setReadTerms,
      setReadTermsReport: setReadTermsReport,
      setImageDataList: setImageDataList,
      setImageList: setImageList,
      setDate: setDate,
      addDiagnosis: addDiagnosis,
      removeDiagnosis: removeDiagnosis,
      setHosts: setHosts,
      setComment: setComment,
      setImage: setImage,
      setObserver: setObserver,
      setEmail: setEmail,
      setPhone: setPhone,
      setGeoPristine: setGeoPristine,
      clearFormData: clearFormData,
      checkNewImage: checkNewImage,
      formateDate: formateDate,
      date: getDate,
      diagnosis: getDiagnosis,
      hosts: getHosts,
      comment: getComment,
      image: getImage,
      observer: getObserver,
      email: getEmail,
      phone: getPhone,
      geoPristine: getGeoPristine,
    };
    return interfaces;
  }
})(angular.module('skogskadeServices'));
