<div class="container-fluid mb-5">
  <div class="row justify-content-md-center">
    <div *ngIf="attemptedFetch && (!damageId || !damage)" class="col-sm-10">
      <h1>Feil</h1>
      Feil: Fant ingen skadebeskrivelse med den angitte ID-en {{ damageId }}.
    </div>

    <div *ngIf="damageId && damage" class="col-sm-12 col-md-10 damage">
      <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

      <h1>{{ damage.info.diagnosis?.text }}</h1>

      <section class="data">
        <a *ngIf="damage.visible" [queryParams]="{ diagnosisId: damageId }" routerLink="/meld-skade"
          >Registrer ny observasjon</a
        >
        <div *ngIf="carouselItems?.length" class="photos">
          <app-carousel [items]="carouselItems"></app-carousel>
        </div>

        <div class="details">
          <ng-container *ngFor="let detail of details">
            <h3 class="descriptor">{{ detail.label }}</h3>
            <div *ngIf="detail.text?.length" class="text">
              <!-- Latin names shown in cursive -->
              <div [class.cursive]="detail.id === '3'" [innerHTML]="detail.text"></div>

              <button
                *ngIf="detail.id === '1' && damage.visible"
                (click)="openReportsModal($event)"
                (keyup.enter)="openReportsModal($event)"
                class="btn btn-primary btn-sm"
                type="button"
              >
                Se rapportene
              </button>
            </div>

            <!-- Vertsplanter -->
            <ul *ngIf="detail.id === '14' && detail['children']?.length > 0" class="nested nested-host">
              <li *ngFor="let host of detail['children']">{{ host.label }}</li>
            </ul>

            <!-- Often mistaken for -->
            <ul *ngIf="detail.id === '13' && detail['children']?.length > 0" class="nested nested-confuse">
              <li *ngFor="let child of detail['children']">
                <a [routerLink]="'/skader/' + child.id" class="nested-link">{{ child.label }}</a>
                <span [innerHTML]="child.text" class="text"></span>
              </li>
            </ul>

            <!-- Links and videos -->
            <div *ngIf="['11', '12'].includes(detail.id) && detail['children']?.length > 0" class="nested nested-video">
              <a *ngFor="let child of detail['children']" [href]="child.text" class="item" target="_blank">
                <i *ngIf="detail.id === '11'" class="bi bi-play-btn"></i>
                <i *ngIf="detail.id === '12'" class="bi bi-link"></i>
                {{ child.label }}
              </a>
            </div>
          </ng-container>
        </div>
      </section>

      <section class="map mt-4">
        <app-leaflet-embed [diagnosisId]="damageId">
          <div class="leaflet-embed"></div>
        </app-leaflet-embed>
      </section>
    </div>
  </div>
</div>
