(function (skogskadeServices) {
  'use strict';

  skogskadeServices.factory('mapDataService', mapDataServiceFn);

  mapDataServiceFn.$inject = ['$http', '$q', 'skogskadeGlobalOptions', 'reportFilterService', 'messageService'];

  function mapDataServiceFn($http, $q, skogskadeGlobalOptions, reportFilterService, messageService) {
    var getFeatureForReport = function (report_id) {
      $http.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
      var queryParams =
        'service=WFS&version=1.0.0&' +
        'request=GetFeature&typeName=skogskade:map_report_v&maxFeatures=5000' +
        '&outputFormat=application/json&cql_filter=report_id%20IN%20(' +
        report_id +
        ')&';
      var queryUrl = skogskadeGlobalOptions.geoserverPath + 'skogskade/ows/';
      return $http
        .post(queryUrl, queryParams)
        .success(function (response, status) {
          return response;
        })
        .error(function (data, status) {
          var userMessage = 'Kartserver feiler,status: ' + status + '.<br>';
          var infoMessage = {
            title: 'Finner ingen kartobjekter.',
            text: userMessage,
          };
          messageService.setMessage(infoMessage);
          messageService.showMessage(true);
        });
    };

    return {
      getFeatureForReport: getFeatureForReport,
    };
  }
})(angular.module('skogskadeServices'));
