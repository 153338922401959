(function (skogskadeControllers) {
  'use strict';

  // http://localhost:8000/app/#/map/10029/editReport
  skogskadeControllers.controller('mapEditReportController', mapEditReportControllerFn);

  mapEditReportControllerFn.$inject = [
    '$scope',
    '$http',
    '$location',
    '$routeParams',
    '$rootScope',
    '$window',
    '$upload',
    'leafletData',
    'mapService',
    'mapModeService',
    'skogskadeGlobalOptions',
    'messageService'
  ];

  function mapEditReportControllerFn(
    $scope,
    $http,
    $location,
    $routeParams,
    $rootScope,
    $window,
    $upload,
    leafletData,
    mapService,
    mapModeService,
    skogskadeGlobalOptions,
    messageService
  ) {
    $http.defaults.useXDomain = true;
    var searchMarkerStyle = mapService.searchMarkerStyle();
    var searchMarker = null;
    var myMap = null;
    leafletData.getMap().then(function (map) {
      myMap = map;
      if (mapService.getSearchMarker() !== null) {
        // restore marker point from mapservice
        searchMarker = L.circleMarker(mapService.getSearchMarker(), searchMarkerStyle);
        searchMarker.addTo(myMap);
      }
    });
    mapModeService.setMapMode('editReport');
    $scope.mapMode = 'editReport';
    if ($routeParams.reportId) {
      $scope.report = $routeParams.reportId;
    }

    var bounds = null;

    $scope.numFeatures = mapService.getNumGeoFiguresWithText($scope.mapMode);

    $rootScope.$on('addFeatureAsSearchSite', function (event, value) {
      var antall = mapService.getNumGeoFigures($scope.mapMode);
      $scope.numFeatures = mapService.getNumGeoFiguresWithText($scope.mapMode);
    });

    $scope.setMunicipality = function (fylke, kommune, aust, nord) {
      var komNR = mapService.getMunicipalityNR(fylke, kommune, aust, nord);
      if (!komNR) {
        alert('Fant ikke kommune');
      } else {
        $scope.showMunicipality(komNR, aust, nord);
      }
    };

    $scope.showMunicipality = function (komNR, aust, nord) {
      var geoserverUrl =
        skogskadeGlobalOptions.geoserverPath +
        'ows?srsname=EPSG:4258&format_options=decimals:1' +
        '&service=WFS&version=1.0.0&outputFormat=json&request=GetFeature' +
        '&typeName=sl:n2000_komm_flate&cql_filter=komid=%27' +
        komNR +
        '%27&';
      $http
        .get(geoserverUrl)
        .error(function (data) {
          alert('Fant ikke kommunegrense');
        })
        .success(function (komdata, status) {
          if (komdata.totalFeatures === 0) {
            alert('Fant ikke kommunegrense, viser punkt');
            $scope.setMarker(aust, nord);
          } else {
            var komLayerArr = L.geoJson(komdata).getLayers();
            angular.forEach(komLayerArr, function (value, key) {
              //Remove properties from kommunegrenser
              delete value.feature.properties['navn'];
              delete value.feature.properties['komm'];
              delete value.feature.geometry_name;
              value.options.addAsFindSite = false;
              value.addTo(editReportItems);
            });
            myMap.fitBounds(editReportItems.getBounds());
            mapService.setFeatureGroup(editReportItems, $scope.mapMode);
            $scope.numFeatures = mapService.getNumGeoFiguresWithText($scope.mapMode);
          }
        });
    };

    $scope.closeWithoutSaving = function () {
      $window.close();
    };

    $scope.saveReportToServer = function () {
      // Remove alle feature we have find in search and not used "Angi søk som funnsted" button
      var drawnItems = mapService.getFeatureGroup('editReport');
      drawnItems.eachLayer(function (layer) {
        if (layer.options.addAsFindSite === false) {
          drawnItems.removeLayer(layer);
        }
      });
      var url = skogskadeGlobalOptions.skogskadeWsAdminUrl;
      var geoJsonToBackend = mapService.getGeoJson($scope.mapMode);
      var reportId = $scope.report;
      var sessionId = $routeParams.sessionid;

      var featureProperties = {
        reportId: $scope.report,
        sessionId: $routeParams.sessionid,
      };

      if (geoJsonToBackend.features.length < 1) {
        var userMessage = 'Det må tegnes inn minst et kartobjekt på denne rapporten.<br>';
        var infoMessage = {
          title: 'Det er ikke lov å slette alle kartobjekter tilhørende en rapport.',
          text: userMessage,
        };
        messageService.setMessage(infoMessage);
        messageService.showMessage(true);
        return;
      }
      geoJsonToBackend.commondata = featureProperties;

      var res = $http.post(url, geoJsonToBackend);

      res.success(function (data, status, headers, config) {
        var userMessage =
          'Lagring av kartobjekter for rapport med id ' + $scope.report + ' var vellykket. Siden lukkes.<br>';
        var infoMessage = {
          title: 'Kartobjekter lagret.',
          text: userMessage,
        };

        messageService.setCloseFunction(function () {
          //var adminUrl = skogskadeGlobalOptions.skogskadeAdminUrl + "report/show/" + $scope.report;
          //$window.location.href = adminUrl;
          $window.close();
        });
        messageService.setMessage(infoMessage);
        messageService.showMessage(true);
      });
      res.error(function (data, status, headers, config) {
        if (status == '401') {
          var errorText = 'Sjekk om du er logget inn i admingrensesnittet eller ta kontakt med geomatikk-seksjonen.';
          errorText += '<br>Feilmelding:<br>';
          errorText += data;
          errorText += '<br>Guru meditation value: ' + sessionId + '<br>';

          var errorMessage = {
            title: 'Noe gikk galt',
            text: errorText,
          };

          messageService.setMessage(errorMessage);
          messageService.showMessage(true);
        } else {
          var errorText = 'Kunne ikke lagre kartobjektene. Ta kontakt med geomatikk-seksjonen.';
          errorText += '<br>Guru meditation value:' + sessionId + '<br>';
          errorText += '<br>Data:<br>';
          errorText += data;
          var errorMessage = {
            title: 'Noe gikk galt',
            text: errorText,
          };
          messageService.setMessage(errorMessage);
          messageService.showMessage(true);
        }
      });
    };

    $scope.setMarker = function (east, north) {
      var latitude = Number(north);
      var longitude = Number(east);
      var point = [latitude, longitude];
      var customOption = { addAsFindSite: false };
      L.marker(point, customOption).addTo(editReportItems);
      mapService.setFeatureGroup(editReportItems, $scope.mapMode);
      $scope.numFeatures = mapService.getNumGeoFiguresWithText($scope.mapMode);
      $scope.$apply(function () {
        $scope.center = {
          lat: latitude,
          lng: longitude,
          zoom: 12,
        };
      });
    };

    $scope.removeSearchMarker = function (east, north) {
      if (searchMarker !== null) {
        myMap.removeLayer(searchMarker);
      }
    };

    L.Control.skogskadeLocate = L.Control.Locate.extend({
      drawMarker: function (map) {
        if (this._event.accuracy === undefined) {
          this._event.accuracy = 0;
        }
        var radius = this._event.accuracy;
        if (this._locateOnNextLocationFound) {
          if (this._isOutsideMapBounds()) {
            this.options.onLocationOutsideMapBounds(this);
          } else {
            map.fitBounds(this._event.bounds, {
              padding: this.options.circlePadding,
              maxZoom: this.options.keepCurrentZoomLevel ? map.getZoom() : this.options.locateOptions.maxZoom,
            });
          }
          this._locateOnNextLocationFound = false;
        }
        // circle with the radius of the location's accuracy
        var style, o;
        if (this.options.drawCircle) {
          if (this._following) {
            style = this.options.followCircleStyle;
          } else {
            style = this.options.circleStyle;
          }
          if (!this._circle) {
            this._circle = L.circle(this._event.latlng, radius, style).addTo(this._layer);
          } else {
            this._circle.setLatLng(this._event.latlng).setRadius(radius);
            for (o in style) {
              this._circle.options[o] = style[o];
            }
          }
        }
        var distance, unit;
        if (this.options.metric) {
          distance = radius.toFixed(0);
          unit = 'meters';
        } else {
          distance = (radius * 3.2808399).toFixed(0);
          unit = 'feet';
        }
        // small inner marker
        var mStyle;
        if (this._following) {
          mStyle = this.options.followMarkerStyle;
        } else {
          mStyle = this.options.markerStyle;
        }

        if (!this._marker) {
          // Her legges det til et punkt i kartet ala tegning
          if ($scope.mapMode === 'editReport') {
            editReportItems.eachLayer(function (layer) {
              if (layer.options.geoloc) {
                // Remove other geoloc objects
                editReportItems.removeLayer(layer);
              }
            });

            var newMarker = L.marker(this._event.latlng);
            newMarker.options.geoloc = true;

            newMarker.addTo(editReportItems);
            //var markergeoJson = JSON.stringify(L.marker(this._event.latlng).toGeoJSON());
            mapService.setFeatureGroup(editReportItems, $scope.mapMode);
            $scope.numFeatures = mapService.getNumGeoFiguresWithText($scope.mapMode);
          }
        } else {
          this.updateMarker(this._event.latlng, mStyle);
        }
        var t = this.options.strings.popup;

        if (this.options.showPopup && t) {
          if (this._marker) {
            this._marker
              .bindPopup(
                L.Util.template(t, {
                  distance: distance,
                  unit: unit,
                }),
              )
              ._popup.setLatLng(this._event.latlng);
          }
        }
        this._toggleContainerStyle();
      },
    });

    /* Se dokumentasjon her https://github.com/domoritz/leaflet-locatecontrol*/
    var locateControl = new L.Control.skogskadeLocate({
      icon: 'leaflet-icon glyphicon glyphicon-record',
      follow: false,
      iconLoading: 'leaflet-icon',
      onLocationError: function (err) {
        alert(err.message);
      },
      strings: {
        title: 'Vis meg hvor jeg er', // title of the locate control
        popup: 'Du er innenfor {distance} {unit} fra dette punktet', // text to appear if user clicks on circle
        outsideMapBoundsMsg: 'Ser ut til at du er utenfor grensene for dette kartet', // default message for onLocationOutsideMapBounds
      },
    });

    /* Se dokumentasjon her https://github.com/michaelguild13/Leaflet.draw */
    var editReportItems = new L.FeatureGroup();
    var options = {
      edit: {
        featureGroup: editReportItems,
      },
      draw: {
        circle: false,
      },
    };
    var drawControl = new L.Control.Draw(options);
    var customControls = [
      new L.Control.Zoom({
        zoomInTitle: 'Zoom inn',
        zoomOutTitle: 'Zoom ut',
      }),
      drawControl,
      locateControl,
    ];

    var mapConfig = mapService.getMapConfig(customControls);

    angular.extend($scope, mapConfig);

    //ta vare på kartutsnittet for ulike kartmodus
    if (mapService.getCenterForMode($scope.mapMode)) {
      $scope.center = mapService.getCenterForMode($scope.mapMode);
    }

    $scope.$watch('center', function () {
      mapService.setCenterForMode($scope.mapMode, $scope.center);
    });

    var owsAdminUrl =
      skogskadeGlobalOptions.geoserverPath +
      'skogskade/ows?service=WFS&version=1.0.0&' +
      'request=GetFeature&typeName=skogskade:map_adm_report_v&maxFeatures=5000' +
      '&outputFormat=application/json&cql_filter=report_id%20IN%20(' +
      $scope.report +
      ')&';

    $http.get(owsAdminUrl).success(function (data, status) {
      if (data.totalFeatures === 0) {
        alert(
          'Det er ingen stedfestede skader knyttet til denne rapporten. Du kan legge til kartobjekter ved å tegne.',
        );
      } else {
        try {
          var layerArr = L.geoJson(data).getLayers();
          angular.forEach(layerArr, function (value, key) {
            //Remove properties from kommunegrenser
            delete value.feature.properties['navn'];
            delete value.feature.properties['komm'];
            // delete value.feature.properties;
            delete value.feature.geometry_name;
            value.addTo(editReportItems);
          });

          myMap.fitBounds(editReportItems.getBounds());
          mapService.setFeatureGroup(editReportItems, $scope.mapMode);
          $scope.numFeatures = mapService.getNumGeoFiguresWithText($scope.mapMode);
        } catch (e) {
          var userMessage = 'Mottok:<br>';
          userMessage += data;
          userMessage += '<br>Ta kontakt med geomatikk hvis dette gjentar seg.';
          var infoMessage = {
            title: 'Klarte ikke bruke dataene jeg fikk:',
            text: userMessage,
          };
          messageService.setMessage(infoMessage);
          messageService.showMessage(true);
        }
      }
    });

    //Lagre tegnede objekter
    leafletData.getMap().then(function (map) {
      if (editReportItems) {
        map.addLayer(editReportItems);
      }
      map.on('draw:created', function (e) {
        var layer = e.layer;
        editReportItems.addLayer(layer);
        mapService.setFeatureGroup(editReportItems, $scope.mapMode);
        $scope.numFeatures = mapService.getNumGeoFiguresWithText($scope.mapMode);
      });

      map.on('draw:edited', function (e) {
        mapService.setFeatureGroup(editReportItems, $scope.mapMode);
        $scope.numFeatures = mapService.getNumGeoFiguresWithText($scope.mapMode);
      });

      map.on('draw:deleted', function (e) {
        var ant = editReportItems.getLayers().length;
        //console.log('Deleted: '+ant);
        if (ant > 0) {
          mapService.setFeatureGroup(editReportItems, $scope.mapMode);
          $scope.numFeatures = mapService.getNumGeoFiguresWithText($scope.mapMode);
        } else {
          mapService.setFeatureGroup(null, $scope.mapMode);
          $scope.numFeatures = mapService.getNumGeoFiguresWithText($scope.mapMode);
        }
      });
    });

    $scope.$watch('center', function () {
      mapService.setCenterForMode($scope.mapMode, $scope.center);
    });

    /*$scope.$on('$viewContentLoaded', function() { // Setter størrelsen på kartet etter at kartsiden er lastet.
     $scope.headerHeigth = jQuery(document.body).height();
     var mapHeigth = jQuery(window).height() - $scope.headerHeigth;
     jQuery('.angular-leaflet-map').height(mapHeigth);
     jQuery('.angular-leaflet-map').width(jQuery(document.body).width());

     });


     jQuery(window).resize(function() {
     var mapHeigth = jQuery(window).height() - $scope.headerHeigth;
     jQuery('.angular-leaflet-map').height(mapHeigth);
     jQuery('.angular-leaflet-map').width(jQuery(document.body).width());
     });*/

    $scope.$on('$viewContentLoaded', function () {
      // Setter størrelsen på kartet etter at kartsiden er lastet.
      /*Do this because delete white space in bottom on phone and avoid scrolling*/
      var navbar = mapService.getHeaderSize($scope.mapMode);
      var mapHeigth = jQuery(window).height() - navbar;
      jQuery('.angular-leaflet-map').height(mapHeigth);
      jQuery('.angular-leaflet-map').width(jQuery(document.body).width());
    });

    jQuery(window).resize(function () {
      var navbar = mapService.getHeaderSize($scope.mapMode);
      var mapHeigth = jQuery(window).height() - navbar;
      jQuery('.angular-leaflet-map').height(mapHeigth);
      jQuery('.angular-leaflet-map').width(jQuery(document.body).width());
    });
  }
})(angular.module('skogskadeControllers'));
