import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(appRoutes, {
      anchorScrolling: 'enabled',
      bindToComponentInputs: true,
      enableTracing: false,
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
      scrollOffset: [0, 50],
      scrollPositionRestoration: 'enabled',
    }),
  ],
})
export class AppRoutingModule {}
