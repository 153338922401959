import { AvailableApisType } from '@environments/assets/available-apis.type';
import { EnvironmentTypeEnum } from '@environments/assets/environment-type.enum';
import { ProxyApiType, SkogskadeApiType } from '@environments/assets/environment.interface';
import { environment } from '@environments/environment';
import { throwError } from 'rxjs';

export class EnvironmentHelper {
  /**
   * Generates API URL for a given path
   */
  static fetchAPIBase(api: AvailableApisType, path = ''): string {
    try {
      if (path.length && environment.APIS[api]['paths']) {
        path = environment.APIS[api]['paths'][path];
        console.log(`set path to`, path);
      }

      return environment.APIS[api].baseUrl + path;
    } catch (error) {
      throwError(() => error);
    }
    return '';
  }

  /**
   * Generates URL for a given path to the front end (based on config)
   * Returns URL without trailing slash
   */
  static fetchFrontEndURL(): string {
    const segments: string[] = [environment.frontend.baseUrl, environment.frontend.baseHref];
    segments.forEach((s, idx) => {
      if (s.startsWith('/')) {
        s = s.substring(1);
      }
      if (s.endsWith('/')) {
        s = s.substring(0, s.length - 1);
      }
      segments[idx] = s;
    });
    return segments.filter(s => s.length).join('/');
  }

  static getDateFormat(): string {
    return environment.formats.date;
  }

  static getProxyPaths() {
    return (environment.APIS['nibioProxy'] as ProxyApiType).paths;
  }

  static getSkogskadePaths() {
    return (environment.APIS['skogskade'] as SkogskadeApiType).paths;
  }

  static getTimeAndDateFormat(): string {
    return environment.formats.dateTime;
  }

  static isAdmin(): boolean {
    return (
      environment.type === EnvironmentTypeEnum.ADMINISTRATIVE_PROD ||
      environment.type === EnvironmentTypeEnum.ADMINISTRATIVE_TEST
    );
  }

  static isDevelopment(): boolean {
    return environment.type === EnvironmentTypeEnum.DEVELOPMENT;
  }

  static isProduction(): boolean {
    return environment.type === EnvironmentTypeEnum.PRODUCTION;
  }

  /**
   * 1. Remove API base
   * 2. Prefix local folder base path (assets/mocks/api/)
   * 3. Postfix method name (get/post/etc.)
   * 4. Postfix file extension (.json)
   * 5. Return string. Ex: assets/mocks/api/users/self.get.json
   */
  static replaceWithMockUrl(url: string, method: string): string {
    return 'assets/mocks/api' + url.replace(new URL(url).origin, '') + '.' + method.toLowerCase() + '.json';
  }

  /**
   *
   */
  static useMocks(): boolean {
    return environment.useMocks;
  }
}
