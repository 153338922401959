(function (skogskadeControllers) {
  'use strict';

  skogskadeControllers.controller('messageController', messageControllerFn);

  messageControllerFn.$inject = ['$scope', '$uibModal', '$log', 'messageService'];

  function messageControllerFn($scope, $uibModal, $log, messageService) {
    $scope.animationsEnabled = true;

    $scope.open = function (size) {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'ajs/partials/messageModalContent.html',
        controller: 'modalInstanceController',
        size: size,
        resolve: {
          message: function () {
            return messageService.getMessage();
          },
        },
      });

      modalInstance.result.then(
        function (selectedItem) {
          $scope.selected = selectedItem;
          messageService.resetMessage();
        },
        function () {
          $log.info('Modal dismissed at: ' + new Date());
        },
      );
    };

    $scope.$watch(
      function ($scope) {
        // This becomes the value we're "watching".
        return messageService.hasMessage();
      },
      function (newValue) {
        if (newValue === true) {
          // Show the modal with the message set:
          $scope.open();
        }
      },
    );
  }
})(angular.module('skogskadeControllers'));
