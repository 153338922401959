(function (skogskadeDirectives) {
  'use strict';

  skogskadeDirectives.directive('searchPlace', searchPlaceFn);

  searchPlaceFn.$inject = ['$http', 'mapService', 'skogskadeGlobalOptions'];

  window.Bloodhound = require('@ajs/js/modified_extras/bloodhound.js');

  // for converting from degrees to decimal latlong values
  // http://stackoverflow.com/questions/1140189/converting-latitude-and-longitude-to-decimal-values
  /*
   This module uses twitter typeahead:
   https://twitter.github.io/typeahead.js/examples/
   with Bloodhound backend

   */

  function searchPlaceFn($http, mapService, skogskadeGlobalOptions) {
    var geoExtent = [4.5, 58, 31, 70.1];
    // Build a comma separated string for
    // municipalities that matches the searchParts array
    // searchParts is an array of strings where the first element
    // is the place search string and the 1:... part of array is the
    // municipality patterns
    // municipalities is an aray with mapping between
    // municipality number and municipailty name
    function buildLimitations(searchParts, municipalities) {
      var municipalityLimitations = '';
      // Ignore the first element in array,which is namesearch string.
      for (var i = 1; i < searchParts.length; i++) {
        // Look up municipality number and build the municipality
        // search list...
        if (searchParts[i] !== '') {
          for (var munic in municipalities) {
            var municName = munic.toLowerCase();
            var userTyped = searchParts[i].trim().toLowerCase();
            // Only qualify for municipality match if:
            // 1. user has typed more than 2 letters and
            // typed string matches beginning of a municipality
            // 2. user has typed 1 or 2 characters and there are
            // an exact match in municipality name
            // 3. The userTyped string is not the last kommune
            if (
              (userTyped.length > 2 && municName.indexOf(userTyped) === 0) ||
              (userTyped.length <= 2 && municName === userTyped) ||
              (i !== searchParts.length - 1 && municName === userTyped)
            ) {
              municipalityLimitations += '&knr=' + municipalities[munic];
            }
          }
        }
      }
      // console.log("Bygget følgende limitations:" + municipalityLimitations);

      return municipalityLimitations;
    }

    function getPlaces(res) {
      if (!res || typeof res.navn === 'undefined') {
        return [];
      }
      var places = res.navn;
      var jsonRes = [];
      if (res.metadata.totaltAntallTreff > 0) {
        var navnetypeFilter = ['Nasjon', 'Fylke', 'Navnegard'];
        for (var i = 0; i < places.length; i++) {
          var place = places[i];
          // Filter out some of the names with this filter
          var skrivemaate = place.stedstatus;
          var navnetype = place.navneobjekttype;
          if (
            (place.stedstatus === 'aktiv' || place.stedstatus === 'Vedtatt') &&
            navnetypeFilter.indexOf(navnetype) === -1 &&
            Object.prototype.toString.call(place.kommuner) === '[object Array]'
          ) {
            place.label =
              place.stedsnavn[0].skrivemåte + ', ' + place.navneobjekttype + ', ' + place.kommuner[0].kommunenavn;

            // Set on same format as early because of coordinate search
            place.fylkesnavn = place.fylker[0].fylkesnavn;
            place.kommunenavn = place.kommuner[0].kommunenavn;
            place.aust = place.representasjonspunkt.øst;
            place.nord = place.representasjonspunkt.nord;

            jsonRes.push(place);
          }
        }
      }
      return jsonRes;
    }

    return {
      restrict: 'A',
      replace: true,
      templateUrl: 'ajs/partials/search.html',
      link: function (scope, element, attrs) {
        var presentSearch = function (datum) {
          if (datum.navneobjekttype === 'Kommune') {
            scope.setMunicipality(datum.fylkesnavn, datum.kommunenavn, datum.aust, datum.nord);
          } else {
            scope.setMarker(datum.aust, datum.nord);
          }
        };
        var searchUrl = skogskadeGlobalOptions.kartverketRestUrl;
        var remoteConfig = {
          url: searchUrl,
          dataType: 'xml',
          replace: function (url, searchText) {
            var decodedText = decodeURIComponent(searchText);
            var searchParts = decodedText.split(',');
            var text = searchParts[0].trim();
            var queryText = 'sok=' + encodeURIComponent(text) + '*';
            var m = mapService.getMunicipalities(); //scope.municipalityMapping;
            var limitations = buildLimitations(searchParts, m);
            url = url + queryText + limitations + '&treffPerSide=25&utkoordsys=4258&side=1&fuzzy=true';
            return url;
          },
          filter: function (response) {
            return getPlaces(response);
          },
        }; // end remote

        var remoteSource = new Bloodhound({
          datumTokenizer: Bloodhound.tokenizers.obj.whitespace('label'),
          queryTokenizer: Bloodhound.tokenizers.whitespace,
          remote: remoteConfig,
        });

        remoteSource.initialize();

        var taElt = $(element).find('input');
        taElt.typeahead(
          { autoselect: true },
          {
            name: 'coords',
            displayKey: 'label',
            source: scope.coordMatcher(scope.query),
            templates: {
              suggestion: function (data) {
                return '<p>Koordinat, desimalgrader: <strong>' + data.label + '</strong></p>';
              },
              notFound: function (dataset) {
                // used to hint user on format of input
                var notFoundMsg = '';
                if (scope.isPossibleDesimalCoord(dataset.query)) {
                  notFoundMsg =
                    '<div class="empty-message"><div>' +
                    "Angi koordinater (desimalgrader) slik:</div><div> nord , øst </div><div> eks '59.66868 , 10.71871'</div>" +
                    '</div>';
                }
                return notFoundMsg;
              },
            },
          },
          {
            name: 'places',
            displayKey: 'label',
            limit: '25',
            source: remoteSource.ttAdapter(),
          },
        );

        /*
         Add custom typeahead handlers on select
         */
        taElt.on('typeahead:autocompleted', function (event, datum, dataset) {
          presentSearch(datum);
        });

        taElt.on('typeahead:selected', function (event, datum) {
          presentSearch(datum);
        });
      },
    };
  }
})(angular.module('skogskadeDirectives'));
