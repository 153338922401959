import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { FilterValuesType } from '@apptypes/filter-values.type';
import { MapService } from '@core/services/map/map.service';
import { ReportFilterService } from '@core/services/report-filter.service';
import { SkogskadeReportsWms } from '@core/wms/skogskade-reports-wms';
import { SkogskadeReportsWmsFeatureInfo } from '@core/wms/skogskade-reports-wms-feature-info';
import { MapModesEnum } from '@enums/map-modes.enum';
import { LeafletGeomanComponent } from '@features/leaflet-geoman/leaflet-geoman.component';
import { ReportFilterComponent } from '@shared/components/report-filter/report-filter.component';
import { SearchPlaceComponent } from '@shared/components/search-place/search-place.component';
import { Map as LeafletMap } from 'leaflet';
import { BehaviorSubject, filter, tap } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, LeafletGeomanComponent, ReportFilterComponent, SearchPlaceComponent],
  selector: 'app-leaflet',
  standalone: true,
  styleUrls: ['./leaflet.component.scss'],
  templateUrl: './leaflet.component.html',
})
export default class LeafletComponent implements AfterViewInit, OnDestroy, OnInit {
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _mapService = inject(MapService);
  private readonly _reportFilterService = inject(ReportFilterService);

  private _loadingReports$ = new BehaviorSubject<boolean>(true);

  isReportFilterVisible: boolean = true;
  loadingReports$ = this._loadingReports$.asObservable();
  map?: LeafletMap;
  mapMode = this._mapService.mapMode;
  mapModes = MapModesEnum;

  ngAfterViewInit(): void {
    const mapStateExisted = this._mapService.getMap();

    this.map = this._mapService.initMap('leaflet', this.mapMode());

    if (mapStateExisted && this.mapMode() !== MapModesEnum.DRAW) {
      this._mapService.restoreMapState();
    }
    this._cdr.detectChanges();

    if (this.mapMode() === MapModesEnum.SHOW_SKOGSKADER) {
      // Include custom skogskade popup content
      const skogskadeWmsFeatureInfo = new SkogskadeReportsWmsFeatureInfo();
      skogskadeWmsFeatureInfo.init(this.map);
      this._cdr.detectChanges();

      // Listen for changed filters and create/update WMS layer
      const skogskadeReportsWms = new SkogskadeReportsWms();
      this._reportFilterService.filterValueChanges$
        .pipe(
          filter(() => this.isReportFilterVisible && this.mapMode() !== MapModesEnum.DRAW),
          tap(values => {
            this._loadingReports$.next(true);
            setTimeout(() => {
              this._loadingReports$.next(false);
            }, 2000); // TODO: Define timeout per environment?
            // Create new WMS layer based on filtered values
            if (this.map) {
              skogskadeReportsWms.addReportFiltersAsWmsLayer(values, this.map);
            }
            this._cdr.detectChanges();
          }),
          takeUntilDestroyed(this._destroyRef),
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.stopLocate();
      if (this.mapMode() !== MapModesEnum.DRAW) {
        this._mapService.saveMapState();
      }
      this.map = undefined;
    }
  }

  ngOnInit(): void {
    this._activatedRoute.data.pipe(take(1)).subscribe({
      next: data => {
        if (data['mapMode'] !== undefined) {
          this._mapService.mapMode.set(data['mapMode']);
        }
      },
    });
  }

  updateFilterValues(values: FilterValuesType) {
    this._reportFilterService.updateValues(values);
  }
}
