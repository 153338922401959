(function (skogskadeConstants) {
  'use strict';

  skogskadeConstants.constant('mapConf', {
    getSimpleMapConfig: function (tokenKey: string) {
      let cacheUrl: string | null = null;
      const cacheTopo = '&layers=topo4&zoom={z}&x={x}&y={y}';
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const cacheGrunnkart = '&layers=norges_grunnkart&zoom={z}&x={x}&y={y}';
      if (tokenKey) {
        cacheUrl = 'https://gatekeeper2.geonorge.no/BaatGatekeeper/gk/gk.cache_gmaps?gkt=' + tokenKey;
      } else {
        //Begrensning på max 10 000 kart pr IP pr dag.
        cacheUrl = 'https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?';
      }
      return {
        center: {
          lat: 65,
          lng: 10,
          zoom: 6,
        },
        defaults: {
          zoomControl: false, // To change mouseover
        },
        layers: {
          skmap: {
            name: 'Topo4',
            options: {
              attribution:
                'Maps: <a href="http://statkart.no" title="The National Mapping Authority of Norway">Kartverket</a>',
            },
            type: 'xyz',
            url: cacheUrl + cacheTopo,
          },
        },
      };
    },
  });
})(angular.module('skogskadeConstants'));
