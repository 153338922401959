import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService } from '@core/services/error.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly _errorService: ErrorService) {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((e?: Error) => {
        if (e instanceof Error) {
          this._errorService.handleRequestError(e, req);
        }

        return throwError(() => e);
      }),
    );
  }
}
