(function (skogskadeConstants) {
  'use strict';

  skogskadeConstants.constant('dateHelper', {
    /*
     *  Converts a date object to string of format '22.06.2015'
     */
    dateToString: function (date: Date) {
      if (typeof date === 'undefined') {
        return '';
      }
      const dd = date.getDate();
      const mm = date.getMonth() + 1; //January is 0!
      const yyyy = date.getFullYear();
      return dd.toString().padStart(2, '0') + '.' + mm.toString().padStart(2, '0') + '.' + yyyy;
    },
    /*
     *  Converts a date object to string of format '2015.06.22'
     */
    dateToStringReverse: function (date: Date) {
      const dd = date.getDate();
      const mm = date.getMonth() + 1; //January is 0!
      const yyyy = date.getFullYear();
      return yyyy + '.' + mm.toString().padStart(2, '0') + '.' + dd.toString().padStart(2, '0');
    },
    /*
     *  Converts a string of format '06.02.2015' to a date opbject
     */
    skToDate: function (datestring: string) {
      const day = parseInt(datestring.substring(0, 2), 10);
      const month = parseInt(datestring.substring(3, 5), 10);
      const year = parseInt(datestring.substring(6, 10), 10);
      // Javascript counts months by 0 - 11
      return new Date(year, month - 1, day);
    },
  });
})(angular.module('skogskadeConstants'));
