import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AppConfig } from '@core/app.config';
import { DiagnosisService } from '@core/services/diagnosis.service';
import { MapService } from '@core/services/map/map.service';
import { ReportService } from '@core/services/report.service';
import { MapModesEnum } from '@enums/map-modes.enum';
import { Map as LeafletMap } from 'leaflet';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  selector: 'app-leaflet-embed',
  standalone: true,
  styleUrls: ['./leaflet-embed.component.scss'],
  templateUrl: './leaflet-embed.component.html',
})
export class LeafletEmbedComponent implements AfterViewInit, OnChanges {
  @Input()
  diagnosisId?: string;

  @Input()
  reportId?: string;

  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _diagnosisService = inject(DiagnosisService);
  private readonly _mapService = inject(MapService);
  private readonly _reportService = inject(ReportService);

  readonly mapContainerId = 'leaflet-embed';

  map?: LeafletMap;
  mapModes = MapModesEnum;

  ngAfterViewInit(): void {
    this.map = this._mapService.initMap(this.mapContainerId, MapModesEnum.EMBED);

    this._addSkogskadeLayers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.map) {
      return;
    }
    if (
      (changes['diagnosisId']?.currentValue && !changes['diagnosisId']?.isFirstChange()) ||
      (changes['reportId']?.currentValue && !changes['reportId']?.isFirstChange())
    ) {
      // Remove any existing markers from map
      this.map.eachLayer(l => {
        if (
          l.options[AppConfig.MAP_KEY_CUSTOMID] in
          [AppConfig.MAP_KEY_DIAGNOSIS_MARKERS, AppConfig.MAP_KEY_REPORT_MARKERS]
        ) {
          if (this.map) {
            l.removeFrom(this.map);
          }
        }
      });
      // Add new markers
      this._addSkogskadeLayers();
    }
  }

  private _addSkogskadeLayers(): void {
    if (!this.map) {
      return;
    }

    if (this.diagnosisId) {
      this._diagnosisService.addDiagnosisInstancesToMap(this.diagnosisId, this.map);
    }

    if (this.reportId) {
      this._reportService.addReportFeaturesToMap(this.reportId, this.map);
    }

    this._cdr.detectChanges();
  }
}
