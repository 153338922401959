<div class="container-fluid mb-5 mt-4">
  <div class="row justify-content-md-center">
    <div class="col-sm-12 col-md-10">
      <div class="filters">
        <app-report-filter
          (filtersChanged)="setSearchTerms($event)"
          [damagePlace]="filters.queryPlace"
          [damageType]="filters.queryDamage"
          [dateFrom]="filters.dateStart"
          [dateTo]="filters.dateEnd"
          [emitFiltersOnInit]="false"
          [loading$]="service.loading$"
        ></app-report-filter>
      </div>

      <app-reports-table
        (pageChanged)="pageChanged()"
        (perPageChanged)="perPageChanged()"
        [filters]="filters"
        [reports$]="reports$"
      ></app-reports-table>
    </div>
  </div>
</div>
