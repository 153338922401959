(function (skogskadeControllers) {
  'use strict';

  // TODO: Refactor to its own file
  //function headerControllerFn($scope, $location) {
  //  $scope.isActive = function (viewLocation) {
  //    return viewLocation === $location.path();
  //  };
  //}
  //
  //var headerController = skogskadeControllers.controller('HeaderController', ['$scope', '$location', headerControllerFn]);
})(angular.module('skogskadeControllers', []));
