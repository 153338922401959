import { Injectable } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import * as angular from 'angular';

@Injectable({
  providedIn: 'root',
})
export class AngularJsLazyLoaderService {
  private _app: angular.auto.IInjectorService | undefined;

  constructor(private readonly _upgradeModule: UpgradeModule) {}

  load(el: HTMLElement): void {
    import('./angular-js.app').then(app => {
      try {
        this._app = app.bootstrap(el, this._upgradeModule);
      } catch (e) {
        console.error(e);
      }
    });
  }

  destroy() {
    if (this._app) {
      this._app.get('$rootScope').$destroy();
    }
  }
}
