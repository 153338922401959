import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  selector: 'app-terms-of-use-modal',
  standalone: true,
  styleUrls: ['./terms-of-use-modal.component.css'],
  templateUrl: './terms-of-use-modal.component.html',
})
export class TermsOfUseModalComponent {
  constructor(public modal: NgbActiveModal) {}
}
