import { Component } from '@angular/core';
import { AppConfig } from '@core/app.config';
import { ErrorService } from '@core/services/error.service';
import { FormService } from '@core/services/form.service';
import { ToastsComponent } from '@shared/components/toasts/toasts.component';

@Component({
  providers: [ToastsComponent],
  selector: 'app-root',
  styleUrls: ['./app.component.css'],
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'Skogskader';

  constructor(
    private readonly _errorService: ErrorService,
    private readonly _formService: FormService,
  ) {
    this._errorService.clearErrors();
    // We store form values in session storage between routes, but want to clear out these values on page reload
    this._formService.cacheValuesClear(AppConfig.CACHE_KEY_DAMAGE_FORM);
  }

  reloadPage(): void {
    this._errorService.clearErrors();
    window.location.reload();
    // or https://stackoverflow.com/a/65846293/504075
  }
}
