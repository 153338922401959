import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { Map as LeafletMap, TileLayer } from 'leaflet';
import { Source, WmsParamsOptions } from 'leaflet.wms';

/**
 * Given a diagnosis ID, fetches specific layers using WMS and
 * adds each to Leaflet as WMS Layer
 */
export class SkogskadeDiagnosisWms {
  dmgLayers: TileLayer.WMS[] = [];
  isWmsInitialized: boolean = false;

  private readonly _diagLayerKey = 'diagnosis_reports';

  addDiagnosisAsWmsLayers(diagnoseId: string, map: LeafletMap) {
    if (this.isWmsInitialized) {
      this.dmgLayers.forEach(dl => {
        map.removeLayer(dl);
      });
    }

    const wmsUrl = EnvironmentHelper.fetchAPIBase('skogskade') + EnvironmentHelper.getSkogskadePaths().wmsUrl;
    const wmsConfig: WmsParamsOptions & { [p: string]: any } = {
      FEATURE_COUNT: 1000,
      attribution: 'NIBIO',
      diagnosisid: diagnoseId,
      format: 'image/png',
      info_format: 'application/vnd.ogc.gml',
      isBack: false,
      layers: this._diagLayerKey,
      maxZoom: 21,
      opacity: 0.75,
      tiled: false,
      transparent: true,
      version: '1.3.0',
      zIndex: 10,
    };

    // Fetch each diagnose layer type separately (to correspond with map legend)
    ['center', 'line', 'point', 'polygon'].forEach(key => {
      const wmsSrc = new Source(wmsUrl, wmsConfig);
      const layer = wmsSrc.getLayer(this._diagLayerKey + '_' + key);
      map.addLayer(layer);
      this.dmgLayers.push(layer);
    });

    this.isWmsInitialized = true;
  }
}
