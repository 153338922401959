import { inject, Injectable } from '@angular/core';
import { BreadcrumbType } from '@apptypes/breadcrumb.type';
import { DamageType } from '@apptypes/damage.type';
import { DiagnosisListType } from '@apptypes/diagnosis-list.type';
import { DiagnosisDetailResponseType } from '@apptypes/responses/diagnosis-detail-response.type';
import { DiagnosisListResponseType } from '@apptypes/responses/diagnosis-list-response.type';
import { SkogskadePictureResponseType } from '@apptypes/responses/skogskade-picture-response.type';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { ApiService } from '@core/services/api.service';
import { SkogskadeDiagnosisWms } from '@core/wms/skogskade-diagnosis-wms';
import { SkogskadeDiagnosisWmsFeatureInfo } from '@core/wms/skogskade-diagnosis-wms-feature-info';
import { SkogskadeWsEndpointsEnum } from '@environments/apis/skogskade.api';
import { Map as LeafletMap } from 'leaflet';
import { Observable, of } from 'rxjs';
import { concatMap, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DiagnosisService {
  cachedData: DiagnosisListType = {
    damages: [],
    hosts: [],
  };
  private readonly _apiService = inject(ApiService);

  addDiagnosisInstancesToMap(diagnosisId: string, map: LeafletMap) {
    // Include custom FeatureInfo for popups
    const dmgWmsFeatureInfo = new SkogskadeDiagnosisWmsFeatureInfo();
    dmgWmsFeatureInfo.init(map);

    // Add WMS layers based on given diagnosis
    const dmgWms = new SkogskadeDiagnosisWms();
    dmgWms.addDiagnosisAsWmsLayers(diagnosisId, map);
  }

  getBreadcrumbs(diagnosisId: string): Observable<BreadcrumbType[]> {
    return this.getDiagnosisTree().pipe(
      take(1),
      map(tree => {
        return this._getNodesUntil(tree.damages, diagnosisId).map(node => {
          // Strip the latin names inside parenthesis, so return first part of string up until first '('
          if (node.label.indexOf('(') > -1) {
            node.label = node.label.substring(0, node.label.indexOf('('));
          }
          return {
            label: node.label,
            route: '/skader/' + node.id,
          } as BreadcrumbType;
        });
      }),
    );
  }

  getDetailsForDiagnosis(id: string) {
    const path = EnvironmentHelper.getSkogskadePaths().ws + SkogskadeWsEndpointsEnum.GET_DIAGNOSIS_DETAIL;

    return this._apiService
      .get<DiagnosisDetailResponseType>('skogskade', path, {
        diagnoseId: id,
      })
      .pipe(
        concatMap(diagnosisResponse => {
          return this._apiService
            .getCollection<SkogskadePictureResponseType[]>(
              'skogskade',
              EnvironmentHelper.getSkogskadePaths().ws + SkogskadeWsEndpointsEnum.GET_DIAGNOSIS_DETAIL_PICTURES,
              [],
              {
                diagnoseId: id,
              },
            )
            .pipe(
              map(picturesResponse => {
                if (picturesResponse.length) {
                  diagnosisResponse.photos = picturesResponse;
                }
                return diagnosisResponse;
              }),
            );
        }),
      );
  }

  getDiagnosisTree() {
    if (!this.cachedData?.damages?.length) {
      return this._fetchDiagnosisTree();
    }

    return of(this.cachedData);
  }

  private _fetchDiagnosisTree() {
    const path = EnvironmentHelper.getSkogskadePaths().ws + SkogskadeWsEndpointsEnum.GET_REPORT_INIT_FORM;

    return this._apiService.get<DiagnosisListResponseType>('skogskade', path).pipe(
      take(1),
      map(response => {
        this.cachedData.damages = response.diagnosis.children;
        this.cachedData.hosts = response.host.children;
        return this.cachedData;
      }),
    );
  }

  private _getNodesUntil(tree: DamageType[], id: string): DamageType[] {
    let found: boolean = false;
    const trail: DamageType[] = [];

    function recurse(tree: DamageType[], levels: number = 0) {
      for (const node of tree) {
        trail.push(node); // Add as possible trail
        if (node.id.toString() === id.toString()) {
          found = true;
          break;
        } else if (node.category === 'folder' && node.children?.length) {
          recurse(node.children, ++levels);
          if (found) {
            break;
          }
        }
        trail.pop(); // Remove, turns out was not relevant for trail
      }
    }

    recurse(tree);

    return trail;
  }
}
