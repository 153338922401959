import { geonorgeApi } from '@environments/apis/geonorge.api';
import { nibioProxyApi } from '@environments/apis/nibio-proxy.api';
import { skogskadeApi } from '@environments/apis/skogskade.api';
import { AvailableApisType } from '@environments/assets/available-apis.type';
import { EnvironmentTypeEnum } from '@environments/assets/environment-type.enum';
import { EnvironmentInterface } from '@environments/assets/environment.interface';

/**
 * This file will override environment.ts if Angular is served with a specific --configuration parameter
 * See file angular.json in root folder, ref its section:
 * "configurations": { "myEnvOverride": { "fileReplacements": []}}
 */
export const environment = {
  APIS: {
    geonorge: geonorgeApi.production,
    nibioProxy: nibioProxyApi.production,
    skogskade: skogskadeApi.production,
  } as Record<AvailableApisType, EnvironmentInterface>,
  formats: {
    date: 'dd.MM.yyyy',
    dateTime: 'dd.MM.yyyy HH:mm',
  },
  frontend: {
    baseHref: '/skogskader2/',
    baseUrl: 'https://skogskader.nibio.no',
    geoserverPath: 'https://kart13.nibio.no/geoserver/',
    serviceUrl: 'https://kart13.nibio.no',
  },
  keys: {
    bing: 'Ahytczt-m0g_20M2RLRAPuGzfDX0XtFODW9tFp3EaGJB2Y91l3AZ2POSG0OBE_QH',
  },
  libraryConfigs: {
    analyticsTrackingCode: 'UA-89300873-1',
  },
  type: EnvironmentTypeEnum.PRODUCTION,
  useMocks: false,
};
