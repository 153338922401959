import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationCancel, NavigationEnd, Router, RouterLink } from '@angular/router';
import { filter, tap } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterLink],
  selector: 'app-route-breadcrumbs',
  standalone: true,
  styleUrls: ['./route-breadcrumbs.component.scss'],
  templateUrl: './route-breadcrumbs.component.html',
})
export class RouteBreadcrumbsComponent implements OnInit {
  breadcrumbs: BreadcrumbInterface[] = [];

  @HostBinding('class.show')
  get shouldShow(): boolean {
    return this.breadcrumbs?.length > 0;
  }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    private _router: Router,
  ) {}

  ngOnInit(): void {
    this._updateBreadcrumbs();

    this._router.events
      .pipe(
        filter(
          (event: Event): event is NavigationEnd => event instanceof NavigationEnd || event instanceof NavigationCancel,
        ),
        tap(() => {
          this._updateBreadcrumbs();
        }),
      )
      .subscribe();
  }

  /**
   * https://medium.com/applantic/https-medium-com-applantic-how-to-implement-breadcrumb-navigation-in-angular-and-primeng-52573e49b97a
   */
  private _getBreadCrumbs(
    activatedRoute: ActivatedRoute,
    url = '',
    breadcrumbs: BreadcrumbInterface[] = [],
  ): BreadcrumbInterface[] {
    const [child]: ActivatedRoute[] = activatedRoute.children;

    if (child == null) {
      return breadcrumbs;
    }

    const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

    if (routeURL !== '') {
      url += `/${routeURL}`;
    }

    const label = child.snapshot.data['breadcrumb'];

    if (label) {
      breadcrumbs.push({ label, url });
    }

    return this._getBreadCrumbs(child, url, breadcrumbs);
  }

  private _updateBreadcrumbs(): void {
    this.breadcrumbs = this._getBreadCrumbs(this._activatedRoute.root);
    this._cdr.detectChanges();
  }
}

interface BreadcrumbInterface {
  label: string;
  url: string;
}
