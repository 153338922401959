import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { AppConfig } from '@core/app.config';

@Injectable()
export class CustomTitleStrategy extends TitleStrategy {
  private readonly _title = inject(Title);

  override getResolvedTitleForRoute(snapshot: ActivatedRouteSnapshot): string {
    const breadcrumb = snapshot.data['breadcrumb'];
    let response = '';
    if (breadcrumb?.length) {
      response = breadcrumb;
    }
    response += AppConfig.TITLE;
    return response;
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      // this._title.setTitle(`${title} ${AppConfig.TITLE}`);
      this._title.setTitle(AppConfig.TITLE);
    } else {
      this._title.setTitle(AppConfig.TITLE);
    }
  }
}
