import { NgClass, NgForOf } from '@angular/common';
import { Component } from '@angular/core';
import { ToastService } from '@core/services/toast.service';
import { NgbToast } from '@ng-bootstrap/ng-bootstrap';

@Component({
  imports: [NgbToast, NgForOf, NgClass],
  selector: 'app-toasts',
  standalone: true,
  styleUrls: ['toasts.component.scss'],
  templateUrl: 'toasts.component.html',
})
export class ToastsComponent {
  constructor(public readonly toastService: ToastService) {}
}
