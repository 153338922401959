import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormGroup,
  FormsModule,
  NgForm,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FilterValuesType } from '@apptypes/filter-values.type';
import { DateHelper } from '@core/helpers/date.helper';
import { ReportFilterService } from '@core/services/report-filter.service';
import { NgbCalendar, NgbDate, NgbDatepicker, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { FormFieldErrorsComponent } from '@shared/components/form-field-errors/form-field-errors.component';
import { ReportFilterFormType } from '@shared/components/report-filter/report-filter.type';
import { debounce, Observable, Subject, tap, timer } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    FormFieldErrorsComponent,
    ReactiveFormsModule,
    NgbDatepicker,
    NgbInputDatepicker,
  ],
  selector: 'app-report-filter',
  standalone: true,
  styleUrls: ['./report-filter.component.scss'],
  templateUrl: './report-filter.component.html',
})
export class ReportFilterComponent implements OnChanges, OnInit {
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _fb = inject(NonNullableFormBuilder);
  private readonly _minDate = new Date(1900, 0, 1, 2, 1, 1);
  private readonly _ngbCalendar = inject(NgbCalendar);
  private readonly _reportFilterService = inject(ReportFilterService);

  manualSearch$ = new Subject<number>();
  pickerMaxDate = DateHelper.toNgbDate(this.maxDate);
  pickerMinDate = DateHelper.toNgbDate(this._minDate);
  validationMessages = {
    dateField: {
      ngbDate: {
        maxDate: 'Over maksimum: I dag',
        minDate: 'Under minimum: ' + DateHelper.toShortNorString(this._minDate),
      },
    },
  };

  @Input()
  damagePlace: string = '';

  @Input()
  damageType: string = '';

  @Input()
  emitFiltersOnInit: boolean = false;

  @Input()
  set dateFrom(datum: Date | string) {
    try {
      let dateObj: Date = datum as Date;
      if (!(datum instanceof Date)) {
        dateObj = DateHelper.parseNorwegianDate(datum as string);
      }
      if (dateObj) {
        this.ngbDateFrom = DateHelper.toNgbDate(dateObj);
        // Update form value
        if (this.frm?.controls?.dateFrom) {
          this.frm.controls.dateFrom.setValue(this.ngbDateFrom);
        }
      }
    } catch (e) {
      return;
    }
  }

  @Input()
  set dateTo(datum: Date | string) {
    try {
      let dateObj: Date = datum as Date;
      if (!(datum instanceof Date)) {
        dateObj = DateHelper.parseNorwegianDate(datum as string);
      }

      if (dateObj) {
        // Ensure the time of the user selected date does not exceed the time of our maxDate:
        dateObj.setHours(0, 0, 1, 1);
        this.ngbDateTo = DateHelper.toNgbDate(dateObj);
        // Update form value
        if (this.frm?.controls?.dateTo) {
          this.frm.controls.dateTo.setValue(this.ngbDateTo);
        }
      }
    } catch (e) {
      return;
    }
  }

  @Input()
  loading$!: Observable<boolean>;

  @Output()
  filtersChanged = new EventEmitter<FilterValuesType>();

  @ViewChild('form')
  ngForm!: NgForm;

  frm!: FormGroup<ReportFilterFormType>;
  ngbDateFrom: NgbDate | undefined;
  ngbDateTo: NgbDate | undefined;

  get maxDate() {
    const today = new Date();
    // Server has a +2 hour timezone, so we use 21:59 to not cross over to next day
    today.setHours(21, 59, 59, 1);
    return today;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['damagePlace']?.isFirstChange() && this.frm) {
      this.frm.controls.filterPlace.setValue(this.damagePlace);
    }
    if (!changes['damageType']?.isFirstChange() && this.frm) {
      this.frm.controls.filterDamageType.setValue(this.damageType);
    }
  }

  ngOnInit(): void {
    const serviceValues = this._reportFilterService.filterCurrentValues;
    if (serviceValues.fromDate && !this.ngbDateFrom) {
      this.ngbDateFrom = DateHelper.toNgbDate(serviceValues.fromDate);
    }
    if (serviceValues.toDate && !this.ngbDateTo) {
      this.ngbDateTo = DateHelper.toNgbDate(serviceValues.toDate);
    }
    if (serviceValues.diagnosisSearch?.length && !this.damageType.length) {
      this.damageType = serviceValues.diagnosisSearch;
    }
    if (serviceValues.placeSearch?.length && !this.damagePlace.length) {
      this.damagePlace = serviceValues.placeSearch;
    }

    this._buildForm();

    this.manualSearch$
      .pipe(
        debounce(duration => timer(duration)),
        tap(() => {
          if (this._validate()) {
            this._emitFilterValues();
          }
        }),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();

    if (this.emitFiltersOnInit) {
      this._emitFilterValues();
    }
  }

  clearFilter(controlName: keyof ReportFilterFormType): void {
    const ctrl = this.frm.get(controlName);
    if (ctrl) {
      ctrl.setValue('');
      if (this._validate()) {
        this._emitFilterValues();
      }
    }
  }

  datePicked() {
    if (this._validate()) {
      this._emitFilterValues();
    }
  }

  private _buildForm(): void {
    this.frm = this._fb.group<ReportFilterFormType>({
      dateFrom: this._fb.control<NgbDate>(
        this.ngbDateFrom || this._ngbCalendar.getPrev(this._ngbCalendar.getToday(), 'y'),
        { validators: Validators.required },
      ),
      dateTo: this._fb.control<NgbDate>(this.ngbDateTo || this._ngbCalendar.getToday(), {
        validators: [Validators.required],
      }),
      filterDamageType: this._fb.control<string>(this.damageType),
      filterPlace: this._fb.control<string>(this.damagePlace),
    });
  }

  private _emitFilterValues(): void {
    const fromDate = this.frm.controls.dateFrom.value;
    const toDate = this.frm.controls.dateTo.value;
    this.filtersChanged.emit({
      diagnosisSearch: this.frm.controls.filterDamageType.value,
      fromDate: DateHelper.toJSDate(fromDate),
      placeSearch: this.frm.controls.filterPlace.value,
      toDate: DateHelper.toJSDate(toDate),
    });
  }

  private _validate(): boolean {
    try {
      // TODO: Update DateValidators to work with NgbDate | NgbDateStruct instead of regualar JS Date()
      // this.frm.controls.dateTo.setValidators([
      //   Validators.required,
      //   DateValidator.notOlderThan(this.frm.controls.dateFrom.value),
      //   DateValidator.notNewerThan(),
      // ]);
      this.frm.updateValueAndValidity();

      // Validate struct syntax and content
      const dateFrom = this.frm.controls.dateFrom.value;
      const dateTo = this.frm.controls.dateTo.value;
      if (!DateHelper.validateNgbDateStruct(dateFrom) || !DateHelper.validateNgbDateStruct(dateTo)) {
        return false;
      }

      // Validate parsed type
      let parsedFrom = DateHelper.toJSDate(dateFrom);
      let parsedTo = DateHelper.toJSDate(dateTo);
      if (isNaN(parsedFrom.getTime()) || isNaN(parsedTo.getTime())) {
        return false;
      }

      // Validate within bounds
      if (parsedFrom < this._minDate) {
        return false;
      }
      if (parsedTo > this.maxDate) {
        parsedTo = this.maxDate;
        this.frm.controls.dateTo.setValue(DateHelper.toNgbDate(parsedTo));
      }
      if (parsedFrom > parsedTo) {
        const tmp = parsedTo;
        parsedFrom = DateHelper.subtractFromDate(tmp, 0, 0, 1);
        this.frm.controls.dateFrom.setValue(DateHelper.toNgbDate(parsedFrom));
      }

      // Validation passed
      return true;
    } catch (e: unknown) {
      return false;
    }
  }
}
