<!-- Modal: Draw Introduction -->
<div class="modal-header">
  <h1 class="modal-title fs-5" id="modalHeaderTitle">
    Hvordan angi skade i kartet?
    <div class="fs-6">Se også fanen «Veiledning» for en utførlig bruksanvisning.</div>
  </h1>
  <button (click)="modal.dismiss('Cross click')" aria-label="Lukk" class="btn-close" type="button"></button>
</div>

<div class="modal-body">
  <p>Her kan du søke og finne et sted ved å</p>

  <ul>
    <li>bruke «min posisjon» («vis meg hvor jeg er»-knapp til høyre på skjermen),</li>
    <li>søke etter stedsnavn/kommune i et eget felt (søkefelt øverst til venstre på skjermen),</li>
    <li>zoome i kartet (+/- knapper til venstre på skjermen eller musehjul),</li>
  </ul>

  <p>og angi funnsted for dine skadeobservasjoner i kartet ved å</p>

  <ul>
    <li>tegne figurer i kartet (forskjellige knapper til venstre på skjermen),</li>
    <li>plassere en eller flere markører i kartet (knapp til venstre på skjermen),</li>
    <li>bruke markøren fra «vis meg hvor jeg er»,</li>
    <li>søke på et stedsnavn i søkefeltet, en markør vil da plasseres ved det angitte stedet i kartet,</li>
    <li>
      søke på en kommune i søkefeltet, kommunegrensen vil da tegnes inn på kartet og hele kommunens areal brukes som
      stedsangivelse.
    </li>
  </ul>

  <p>
    Når du er ferdig trykker du på Lagre-knappen i nedre venstre hjørne for å lagre dine stedsangivelser og gå tilbake
    til rapporteringsskjemaet.
  </p>
</div>

<div class="modal-footer">
  <button (click)="optOutIntro()" class="dontShowDrawTuple btn btn-secondary">Lukk, ikke vis igjen</button>
  <button (click)="modal.close('Close click')" class="btn btn-primary" type="button">Lukk</button>
</div>
