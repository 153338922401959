import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ReportListFiltersType } from '@apptypes/report-list-filters.type';
import { ReportType } from '@apptypes/report.type';
import { ReportListResponseType } from '@apptypes/responses/report-list-response.type';
import { SkogskadeMetadataType } from '@apptypes/responses/skogskade-metadata.type';
import { DateHelper, EmptyInputError, RegexNoMatchError } from '@core/helpers/date.helper';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { ApiService } from '@core/services/api.service';
import { ErrorService } from '@core/services/error.service';
import { SkogskadeWsEndpointsEnum } from '@environments/apis/skogskade.api';
import { BehaviorSubject, debounceTime, EMPTY, filter, Subject, switchMap, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReportListService {
  private readonly _apiService = inject(ApiService);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _errorService = inject(ErrorService);

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _reports$ = new BehaviorSubject<ReportType[]>([]);
  private _reportsError$ = new Subject<boolean>();
  private _reportsMeta$ = new Subject<Partial<SkogskadeMetadataType>>();
  private _search$ = new Subject<void>();

  private _state: Required<ReportListFiltersType> = {
    dateEnd: '',
    dateStart: '',
    id: '',
    page: 1,
    perPage: 20,
    queryDamage: '',
    queryPlace: '',
    sort: '',
  };

  loading$ = this._loading$.asObservable();
  reports$ = this._reports$.asObservable();
  reportsCount = toSignal(this._reportsMeta$.pipe(map(meta => meta.totalCount || 0)), {
    initialValue: 0,
  });
  reportsError$ = this._reportsError$.asObservable();
  reportsMeta$ = this._reportsMeta$.asObservable();

  get damagePlace() {
    return this._state.queryPlace;
  }
  get damageType() {
    return this._state.queryDamage;
  }
  get page() {
    return this._state.page;
  }
  get perPage() {
    return this._state.perPage;
  }
  get sort() {
    return this._state.sort;
  }

  set damagePlace(damagePlace: string) {
    this._set({ queryPlace: damagePlace });
  }
  set damageType(damageType: string) {
    this._set({ queryDamage: damageType });
  }
  set dateEnd(dateEnd: string) {
    this._set({ dateEnd });
  }
  set dateStart(dateStart: string) {
    this._set({ dateStart });
  }
  set page(page: number) {
    this._set({ page });
  }
  set perPage(count: number) {
    this._set({ perPage: count });
  }
  set searchTerms(terms: Partial<ReportListFiltersType>) {
    Object.assign(this._state, terms);
    this._search$.next();
  }
  set sort(sort: string) {
    this._set({ sort });
  }

  private _set(patch: Partial<ReportListFiltersType>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  constructor() {
    this._search$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        tap(() => {
          this._reportsError$.next(false);
          this._loading$.next(true);
        }),
        debounceTime(200),
        filter(() => {
          if (!this._state.dateStart?.length || !this._state.dateEnd?.length) {
            this._loading$.next(false);
            return false;
          }
          return true;
        }),
        switchMap(() => this._search()),
        tap(() => this._loading$.next(false)),
      )
      .subscribe({
        next: response => {
          this._reports$.next(response.records);
          this._reportsError$.next(false);
          this._reportsMeta$.next(response.metadata);
        },
      });

    this._search$.next();
  }

  getCsvPath(params: ReportListFiltersType) {
    if (params.page) {
      this._state.page = params.page;
    }
    if (params.perPage) {
      this._state.perPage = params.perPage;
    }
    if (params.sort) {
      this._state.sort = params.sort;
    }

    const qparams = {
      diagnosis: params.queryDamage || '',
      diagnosisid: params.id || '',
      from: params.dateStart ? DateHelper.toShortNorString(DateHelper.parseNorwegianDate(params.dateStart)) : '',
      municipality: params.queryPlace || '',
      page: this._state.page,
      size: this._state.perPage,
      sort: this._state.sort,
      to: params.dateEnd ? DateHelper.toShortNorString(DateHelper.parseNorwegianDate(params.dateEnd)) : '',
    };
    const path = EnvironmentHelper.getSkogskadePaths().ws + SkogskadeWsEndpointsEnum.GET_REPORTS_CSV;
    return this._apiService.get<{ csvPath: string }>('skogskade', path, {}, qparams).pipe(
      map(response => {
        return response;
      }),
    );
  }

  getReports(params: ReportListFiltersType) {
    let fromStr = params.dateStart;
    let toStr = params.dateEnd;
    try {
      if (params.dateStart?.length) {
        const from = DateHelper.parseNorwegianDate(params.dateStart);
        fromStr = DateHelper.toShortNorString(from) || params.dateStart;
      }
    } catch (e: unknown) {
      console.warn(e);

      // parsing dates might throw errors while user changes input, this is expected, swallow these errors
      if (!(e instanceof EmptyInputError || e instanceof RegexNoMatchError)) {
        throw e;
      }
    }
    try {
      if (params.dateEnd?.length) {
        const to = DateHelper.parseNorwegianDate(params.dateEnd);
        toStr = DateHelper.toShortNorString(to) || params.dateEnd;
      }
    } catch (e: unknown) {
      console.warn(e);

      // parsing dates might throw errors while user changes input, this is expected, swallow these errors
      if (!(e instanceof EmptyInputError || e instanceof RegexNoMatchError)) {
        throw e;
      }
    }

    if (params.page) {
      this._state.page = params.page;
    }
    if (params.perPage) {
      this._state.perPage = params.perPage;
    }
    if (params.sort) {
      this._state.sort = params.sort;
    }

    const path = EnvironmentHelper.getSkogskadePaths().ws + SkogskadeWsEndpointsEnum.GET_REPORTS_PAGED;
    const qparams = {
      diagnosis: params.queryDamage || '',
      diagnosisid: params.id || '',
      from: fromStr || '',
      municipality: params.queryPlace || '',
      page: this._state.page,
      size: this._state.perPage,
      sort: this._state.sort,
      to: toStr || '',
    };

    return this._apiService.get<ReportListResponseType>('skogskade', path, {}, qparams).pipe(
      map(response => {
        this._reports$.next(response.records);
        this._reportsError$.next(false);
        this._reportsMeta$.next(response.metadata);
        this._state.page = response.metadata.page;
        this._state.perPage = response.metadata.perPage;
        return response;
      }),
    );
  }

  private _search() {
    return this.getReports({
      dateEnd: this._state.dateEnd,
      dateStart: this._state.dateStart,
      page: this._state.page,
      perPage: this._state.perPage,
      queryDamage: this._state.queryDamage,
      queryPlace: this._state.queryPlace,
      sort: this._state.sort,
    }).pipe(
      catchError(e => {
        this._reports$.next([]);
        this._reportsError$.next(true);
        this._reportsMeta$.next({ totalCount: 0 });
        this._errorService.showCustomError('Feil ved innhenting av rapporter fra server', e, false);
        return EMPTY;
      }),
    );
  }
}
