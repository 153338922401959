<div class="container-fluid">
  <div class="row justify-content-md-center">
    <div class="col-xs-12 col-sm-8">
      <h2>Om Skogskader.no</h2>
      <p>
        Skogskader.no bygger på brevarkivet ved tidligere Norsk institutt for skogforskning (nå NIBIO) med
        skaderapporter tilbake til 1960-tallet. Skogskader.no inneholder nå flere tusen innsendte rapporter samt
        digitaliserte rapporter fra skadearkivet. Innsending av skaderapporter kan gjøres av alle naturinteresserte
        privatpersoner, skogeiere, skogbrukssjefer og Fylkesmannens landbruksavdeling. Norsk institutt for bioøkonomi
        (NIBIO) har det faglige ansvaret og utfører kvalitetssikring av alle innsendte rapporter.
      </p>
      <p>
        Kontaktpersoner: <a href="https://www.nibio.no/ansatte/isabella-borja" target="_blank">Isabella Børja</a>,
        <a href="https://www.nibio.no/ansatte/paal-krokene" target="_blank">Paal Krokene</a>,
        <a href="https://nibio.no/ansatte/volkmar-timmermann" target="_blank">Volkmar Timmermann </a>
      </p>
      Copyright for tekst og bilder dersom ikke annet er angitt: <br />
      © NIBIO <span id="year">2023</span>.

      <script>
        var d = new Date();
        document.getElementById('year').innerHTML = d.getFullYear();
      </script>

      <p></p>

      <h2>Nedlasting av data</h2>
      <ul>
        <li>
          CSV: Under fanen Rapportoversikt (knapp Last ned øverst til høyre) kan man laste ned de rapportene man har
          valgt med søkefunksjonen. Søkefunksjonen er beskrevet lenger nede her i veiledningen. Man kan også velge alle
          rapporter som er i databasen ved å stille fra-datoen tilbake til 01.01.1960 og la de andre søkefeltene stå
          tomme. Det genereres en csv-fil som enkelt kan åpnes i Excel eller i en teksteditor. Av tekniske årsaker er
          ikke koordinater for observasjonsstedene inkludert i csv-fila.
        </li>
        <li>
          Man kan også laste ned en csv-fil med alle rapporter for en bestemt skadetype ved å gå inn i
          Skadebeskrivelser, velge skaden man er interessert i, klikke på Se alle rapporter (nederst) og trykke på Last
          ned-knappen øverst i vinduet som da åpner seg. Her får man også med eventuelle underordnete kategorier av
          skadegjørere. Hvis man f.eks. velger beskrivelsen Sopp (klikk på i-knappen til høyre) så kan man laste ned
          alle innrapporterte skader av sopper for alle år.
        </li>
        <li>
          WFS: Dersom man trenger koordinater, ønsker å importere data fra Skogskader.no til en database, eller vil
          bruke dem i QGis, Arcgis e.l., kan man laste ned data som WFS som inkluderer alle koordinater.
          WFS-beskrivelsen er tilgjengelig her:
          <a href="https://wfs.nibio.no/cgi-bin/skogskader?SERVICE=WFS&amp;request=GetCapabilities&amp;VERSION=2.0.0">
            https://wfs.nibio.no/cgi-bin/skogskader?SERVICE=WFS&amp;request=GetCapabilities&amp;VERSION=2.0.0
          </a>
        </li>
      </ul>

      <h2>Skadebeskrivelser</h2>
      <p>
        Her kan du lese om mer enn 200 ulike skadetyper. Oversikten omfatter de vanligste skader forårsaket av insekter,
        sopper og klima, forurensing og næringsmangel. De fleste skadetypene er beskrevet med tekst og bilder.
        Forekomst, symptomer, biologi, årsaksforhold, skoglig betydning og mulige forvekslinger omtales.
      </p>
      <h2>Kart</h2>
      <p>
        På kartet vises alle kartfestete stedsangivelser fra innsendte skaderapporter som er godkjent og kvalitetssikret
        av NIBIO. Du kan zoome inn og ut ved å bruke +/- knappene eller musehjulet, og navigere ved å klikke i kartet og
        dra i det (hold venstre museknapp nede), eller ved å søke på kommune, sted eller koordinater i søkefeltet øverst
        til venstre på skjermen. Koordinatene må angis som desimalgrader nord, øst (eks.: 59.66868, 10.71871). Øverst
        til høyre i skjermbildet kan man velge forskjellige bakgrunnskart og karttemaer.
      </p>

      <p>
        Når du klikker på et objekt i kartet, får du opp navnet på skadetypen og en lenke til rapporten. Ved
        overlappende skadeutbredelse vises alle rapportene fra det området i kartet man klikker på.
      </p>
      <h2>Rapportoversikt</h2>
      <p>
        I denne oversikten listes alle innsendte skaderapporter som er godkjent og kvalitetssikret av NIBIO. Klikk på en
        rapport i oversikten for å åpne den. Enkeltrapportvisningen inneholder mer detaljert informasjon om den
        observerte skaden og eventuelle bilder som er vedlagt rapporten.
      </p>
      <p>
        I utgangspunktet vises alle rapportene ett år tilbake i tid fra dagens dato. Du kan endre dette ved å angi et
        annet tidsrom, søke på skadetype og/eller fylke/kommune. Bruk av anførselstegn rundt søkeordet (" ") gir eksakt
        treff på skadetype og kommune. Søkefeltene blir kombinert. Husk å nullstille søkefeltene når du skal gjøre et
        nytt søk. Hver kolonne i rapportoversikten kan sorteres både stigende og synkende ved å klikke på
        kolonneoverskriften. Alle skadeobservasjoner fra søkeresultatet kan vises i kart (lenke øverst). Her vises også
        observasjoner som ikke er koordinat-/kartfestet, angitt med en rød markør i kommunesenteret. Blå markører viser
        nøyaktig kartfestete stedsangivelser.
      </p>

      <h2>Meld skade (send inn skaderapport)</h2>

      <p>
        Skadeobservasjoner kan rapporteres inn og stedfestes ved å tegne inn observasjonsstedet på et digitalt kart.
        Digitale bilder kan lastes opp og lagres sammen med rapporten som separate filvedlegg.
      </p>
      <h3>Obligatoriske opplysninger:</h3>
      <ul>
        <li>
          Observert dato: Dato for skadeobservasjonen (standardverdi er dagens dato, husk å endre dette hvis
          observasjonen ble gjort på en annen dato).
        </li>
        <li>Stedfest skaden: Stedsangivelse gjøres i kartet (se eget avsnitt nedenfor).</li>
        <li>
          Skadetype: Velg én eller flere skadetyper fra lista. Det er enklest å søke etter en skade. Trykk på «Ferdig»
          for å lagre dine valg. Ukjent skadeårsak kan også angis. Da er det viktig å beskrive skaden så nøyaktig som
          mulig i kommentarfeltet og å legge ved bilder som kan være til hjelp ved identifikasjonen.
        </li>
        <li>
          Vertsplante: Velg én eller flere vertsplanter fra lista. Det er enklest å søke etter en vertsplante. For å
          angi at skaden er observert i en blandingsskog, kan man for eksempel velge «Bartrær» + «Lauvtrær». Trykk på
          «Ferdig» for å lagre dine valg.
        </li>
        <li>Observatør: Navn på personen som har observert skaden.</li>
        <li>
          Epost: Observatørens epost. Vi trenger observatørens epost for eventuelt å kunne innhente flere opplysninger
          om den rapporterte skaden. Eposten vil ikke offentliggjøres på våre nettsider og lagres kun internt i
          databasen.
        </li>
      </ul>

      <h3>Andre opplysninger (ikke obligatorisk):</h3>

      <ul>
        <li>Telefon: Observatørens telefonnummer. Telefonnummeret vil ikke offentliggjøres på våre nettsider.</li>
        <li>
          Kommentar: Nærmere beskrivelse av skaden, observerte symptomer, utbredelse i bestandet/kommunen,
          skogens/treets alder, del av treet, etc. En utførlig beskrivelse vil lette skadeidentifikasjonen.
        </li>
        <li>
          Legg ved bilde: Her kan du legge ved ett eller flere bilder (maks. 20) som viser skaden. Fyll inn navn på
          fotografen (feltet er forhåndsutfylt med navn på observatør) og gjerne en bildetekst. Husk å godkjenne våre
          vilkår for opplasting av bilder. En lagre-knapp vil bli synlig etter at vilkårene er godkjent.
        </li>
      </ul>

      <h2>Stedfest skaden/Tegn i kart:</h2>
      <p>Her kan du søke og finne et sted ved å</p>
      <ul>
        <li>bruke «min posisjon» («vis meg hvor jeg er»-knapp til høyre på skjermen),</li>
        <li>søke etter stedsnavn/kommune/koordinater i et eget felt (søkefelt øverst til venstre på skjermen),</li>
        <li>Zoome i kartet (+/- knapper til venstre på skjermen eller musehjul)</li>
      </ul>
      <p>Du kan angi funnsted for dine skadeobservasjoner i kartet ved å</p>
      <ul>
        <li>
          tegne figurer i kartet når skaden har en romlig utbredelse, f.eks. et bestand eller et større område
          (forskjellige knapper til venstre på skjermen),
        </li>
        <li>
          plassere en eller flere markører i kartet når skaden er begrenset til et lite område eller bare et tre (knapp
          til venstre på skjermen),
        </li>
        <li>bruke markøren fra «vis meg hvor jeg er» når du befinner deg rett ved den observerte skaden,</li>
        <li>
          søke på et stedsnavn eller koordinat i søkefeltet, en markør vil da plasseres ved det angitte stedet i kartet,
        </li>
        <li>
          søke på en kommune i søkefeltet. Da vil kommunegrensen tegnes inn på kartet og hele kommunens areal kan brukes
          som stedsangivelse når skaden forekommer i hele eller store deler av kommunen. Dette kan også gjøres når
          skaden opptrer spredt i kommunen, men spesifiser dette gjerne i kommentarfeltet.
        </li>
      </ul>
      <p>Koordinater må angis som desimalgrader nord, øst (eks.: 59.66868, 10.71871).</p>

      <p>Det er mulig å angi flere funnsteder og å kombinere enkelpunktobservasjoner og figurer i samme kart.</p>

      <p>
        Du kan redigere (endre tegnete polygoner, flytte markører) og slette dine stedsangivelser (knapper til venstre i
        menyen).
      </p>
      <p>
        Trykk på Lagre-knappen for å lagre dine stedsangivelser og gå tilbake til rapporteringsskjemaet. Så lenge
        rapporten ikke er sendt inn, kan du når som helst gå tilbake til kartet for å endre stedsangivelsen(e).
      </p>
      <br />
      <p>
        <!-- Button trigger modal -->
        <button (click)="openTermsModal()" class="btn btn-primary" type="button">
          Standardvilkår for rapport og bilder lastet inn på skogskader.no, Norsk institutt for bioøkonomi (NIBIO)
        </button>
      </p>
      <br />
    </div>
  </div>
</div>
