import { Injectable } from '@angular/core';
import { ImageAttachmentType } from '@apptypes/image-attachment.type';
import { ImageUploadType } from '@apptypes/image-upload.type';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  queue = new Map<string, ImageUploadType>();

  addUploadToQueue(key: string, attachment: ImageAttachmentType): void {
    this.queue.set(key, {
      fileName: attachment.file.name,
      fileSize: attachment.file.size,
      fileType: attachment.file.type,
      imageAttachment: attachment,
      validationErrors: null,
    } as ImageUploadType);
  }
}
