<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<div class="container content-padding-bottom content-padding-top">
  <div class="row justify-content-sm-center">
    <div class="col-xs-12 col-sm-8 text-left">
      <section class="intro">
        Her kan du rapportere inn og stedfeste dine skadeobservasjoner. Digitale bilder kan lastes opp og lagres sammen
        med rapporten. Rapporten sendes til NIBIO og kvalitetsikres før den er tilgjengelig i rapportoversikten.
      </section>

      <section class="form">
        <div class="form-intro required">
          Felt merket med * er obligatoriske og må fylles ut. Vennligst fyll ut kontaktinformasjon samt type, sted og
          vertsplante for skaden.
        </div>

        <form #form="ngForm" (ngSubmit)="submitForm()" [class.submitted]="submitClicked" enctype="multipart/form-data">
          <section class="form-section">
            <div [ngClass]="rfh.getFormGroupClasses(fieldsCtrl.observer)" class="form-group">
              <label for="name">
                Observatør
                <span class="help-text">Navn på person som har observert skaden</span>
              </label>
              <input
                [class.touched]="fieldsCtrl.observer.touched"
                [formControl]="fieldsCtrl.observer"
                [ngClass]="rfh.getFormControlClasses(fieldsCtrl.observer)"
                autocomplete="name"
                id="name"
                placeholder="Fornavn Etternavn"
                type="text"
              />
              <app-form-field-errors
                [control]="fieldsCtrl.observer"
                [showErrors]="fieldsCtrl.observer.touched || submitClicked"
              ></app-form-field-errors>
            </div>
          </section>

          <section class="form-section">
            <div [ngClass]="rfh.getFormGroupClasses(fieldsCtrl.email)" class="form-group">
              <label for="email">
                E-postadresse
                <span class="help-text">Adresse observatør kan nås på</span>
              </label>
              <input
                [class.touched]="fieldsCtrl.email.touched"
                [formControl]="fieldsCtrl.email"
                [ngClass]="rfh.getFormControlClasses(fieldsCtrl.email)"
                autocomplete="email"
                id="email"
                placeholder="navn@domene.no"
                type="email"
              />
              <app-form-field-errors
                [control]="fieldsCtrl.email"
                [messages]="validationMessages.email"
                [showErrors]="fieldsCtrl.email.touched || submitClicked"
              ></app-form-field-errors>
            </div>
          </section>

          <section class="form-section">
            <div [ngClass]="rfh.getFormGroupClasses(fieldsCtrl.phone)" class="form-group">
              <label for="phone">
                Telefonnummer
                <span class="help-text">Telefonnr. observatør kan nås på</span>
              </label>
              <input
                [class.touched]="fieldsCtrl.phone.touched"
                [formControl]="fieldsCtrl.phone"
                [ngClass]="rfh.getFormControlClasses(fieldsCtrl.phone)"
                autocomplete="tel"
                id="phone"
                inputmode="number"
                type="text"
              />
              <app-form-field-errors
                [control]="fieldsCtrl.phone"
                [messages]="validationMessages.phone"
                [showErrors]="fieldsCtrl.phone.touched || submitClicked"
              ></app-form-field-errors>
            </div>
          </section>

          <section class="form-section">
            <div [ngClass]="rfh.getFormGroupClasses(dataCtrl.damageTypes)" class="form-group">
              <label for="damage"
                >Skadetype
                <span class="help-text"
                  >Hva slags skade? Velg ev. flere om de er observert på samme vertsplante</span
                ></label
              >
              <div>
                <ul *ngIf="dataCtrl?.damageTypes?.value?.length" class="current-data selected-nodes">
                  <li *ngFor="let dmg of dataCtrl.damageTypes.value">
                    <i
                      (click)="removeFromCollectionField(dmg, dataCtrl.damageTypes)"
                      (keyup.enter)="removeFromCollectionField(dmg, dataCtrl.damageTypes)"
                      class="bi bi-x-circle"
                      tabindex="0"
                      title="Fjern valg"
                    ></i>
                    {{ dmg.label }}
                  </li>
                </ul>

                <button
                  (click)="openDamagesModal()"
                  (keyup.enter)="openDamagesModal()"
                  class="btn btn-primary btn--inline form-control"
                  id="damage"
                  tabindex="0"
                  type="button"
                >
                  Legg til skade
                </button>
                <input [formControl]="dataCtrl.damageTypes" type="hidden" />
              </div>
              <app-form-field-errors
                [control]="dataCtrl.damageTypes"
                [messages]="validationMessages.damageTypes"
                [showErrors]="dataCtrl.damageTypes.touched || submitClicked"
              ></app-form-field-errors>
            </div>
          </section>

          <section class="form-section">
            <div [ngClass]="rfh.getFormGroupClasses(dataCtrl.damageHosts)" class="form-group">
              <label for="host"
                >Vertsplante <span class="help-text">Velg vertsplante(r) skaden er observert på</span></label
              >
              <div>
                <ul *ngIf="dataCtrl?.damageHosts?.value?.length" class="current-data selected-nodes">
                  <li *ngFor="let host of dataCtrl.damageHosts.value">
                    <i
                      (click)="removeFromCollectionField(host, dataCtrl.damageHosts)"
                      (keyup.enter)="removeFromCollectionField(host, dataCtrl.damageHosts)"
                      class="bi bi-x-circle"
                      tabindex="0"
                      title="Fjern valg"
                    ></i>
                    {{ host.label }}
                  </li>
                </ul>

                <button
                  (click)="openHostsModal()"
                  (keyup.enter)="openHostsModal()"
                  class="btn btn-primary btn--inline form-control"
                  id="host"
                  tabindex="0"
                  type="button"
                >
                  Legg til vertsplante
                </button>
                <input [formControl]="dataCtrl.damageHosts" type="hidden" />
              </div>
              <app-form-field-errors
                [control]="dataCtrl.damageHosts"
                [messages]="validationMessages.damageHosts"
                [showErrors]="dataCtrl.damageHosts.touched || submitClicked"
              ></app-form-field-errors>
            </div>
          </section>

          <section class="form-section">
            <div [ngClass]="rfh.getFormGroupClasses(dataCtrl.observationDate)" class="form-group">
              <label>Observert dato <span class="help-text">Når så du skaden?</span></label>
              <div>
                <ngb-datepicker #dp [formControl]="dataCtrl.observationDate" [maxDate]="dateToday"></ngb-datepicker>
              </div>
              <app-form-field-errors
                [control]="dataCtrl.observationDate"
                [showErrors]="dataCtrl.observationDate.touched || submitClicked"
              ></app-form-field-errors>
            </div>
          </section>

          <section class="form-section">
            <div [ngClass]="rfh.getFormGroupClasses(dataCtrl.observationArea)" class="form-group">
              <label for="area"
                >Stedfest skaden
                <span class="help-text"
                  >Hvor så du skaden? Tegn område i kartet og trykk "Lagre funnsted" for å returnere til dette
                  skjemaet.</span
                ></label
              >
              <div>
                <div class="current-data num-figures">
                  {{ numGeoFigures() === 0 ? 'Ingen' : numGeoFigures() }} skadeområde{{
                    numGeoFigures() === 1 ? '' : 'r'
                  }}
                  tegnet i kartet
                </div>
                <button
                  (blur)="dataCtrl.observationArea.markAsTouched()"
                  class="btn btn-primary btn--inline form-control"
                  id="area"
                  routerLink="/kart/tegning"
                  tabindex="0"
                  type="button"
                >
                  Tegn i kart
                </button>
                <input [formControl]="dataCtrl.observationArea" type="hidden" />
              </div>
              <app-form-field-errors
                [control]="dataCtrl.observationArea"
                [messages]="validationMessages.observationArea"
                [showErrors]="dataCtrl.observationArea.touched || submitClicked"
              ></app-form-field-errors>
            </div>
          </section>

          <section class="form-section">
            <div class="form-group">
              <label for="imageInput"
                >Bildedokumentasjon
                <span class="help-text"
                  >Last opp fotografier av skaden. Gyldige filtyper er ({{ allowedFilesAsCsv }}), opptil
                  {{ maxFileCount }} bilder á {{ maxFileSize | fileSize }} hver.
                </span></label
              >
              <!-- List images selected for upload -->
              <div *ngFor="let imgGroupCtrl of formImages.controls; let idx = index" class="img-list">
                <div class="img-container form-group">
                  <img
                    [src]="createImageSrc(imgGroupCtrl.controls.file.value)"
                    [title]="'Forhåndsvisning av « ' + imgGroupCtrl.controls.file.value.name + ' »'"
                    alt="Forhåndsvisning"
                    class="img-preview img-thumbnail rounded float-start"
                  />

                  <span class="img-label">Bilde {{ idx + 1 }}:</span>
                  <span class="img-name"
                    >{{ imgGroupCtrl.controls.file.value.name }}
                    <span class="img-size">({{ imgGroupCtrl.controls.file.value.size | fileSize }})</span>
                  </span>
                  <span
                    (click)="removeImageAttachment(imgGroupCtrl.controls.file.value.name, idx)"
                    (keyup.enter)="removeImageAttachment(imgGroupCtrl.controls.file.value.name, idx)"
                    [title]="'Fjern bildet « ' + imgGroupCtrl.controls.file.value.name + ' »'"
                    class="img-action bi bi-x-circle"
                    tabindex="0"
                  ></span>

                  <label
                    for="caption_{{ idx }}"
                    title="Bildetekst for « {{ imgGroupCtrl.controls.file.value.name }} »"
                    class="caption-label"
                    >Bildetekst</label
                  >
                  <input
                    [formControl]="imgGroupCtrl.controls.captionText"
                    id="caption_{{ idx }}"
                    class="caption-input"
                  />

                  <label
                    for="credit_{{ idx }}"
                    title="Fotograf for « {{ imgGroupCtrl.controls.file.value.name }} »"
                    class="credit-label"
                    >Fotograf</label
                  >
                  <input
                    [formControl]="imgGroupCtrl.controls.photographer"
                    id="credit_{{ idx }}"
                    class="credit-input"
                  />

                  <div
                    *ngIf="
                      imgGroupCtrl.controls.file.touched &&
                      imgGroupCtrl.controls.file.invalid &&
                      imgGroupCtrl.controls.file.errors
                    "
                    class="validation form-validation"
                  >
                    <span *ngIf="imgGroupCtrl.controls.file.errors['fileSize']">For stor filstørrelse, maks 20MB.</span>
                  </div>

                  <app-form-field-errors
                    [control]="imgGroupCtrl.controls.file"
                    [messages]="validationMessages.images"
                    [showErrors]="imgGroupCtrl.controls.file.touched || submitClicked"
                  ></app-form-field-errors>
                </div>
              </div>

              <!-- List any general validation messages for the group of images -->
              <div *ngIf="formImages.errors" class="validation images-validation-group">
                <span>For stor filstørrelse, maks 20MB.</span>
              </div>

              <div>
                <button
                  (click)="imageSelectRef.click()"
                  (keyup.enter)="imageSelectRef.click()"
                  class="btn btn-primary btn--inline form-control"
                  tabindex="0"
                  type="button"
                >
                  Legg til bilde
                </button>
                <input
                  #imageSelectRef
                  (change)="filesSelected()"
                  [accept]="allowedFilesAsCsv"
                  class="visually-hidden"
                  id="imageInput"
                  multiple
                  type="file"
                />
              </div>
            </div>
          </section>

          <section class="form-section">
            <div [ngClass]="rfh.getFormGroupClasses(fieldsCtrl.comment)" class="form-group">
              <label for="comment">
                Kommentar
                <span class="help-text"
                  >Ekstra informasjon som skogens alder, utbredelse i bestandet, del av treet, vegetasjonstype,
                  jordsmonn, høyde over havet</span
                >
              </label>
              <textarea
                [formControl]="fieldsCtrl.comment"
                [ngClass]="rfh.getFormControlClasses(fieldsCtrl.comment)"
                id="comment"
                placeholder="F.eks: Gjelder rundt 100 trær. Middels bonitet. 450 moh."
              ></textarea>
            </div>
          </section>

          <section class="form-section">
            <div [ngClass]="rfh.getFormGroupClasses(fieldsCtrl.terms)" class="form-group">
              <label for="tos">Innsending</label>
              <div class="submission">
                <div class="mb-3">
                  Les
                  <button
                    (click)="openTermsModal()"
                    (keyup.enter)="openTermsModal()"
                    class="link"
                    id="tos"
                    tabindex="0"
                    type="button"
                  >
                    innsendingsbetingelsene</button
                  >.
                </div>
                <div class="checkbox-group">
                  <input [formControl]="fieldsCtrl.terms" class="checkbox-input" id="terms" type="checkbox" />
                  <label class="checkbox-label inline terms-label" for="terms">Jeg aksepterer betingelsene </label>
                </div>
              </div>
              <app-form-field-errors
                [control]="fieldsCtrl.terms"
                [messages]="validationMessages.terms"
                [showErrors]="fieldsCtrl.terms.touched || submitClicked"
              ></app-form-field-errors>
            </div>

            <div class="buttons">
              <button class="btn btn-primary btn-lg form-control" type="submit">
                {{ submitInProgress ? 'Sender rapport..' : 'Send rapport' }}
              </button>
            </div>
          </section>
        </form>
      </section>
    </div>
  </div>
</div>
