import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { LoadingIndicatorComponent } from '@shared/components/loading-indicator/loading-indicator.component';
import { filter, tap } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterLink, LoadingIndicatorComponent, RouterLinkActive],
  selector: 'app-header',
  standalone: true,
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  currentUrl = '';

  private readonly _destroyRef = inject(DestroyRef);

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _router: Router,
  ) {}

  // TODO: Helps mark the active tab in navigation while in hybrid. Also helps
  //  highlight "Meld skade" while drawing in map.
  //  Should be replaced by routerLinkActive when possible.
  isCurrentUrl(page: string, exactMatch: boolean = false): boolean {
    if (exactMatch) {
      return this.currentUrl === page;
    }

    return this.currentUrl.includes(page);
  }

  ngOnInit(): void {
    this.currentUrl = window.location.href;

    // TODO: Helps mark the active tab in navigation while in hybrid. Also helps
    //  highlight "Meld skade" while drawing in map.
    //  Should be replaced by routerLinkActive when possible.
    this._router.events
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        tap(event => {
          this.currentUrl = event.url;
          this._cdr.detectChanges();
        }),
      )
      .subscribe();
  }
}
