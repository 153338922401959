import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { HeaderComponent } from '@shared/components/header/header.component';
import { RouteBreadcrumbsComponent } from '@shared/components/route-breadcrumbs/route-breadcrumbs.component';
import { AngularJsLazyLoaderService } from '../angular-js-lazy-loader.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, RouteBreadcrumbsComponent, HeaderComponent],
  selector: 'app-ajs-loader',
  standalone: true,
  styleUrls: ['./ajs-loader.component.scss'],
  templateUrl: './ajs-loader.component.html',
})
export class AjsLoaderComponent implements OnInit, OnDestroy {
  constructor(
    private readonly _ajsLazyLoaderService: AngularJsLazyLoaderService,
    protected elRef: ElementRef,
  ) {}

  ngOnInit() {
    this._ajsLazyLoaderService.load(this.elRef.nativeElement);
  }

  ngOnDestroy() {
    this._ajsLazyLoaderService.destroy();
  }
}
