(function (skogskadeDirectives) {
  'use strict';

  skogskadeDirectives.directive('reportFilter', reportFilterFn);

  reportFilterFn.$inject = ['reportListService', 'reportFilterService', 'messageService', 'dateHelper', '$filter'];

  function reportFilterFn(reportListService, reportFilterService, messageService, dateHelper, $filter) {
    var validDate = function (jQueryId) {
      var inputText = $(jQueryId).val();
      return inputText.length === 10;
    };

    return {
      restrict: 'A',
      replace: true,
      transclude: true,
      templateUrl: 'ajs/partials/report-filter.html',
      scope: {
        reports: '=',
        configVar: '=',
        filteredReports: '=',
      },
      link: function (scope, element, attrs) {
        scope.fromDateInvalid = false;
        scope.toDateInvalid = false;
        init();

        function init() {
          scope.fromDate = reportFilterService.getFilterValue('fromDate');
          scope.toDate = reportFilterService.getFilterValue('toDate');
          scope.textSearch = reportFilterService.getFilterValue('textSearch');
          scope.placeSearch = reportFilterService.getFilterValue('placeSearch');
        }

        function getReports() {
          reportListService.getReports(scope.fromDate, scope.toDate, scope.textSearch, scope.placeSearch).then(
            function (callbackdata) {
              scope.filteredReports.filtered = callbackdata;
            },
            function (response) {
              var errorMessage = {
                title:
                  'Klarte ikke å hente rapporter fra ' +
                  dateHelper.dateToString(scope.fromDate) +
                  ' til ' +
                  dateHelper.dateToString(scope.toDate) +
                  '.',
                text: 'Vennligst prøv igjen senere.',
              };
              messageService.setMessage(errorMessage);
              messageService.showMessage(true);
            }
          );
        } // end getReports

        scope.updateToDate = function (newVal) {
          //formInitDataService.setDate(newVal);
          // Make sure a searcht is not triggered if year is not valid

          if (typeof newVal !== 'undefined' && validDate('#toDateId')) {
            if (newVal > dateHelper.skToDate('31.12.1900')) {
              scope.toDateInvalid = false;
              reportFilterService.setFilterValue('toDate', newVal);
            } else {
              scope.toDateInvalid = true;
            }
          } else {
            scope.toDateInvalid = true;
          }
        };

        scope.updateFromDate = function (newVal) {
          //formInitDataService.setDate(newVal);
          // Make sure we do not get new reports until date is valid
          if (typeof newVal !== 'undefined' && validDate('#fromDateId')) {
            if (newVal > dateHelper.skToDate('31.12.1900')) {
              scope.fromDateInvalid = false;

              reportFilterService.setFilterValue('fromDate', newVal);
            } else {
              scope.fromDateInvalid = true;
            }
          } else {
            scope.fromDateInvalid = true;
          }
        };

        scope.validDate = function (jQueryId) {
          var inputText = $(jQueryId).val();
          return inputText.length === 10;
        };

        scope.updateTextSearch = function (newVal) {
          if (typeof newVal !== 'undefined') {
            reportFilterService.setFilterValue('textSearch', newVal);
          }
        };

        scope.updatePlaceSearch = function (newVal) {
          //formInitDataService.setDate(newVal);
          if (typeof newVal !== 'undefined') {
            reportFilterService.setFilterValue('placeSearch', newVal);
          }
        };

        scope.maxFrom = dateHelper.dateToStringReverse(new Date());
        scope.maxTo = dateHelper.dateToStringReverse(new Date());

        scope.diagnosisDate = new Date();

        scope.clear = function () {
          scope.fromDate = null;
        };

        scope.fromOpened = false;
        scope.openFrom = function ($event) {
          $event.preventDefault();
          $event.stopPropagation();
          scope.fromOpened = true;
        };

        scope.openTo = function ($event) {
          $event.preventDefault();
          $event.stopPropagation();
          scope.toOpened = true;
        };

        scope.dateOptions = {
          formatYear: 'yyyy',
          formatDay: 'dd',
          formatMonth: 'MM',
          startingDay: 1,
        };

        scope.initDate = new Date();
      },
    };
  }
})(angular.module('skogskadeDirectives'));
