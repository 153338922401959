L.drawLocal = {
    draw: {
        toolbar: {
            actions: {
                title: "Avslutt tegning",
                text: "Avslutt"
            },
            finish: {
              title: "Ferdig med tegning",
              text: "Ferdig"
            },
            undo: {
                title: "Slett siste tegnede punkt",
                text: "Slett siste punkt"
            },
            buttons: {
                polyline: "Tegn en linje",
                polygon: "Tegn et polygon",
                rectangle: "Tegn en rektangel",
                circle: "Tegn en sirkel",
                marker: "Plassèr markør",
                circlemarker: "Plassér en sirkelmarkør",
            }
        },
        handlers: {
            circle: {
                tooltip: {
                    start: "Klikk og dra for å tegne en sirkel."
                }
            },
            circlemarker: {
                tooltip: {
                    start: "Klikk og dra for å tegne en sirkel."
                }
            },
            marker: {
                tooltip: {
                    start: "Klikk i kartet for å plassere en markør."
                }
            },
            polygon: {
                tooltip: {
                    start: "Klikk for å begynne å tegne et objekt.",
                    cont: "Klikk for å fortsette å tegne på objektet.",
                    end: "Klikk i første punkt for å avslutte flaten."
                }
            },
            polyline: {
                error: "<strong>Feil:</strong> Linjen kan ikke krysse seg selv!",
                tooltip: {
                    start: "Klikk for å begynne å tegne en linje.",
                    cont: "Klikk for å fortsette å tegne en linje.",
                    end: "Klikk i siste punkt for å avslutte linjen."
                }
            },
            rectangle: {
                tooltip: {
                    start: "Klikk og dra for å tegne et rektangel."
                }
            },
            simpleshape: {
                tooltip: {
                    end: "Slipp museknapp for å avslutte tegning."
                }
            }
        }
    },
    edit: {
        toolbar: {
            actions: {
                save: {
                    title: "Lagre redigering.",
                    text: "Lagre redigering"
                },
                cancel: {
                    title: "Avslutt editering, fjern alle endringer.",
                    text: "Avbryt"
                },
                clearAll: {
                    title: "Fjern alle tegninger i alle lag",
                    text: "Tøm alt"
                }
            },
            buttons: {
                edit: "Endre stedsangivelse",
                editDisabled: "Ingen stedsangivelser å endre.",
                remove: "Slett stedsangivelse",
                removeDisabled: "Ingen kartlag å slette."
            }
        },
        handlers: {
            edit: {
                tooltip: {
                    text: "Dra punktene, eller markøren for å endre.",
                    subtext: "Klikk 'lagre' for å avslutte og lagre redigering eller 'avbryt' for å angre redigering"
                }
            },
            remove: {
                tooltip: {
                    text: "Klikk på kartobjekt for å slette. Klikk 'lagre' for å lagre sletting eller 'avbryt' for å angre sletting."
                }
            }
        }
    }
};

//module.exports = { L };
