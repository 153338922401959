import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  selector: 'app-damage-list',
  standalone: true,
  styleUrls: ['./damage-list.component.scss'],
  templateUrl: './damage-list.component.html',
})
export default class DamageListComponent {}
