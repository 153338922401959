import { Injectable } from '@angular/core';
import { ToastInfoType } from '@apptypes/toast-info.type';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: ToastInfoType[] = [];

  remove(toast: ToastInfoType) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  show(body: string, header: string, autohide = false, cssClass?: string, delay?: number) {
    this.toasts.push({ autohide, body, cssClass, delay, header });
  }
}
