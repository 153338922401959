(function (skogskadeServices) {
  'use strict';

  skogskadeServices.factory('legendService', legendServiceFn);

  legendServiceFn.$inject = [];

  function legendServiceFn() {
    var getLegendConfig = function () {
      var ownLegends = [
        {
          name: 'Skogskade, stedfestet i kommunesenter',
          html: '<img class ="legendiconsize" src="ajs/img/marker_red_circle.png" />',
        },
        {
          name: 'Skogskade, stedfesting er omtrentlig&nbsp;&nbsp;&nbsp; (Landsskogtakseringen/skogovervåkingen)',
          html: '<img class ="legendiconsize" src="ajs/img/marker_green_circle.png" />',
        },
        {
          name: 'Skogskade, punktlokasjon',
          html: '<img class ="legendiconsize" src="ajs/img/marker_blue_circle.png" />',
        },
        {
          name: 'Skogskade, areal',
          html: '<span class="skogskade-flate-legend"><img/></span>',
        },
      ];
      return ownLegends;
    };

    var getLegendTable = function () {
      var legendTable = '';
      var ownLegends = getLegendConfig();
      for (var j = 0; j < ownLegends.length; j++) {
        legendTable += '<div class="legendItem">';
        legendTable += ownLegends[j].html;
        legendTable += '<span>' + ownLegends[j].name + '</span>';
        legendTable += '</div>';
      }
      return legendTable;
    };

    var getLegendElement = function () {
      var legendElement = L.DomUtil.create('div', 'legend-container-simple');
      var legendButtonHtml =
        '<div class="legend"><span alt="Tegnforklaring" class="legendicon glyphicon glyphicon-info-sign"></span></div>';
      legendElement.innerHTML += legendButtonHtml;
      legendElement.innerHTML = '<div class="legend-popup" alt="Tegnforklaring">' + getLegendTable() + '</div>';
      return legendElement;
    };

    return {
      getLegendConfig: getLegendConfig,
      getLegendTable: getLegendTable,
      getLegendElement: getLegendElement,
    };
  }
})(angular.module('skogskadeServices'));
