import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ValidatorHelper } from '@core/helpers/validator.helper';
import { FeatureCollection } from 'geojson';

export class FeatureCollectionValidator {
  static readonly ERROR_MINIMUM = 'FEATURE_COLLECTION_MIN_FEATURES';

  static minFeatures(minRequired = 1): ValidatorFn {
    return function validate(ctrl: AbstractControl) {
      if (ValidatorHelper.isEmptyInputValue(ctrl.value)) {
        return null;
      }

      if (ctrl.value) {
        const collection = ctrl.value as FeatureCollection;
        if (collection?.features instanceof Array && collection?.features?.length >= minRequired) {
          return null;
        }
        return { [FeatureCollectionValidator.ERROR_MINIMUM]: true };
      }

      return null;
    };
  }
}
