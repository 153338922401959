import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { ApiService } from '@core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private readonly _cacheValuesKey = 'formValues_';
  private readonly _destroyRef = inject(DestroyRef);

  constructor(private readonly _apiService: ApiService) {}

  cacheValuesClear(formId: string) {
    sessionStorage.removeItem(this._cacheValuesKey + formId);
  }

  cacheValuesInit<T extends object>(formId: string, form: FormGroup<T>, formDestroyRef?: DestroyRef) {
    form.valueChanges.pipe(takeUntilDestroyed(this._destroyRef || formDestroyRef)).subscribe(() => {
      sessionStorage.setItem(this._cacheValuesKey + formId, JSON.stringify(form.value));
    });
  }

  cacheValuesRestore<T extends object>(
    formId: string,
    form: FormGroup<T>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reviverFn: ((key: string, value: any) => any) | undefined = undefined,
  ) {
    const cached = sessionStorage.getItem(this._cacheValuesKey + formId);
    if (cached && cached.length > 0) {
      const parsed = JSON.parse(cached, reviverFn) as T;
      form.patchValue(parsed);
    }
  }
}
