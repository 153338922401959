(function (skogskadeControllers) {
  'use strict';

  skogskadeControllers.controller('reportDetailController', reportDetailControllerFn);

  reportDetailControllerFn.$inject = [
    '$scope',
    '$http',
    '$routeParams',
    '$sce',
    '$location',
    'reportDetailService',
    'reportPicturesService',
    'sortDetailService',
    'stateListModel',
    'skogskadeGlobalOptions',
    'mapDataService',
    'leafletStyles'
  ];

  function reportDetailControllerFn(
    $scope,
    $http,
    $routeParams,
    $sce,
    $location,
    reportDetailService,
    reportPicturesService,
    sortDetailService,
    stateListModel,
    skogskadeGlobalOptions,
    mapDataService,
    leafletStyles
  ) {
    $http.defaults.useXDomain = true;
    $scope.reportId = $routeParams.reportId;
    $scope.layers = [];
    $scope.map = null;
    var sortReport = [
      'diagnosisList',
      'hostsList',
      'municipalityList',
      'comments',
      'feedback',
      'date_observed',
      'observer',
      'report_id',
      'location',
      'altitude',
      'age',
      'municipe',
      'stande',
      'treepart',
      'vegetation',
      'soil',
      'report_type',
      'date_start',
      'date_end',
    ];

    $scope.reportMapId = 'reportMap';
    $scope.serviceUrl = skogskadeGlobalOptions.serviceUrl;
    $scope.detailsReport = reportDetailService
      .query({
        reportId: $scope.reportId,
      })
      .$promise.then(function (data) {
        //var sortReport = ['diagnosis', 'latin', 'english', 'occurence', 'symptom', 'biology', 'description', 'sign', 'confuse', 'author', 'report'];
        $scope.detailsReportsSorted = sortDetailService.sortFunction(data, sortReport);
      });
    $scope.detailReportBilder = reportPicturesService.query({
      reportId: $scope.reportId,
    });

    $scope.$on('$routeChangeStart', function () {
      var currentModal = $scope.imgModal;

      if (angular.isDefined(currentModal)) {
        $scope.imgModal.modal('hide');
        $scope.imgModal.modal('removeBackdrop');
      }
    });

    $scope.openModal = function () {
      $scope.imgModal = $('#reportImageModal');
      $scope.imgModal.modal('show');
    };

    var slides = $scope.detailReportBilder;
    $scope.getActiveSlide = function () {
      return slides.filter(function (s) {
        return s.active;
      })[0];
    };

    $scope.setReportMode = function () {
      $location.path(hash);
    };

    $scope.mergeJsonValues = function (json) {
      var outPut = [];

      angular.forEach(json.children, function (value, key) {
        if (value.id) {
          outPut.push(' <u><a href="' + skogskadeGlobalOptions.skadeURL + value.id + '">' + value.text + '</a></u>');
        } else {
          outPut.push(' ' + value.text);
        }
      });

      //var outPut = 'gran, furu';
      return outPut.toString();
    };

    mapDataService.getFeatureForReport($scope.reportId).then(function (response) {
      var layer = L.geoJson(response.data, {
        style: leafletStyles.diagnosisStyle,
        resetStyleOnMouseout: true,
        pointToLayer: function (feature, latlng) {
          if (feature.properties.mun_center) {
            return L.marker(latlng, { icon: leafletStyles.kommSenterIcon });
          } else if (feature.properties.landsskogdata) {
            return L.marker(latlng, { icon: leafletStyles.landskogIcon });
          } else {
            return L.marker(latlng, { icon: leafletStyles.blueIcon });
          }
        },
      });

      $scope.layers = [layer];
    });
  }
})(angular.module('skogskadeControllers'));
