<ngb-carousel *ngIf="items" [showNavigationArrows]="items.length > 1" [showNavigationIndicators]="items.length > 1">
  <ng-container *ngFor="let item of items">
    <ng-template ngbSlide>
      <figure (click)="openModal(item)" (keyup.enter)="openModal(item)" tabindex="0">
        <img [alt]="item.caption || 'Bilde'" [src]="photoBase + item.imgPathThumbnail" />
        <figcaption>
          <strong *ngIf="item.attribution?.length">Foto:</strong> {{ item.attribution }}
          <div *ngIf="item.caption?.length">{{ strHelper.ucFirst(item.caption) }}</div>
        </figcaption>
      </figure>
    </ng-template>
  </ng-container>
</ngb-carousel>
