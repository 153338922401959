import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ReportType } from '@apptypes/report.type';

export type ReportListSortableColumns = keyof ReportType | '';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { '': 'asc', asc: 'desc', desc: '' };

export interface ReportListSortEvent {
  column: ReportListSortableColumns;
  direction: SortDirection;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(click)': 'rotate()',
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
  },
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[sortableReportList]',
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SortableReportListDirective {
  @Input() sortableReportList: ReportListSortableColumns = '';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<ReportListSortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortableReportList, direction: this.direction });
  }
}
