import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { ReportListFiltersType } from '@apptypes/report-list-filters.type';
import { ReportListService } from '@core/services/report-list.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportsTableComponent } from '@shared/components/reports-table/reports-table.component';
import { catchError, tap, throwError } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ReportsTableComponent],
  selector: 'app-reports-modal',
  standalone: true,
  styleUrls: ['./reports-modal.component.scss'],
  templateUrl: './reports-modal.component.html',
})
export class ReportsModalComponent implements OnInit {
  private readonly _reportListService = inject(ReportListService);

  @Input()
  damageId!: string;

  @Input()
  title?: string;

  errorLoading: boolean = false;
  modal = inject(NgbActiveModal);
  processing: boolean = false;
  reports$ = this._reportListService.reports$.pipe(
    tap(() => {
      this.errorLoading = false;
    }),
    catchError((e: HttpErrorResponse) => {
      this.errorLoading = true;
      return throwError(() => e);
    }),
  );
  service = this._reportListService;

  defaultCollectionSize = 15;

  filters: ReportListFiltersType = {
    page: 1,
    perPage: this.defaultCollectionSize,
    sort: 'date_observed,desc',
  };

  ngOnInit(): void {
    this.filters.id = this.damageId;
    this._fetchReports();
  }

  pageChanged() {
    this.filters.page = this._reportListService.page;
    this._fetchReports();
  }

  perPageChanged() {
    this.filters.perPage = this._reportListService.perPage;
    this._fetchReports();
  }

  sortChanged() {
    this.filters.sort = this._reportListService.sort;
    this._fetchReports();
  }

  private _fetchReports() {
    this._reportListService.getReports(this.filters).pipe(take(1)).subscribe();
  }
}
