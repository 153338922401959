import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { computed, ErrorHandler, Injectable, signal } from '@angular/core';
import { ToastService } from '@core/services/toast.service';
import { SkogskadeWsEndpointsEnum } from '@environments/apis/skogskade.api';

@Injectable({
  providedIn: 'root',
})
export class ErrorService implements ErrorHandler {
  constructor(private readonly _toastService: ToastService) {}
  readonly errorMsg = computed<string>(() => {
    return this._error()?.message || '';
  });

  private _error = signal<Error | undefined>(undefined);

  /**
   * Call this once done, aka you have logged and/or shown error to user
   */
  clearErrors(): void {
    this._error.set(undefined);
  }

  handleError(error: Error): void {
    if (error instanceof HttpErrorResponse) {
      const httpStatus = error.status.toString();
      if (httpStatus.startsWith('20')) {
        return;
      }
    }

    this._error.set(error);
    this._toastService.show(error.message, 'Feil', false, 'text-bg-warning');
    console.error(error);
  }

  handleRequestError(error: Error, request: HttpRequest<unknown>): void {
    let showCustomMessage = '';

    if (request.url?.length > 0) {
      if (request.url.includes(SkogskadeWsEndpointsEnum.GET_REPORT_INIT_FORM)) {
        showCustomMessage = 'Det oppstod en feil mens vi forsøkte å hente skadetyper og vertsplanter.';
      } else if (request.url.includes(SkogskadeWsEndpointsEnum.POST_REPORT)) {
        showCustomMessage = 'Det oppstod en feil under sending av rapporten.';
      }
    }

    if (showCustomMessage.length > 0) {
      this.showCustomError(showCustomMessage, error);
    } else {
      this.handleError(error);
    }
  }

  /**
   * Should likely be refactored to leverage the new error.cause:
   *   catch (err) { throw new Error("Some friendly msg", { cause: err }); }
   */
  showCustomError(msg: string, originalErr: Error, showOriginalErr: boolean = true) {
    if (showOriginalErr) {
      msg += `<br><br><em>Opprinnelig feilmld:</em><br> ${originalErr.message}`;
    }

    this._toastService.show(msg, 'Feil', false, 'text-bg-warning');
    console.error(originalErr);
  }
}
