import { Injectable } from '@angular/core';
import { MapOverlayType } from '@apptypes/map-overlay.type';
import * as L from 'leaflet';

@Injectable({
  providedIn: 'root',
})
export class MapLegendService {
  activeLayers: MapOverlayType[] = [];

  appLegends = [
    {
      html: '<img class ="legend-icon-size" src="ajs/img/marker_red_circle.png" />',
      name: 'Skogskade, stedfestet i kommunesenter',
    },
    {
      html: '<img class ="legend-icon-size" src="ajs/img/marker_green_circle.png" />',
      name: 'Skogskade, omtrentlig stedfesting (Lands&shy;skogtakseringen/ skogovervåkingen)',
    },
    {
      html: '<img class ="legend-icon-size" src="ajs/img/marker_blue_circle.png" />',
      name: 'Skogskade, punktlokasjon',
    },
    {
      html: '<span class="skogskade-flate-legend"><img/></span>',
      name: 'Skogskade, areal',
    },
  ];

  private _legendElement!: HTMLDivElement;

  activeLayersAdd(layer: MapOverlayType): void {
    this.activeLayers.push(layer);
  }

  activeLayersRemove(layer: MapOverlayType): void {
    const idx = this.activeLayers.findIndex(al => {
      return al['legendImgUrl'] === layer.legendImgUrl;
    });
    if (idx > -1) {
      this.activeLayers.splice(idx, 1);
    }
  }

  getLegendControl(
    overlays: { [name: string]: MapOverlayType },
    showByDefault: boolean = false,
    includeSkogskadeLegend: boolean = false,
  ): L.Control {
    const legendButton =
      '<div class="legend"><a title="Tegnforklaring" class="legendicon bi bi-info-circle"></a></div>';

    this._legendElement = L.DomUtil.create('div', 'legend-container leaflet-bar');
    this._legendElement.innerHTML += legendButton;

    const legendCtrl = new L.Control({ position: 'topright' });
    // Don't drag map through this, enable possiblity to scroll controls
    L.DomEvent.disableClickPropagation(this._legendElement);

    if (showByDefault) {
      this._legendElement.setAttribute('data-show', 'true');
      this._legendElement.innerHTML =
        legendButton +
        '<section class="legend-popup" title="Tegnforklaring">' +
        this.getMapLegendContent(overlays, includeSkogskadeLegend) +
        '</section>';
    }

    legendCtrl.onAdd = () => {
      const legendShowContent = e => {
        this._legendElement.setAttribute('data-show', 'true');
        this._legendElement.innerHTML =
          legendButton +
          '<section class="legend-popup" title="Tegnforklaring">' +
          this.getMapLegendContent(overlays, includeSkogskadeLegend) +
          '</section>';
        L.DomEvent.removeListener(this._legendElement, 'click', legendShowContent);
        L.DomEvent.addListener(this._legendElement, 'click', legendHideContent);
        L.DomEvent.stopPropagation(e);
      };

      const legendHideContent = e => {
        this._legendElement.removeAttribute('data-show');
        this._legendElement.innerHTML = legendButton;
        L.DomEvent.removeListener(this._legendElement, 'click', legendHideContent);
        L.DomEvent.addListener(this._legendElement, 'click', legendShowContent);
        L.DomEvent.stopPropagation(e);
      };

      if (showByDefault) {
        L.DomEvent.addListener(this._legendElement, 'click', legendHideContent);
      } else {
        L.DomEvent.addListener(this._legendElement, 'click', legendShowContent);
      }

      return this._legendElement;
    };

    return legendCtrl;
  }

  getMapLegendContent(overlays: { [name: string]: MapOverlayType }, includeSkogskadeLegend: boolean = false): string {
    let response: string = '';

    // App legends
    if (includeSkogskadeLegend) {
      this.appLegends.forEach(l => {
        response += '<div class="legend-item">';
        response += l.html;
        response += '<span class="legend-label">' + l.name + '</span>';
        response += '</div>';
      });
    }

    // Legends from overlays (wms)
    Object.keys(overlays).forEach(key => {
      const oVal = overlays[key];

      const isOverlayActive = this.activeLayers.find(al => al['legendImgUrl'] === oVal.legendImgUrl);

      if (isOverlayActive && oVal.legendImgUrl?.length) {
        const layersList = oVal['wmsParams']?.layers.split(',').join(' ') || '';
        response += '<div class="legend-img-heading">' + key + '</div>';
        response += '<div><img class="legend-img ' + layersList + '" src="' + oVal.legendImgUrl + '" /></div>';
      }
    });
    return response;
  }

  isLegendVisible(): boolean {
    return this._legendElement.getAttribute('data-show') === 'true';
  }
}
