/* eslint-disable */
import '@ajs-npm/angular';
import '@ajs-npm/bootstrap/js/bootstrap.min.js';
// AngularJs imports
import '@ajs-npm/jquery.min.js';
// Angular imports
import { Router } from '@angular/router';
import { downgradeInjectable, UpgradeModule } from '@angular/upgrade/static';
import { MapObjectsService } from '@core/services/map/map-objects.service';
import { environment } from '@environments/environment';

// Prettier wants only single newline here
declare const angular: angular.IAngularStatic;
// import * as L from '@ajs-npm/leaflet.js';
// import '@ajs-npm/leaflet.wms.js';
// import '@ajs-npm/leaflet.draw.js';
// import '@ajs-npm/leaflet-pip.js';
// import '@ajs-npm/L.Control.Locate.min.js';

export const angularJsApp = angular.module('skogskadeApp', [
  'ui.bootstrap',
  'ngSanitize',
  'ngRoute',
  'skogskadeConstants',
  'skogskadeConfigs',
  'skogskadeControllers',
  'skogskadeDirectives',
  'skogskadeFilters',
  'skogskadeServices',
  'multi-select',
  'leaflet-directive',
  'angularFileUpload',
  // 'ngMeta',
]);

angularJsApp.constant('skogskadeGlobalOptions', {
  test1: true,
  reportURL: './rd#/rapporter/',
  skadeURL: './rd#/skader/',
  serviceUrl: environment.frontend.serviceUrl,
  serviceUrlProxy: '/getSkogskadeInfo',
  reportListMaxAgeSec: 1800,
  kartverketRestUrl: 'https://ws.geonorge.no/stedsnavn/v1/sted?',
  skogskadeWsAdminUrl: '/skogskade_admin_ws/rest/services/mapdata/input',
  skogskadeAdminUrl: '/skogskade_adm/',
  geoserverPath: environment.frontend.geoserverPath,
  title: 'Skogskader',
  maxFeaturesInMap: 3000,
  descriptionSkadebeskrivelse:
    'Skadebeskrivelser: Her kan du lese om mer enn 200 ulike skadetyper.' +
    'Oversikten omfatter de vanligste skader forårsaket av insekter, sopper og klima,' +
    ' forurensing og næringsmangel. De fleste skadetypene er beskrevet med tekst og bilder.' +
    ' Forekomst, symptomer, biologi, årsaksforhold, skoglig betydning og mulige forvekslinger omtales.',
  descriptionMap:
    'Kart: På kartet vises alle kartfestete stedsangivelser fra innsendte' +
    ' skaderapporter som er godkjent og kvalitetssikret av NIBIO.',
  descriptionReport:
    'Rapportoversikt: I denne oversikten listes alle innsendte skaderapporter' +
    ' som er godkjent og kvalitetssikret av NIBIO. Klikk på en rapport i oversikten for å åpne den.',
  descriptionReportDetail:
    'Enkeltrapportvisningen inneholder mer detaljert informasjon om den observerte' +
    ' skaden og eventuelle bilder som er vedlagt rapporten.',
  descriptionSkadeDetail:
    'Skadebeskrivelser: Her kan du lese om mer enn 200 ulike skadetyper.' +
    'Oversikten omfatter de vanligste skader forårsaket av insekter, sopper og klima,' +
    ' forurensing og næringsmangel. De fleste skadetypene er beskrevet med tekst og bilder.' +
    ' Forekomst, symptomer, biologi, årsaksforhold, skoglig betydning og mulige forvekslinger omtales.',
  descriptionHelp:
    'Skogskader.no bygger på brevarkivet ved tidligere' +
    ' Norsk institutt for skogforskning (nå NIBIO) med skaderapporter tilbake til 1960-tallet.' +
    ' Skogskader.no inneholder nå flere tusen innsendte rapporter samt digitaliserte rapporter' +
    ' fra skadearkivet. Innsending av skaderapporter kan gjøres av alle naturinteresserte ' +
    'privatpersoner, skogeiere, skogbrukssjefer og Fylkesmannens landbruksavdeling.',
  descriptionSkadeForm:
    'Meld skade: Skadeobservasjoner kan rapporteres inn' +
    ' og stedfestes ved å tegne inn observasjonsstedet på et digitalt kart.' +
    ' Digitale bilder kan lastes opp og lagres sammen med rapporten som separate filvedlegg.',
});
//
// angularJsApp.constant('leafletStyles', {
//  kommSenterIcon: L.icon({
//    iconUrl:  'ajs/img/marker_red_circle.png',
//    iconSize: [16, 16]
//  }),
//  landskogIcon:   L.icon({
//    iconUrl:  'ajs/img/marker_green_circle.png',
//    iconSize: [16, 16]
//  }),
//  blueIcon:       L.icon({
//    iconUrl:  'ajs/img/marker_blue_circle.png',
//    iconSize: [16, 16]
//  }),
//  diagnosisStyle: {
//    fillColor:   'red',
//    weight:      3,
//    opacity:     1,
//    color:       'red',
//    fillOpacity: 0.7
//  }
// });
//
// angularJsApp.constant('dateHelper', {
//   /*
//    *  Converts a date object to string of format '22.06.2015'
//    */
//   dateToString: function (date: Date) {
//     if (typeof date === 'undefined') {
//       return '';
//     }
//     const dd = date.getDate();
//     const mm = date.getMonth() + 1; //January is 0!
//     const yyyy = date.getFullYear();
//     return dd.toString().padStart(2, '0') + '.' + mm.toString().padStart(2, '0') + '.' + yyyy;
//   },
//   /*
//    *  Converts a date object to string of format '2015.06.22'
//    */
//   dateToStringReverse: function (date: Date) {
//     const dd = date.getDate();
//     const mm = date.getMonth() + 1; //January is 0!
//     const yyyy = date.getFullYear();
//     return yyyy + '.' + mm.toString().padStart(2, '0') + '.' + dd.toString().padStart(2, '0');
//   },
//   /*
//    *  Converts a string of format '06.02.2015' to a date opbject
//    */
//   skToDate: function (datestring: string) {
//     const day = parseInt(datestring.substring(0, 2), 10);
//     const month = parseInt(datestring.substring(3, 5), 10);
//     const year = parseInt(datestring.substring(6, 10), 10);
//     // Javascript counts months by 0 - 11
//     return new Date(year, month - 1, day);
//   },
// });
//
// angularJsApp.constant('mapConf', {
//   getSimpleMapConfig: function (tokenKey: string) {
//     let cacheUrl: string | null = null;
//     const cacheTopo = '&layers=topo4&zoom={z}&x={x}&y={y}';
//     var cacheGrunnkart = '&layers=norges_grunnkart&zoom={z}&x={x}&y={y}';
//     if (tokenKey) {
//       cacheUrl = 'https://gatekeeper2.geonorge.no/BaatGatekeeper/gk/gk.cache_gmaps?gkt=' + tokenKey;
//     } else {
//       //Begrensning på max 10 000 kart pr IP pr dag.
//       cacheUrl = 'https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?';
//     }
//     return {
//       defaults: {
//         zoomControl: false, // To change mouseover
//       },
//       center: {
//         lat: 65,
//         lng: 10,
//         zoom: 6,
//       },
//       layers: {
//         skmap: {
//           name: 'Topo4',
//           url: cacheUrl + cacheTopo,
//           options: {
//             attribution:
//               'Maps: <a href="http://statkart.no" title="The National Mapping Authority of Norway">Kartverket</a>',
//           },
//           type: 'xyz',
//         },
//       },
//     };
//   },
// });
//
// angularJsApp.config([
//   '$httpProvider',
//   function ($httpProvider: any) {
//     $httpProvider.defaults.useXDomain = true;
//     delete $httpProvider.defaults.headers.common['X-Requested-With'];
//   },
// ]);

// angularJsApp.run([
//   'ngMeta',
//   function (ngMeta: any) {
//     ngMeta.init();
//   },
// ]);

// angularJsApp.directive('ng2Module', downgradeComponent({component: AppComponent}));
// angularJsApp.directive('appRoot', downgradeComponent({component: AjsLoaderComponent}));
angularJsApp.factory('ng2Router', downgradeInjectable(Router));
angularJsApp.factory('ng2MapObjectsService', downgradeInjectable(MapObjectsService));

export function bootstrap(el: HTMLElement, upg: UpgradeModule) {
  return upg.bootstrap(el, [angularJsApp.name], { strictDi: true });
}
