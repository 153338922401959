<div class="row g-0 mx-0 row-no-gutters">
  <div class="col col-1 d-none d-sm-block masthead-back">
    <!--    <a href="javascript: history.go(-1)" title="Gå tilbake en side"-->
    <!--      ><span aria-hidden="true" class="glyphicon glyphicon-arrow-left"></span-->
    <!--    ></a>-->
  </div>
  <div class="col col-xs-10 col-sm-10">
    <div class="branding">
      <div class="row row-cols-auto g-0 mx-0">
        <div class="col masthead-logo d-none d-sm-block">
          <a href="https://www.nibio.no" title="Gå til Nibio.no"
            ><img alt="Nibio" class="logo" src="assets/branding/nibio_logo.png"
          /></a>
        </div>
        <div class="col masthead-text pt-2">
          <a id="homeBtn" routerLink="/" tabindex="0" title="Gå til Skogskader"> Skogskader.no </a>
        </div>
      </div>
    </div>
  </div>
  <div class="col col-xs-2 col-sm-12 masthead-navigation">
    <nav class="navbar navbar-expand-sm" id="nav" role="navigation">
      <div class="container-fluid">
        <button
          aria-controls="navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
          class="navbar-toggler"
          data-bs-target="#navbar"
          data-bs-toggle="collapse"
          type="button"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-collapse collapse" id="navbar">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a
                [class.highlight]="isCurrentUrl('/meld-skade') || currentUrl === '/' || isCurrentUrl('/kart/tegning')"
                class="nav-link"
                data-bs-target=".navbar-collapse.show"
                data-bs-toggle="collapse"
                id="skadeformBtn"
                routerLink="meld-skade"
                routerLinkActive="active"
                >Meld skade</a
              >
            </li>

            <!--            <li class="nav-item">-->
            <!--              <a-->
            <!--                class="nav-link"-->
            <!--                data-bs-target=".navbar-collapse.show"-->
            <!--                data-bs-toggle="collapse"-->
            <!--                routerLink="skader"-->
            <!--                routerLinkActive="active"-->
            <!--                >Skade&shy;&shy;beskrivelser</a-->
            <!--              >-->
            <!--            </li>-->
            <li class="nav-item">
              <a
                [class.active]="
                  isCurrentUrl('/rd#/skadebeskrivelse') || isCurrentUrl('/rd#/skader/') || isCurrentUrl('/skader/')
                "
                class="nav-link"
                data-bs-target=".navbar-collapse.show"
                data-bs-toggle="collapse"
                fragment="/skadebeskrivelse"
                routerLink="rd"
                >Skade&shy;&shy;beskrivelser</a
              >
            </li>

            <li class="nav-item">
              <a
                [class.active]="isCurrentUrl('/kart', true)"
                [routerLinkActiveOptions]="{ exact: true }"
                class="nav-link"
                data-bs-target=".navbar-collapse.show"
                data-bs-toggle="collapse"
                routerLink="kart"
                routerLinkActive
                >Kart</a
              >
            </li>

            <li class="nav-item">
              <a
                [class.active]="isCurrentUrl('/rapporter')"
                [queryParams]="null"
                class="nav-link"
                data-bs-target=".navbar-collapse.show"
                data-bs-toggle="collapse"
                routerLink="rapporter"
                routerLinkActive="active"
                >Rapport&shy;oversikt</a
              >
            </li>

            <li class="nav-item">
              <a
                [class.active]="isCurrentUrl('/veiledning')"
                class="nav-link"
                data-bs-target=".navbar-collapse.show"
                data-bs-toggle="collapse"
                routerLink="veiledning"
                routerLinkActive="active"
                >Veiledning</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</div>
