(function (skogskadeServices) {
  'use strict';

  /* Definitions */

  //skogskadeServices.factory('Skadebeskrivelse', ['$resource',
  //  function ($resource) {
  //    return $resource('ajs/skader/:skadeId.json', {}, {
  //      query: {method: 'GET', params: {skadeId: 'skader'}, isArray: true}
  //    });
  //  }]);

  skogskadeServices.service('stateListModel', [
    'Skadebeskrivelse',
    function (Skadebeskrivelse) {
      this.tree = Skadebeskrivelse.query([], function (response) {
        addPropertiesToResponseTree(response);
      });
      this.search = '';
      this.orderProp = 'id';
      this.selectedSearch = null;
      this.showAll = false;
      this.hostSearchText = { text: '', id: '' };
      this.hostList = {};
      this.title = 'Velg vertsplante';

      this.setSearch = function (newValue) {
        this.search = newValue;
      };
      this.setShowAll = function (newValue) {
        this.showAll = newValue;
      };

      this.setHostSearchText = function (searchText, id) {
        this.hostSearchText.text = searchText;
        this.hostSearchText.id = id;
      };

      this.getHostSearchText = function () {
        return this.hostSearchText;
      };
      this.setHostList = function (hostList) {
        this.hostList = hostList;
      };
      this.setDiagnosisList = function (list) {
        this.diagnosisList = list;
      };
      this.getHostList = function () {
        return this.hostList;
      };
      this.getDiagnosisList = function () {
        return this.diagnosisList;
      };
      this.getTitle = function () {
        return this.title;
      };

      this.getBreadcrumb = function (id) {
        if (!this.diagnosisList || !this.diagnosisList.children) return;
        var tree = this.diagnosisList;
        var parentCatalogs = findParent(tree.children, id, [], []);
        if (!parentCatalogs) return [];
        var breadcrump = makeBreadcrump(parentCatalogs, id, []);
        return removeLatinskeNames(breadcrump);
      };

      /**Find all parents to all and build object with nesseary info
       *
       * @param {*} items an Array
       * @param {*} selectedIds a number, id for selected item
       * @param {*} parentCatalogs array with objects
       * @param {*} parent
       * @returns Array with parents
       */
      var findParent = function (items, selectedIds, parentCatalogs, parent) {
        for (var i = 0; i < items.length; i++) {
          if (items[i].category === 'item' && typeof items[i].id !== 'undefined') {
            if (items[i].id == selectedIds) {
              parentCatalogs.push({ item: items[i].id, label: items[i].label, parent: parent });
              return parentCatalogs;
            }
          } else if (items[i].category === 'folder' && typeof items[i].children !== 'undefined') {
            parentCatalogs.push({ item: items[i].id, label: items[i].label, parent: parent });
            if (items[i].id == selectedIds) {
              return parentCatalogs;
            }

            var found = findParent(items[i].children, selectedIds, parentCatalogs, items[i].id);
            if (found) return found;
          }
        }
      };

      /**
       * Find all parents to selected item
       * @param {*} array same as parentcatalogs
       * @param {*} selectedId selected item
       * @param {*} result Array with object to breadcrump, also have order if needed
       * @returns result
       */
      var makeBreadcrump = function (array, selectedId, result) {
        array.forEach((item, index) => {
          if (item.item == selectedId) {
            result.push({ parent: item.parent, id: item.item, label: item.label, order: index });
            if (item.parent) {
              makeBreadcrump(array, item.parent, result);
            }
          }
        });
        return result;
      };
      /**
       * Before: Insekter og andre dyr/Edderkoppdyr (Arachnida)/Bartremidd (Oligonychus ununguis)
       * After: Insekter og andre dyr/Edderkoppdyr/ Bartremidd
       * @param {*} array  array with object to breadcrump
       * @returns rray with object to breadcrump without Latinske names
       */
      var removeLatinskeNames = function (array) {
        array.forEach((item, index) => {
          item.label = item.label.split('(')[0];
        });
        return array;
      };

      var addPropertiesToResponseTree = function (diagnosis) {
        var stack = [
          {
            depth: 0,
            element: diagnosis,
          },
        ];
        var current;
        var children, i, len;
        var depth;
        while (stack.length > 0) {
          current = stack.pop();

          //get the arguments
          depth = current.depth;
          current = current.element;
          children = current.children;
          for (i = 0, len = children.length; i < len; i++) {
            // Add extra properties to tree
            children[i].icon = 'glyphicon-arrow-right';
            children[i].clicked = false;
            children[i].searchHit = false;

            if (typeof current.label !== 'undefined') {
              children[i].parent = current;
            }
            if (children[i].category === 'folder') {
              children[i].icon = 'glyphicon-plus-sign';
              stack.push({
                //pass args via object or array
                element: children[i],
                depth: depth + 1,
              });
            }
          }
        }
        return diagnosis;
      };
    },
  ]);

  skogskadeServices.service('sortDetailService', [
    function () {
      //var sortlist = ['diagnosis', 'latin', 'english', 'occurence', 'symptom', 'biology', 'description', 'sign', 'confuse', 'author', 'report'];
      this.sortFunction = function (unSortedList, sortList) {
        var sortedList = [];
        for (var i = 0; i < sortList.length; i++) {
          var selected = sortList[i];
          if (selected === 'map' && unSortedList[selected]) {
            unSortedList[selected].map = true;
          }
          if (unSortedList[selected]) {
            sortedList.push(unSortedList[selected]);
          }
        }
        return sortedList;
      };
    },
  ]);

  skogskadeServices.factory('Skadebeskrivelse', [
    '$resource',
    'skogskadeGlobalOptions',
    function ($resource, skogskadeGlobalOptions) {
      return $resource(
        skogskadeGlobalOptions.serviceUrl + '/skogskade_ws/rest/services/diagnosis',
        {},
        {
          query: {
            method: 'GET',
            isArray: false,
          },
        },
      );
    },
  ]);

  skogskadeServices.factory('SkadeDetail', [
    '$resource',
    'skogskadeGlobalOptions',
    function ($resource, skogskadeGlobalOptions) {
      return $resource(
        skogskadeGlobalOptions.serviceUrl + '/skogskade_ws/rest/services/diagnosis/:skadeId',
        {},
        {
          query: {
            method: 'GET',
            isArray: false,
            skadeId: '@skadeId',
          },
        },
      );
    },
  ]);

  skogskadeServices.factory('SkadeBilder', [
    '$resource',
    'skogskadeGlobalOptions',
    function ($resource, skogskadeGlobalOptions) {
      return $resource(
        skogskadeGlobalOptions.serviceUrl + '/skogskade_ws/rest/services/pictures/diagnosis/:skadeId',
        {},
        {
          query: {
            method: 'GET',
            isArray: true, //,
            //skadeId: '@skadeId'
          },
        },
      );
    },
  ]);

  skogskadeServices.factory('SkadeReport', [
    '$upload',
    'skogskadeGlobalOptions',
    function ($upload, skogskadeGlobalOptions) {
      return {
        save: function (geoJsonToBackend, files) {
          return $upload.upload({
            url: skogskadeGlobalOptions.serviceUrl + '/skogskade_ws/rest/services/report/input',
            headers: { Accept: 'text/plain' },
            data: {
              formDataObject: geoJsonToBackend,
            },
            file: files,
          });
        },
      };
    },
  ]);
})(angular.module('skogskadeServices', ['ngResource']));
