<!-- Modal: Terms of use -->
<div *ngIf="config.showHeader" class="modal-header">
  <h1 class="modal-title fs-5" id="modalHeaderTitle">{{ title }}</h1>
  <button (click)="modal.dismiss('Cross click')" aria-label="Lukk" class="btn-close" type="button"></button>
</div>
<div class="modal-body">
  <div *ngIf="bodyText?.length">{{ bodyText }}</div>

  <div *ngIf="bodyHtml?.length" [innerHTML]="sanitizedBody"></div>
</div>
<div *ngIf="config.showFooter" class="modal-footer">
  <button (click)="modal.close('Close click')" class="btn btn-secondary" type="button">Lukk</button>
</div>
