(function (skogskadeServices) {
  'use strict';

  skogskadeServices.factory('reportPicturesService', reportPicturesServiceFn);

  reportPicturesServiceFn.$inject = ['$resource', 'skogskadeGlobalOptions'];

  function reportPicturesServiceFn($resource, skogskadeGlobalOptions) {
    return $resource(
      skogskadeGlobalOptions.serviceUrl + '/skogskade_ws/rest/services/pictures/report/:reportId',
      {},
      {
        query: {
          method: 'GET',
          isArray: true, //,
          //reportId: '@reportId'
        },
      }
    );
  }
})(angular.module('skogskadeServices'));
