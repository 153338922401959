(function(skogskadeServices) {

  var noCacheFolders = ["skogskade_ws"];

  var shouldApplyNoCache = function(config) {
    // The logic in here can be anything you like, filtering on
    // the HTTP method, the URL, even the request body etc.
    for (var i = 0; i < noCacheFolders.length; i++) {
      var folder = noCacheFolders[i];
      if (config.url.indexOf(folder + "/") !== -1) {
        return true;
      }
    }
    return false;
  };

  var applyNoCache = function(config) {
    if (config.url.indexOf("?") !== -1) {
      config.url += "&";
    } else {
      config.url += "?";
    }
    config.url += "nocache=" + new Date().getTime();
  };

  var Interceptor = function() {
    return {
      request: function(config) {
        if (shouldApplyNoCache(config)) {
            applyNoCache(config);
        }
        return config;
      }

    }
  }

  skogskadeServices.config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push(Interceptor);
  }]);
}(angular.module("skogskadeServices")));
