import { Routes, UrlSegment } from '@angular/router';
import { MapModesEnum } from '@enums/map-modes.enum';
import { AjsLoaderComponent } from '@features/angularjs/ajs-loader/ajs-loader.component';
import { BreadcrumbsEnum } from '@shared/components/route-breadcrumbs/breadcrumbs.enum';
import { DiagnosisListResolver } from '@shared/resolvers/diagnosis-list.resolver';

/* eslint-disable sort-keys */
export const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'meld-skade',
  },
  { path: 'help', redirectTo: 'veiledning' },
  { path: 'rd/skadeform', redirectTo: 'meld-skade' },
  { path: 'rd/rapporter', redirectTo: '/rd#/rapporter' },
  { path: 'skadeform', redirectTo: 'meld-skade' },
  { path: 'skadebeskrivelse', redirectTo: '/rd#/skadebeskrivelse' },
  {
    path: 'kart/tegning',
    pathMatch: 'full',
    data: {
      breadcrumb: BreadcrumbsEnum.MAP,
      mapMode: MapModesEnum.DRAW,
    },
    loadComponent: () => import('@features/leaflet/leaflet.component'),
  },
  {
    path: 'kart',
    pathMatch: 'full',
    data: {
      breadcrumb: BreadcrumbsEnum.MAP,
      mapMode: MapModesEnum.SHOW_SKOGSKADER,
    },
    loadComponent: () => import('@features/leaflet/leaflet.component'),
  },
  { path: 'map/showSkogskader', redirectTo: 'kart' },
  {
    path: 'veiledning',
    data: {
      breadcrumb: BreadcrumbsEnum.HELP,
    },
    loadComponent: () => import('@features/about/about.component'),
  },
  {
    path: 'rapporter/:id',
    pathMatch: 'full',
    data: {
      breadcrumb: BreadcrumbsEnum.REPORT_DETAIL,
    },
    loadComponent: () => import('@features/report-detail/report-detail.component'),
  },
  {
    path: 'rapporter',
    pathMatch: 'full',
    data: {
      breadcrumb: BreadcrumbsEnum.REPORT_LIST,
    },
    loadComponent: () => import('@features/report-list/report-list.component'),
  },
  {
    path: 'meld-skade',
    data: {
      breadcrumb: BreadcrumbsEnum.DAMAGE_FORM,
    },
    loadComponent: () => import('@features/damage-form/damage-form.component'),
    resolve: {
      diagnosisList: DiagnosisListResolver,
    },
  },
  {
    path: 'skader/:diagnoseId',
    pathMatch: 'full',
    data: {
      breadcrumb: BreadcrumbsEnum.DAMAGE_DETAIL,
    },
    loadComponent: () => import('@features/damage-detail/damage-detail.component'),
    resolve: {
      diagnosisList: DiagnosisListResolver,
    },
  },
  {
    path: 'skader',
    data: {
      breadcrumb: BreadcrumbsEnum.DAMAGE_LIST,
    },
    loadComponent: () => import('@features/damage-list/damage-list.component'),
    resolve: {
      diagnosisList: DiagnosisListResolver,
    },
  },
  {
    matcher: isAngularJsUrl,
    component: AjsLoaderComponent,
  },
  {
    path: '**',
    data: {
      breadcrumb: BreadcrumbsEnum.ERROR_404,
    },
    loadComponent: () => import('@features/error404/error404.component'),
  },
];
/* eslint-enable sort-keys */

/**
 * Available parameters: (segments: UrlSegment[], group: UrlSegmentGroup, route: Route)
 */
export function isAngularJsUrl(segments: UrlSegment[]) {
  // const wl = window.location;
  // const hash = wl.hash;
  // const baseHref = environment.frontend.baseHref;
  // const base = wl.pathname.replace(/\/$/g, '') === baseHref.replace(/\/$/g, '');
  // console.log(`url hash:`, segments.toString(), hash);
  // console.log(`wl.pathn`, wl.pathname.replace(/\/$/g, ''));
  // console.log(`baseHref`, baseHref.replace(/\/$/g, ''));
  // console.log(`route`, route.path, route.data, route.providers);

  // const ajsRouting = !!(segments.length > 0 && segments[0]?.path.startsWith('rd') && (hash || base));
  const ajsRouting = !!(segments.length > 0 && segments[0]?.path.startsWith('rd'));
  return ajsRouting ? { consumed: segments } : null;
}
