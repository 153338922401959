<div class="modal-header">
  <h1 class="modal-title fs-4" id="modalHeaderTitle">{{ config.labels?.title }}</h1>
  <button (click)="modal.close(selected)" aria-label="Lukk" class="btn-close" type="button"></button>
</div>

<div class="modal-body">
  <h2 class="fs-6">Valgte {{ config.labels?.itemPlural }}:</h2>
  <ul class="selected-nodes">
    <li *ngFor="let item of selected">
      <i
        (click)="toggleSelected(item)"
        (keyup.enter)="toggleSelected(item)"
        class="bi bi-x-circle"
        tabindex="0"
        title="Fjern valg"
      ></i>
      {{ item[config.fields.label.toString()] }}
    </li>
  </ul>

  <hr />

  <h2 class="fs-6">Legg til {{ config.labels?.item }}:</h2>
  <label class="collection-filter">
    <span>Filter:</span>
    <input
      (input)="filter()"
      [(ngModel)]="filterInput"
      class="filter-tree"
      id="collection-tree-filter"
      placeholder="Filtrer listen ved å søke her"
      type="text"
    />
  </label>
  <ul class="node-tree" id="collection-tree">
    <ng-container
      *ngTemplateOutlet="recursiveListTmpl; context: { $implicit: collectionFiltered$ | async, level: 0 }"
    ></ng-container>
  </ul>
</div>

<div class="modal-footer">
  <button (click)="modal.close(selected)" class="btn btn-primary" type="button">
    Lagre utvalg ({{ selected.length }})
  </button>
</div>

<!-- The template used (recursively) by the tree -->
<ng-template #recursiveListTmpl let-level="level" let-list>
  <li
    *ngFor="let item of list"
    class="node level-{{ level }}"
    [class.has-children]="item.category === 'folder' && item.children?.length > 0"
  >
    <i
      *ngIf="level === 0"
      (click)="item.expanded = !item.expanded"
      (keyup.enter)="item.expanded = !item.expanded"
      [class]="item.expanded ? 'bi bi-arrow-up-circle-fill' : 'bi bi-plus-circle-fill'"
      [class.hidden]="item.category !== 'folder' || !item.children?.length"
      title="Utvid kategorien '{{ item[config.fields.label.toString()] }}'"
      tabindex="0"
    ></i>
    <a
      (click)="toggleSelected(item)"
      (keyup.enter)="toggleSelected(item)"
      [class.selected]="selected.includes(item)"
      [title]="
        selected.includes(item)
          ? 'Velg vekk: ' + item[config.fields.label.toString()]
          : 'Velg: ' + item[config.fields.label.toString()]
      "
      class="label"
      tabindex="0"
    >
      {{ item[config.fields.label.toString()] }}
    </a>
    <ul *ngIf="(item.expanded || level > 0) && item.children?.length > 0">
      <ng-container
        *ngTemplateOutlet="recursiveListTmpl; context: { $implicit: item.children, level: level + 1 }"
      ></ng-container>
    </ul>
  </li>
</ng-template>
