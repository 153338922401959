export class ArrayHelper {
  /**
   * Use this as callback for .sort() to easily sort by multiple fields
   *
   * @param fields Array of field/property names to sort by.
   *    Prefix with dash to sort descending. (Default: ascending)
   *    Prefix with exclamation mark to sort nonexisting first. (Default: sort items missing the fieldname last)
   *    Combine them in order -!
   *
   * @example const fastestCars = cars.sort(fieldSorter(['-topSpeed', '-!price']));
   *    Lists cars fastest first. Any equally fast are sorted descending by price, if no price then first in group
   */
  static fieldSorter = (fields: string[]) => (a, b) =>
    fields
      .map(o => {
        let direction = 1;
        let missingLast = 1;

        if (o[0] === '-') {
          direction = -1;
          o = o.substring(1);
        }

        if (o[0] === '!') {
          missingLast = -1;
          o = o.substring(1);
        }

        // Check exists on both sides
        const aHas = Object.hasOwn(a, o);
        const bHas = Object.hasOwn(b, o);
        if (aHas && !bHas) {
          return -missingLast;
        } else if (!aHas && bHas) {
          return missingLast;
        } else if (!aHas && !bHas) {
          return 0;
        }

        // Sort by value according to given direction
        return a[o] > b[o] ? direction : a[o] < b[o] ? -direction : 0;
      })
      .reduce((p, n) => (p ? p : n), 0);

  /**
   * Nested search through arrays, returns every matching result in an array.
   * searchMatchType.identical: Checks item === searchTerm
   * searchMatchType.identicalString: Checks item.toString.toLowerCase === searchTerm.toString.toLowerCase
   * searchMatchType.includesString: Checks item.toString.toLowerCase.includes(searchTerm.toString.toLowerCase)
   * Returns match if item.<searchPropertyName> includes <searchTerm>.
   */
  static findRecursive<T extends object>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    searchTerm: any = '',
    haystack: T[] = [],
    searchMatchType: 'identical' | 'identicalString' | 'includesString',
    searchPropertyName = 'name',
    childrenProperty = 'children',
    results: T[] = [],
  ): T[] {
    for (const item of haystack) {
      // Look in current level
      switch (searchMatchType) {
        case 'identical':
          if (item[searchPropertyName] === searchTerm) {
            results.push(item);
          }
          break;
        case 'identicalString':
          if (item[searchPropertyName]?.toString().toLowerCase() === searchTerm.toLowerCase()) {
            results.push(item);
          }
          break;
        case 'includesString':
          if (item[searchPropertyName]?.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
            results.push(item);
          }
          break;
      }

      // Look in children
      if (item[childrenProperty]?.length > 0) {
        const childSearch = ArrayHelper.findRecursive<T>(
          searchTerm,

          item[childrenProperty],
          searchMatchType,
          searchPropertyName,
          childrenProperty,
        );
        if (childSearch !== undefined && childSearch?.length) {
          results = results.concat(childSearch);
        }
      }
    }
    return results;
  }

  static nestedSort<T extends object>(collection: T[], childProp: string, sortBy: string[]): T[] {
    collection.forEach(item => {
      if (item && childProp in item && Array.isArray(item[childProp as string])) {
        item[childProp as string] = ArrayHelper.nestedSort(item[childProp as string] as T[], childProp, sortBy);
      }
    });
    collection.sort(ArrayHelper.fieldSorter(sortBy));
    return collection;
  }

  static removeIfExists<T>(haystack: T[], needle: T, compareProperty = 'uuid'): boolean {
    const idx = haystack.findIndex(stack => stack[compareProperty] === needle[compareProperty]);
    if (idx === -1) {
      return false;
    }

    haystack.splice(idx, 1);
    return true;
  }
}
