import { EnvironmentTargetInterface } from '@environments/assets/environment-target.interface';
import { SkogskadeApiType } from '@environments/assets/environment.interface';

export enum SkogskadeGeoserverEndpointsEnum {
  GET_OWS = '/ows',
}
export enum SkogskadeWsEndpointsEnum {
  GET_DIAGNOSIS_DETAIL = '/diagnosis/:diagnoseId',
  GET_DIAGNOSIS_DETAIL_PICTURES = '/pictures/diagnosis/:diagnoseId',
  GET_DIAGNOSIS = '/diagnosis',
  GET_MUNICIPALITIES_AND_COUNTIES = '/municipalitiesAndCounties',
  GET_REPORT_DETAIL = '/report/:reportId',
  GET_REPORT_DETAIL_PICTURES = '/pictures/report/:reportId',
  GET_REPORTS_PAGED = '/report_page',
  GET_REPORTS_CSV = '/report_page/csv',
  GET_REPORT_INIT_FORM = '/report/initform',
  POST_REPORT = '/report/input',
}

export const skogskadeApi: EnvironmentTargetInterface = {
  develop: {
    baseUrl: 'https://kart13utv.int.nibio.no',
    paths: {
      adminWs: '/skogskade_admin_ws/rest/services/mapdata/input',
      geoserver: '/geoserver',
      wmsUrl: '/getSkogskadeInfo',
      ws: '/skogskade_ws/rest/services',
    },
    proxyUrl: 'https://proxy1utv.int.nibio.no',
  } as SkogskadeApiType,
  production: {
    baseUrl: 'https://kart13.nibio.no',
    paths: {
      adminWs: '/skogskade_admin_ws/rest/services/mapdata/input',
      geoserver: '/geoserver',
      wmsUrl: '/getSkogskadeInfo',
      ws: '/skogskade_ws/rest/services',
    },
    proxyUrl: 'https://proxy1.nibio.no',
  } as SkogskadeApiType,
  test: {
    baseUrl: 'https://kart13test.nibio.no',
    paths: {
      adminWs: '/skogskade_admin_ws/rest/services/mapdata/input',
      geoserver: '/geoserver',
      wmsUrl: '/getSkogskadeInfo',
      ws: '/skogskade_ws/rest/services',
    },
    proxyUrl: 'https://proxy1test.nibio.no',
  } as SkogskadeApiType,
};
