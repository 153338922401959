import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { DiagnosisListType } from '@apptypes/diagnosis-list.type';
import { DiagnosisService } from '@core/services/diagnosis.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const DiagnosisListResolver: ResolveFn<DiagnosisListType | boolean> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  // errorService: ErrorService = inject(ErrorService),
  diagnosisService = inject(DiagnosisService),
): Observable<DiagnosisListType | boolean> => {
  return diagnosisService.getDiagnosisTree().pipe(
    catchError(() => {
      // let err = e;
      // if (err instanceof HttpErrorResponse) {
      //   if (err.status === 0 || err.status.toString().startsWith('40')) {
      //     err = new Error('Det oppstod en feil mens vi forsøkte å hente skadetyper og vertsplanter.', { ...e });
      //   }
      // }
      // errorService.handleError(err);
      return of(false); // If you want the rest of the page to continue loading, return this
      // return throwError(() => e); // If you want to halt page loading, return this
    }),
  );
};
