export class AppConfig {
  static readonly APP_GEO_EXTENT = [4.5, 58, 31, 70.1];

  static readonly CACHE_KEY_DAMAGE_FORM = 'DamageForm';

  static readonly FORM_ATTACHMENTS_MAX_COUNT = 20;
  static readonly FORM_ATTACHMENT_MAX_SIZE = 6291456; // Default 6291456 = 6MB
  // static readonly FORM_ATTACHMENT_EXTENSIONS = ['gif', 'jpeg', 'jpg', 'png', 'webp'];
  static readonly FORM_ATTACHMENT_EXTENSIONS = ['jpeg', 'jpg', 'JPG', 'JPEG'];
  // static readonly FORM_ATTACHMENT_MIME_TYPES = ['image/gif', 'image/jpeg', 'image/png', 'image/webp'];
  static readonly FORM_ATTACHMENT_MIME_TYPES = ['image/jpeg'];

  static readonly MAP_ANIMATION_DURATION = 1.2;
  static readonly MAP_FEATURE_FINDSITE = 'useAsFindSite';
  static readonly MAP_ID_SEARCHED_PLACE = 'searchedPlace';
  static readonly MAP_KEY_CUSTOMID = 'customId';
  static readonly MAP_KEY_DIAGNOSIS_MARKERS = 'diagnosis_markers';
  static readonly MAP_KEY_REPORT_MARKERS = 'report_markers';

  static readonly MUNICIPALITY_LIST_CACHE_TTL = 18000; // Seconds to cache municipality lists (18000 = 5 hours)
  static readonly REPORT_LIST_CACHE_TTL = 1800; // Seconds to cache report lists (1800 = 30 minutes)

  // static readonly TITLE = ' 🪲 Skogskader';
  static readonly TITLE = 'Skogskader';
}
