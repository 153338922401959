import { inject, Injectable } from '@angular/core';
import { OwsResponseType } from '@apptypes/responses/ows-response.type';
import { ReportDetailResponseType } from '@apptypes/responses/report-detail-response.type';
import { SkogskadePictureResponseType } from '@apptypes/responses/skogskade-picture-response.type';
import { AppConfig } from '@core/app.config';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { ApiService } from '@core/services/api.service';
import { SkogskadeGeoserverEndpointsEnum, SkogskadeWsEndpointsEnum } from '@environments/apis/skogskade.api';
import { MarkerSmallBlueCircleIcon } from '@shared/constants/map/marker-small-blue-circle.icon';
import { MarkerSmallGreenCircleIcon } from '@shared/constants/map/marker-small-green-circle.icon';
import { MarkerSmallRedCircleIcon } from '@shared/constants/map/marker-small-red-circle.icon';
import { PolylineDiagnosisStyle } from '@shared/constants/map/polyline-diagnosis.style';
import * as L from 'leaflet';
import { Map as LeafletMap, Marker, Polygon, Polyline, Rectangle } from 'leaflet';
import { concatMap, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private readonly _apiService = inject(ApiService);

  addReportFeaturesToMap(reportId: string, map: LeafletMap): void {
    this.getReportMapFeatures(reportId)
      .pipe()
      .subscribe({
        next: response => {
          if (map) {
            // Convert from GeoJSON, use pointToLayer to create points as Markers
            const features = L.geoJSON(response, {
              pointToLayer: function (feature, latlng) {
                if (feature.properties.mun_center) {
                  return L.marker(latlng, { icon: MarkerSmallRedCircleIcon });
                } else if (feature.properties.landsskogdata) {
                  return L.marker(latlng, { icon: MarkerSmallGreenCircleIcon });
                } else {
                  return L.marker(latlng, { icon: MarkerSmallBlueCircleIcon });
                }
              },
              style: PolylineDiagnosisStyle,
            });

            // Add id to help get the handle later on, i.e for removeLayer
            features.options[AppConfig.MAP_KEY_CUSTOMID] = AppConfig.MAP_KEY_REPORT_MARKERS;

            // Read custom properties from GeoJSON and use as Leaflet Layer options
            features.eachLayer(l => {
              if (l instanceof Marker || l instanceof Polyline || l instanceof Polygon || l instanceof Rectangle) {
                if (l.feature?.properties) {
                  if (l.feature.properties['diagnosis']?.length) {
                    l.bindPopup(`${l.feature.properties['diagnosis']} - rapport ${reportId}`);
                  }
                }
              }
            });

            // Add features and fit in view
            features.addTo(map);
            map.fitBounds(features.getBounds(), {
              maxZoom: 12,
            });
          }
        },
      });
  }

  getReport(reportId: string) {
    return this._fetchReport(reportId).pipe(take(1));
  }

  getReportMapFeatures(reportId: string) {
    return this._fetchReportMapFeatures(reportId).pipe(take(1));
  }

  private _fetchReport(reportId: string) {
    return this._apiService
      .get<ReportDetailResponseType>(
        'skogskade',
        EnvironmentHelper.getSkogskadePaths().ws + SkogskadeWsEndpointsEnum.GET_REPORT_DETAIL,
        {
          reportId,
        },
      )
      .pipe(
        concatMap(reportResponse => {
          return this._apiService
            .getCollection<SkogskadePictureResponseType[]>(
              'skogskade',
              EnvironmentHelper.getSkogskadePaths().ws + SkogskadeWsEndpointsEnum.GET_REPORT_DETAIL_PICTURES,
              [],
              {
                reportId,
              },
            )
            .pipe(
              map(picturesResponse => {
                reportResponse.photos = picturesResponse;
                return reportResponse;
              }),
            );
        }),
      );
  }

  private _fetchReportMapFeatures(reportId: string) {
    const path = EnvironmentHelper.getSkogskadePaths().geoserver + SkogskadeGeoserverEndpointsEnum.GET_OWS;
    const queryParams = {
      cql_filter: 'report_id%20IN%20(' + reportId + ')',
      maxFeatures: 5000,
      outputFormat: 'application/json',
      request: 'GetFeature',
      service: 'WFS',
      srsname: 'EPSG:4258',
      typeName: 'skogskade:map_report_v',
      version: '1.0.0',
    };

    return this._apiService.get<OwsResponseType>('skogskade', path, {}, queryParams).pipe(take(1));
  }
}
