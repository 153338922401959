export enum BreadcrumbsEnum {
  DAMAGE_DETAIL = 'Skade',
  DAMAGE_FORM = 'Meld skade',
  DAMAGE_LIST = 'Skader',
  ERROR_404 = 'Feil: Ikke funnet',
  HELP = 'Veiledning',
  MAP = 'Kart',
  REPORT_DETAIL = 'Rapport',
  REPORT_LIST = 'Rapportoversikt',
}
