(function (skogskadeControllers) {
  'use strict';

  skogskadeControllers.controller('selectModalHostController', selectHostModalController);

  selectHostModalController.$inject = ['$scope', '$uibModalInstance', 'hostInstance', 'formInitDataService'];

  function selectHostModalController($scope, $uibModalInstance, hostInstance, formInitDataService) {
    $scope.selectedHosts = hostInstance.selectedHosts;
    $scope.hostsList = hostInstance.hostsList;
    $scope.hostSearchText = '';
    $scope.hostInstance = hostInstance;

    $scope.$watch('hostSearchText', function (newVal, oldVal) {
      if (angular.equals(newVal, oldVal)) {
        return;
      }

      if (newVal.trim() === '') {
        $scope.hostInstance.unsetSearchForList($scope.hostsList, 'hosts');
      } else if (newVal !== oldVal) {
        formInitDataService.filterItem($scope.hostsList, newVal, 'hosts');
      }
    });

    $scope.$watch('hostsList', function (newVal, oldVal) {
      if (angular.equals(newVal, oldVal)) {
        return;
      }
    });

    $scope.clearHostTextSearch = function () {
      if ($scope.hostSearchText.trim() !== '') {
        $scope.hostSearchText = '';
        $scope.hostInstance.unsetSearchForList($scope.hostsList, 'hosts');
      }
    };

    $scope.selectHost = function (host) {
      hostInstance.selectHost(host, $scope.selectedHosts);
      hostInstance.setTickForHosts(host, true, $scope.hostsList);
    };

    $scope.unSelectHost = function (host) {
      hostInstance.unSelectHost(host, $scope.selectedHosts, $scope.hostsList);
    };

    $scope.ok = function () {
      $scope.hostInstance.hostsPristine = false;
      $uibModalInstance.close($scope.selectedHosts);
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }

})(angular.module('skogskadeControllers'));
