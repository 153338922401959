(function (skogskadeServices) {
  'use strict';

  skogskadeServices.factory('mapModeService', mapModeServiceFn);

  mapModeServiceFn.$inject = [];

  function mapModeServiceFn() {
    var mode = 'showSkogskader'; // Default mode

    var getMapMode = function () {
      return mode;
    };
    var setMapMode = function (value) {
      mode = value;
    };

    return {
      getMapMode: getMapMode,
      setMapMode: setMapMode,
    };
  }
})(angular.module('skogskadeServices'));
