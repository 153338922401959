<div class="search-place-wrapper">
  <ng-template #rt let-r="result" let-t="term">
    <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
  </ng-template>

  <div class="input-group">
    <input
      #searchInput
      (focus)="searchFocus$.next($any($event).target.value)"
      (keyup.enter)="handleKeyboardEnter()"
      (selectItem)="handleMouseSelectItem($event); searchInput.blur()"
      [class.is-invalid]="searchFailed"
      [formControl]="searchCtrl"
      [inputFormatter]="formatter"
      [ngbTypeahead]="search"
      [resultTemplate]="rt"
      [selectOnExact]="true"
      class="form-control"
      id="typeahead-http"
      placeholder="Søk på sted/fylke/koordinat"
      type="text"
    />
    <button *ngIf="searchInput?.value?.length" (click)="clearSearch()" class="btn btn-secondary" type="button">
      <span class="bi bi-x"></span>
    </button>
  </div>

  <div *ngIf="showCoordinateSearchHint && !isValidCoordinate" class="search-hint">
    <div>
      Angi koordinater (desimalgrader) slik:<br />
      nord , øst<br />
      f.eks. '59.66868 , 10.71871'
    </div>
    <span class="bi bi-info-circle"></span>
  </div>

  <div *ngIf="showCoordinateSearchHint && isValidCoordinate && !isWithinBoundsCoordinate" class="search-hint">
    <div>
      Angi et koordinat innenfor fastland Norge.<br />
      f.eks. '59.66868 , 10.71871'
    </div>
    <span class="bi bi-exclamation-triangle"></span>
  </div>

  <div
    *ngIf="showCoordinateSearchHint && isValidCoordinate && isWithinBoundsCoordinate"
    (click)="handleKeyboardEnter()"
    (keyup.enter)="handleKeyboardEnter()"
    class="search-hint match btn btn-outline-info"
    tabindex="0"
  >
    <div>Trykk Enter for å gå til angitt koordinat</div>
    <span class="bi bi-info-circle"></span>
  </div>
</div>

<button
  *ngIf="mapMode() === mapModes.DRAW && qtyFindSiteCandidates()"
  (click)="markSearchedAsFindSites()"
  (keyup.enter)="markSearchedAsFindSites()"
  class="btn btn-primary mark-searched-as-findsites"
  id="btn-mark-sites"
  title="Markér søkeresultatene som funnsteder for skade"
>
  Angi søket som funnsted
</button>
