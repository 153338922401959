import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ObjectHelper } from '@core/helpers/object.helper';
import { GenericTextModalOptionsType } from '@modals/generic-text/generic-text-modal-options.type';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  selector: 'app-generic-text-modal',
  standalone: true,
  styleUrls: ['./generic-text-modal.component.css'],
  templateUrl: './generic-text-modal.component.html',
})
export class GenericTextModalComponent {
  @Input()
  bodyHtml?: string;

  @Input()
  bodyText?: string;

  @Input()
  title?: string;

  @Input()
  set options(data: GenericTextModalOptionsType) {
    this.config = ObjectHelper.nestedAssign(this.config, data);
  }

  get sanitizedBody(): string {
    if (!this.bodyHtml?.length) {
      return '';
    }

    return this._sanitizer.sanitize(SecurityContext.HTML, this.bodyHtml) as string;
  }

  config: GenericTextModalOptionsType = {
    showFooter: false,
    showHeader: true,
  };

  constructor(
    public modal: NgbActiveModal,
    private _sanitizer: DomSanitizer,
  ) {}
}
