import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  effect,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AppConfig } from '@core/app.config';
import { MapObjectsService } from '@core/services/map/map-objects.service';
import { MapSearchService } from '@core/services/map/map-search.service';
import { MapService } from '@core/services/map/map.service';
import { MunicipalityService } from '@core/services/municipality.service';
import { MapModesEnum } from '@enums/map-modes.enum';
import { MapObjectsEnum } from '@enums/map-objects.enum';
import { DrawIntroductionModalComponent } from '@modals/draw-introduction/draw-introduction-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MarkerDrawIcon } from '@shared/constants/map/marker-draw.icon';
import * as L from 'leaflet';
import {
  ControlPosition,
  Evented,
  FeatureGroup,
  LeafletEvent,
  Map as LeafletMap,
  Marker,
  Polygon,
  Polyline,
  Rectangle,
} from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import { take } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  selector: 'app-leaflet-geoman',
  standalone: true,
  styleUrls: ['./leaflet-geoman.component.scss'],
  templateUrl: './leaflet-geoman.component.html',
})
export class LeafletGeomanComponent implements AfterViewInit, OnDestroy, OnInit {
  private readonly _btnFinishDraw = 'btn-finish-draw';
  private readonly _btnPurgeDraw = 'btn-purge-draw';
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _mapObjectsService = inject(MapObjectsService);
  private readonly _mapSearchService = inject(MapSearchService);
  private readonly _mapService = inject(MapService);
  private readonly _municipalityService = inject(MunicipalityService);
  private readonly _ngbModal = inject(NgbModal);
  private readonly _router = inject(Router);

  private _effectHasExecuted: boolean = false;
  private _drawnItems!: L.FeatureGroup;
  private _skogskadeLocateControl!: L.Control.Locate;
  private _skogskadeLocateMarker!: L.Marker;

  labelFinishDrawMode: string = 'Lagre';
  @Input({ required: true })
  map!: LeafletMap | undefined;
  mapMode = this._mapService.mapMode;
  titleFinishDrawMode: string = 'Gå til skadeskjemaet';

  constructor() {
    // Empty locally cached objects when the map is cleared
    this._mapService.mapCleared$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe({
      next: () => {
        this._drawnItems = new FeatureGroup();
      },
    });

    // Whenever the signal changes, update button label
    effect(() => {
      const drawingsCount = this._mapObjectsService.savedDrawingsCount();
      const searchesCount = this._mapObjectsService.savedSearchesCount();
      const objectsCount: number = drawingsCount + searchesCount;

      if (objectsCount) {
        this.labelFinishDrawMode = 'Lagre ' + objectsCount + (objectsCount === 1 ? ' funnsted' : ' funnsteder');
      }

      // Button is only visible if there are objects to save
      const btn = document.getElementById(this._btnFinishDraw);
      if (btn) {
        btn.style.display = objectsCount ? 'inline-block' : 'none';
      }
      this._updateSaveButtonContent();
      this._cdr.detectChanges();
    });
  }

  goToDamageForm(): void {
    this._saveDrawings();
    void this._router.navigate(['meld-skade'], { fragment: 'area' });
  }

  ngAfterViewInit(): void {
    // Show modal with howto draw?
    if (!localStorage?.getItem(DrawIntroductionModalComponent.INTRO_OPTOUT_KEY)?.length) {
      this._showModalDrawIntro();
    }

    /**
     * Using a timeout to wait for the map to be ready. Leaflet.comp calls MapService.initMap() and then passes
     * the map instance to this comp via @Input this.map
     */
    setTimeout(() => {
      this._initGeoman();
      this._restoreDrawings();

      this.map?.on('pm:create', (e: LeafletEvent | Evented | any) => {
        this._onDrawCreate(e);
      });

      this.map?.on('pm:edit', () => {
        console.log(`map pm:edit`);
        this._saveDrawings();
      });

      this.map?.on('pm:remove', () => {
        console.log(`map pm:remove`);
        this._saveDrawings();
      });

      this._addSaveButtons();
    }, 1);
  }

  ngOnDestroy(): void {
    this._mapService.removeControl('purgeButtonControl');
    this._mapService.removeControl('saveButtonControl');
    if (this.map) {
      this.map.stopLocate();
      this._skogskadeLocateControl?.stop();
    }
  }

  ngOnInit(): void {
    // Fetch munis to have the search cache ready
    this._municipalityService.fetchMunicipalities().pipe(take(1)).subscribe();

    // Override locate function
    this._initSkogskadeLocate();
  }

  private _addAsMapControl(ctrlKey: string, element: HTMLElement, position: ControlPosition): void {
    // Add this leaflet control
    const mapControl = L.Control.extend({
      options: {
        // if you wish to edit the position of the button, change the position here and also make the corresponding changes in the css attached below
        position: position,
      },

      onAdd: function () {
        const container = L.DomUtil.create('div');
        container.appendChild(element);
        return container;
      },
    });

    // Add the control to the map
    this._mapService.addControl(ctrlKey, new mapControl(), false);
  }

  /**
   * Using this workaround to add our "Save Drawings"-button as a Leaflet Control.
   * We're using this method to have Leaflet position the button without us having to create an overlay
   * with position:absolute which gives us several other quirks with z-index and pointer-event issues etc.
   */
  private _addSaveButtons() {
    // Save button, for saving and returning to DamageForm
    const btnSaveGoToForm = document.createElement('button');
    btnSaveGoToForm.className = 'btn btn-primary btn-finish-draw';
    btnSaveGoToForm.id = this._btnFinishDraw;
    btnSaveGoToForm.onclick = event => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.goToDamageForm();
    };
    btnSaveGoToForm.title = this.titleFinishDrawMode;
    const btnSaveContentSpan = document.createElement('span');
    btnSaveContentSpan.innerHTML = this.titleFinishDrawMode;
    btnSaveGoToForm.innerHTML = this.labelFinishDrawMode;
    btnSaveGoToForm.appendChild(btnSaveContentSpan);
    const drawingsCount = this._mapObjectsService.savedDrawingsCount();
    const searchesCount = this._mapObjectsService.savedSearchesCount();
    const objectsCount: number = drawingsCount + searchesCount;
    btnSaveGoToForm.style.display = objectsCount ? 'inline-block' : 'none';

    // Purge button, for deleting objects and returning to DamageForm
    const btnPurgeGoToForm = document.createElement('button');
    btnPurgeGoToForm.className = 'btn btn-danger btn-purge-draw';
    btnPurgeGoToForm.id = this._btnPurgeDraw;
    btnPurgeGoToForm.onclick = event => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      // Delete all saved searches and drawn objects from cache/service, and remove from map
      this._mapSearchService.clearSearches();
      this._mapSearchService.clearFindSites();
      this._mapObjectsService.clearSavedObjects(MapObjectsEnum.SEARCHES);
      this._mapObjectsService.clearSavedObjects(MapObjectsEnum.DRAWINGS);
      if (this.map) {
        this._getDrawnItems().removeFrom(this.map);
      }
      this.goToDamageForm();
    };
    btnPurgeGoToForm.title = 'Slett tegninger og returner til skadeskjemaet';
    const btnPurgeContentSpan = document.createElement('span');
    btnPurgeContentSpan.innerHTML = this.titleFinishDrawMode;
    btnPurgeGoToForm.innerHTML = 'Avbryt uten å lagre';
    btnPurgeGoToForm.appendChild(btnPurgeContentSpan);

    // Add buttons as custom Leaflet controls
    this._addAsMapControl('purgeButtonControl', btnPurgeGoToForm, 'bottomleft');
    this._addAsMapControl('saveButtonControl', btnSaveGoToForm, 'bottomleft');
  }

  private _getDrawnItems(): FeatureGroup {
    if (!this._drawnItems) {
      this._drawnItems = new FeatureGroup();
      if (this.map) {
        this._drawnItems.addTo(this.map);
      }
    }
    return this._drawnItems;
  }

  private _initGeoman(): void {
    this._loadPm().then(() => {
      if (!this.map) {
        return;
      }

      this._mapService.mapMode.set(MapModesEnum.DRAW);

      // Set Geoman options
      this.map.pm.setLang('no');

      // Add a LayerGroup to hold draw layers in
      this.map.pm.setGlobalOptions({
        allowSelfIntersection: false, // https://gis.stackexchange.com/a/439707/243714
        continueDrawing: false,
        layerGroup: this._getDrawnItems(),
      });

      // Add the draw controls (toolbar)
      this.map.pm.addControls({
        cutPolygon: false,
        drawCircle: false,
        drawCircleMarker: false,
        drawText: false,
        editControls: true,
        editMode: true,
        position: 'topleft',
      });

      // Set the visible order of the tools
      this.map.pm.Toolbar.changeControlOrder([
        'drawMarker',
        'drawPolyline',
        'drawRectangle',
        'drawPolygon',
        'drawCircle',
        'drawCircleMarker',
        'dragMode',
        'editMode',
        'rotateMode',
        'removalMode',
      ]);

      // Custom Locate
      this._mapService.replaceLocateControlWith(this._skogskadeLocateControl);
      // Listen for the deactivate-event
      this._mapService.getMap().on('locatedeactivate', () => {
        this._mapService.getMap().eachLayer(l => {
          // remove any existing locate-markers from map
          if (l.options['className'] === 'leaflet-control-locate-marker') {
            l.remove();
          }
        });
        // Remove the instance of our custom Marker
        this._skogskadeLocateMarker.remove();
        this._cdr.detectChanges();
      });
    });
  }

  /**
   * This is a customized (extended) version of the LocateControl which adds a Point Marker to the map as
   * well as the default "live region" circle which shows while tracking geolocation. We need the extra Marker
   * to be able to offer users to "save as findSite".
   */
  private _initSkogskadeLocate(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const classThis = this;

    L.Control.SkogskadeLocate = L.Control.Locate.include({
      _drawMarker: function (map) {
        const blueIcon = L.icon({
          iconAnchor: [8, 8],
          iconSize: [16, 16],
          iconUrl: 'assets/leaflet/marker_blue_circle.png',
          popupAnchor: [0, 0],
        });

        if (this._event.accuracy === undefined) {
          this._event.accuracy = 0;
        }
        const radius = this._event.accuracy || 1;
        if (this._locateOnNextLocationFound) {
          if (this._isOutsideMapBounds()) {
            this.options.onLocationOutsideMapBounds(this);
          } else {
            map.fitBounds(this._event.bounds, {
              maxZoom: this.options.keepCurrentZoomLevel ? map.getZoom() : this.options.locateOptions.maxZoom,
              padding: this.options.circlePadding,
            });
          }
          this._locateOnNextLocationFound = false;
        }

        // circle with the radius of the location's accuracy
        let style, o;
        if (this.options.drawCircle) {
          if (this._following) {
            style = this.options.followCircleStyle;
          } else {
            style = this.options.circleStyle;
          }
          if (!this._circle) {
            this._circle = L.circle(this._event.latlng, radius, style).addTo(this._layer);
          } else {
            this._circle.setLatLng(this._event.latlng).setRadius(radius);
            for (o in style) {
              this._circle.options[o] = style[o];
            }
          }
        }

        // Determine units
        let distance, unit;
        if (this.options.metric) {
          distance = radius.toFixed(0);
          unit = 'meter';
        } else {
          distance = (radius * 3.2808399).toFixed(0);
          unit = 'fot';
        }

        // small inner marker
        let mStyle;
        if (this._following) {
          mStyle = this.options.followMarkerStyle;
        } else {
          mStyle = this.options.markerStyle;
        }

        // The custom part which adds a Point Marker to drawnItems
        if (this._marker) {
          this._marker = L.marker(this._event.latlng, mStyle);
        } else {
          if (classThis.mapMode() === MapModesEnum.DRAW) {
            classThis._drawnItems.eachLayer(function (layer) {
              if (layer.options['geoloc']) {
                // Remove other geoloc objects
                classThis._drawnItems.removeLayer(layer);
              }
            });
            const newMarker = L.marker(this._event.latlng);
            newMarker.options['geoloc'] = true;
            newMarker.addTo(classThis._drawnItems);
            classThis._saveDrawings();
            this._marker = newMarker;
          } else {
            this._marker = L.marker(this._event.latlng, mStyle).addTo(this._layer);
          }
        }

        // Customize display of marker: Blue circle with custom popup
        this._marker.setIcon(blueIcon);

        // Add class to layer and marker
        this._marker.options['className'] = 'leaflet-control-locate-marker';
        if (this._marker._icon) {
          L.DomUtil.setClass(this._marker._icon, 'leaflet-control-locate-marker');
        }

        const t = this.options.strings.popup;
        if (this.options.showPopup && t) {
          if (this._marker) {
            this._marker
              .bindPopup(
                L.Util.template(t, {
                  distance: distance,
                  unit: unit,
                }),
              )
              ._popup.setLatLng(this._event.latlng);
          }
        }

        classThis._skogskadeLocateMarker = this._marker;
      },
    });

    // Create a new instance of our custom SkogskadeLocate:
    this._skogskadeLocateControl = new L.Control.SkogskadeLocate({
      icon: 'leaflet-icon bi bi-crosshair',
      locateOptions: {
        enableHighAccuracy: true,
      },
      metric: true,
      position: 'topright',
      showPopup: true,
      strings: {
        outsideMapBoundsMsg: 'Ser ut til at du er utenfor grensene for dette kartet', // default message for onLocationOutsideMapBounds
        popup: 'Du er innenfor {distance} {unit} fra dette punktet', // text to appear if user clicks on circle
        title: 'Vis min posisjon', // title of the locate control
      },
    });
  }

  /**
   * PM was the original name for the Geoman package.
   * This follows example from Geoman docs
   * @see https://www.geoman.io/docs/lazy-loading
   */
  private async _loadPm() {
    if (!this.map?.pm) {
      await import('@geoman-io/leaflet-geoman-free');
    }
  }

  private _onDrawCreate(event: LeafletEvent | Evented | any): void {
    if (!event?.layer) {
      console.warn(`event.layer missing`, event);
      return;
    }

    const layer = event.layer;
    this._mapService.initLayerProperties(layer);

    // Determine popup text based on type
    let latlng: L.LatLng;
    let popupText: string = '';
    switch (event.shape) {
      case 'Polyline':
      case 'Polygon':
        popupText = 'Tegnet flate';
        break;
      case 'Rectangle':
        popupText = 'Tegnet rektangel';
        break;
      case 'Point':
      case 'Marker':
        latlng = (event.marker as Marker).getLatLng();
        popupText = 'Koordinat: ' + latlng.lat + ', ' + latlng.lng;
        break;
    }

    // Add popup if there is content
    if (popupText?.length) {
      layer.feature.properties['popupContent'] = popupText;
      if (layer.options) {
        layer.options['popupContent'] = popupText;
      }
      layer.bindPopup(popupText);
    }

    // Add other custom options
    if (layer.options) {
      layer.options.pmIgnore = false;
      layer.options[AppConfig.MAP_FEATURE_FINDSITE] = true;
      layer.feature.properties[AppConfig.MAP_FEATURE_FINDSITE] = true;
    }

    // Reinitialize Geoman on this layer now that its updated
    L.PM.reInitLayer(layer);

    // Add edit listener
    layer.on('pm:edit', (e: LeafletEvent | Evented | any) => {
      console.log(`pm:edit inside drawCreate`, e);
      this._saveDrawings();
    });

    // Add remove listener
    layer.on('pm:remove', (e: LeafletEvent | Evented | any) => {
      console.log(`pm:remove inside drawCreate`, e);
      this._saveDrawings();
    });

    this._drawnItems.addLayer(layer);

    this._saveDrawings();
  }

  private _restoreDrawings(): void {
    if (!this.map) {
      return;
    }

    const drawings = this._mapObjectsService.getFeatureCollection(MapObjectsEnum.DRAWINGS);
    if (drawings) {
      L.geoJson(drawings, {}).eachLayer(l => {
        if (l instanceof Marker) {
          l.setIcon(MarkerDrawIcon);
        }

        // Read custom properties from GeoJSON and use as Leaflet Layer options
        if (l instanceof Marker || l instanceof Polyline || l instanceof Polygon || l instanceof Rectangle) {
          if (l.feature?.properties) {
            if (l.feature.properties['popupContent']?.length) {
              l.bindPopup(l.feature.properties['popupContent']);
            }
            l.feature.properties[AppConfig.MAP_FEATURE_FINDSITE] = true;
            l.options[AppConfig.MAP_FEATURE_FINDSITE] = true;
          }
        }

        l.options.pmIgnore = false;
        this._getDrawnItems().addLayer(l);

        // Add edit listener for this restored object so we save any edits done to it
        l.on('pm:edit', () => {
          // console.log(`pm:edit from restored`, e);
          this._saveDrawings();
        });
      });

      this._getDrawnItems().addTo(this.map);

      // Zoom map to restored objects
      if (this._getDrawnItems().getLayers().length) {
        this.map.fitBounds(this._getDrawnItems().getBounds(), { maxZoom: 13, padding: [10, 10] });
      }
    }
  }

  private _saveDrawings(): void {
    const geomanGroup = this.map?.pm.getGeomanLayers(true);
    this._mapObjectsService.saveObjects(geomanGroup, MapObjectsEnum.DRAWINGS, true);
    this._mapObjectsService.saveSearchesAsDrawings();
  }

  private _showModalDrawIntro(): void {
    this._ngbModal.open(DrawIntroductionModalComponent, {
      ariaLabelledBy: 'modalHeaderTitle',
      fullscreen: 'sm',
      scrollable: true,
      size: 'lg',
    });
  }

  private _updateSaveButtonContent(): void {
    const saveBtn = document.getElementById(this._btnFinishDraw);
    if (saveBtn) {
      const btnContentSpan = document.createElement('span');
      btnContentSpan.innerHTML = this.titleFinishDrawMode;
      saveBtn.innerHTML = this.labelFinishDrawMode;
      saveBtn.appendChild(btnContentSpan);
    }
    this._cdr.detectChanges();
  }
}
