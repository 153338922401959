(function (skogskadeFilters) {
  'use strict';

  /* Definitions */
  skogskadeFilters.filter('checkmark', checkMarkFilter);

  // add filter that matches for diagnosis, first is matched
  skogskadeFilters.filter('filterDiagnosis', filterDiagnosis);

  // add combined filter, first is matched
  skogskadeFilters.filter('combinedFilter', combinedFilter);

  /*
   Filter function used when searching for fylke or kommune
   Entering search-phrase in "" activates exaxt search
   */
  skogskadeFilters.filter('filterPlace', filterPlace);


  /* Filters */
  function checkMarkFilter() {
    return function(input) {
      return input ? '\u2713' : '\u2718';
    };
  }

  function filterDiagnosis() {
    /*
     items : array of items to search in. each entry in array must be object with
     diagPipeSep proterty that is string
     query : string that is used to search for exact match in each objects diagPipeSep property

     Returns array of elements where one of diagnosises exactly match (caseinsensitive) diagnosis of item
     */
    function exactMatch(items,query){
      var results = [];
      for (var i = 0; i < items.length; i++){
        var searchString = query.toLowerCase();
        var diagnosis = items[i].diagPipeSep.split('|');
        var hit = false;
        for ( var j = 0; j < diagnosis.length; j++){
          if (diagnosis[j].toLowerCase() === searchString ){
            // console.log("****** DIAGNOSE {" + searchString + "} er Exact match in " + items[i].diagPipeSep);
            results.push(items[i]);
            // don't have to look at the other diagnosises
            hit = true;
            break;
          }
        }
        // If there are no hits in diagnosis themselves, search in the
        //  parent diagnosises...
        if(!hit && typeof items[i].parentPipeSep === 'string'  ){
          // look for hits in the parent diagnosises as well
          var parentDiags = items[i].parentPipeSep.split('|');
          for ( var p = 0; p < parentDiags.length; p++){
            if (parentDiags[p].toLowerCase() === searchString ){
              results.push(items[i]);
              // don't have to look at the other diagnosises
              hit = true;
              break;
            }
          }
        }

      }
      return results;
    }

    /* Analyse if string qualify for exact match or not
     (Must be enclosed in double brackets).

     query: string to check
     returns object whith property exact set to true if this is an string that is enclosed in bracets

     */
    function analyzeQuery(query){
      var retVal ={exact:false,
        query:query}
      if (query.trim().charAt(0) === '"'  ) {
        // might be an exact match search, has to check for the
        // second
        var splitString = query.split('"');
        if(splitString.length >= 3){
          retVal.exact = true;
          // retVal.query = splitString[1].trim();
        }
        retVal.query = splitString[1].trim();
      }
      return retVal;
    }


    return function(items, field) {
      if(typeof field === 'undefined' || field.trim() === ''){
        return items;
      }
      field = field.trim();
      var results = [];
      // First, split on space to enable searching for more than one diagnosis
      var targets = null;
      var searchString;
      var reportDiagnosis;
      var queryInfo = analyzeQuery(field);
      if ( queryInfo.exact) {
        return exactMatch(items,queryInfo.query.trim());
      }

      targets = queryInfo.query.split(' ');
      for (var i = 0; i < items.length; i++){
        for( var t = 0; t < targets.length ; t++){
          searchString = targets[t].toLowerCase();
          // only last target is matched with substing
          // console.log("**** Searching for "+ searchString + " as ");
          if(t === (targets.length -1)) {
            // console.log("***** Substringsearch  in {" +  items[i].diagnosis.toLowerCase() + "}");
            if (items[i].diagPipeSep.toLowerCase().indexOf(searchString) > -1 ){
              // console.log("****** DIAGNOSE {" + searchString + "} er SUBSTRING av " + items[i].diagnosis);
              results.push(items[i]);
              break;
            }

          }
          else{
            var diagnosisSplit = items[i].diagPipeSep.toLowerCase().split('|');
            // console.log("***** Array exact match in " + diagnosisSplit.toString());
            // perform exact match
            if (diagnosisSplit.indexOf(searchString) > -1 ){
              // console.log("****** DIAGNOSE " + searchString + " er EXACT MATCH med en av diagnosene i " + items[i].diagnosis);
              results.push(items[i]);
              break;
            }

          }

        }

      }
      return results;
    };
  }


  function combinedFilter() {
    return function(items, field) {

      if(typeof field === 'undefined' || field.trim() === ''){
        return items;
      }
      field = field.trim();
      var results = [];
      //console.log("combinedFilter: field");
      //console.dir(field);
      // First, split on space to enable searching for more than one diagnosis
      var targets = field.split(' ');
      var searchString;
      var reportDiagnosis;
      for (var i = 0; i < items.length; i++){

        if(inDiagnosisFilter(items[i]) || inPlaceFilter(items[i])) {
          results.push(items[i]);

        }
      }
      return results;
    };
  }

  function filterPlace() {
    return function(items, searchTerm) {
      var exactMatch = false;
      searchTerm = searchTerm.trim();
      if(typeof searchTerm === 'undefined' || searchTerm === ''){
        return items;
      }
      var results = [];
      searchTerm = searchTerm.toLowerCase();
      //exactMatch = (searchTerm.charAt(0) === '"');
      if (searchTerm.charAt(0) === '"'  ) {
        // might be an exact match search, has to check for the
        // second "
        var splitString = searchTerm.split('"');
        if(splitString.length >= 3){
          exactMatch = true;
        }
        searchTerm=splitString[1];
      }
      // First, slipt on space to enable searching for more than one diagnosis
      for (var i = 0; i < items.length; i++){
        if(!exactMatch) {
          if(items[i].municipalities.toLowerCase().indexOf(searchTerm) > -1 ||
            items[i].counties.toLowerCase().indexOf(searchTerm)   > -1
          ){
            results.push(items[i]);
          }
        }
        else{
          // Search in counties
          var counties = items[i].counties.toLowerCase().split(',');
          var municipalities = items[i].municipalities.toLowerCase().split(',');
          var continueSearch = true;
          for (var c = 0; c < counties.length; c++){
            if (counties[c] === searchTerm) {
              results.push(items[i]);
              // setting this flag to avoid searching in municiplaities if there is already a match in  county
              continueSearch = false;
              break;
            }
          }
          if(continueSearch){
            for (var m = 0; m < municipalities.length; m++){
              if (municipalities[m].trim() === searchTerm) {
                results.push(items[i]);
                break;
              }
            }
          }


        }
      }
      return results;
    };
  }
})(angular.module('skogskadeFilters', []));
