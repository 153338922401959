import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { ReportListFiltersType } from '@apptypes/report-list-filters.type';
import { ReportType } from '@apptypes/report.type';
import { ReportListService } from '@core/services/report-list.service';
import { environment } from '@environments/environment';
import { NgbHighlight, NgbPagination, NgbPaginationPages } from '@ng-bootstrap/ng-bootstrap';
import { ReportListSortEvent, SortableReportListDirective, SortDirection } from '@shared/directives/sortable.directive';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    NgbHighlight,
    NgbPagination,
    NgbPaginationPages,
    SortableReportListDirective,
    RouterLink,
  ],
  selector: 'app-reports-table',
  standalone: true,
  styleUrls: ['./reports-table.component.scss'],
  templateUrl: './reports-table.component.html',
})
export class ReportsTableComponent {
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _reportListService = inject(ReportListService);
  private readonly _router = inject(Router);

  @Output()
  pageChanged = new EventEmitter<void>();

  @Output()
  perPageChanged = new EventEmitter<void>();

  @Output()
  sortChanged = new EventEmitter<void>();

  @Input()
  filters!: ReportListFiltersType;

  @Input()
  reports$!: Observable<ReportType[]>;

  @ViewChild('damageTypeRef', { static: true })
  damageTypeRef!: ElementRef;

  @ViewChildren(SortableReportListDirective)
  headers!: QueryList<SortableReportListDirective>;

  processing: boolean = false;
  reportsCount = this._reportListService.reportsCount;
  reportsError$ = this._reportListService.reportsError$;
  service = this._reportListService;
  sortDirection: SortDirection = 'desc';

  get perPage(): number[] {
    const defaults = [10, 30, 60];
    if (!defaults.includes(this._reportListService.perPage)) {
      defaults.push(this._reportListService.perPage);
      defaults.sort();
    }
    return defaults;
  }

  downloadCsv() {
    this._reportListService
      .getCsvPath({
        dateEnd: this.filters.dateEnd,
        dateStart: this.filters.dateStart,
        id: this.filters.id,
        page: this.filters.page,
        perPage: this.filters.perPage,
        queryDamage: this.filters.queryDamage,
        queryPlace: this.filters.queryPlace,
        sort: this.filters.sort,
      })
      .pipe(take(1))
      .subscribe({
        next: response => {
          if (response.csvPath?.length) {
            window.location.href = environment.frontend.serviceUrl + '/' + response.csvPath;
          }
        },
      });
  }

  formatInput(input: HTMLInputElement) {
    const FILTER_PAG_REGEX = /[^0-9]/g;
    input.value = input.value.replace(FILTER_PAG_REGEX, '');
  }

  navigateToReport(report_id: number): void {
    void this._router.navigate(['/rapporter/' + report_id]);
  }

  onSort({ column, direction }: ReportListSortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortableReportList !== column) {
        header.direction = '';
      }
    });
    this.filters.sort = [column, direction].join(',');
    this._reportListService.sort = this.filters.sort;
    this.sortDirection = direction;
    this.sortChanged.emit();
    this._cdr.detectChanges();
  }

  setPage(page: number) {
    this._reportListService.page = page || 1;
    this.pageChanged.emit();
  }

  setPerPage(count: string) {
    this._reportListService.perPage = parseInt(count) || 20;
    this.perPageChanged.emit();
  }

  userUpdatePage(page: string) {
    this._reportListService.page = parseInt(page) || 1;
    this.pageChanged.emit();
  }
}
