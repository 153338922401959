import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormErrorMessageType } from '@apptypes/form-error-message.type';
import { UserFeedbackType } from '@apptypes/user-feedback.type';
import { UserFeedbackTypesEnum } from '@enums/user-feedback-types.enum';

export class ReactiveFormsHelper {
  static addFormGroupToFormArray(form: UntypedFormGroup, formArrayName: string, formGroup = null) {
    ReactiveFormsHelper.getArray(form, formArrayName).push(formGroup);
  }

  /**
   * Read error constants on fields and compose error message for each, return as array
   */
  static composeErrorMessages(fields: FormErrorMessageType[] = []): {
    errorCount: number;
    errorMessages: UserFeedbackType[];
  } {
    const errorMessages: UserFeedbackType[] = [];
    let errorCount = 0;

    fields.forEach((field: FormErrorMessageType) => {
      if (field.err && field.ctrl.hasError(field.err)) {
        errorCount++;
        errorMessages.push({ message: field.msg, type: UserFeedbackTypesEnum.ERROR });
      } else if (field.ctrl.parent && field.ctrl.parent.invalid) {
        errorCount++;
      }
    });

    return { errorCount, errorMessages };
  }

  /**
   * Retrieve the given controls name as proper FormArray
   *
   * @example (userForm, 'names.firstName')
   */
  static getArray(formGroup: UntypedFormGroup, path: Array<string> | string): UntypedFormArray {
    return formGroup.get(path) as UntypedFormArray;
  }

  /**
   * Return given ctrl as FormArray
   */
  static getAsArray(ctrl: AbstractControl): UntypedFormArray {
    return ctrl as UntypedFormArray;
  }

  /**
   * Return given ctrl as FormControl
   */
  static getAsCtrl(ctrl: AbstractControl): UntypedFormControl {
    return ctrl as UntypedFormControl;
  }

  /**
   * Return given ctrl as FormGroup
   */
  static getAsGroup(ctrl: AbstractControl): UntypedFormGroup {
    return ctrl as UntypedFormGroup;
  }

  /**
   * Retrieve the given controls name as proper FormArray
   *
   * @example (userForm, 'names.firstName')
   */
  static getCtrl(formGroup: UntypedFormGroup, path: Array<string> | string): UntypedFormControl {
    return formGroup.get(path) as UntypedFormControl;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getFormGroupClasses(ctrl: FormControl<any> | undefined): string {
    const classes: string[] = [];
    if (ctrl !== undefined) {
      if (ctrl.hasValidator(Validators.required)) {
        classes.push('required');
      }

      if (ctrl.invalid) {
        classes.push('invalid');
      }
    }
    return classes.join(' ');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getFormControlClasses(ctrl: FormControl<any> | undefined): string {
    const classes: string[] = ['form-control'];
    if (ctrl !== undefined) {
      if (ctrl.hasValidator(Validators.required)) {
        classes.push('is-required');
      }

      if (ctrl.touched) {
        // Bootstrap classes for showing status-icon on right
        classes.push(ctrl.valid ? 'is-valid' : 'is-invalid');
      }
    }
    return classes.join(' ');
  }

  /**
   * Retrieve the given controls name as proper FormArray
   *
   * @example (userForm, 'names.firstName')
   */
  static getGroup(formGroup: UntypedFormGroup, path: Array<string> | string): UntypedFormGroup {
    return formGroup.get(path) as UntypedFormGroup;
  }

  static getAllFormControlsIn(formGroup: FormGroup): FormControl[] {
    const response: FormControl[] = [];
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        response.push(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach(row => this.getAllFormControlsIn(row as FormGroup));
      } else if (control instanceof FormGroup) {
        this.getAllFormControlsIn(control);
      }
    });
    return response;
  }
}
