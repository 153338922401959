import { inject, Injectable, signal } from '@angular/core';
import { MapOverlayType } from '@apptypes/map-overlay.type';
import { MapLegendService } from '@core/services/map/map-legend.service';
import { MapModesEnum } from '@enums/map-modes.enum';
import { environment } from '@environments/environment';
import * as L from 'leaflet';
import { Control, Map as LeafletMap, TileLayer, tileLayer } from 'leaflet';
import 'leaflet-bing-layer';
import 'leaflet-plugins/layer/tile/Bing.js';
import 'leaflet.locatecontrol';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  readonly defaultControls = {
    layersControl: new L.Control.Layers(),
    legendControl: new L.Control(),
    locateControl: new L.Control.Locate(),
    scaleControl: new L.Control.Scale(),
    searchControl: new L.Control(),
    zoomControl: new L.Control.Zoom(),
  };

  /**
   * this.currentMapControlKeys is an array that holds the keys (strings) of all currently loaded ctrls.
   * This is a helper to give an easy way of fetching the Leaflet returned handle in this.mapControls,
   * so keep these two in sync.
   */
  currentMapControlKeys: string[] = [];
  /**
   * Holds all Control handles added to Leaflet. Keep this.currentMapControlKeys in sync with this object.
   */
  mapControls: {
    [key: string]: L.Control;
    layersControl: L.Control.Layers;
    legendControl: L.Control;
    locateControl: L.Control.Locate | L.Control.SkogskadeLocate;
    scaleControl: L.Control.Scale;
    searchControl: L.Control;
    zoomControl: L.Control.Zoom;
  } = this.defaultControls;

  mapMode = signal<MapModesEnum>(MapModesEnum.SHOW_SKOGSKADER);

  private readonly _mapLegendService = inject(MapLegendService);

  private _defaultLayer: TileLayer | undefined;
  private _map!: LeafletMap;
  private _mapClearSub$ = new Subject<void>();
  private _savedMapState: { center: any; zoom: any } | undefined;

  mapCleared$ = this._mapClearSub$.asObservable();

  constructor() {
    this.mapControls.locateControl = new L.Control.Locate({
      icon: 'leaflet-icon bi bi-crosshair',
      locateOptions: {
        enableHighAccuracy: true,
      },
      position: 'topright',
      strings: {
        outsideMapBoundsMsg: 'Ser ut til at du er utenfor grensene for dette kartet', // default message for onLocationOutsideMapBounds
        popup: 'Du er innenfor {distance} {unit} fra dette punktet', // text to appear if user clicks on circle
        title: 'Vis min posisjon', // title of the locate control
      },
    });

    this.mapControls.searchControl = new L.Control({
      position: 'topleft',
    });

    this.mapControls.zoomControl = new L.Control.Zoom({
      position: 'topright',
      zoomInTitle: 'Zoom inn',
      zoomOutTitle: 'Zoom ut',
    });
  }

  /**
   * Allow adding controls to map from outside
   */
  addControl(controlKey: string, control: L.Control, replaceExisting: boolean = false): void {
    const exists = Object.hasOwn(this.mapControls, controlKey);
    if (!replaceExisting && exists) {
      return;
    }

    this._loadMapControls({ [controlKey]: control });
  }

  clearMap() {
    this._mapClearSub$.next();
    this.removeObjectLayers();
  }

  /**
   * Config the different base layers available for the users.
   */
  getBaseLayers(): Control.LayersObject {
    const bing = tileLayer.bing({
      bingMapsKey: environment.keys.bing,
      culture: 'nb_NO',
      imagerySet: 'AerialWithLabelsOnDemand',
      maxNativeZoom: 19,
      minNativeZoom: 1,
      minZoom: 1,
      zIndex: 1,
    });

    const osm = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      zIndex: 1,
    });

    /* eslint-disable */
    return {
      Topo: this.getDefaultLayer(),
      'Flybilder (Bing)': bing,
      OpenStreetMap: osm,
    } as Control.LayersObject;
    /* eslint-enable */
  }

  /**
   * Our default Topo background map:
   * @see https://cache.kartverket.no/v1/wmts/1.0.0/WMTSCapabilities.xml
   *
   * Map Legends for this layer:
   * While the WMTS standard does support GetLegendGraphic:
   * @see https://loaders.gl/docs/modules/wms/formats/wmts
   * Geonorge seems to not have implemented that for their WMTS yet, and
   * Geonorge recommends using WMS to display legend for the WMTS:
   * @see https://www.geonorge.no/aktuelt/om-geonorge/slik-bruker-du-geonorge/bruke-tjenester-og-api-er/
   * ^- Search page for "tegnforklaring"
   *
   * Possible values for Legends:
   * - https://openwms.statkart.no/skwms1/wms.topo?version=1.3.0&sld_version=1.1.0&service=WMS&request=GetLegendGraphic&format=image/png&layer=kd_hoydelag
   * - https://openwms.statkart.no/skwms1/wms.topo?version=1.3.0&sld_version=1.1.0&service=WMS&request=GetLegendGraphic&format=image/png&layer=ar5
   * - https://openwms.statkart.no/skwms1/wms.topo?version=1.3.0&sld_version=1.1.0&service=WMS&request=GetLegendGraphic&format=image/png&layer=topo
   */
  getDefaultLayer(): TileLayer {
    if (!this._defaultLayer) {
      this._defaultLayer = tileLayer(this.getKartverketWmtsUrl('topo'), {
        attribution:
          '<a href="https://www.kartverket.no" title="The National Mapping Authority of Norway">Kartverket</a>',
        detectRetina: true,
        maxZoom: 18, // Fetched from GetCapabilities for Kartverket Topo, ref docblock
        zIndex: 1,
      });
    }

    return this._defaultLayer;
  }

  /**
   * A note on projections:
   * From the following URL we can see which TileMatrixSet (=CRS or EPSG) values are valid for topo:
   * @see https://cache.kartverket.no/v1/wmts/1.0.0/WMTSCapabilities.xml
   * @see https://cache.kartverket.no/capabilities/norges_grunnkart/WMTSCapabilities.xml?request=GetCapabilities
   * From there we can see <TileMatrixSet>webmercator</TileMatrixSet> which
   *   is Kartverkets identifier for the projection Leaflet uses by default, commonly referred to as:
   *   EPSG:3857 aka WGS84 aka WebMercator aka Google Mercator aka 900913
   *
   * By requesting WMTS with tilematrixset=webmercator we receive tiles compatible with Leaflets default projection
   */
  getKartverketWmtsUrl(id: string, epsg: string = 'webmercator') {
    return `https://cache.kartverket.no/v1/wmts/1.0.0/?layer=${id}&style=default&tilematrixset=${epsg}&service=WMTS&request=GetTile&version=1.0.0&format=image/png&tileMatrix={z}&tileCol={x}&tileRow={y}`;
  }

  getMap(): LeafletMap {
    return this._map;
  }

  getMapOptions(): L.MapOptions {
    return {
      center: {
        lat: 65,
        lng: 10,
      },
      layers: [],
      pmIgnored: false,
      zoom: 5,
      zoomControl: false,
    } as L.MapOptions;
  }

  getOverlays(): { [name: string]: MapOverlayType } {
    const response: { [name: string]: MapOverlayType } = {
      Kommunegrenser: tileLayer.wms('https://wms.geonorge.no/skwms1/wms.adm_enheter2', {
        attribution: '<a href="https://www.kartverket.no" title="Kartverket">Kartverket</a>',
        format: 'image/png',
        layers: 'kommuner',
        opacity: 1,
        transparent: true,
        zIndex: 5,
      }),

      'Treslag (AR50)': tileLayer.wms('https://wms.nibio.no/cgi-bin/ar50_2', {
        attribution: '<a href="https://www.nibio.no" title="NIBIO">NIBIO</a>',
        format: 'image/png',
        layers: 'Treslag',
        opacity: 1,
        transparent: true,
        zIndex: 5,
      }),

      'Verneområder (Mdir)': tileLayer.wms('http://wms.miljodirektoratet.no/arcgis/services/vern/mapserver/WMSServer', {
        attribution: '<a href="https://www.miljodirektoratet.no" title="Miljodirektoratet">Miljodirektoratet</a>',
        format: 'image/png',
        layers: 'naturvern_klasser_omrade',
        // legendUrl: 'http://wms.miljodirektoratet.no/arcgis/services/vern/mapserver/WMSServer?version=1.1.1&service=WMS&request=GetLegendGraphic&format=image/png&layer=naturvern_klasser_omrade',
        opacity: 1,
        transparent: true,
        zIndex: 5,
      }),
    };

    // Add legend image urls:
    response['Treslag (AR50)'].legendImgUrl =
      'https://wms.nibio.no/cgi-bin/ar50_2?version=1.1.1&service=WMS&request=GetLegendGraphic&format=image/png&layer=Treslag';
    response['Verneområder (Mdir)'].legendImgUrl =
      'https://wms.miljodirektoratet.no/arcgis/services/vern/mapserver/WMSServer?version=1.1.1&service=WMS&request=GetLegendGraphic&format=image/png&layer=naturvern_klasser_omrade';

    return response;
  }

  /**
   * Make sure layer has commonly used properties available
   */
  initLayerProperties(layer: any) {
    // Forces Leaflet to add an internal identifier (_leaflet_id) for layer
    L.Util.stamp(layer);

    // Add options object
    if (!layer.options) {
      layer.options = {};
    }

    // Force feature properties on layer to later be used with GeoJSON:
    if (!layer.feature) {
      layer.feature = {};
    }
    if (!layer.feature.type) {
      layer.feature.type = 'Feature';
    }
    if (!layer.feature.properties) {
      layer.feature.properties = {};
    }
  }

  /**
   * Initialize the map with default options
   */
  initMap(elementId: string, mapMode?: MapModesEnum): LeafletMap {
    // initMap(elementId: string | HTMLElement, mapMode?: MapModesEnum): LeafletMap {
    // Init Map
    this._map = L.map(elementId, this.getMapOptions());
    this._defaultLayer = undefined;

    if (mapMode) {
      this.mapMode.set(mapMode);
    }

    // Set available zoom levels
    this._map.setMaxZoom(18);
    this._map.setMinZoom(4);

    // Reset and assign default active layer
    this._defaultLayer = undefined;
    this.getDefaultLayer().addTo(this._map);

    if (this.mapMode() === MapModesEnum.EMBED) {
      this._loadEmbedControls();
      return this._map;
    }

    this._loadMapControls();

    this._map.on('overlayadd', e => {
      this._mapLegendService.activeLayersAdd(e.layer);
      this.reloadLegendControl();
    });

    this._map.on('overlayremove', e => {
      this._mapLegendService.activeLayersRemove(e.layer);
      this.reloadLegendControl();
    });

    return this._map;
  }

  /**
   * Recreates and re-renders the Legend (Leaflet) Control
   */
  reloadLegendControl(): void {
    const legendWasVisible = this._mapLegendService.isLegendVisible();
    this._map.removeControl(this.mapControls.legendControl);
    this.mapControls.legendControl = this._mapLegendService.getLegendControl(
      this.getOverlays(),
      legendWasVisible,
      this.mapMode() !== MapModesEnum.DRAW,
    );
    this._map.addControl(this.mapControls.legendControl);
  }

  /**
   * Remove a (Leaflet) Control by its key
   */
  removeControl(ctrlKey: string) {
    const idx = this.currentMapControlKeys.findIndex(haystack => haystack === ctrlKey);
    if (idx > -1) {
      const ctrl = this.mapControls[ctrlKey];
      this.getMap().removeControl(ctrl);
      delete this.mapControls[ctrlKey];
      this.currentMapControlKeys.splice(idx, 1);
    }
  }

  /**
   * Removes any layers with flag pmIgnore set to false. Used when
   * submitting DamageForm, prevent leftover objects in map
   */
  removeObjectLayers(): void {
    this.getMap().eachLayer(l => {
      if (l.options.pmIgnore === false) {
        l.removeFrom(this.getMap());
      }
    });
  }

  replaceLocateControlWith(newControl: L.Control.Locate | L.Control.SkogskadeLocate | any) {
    this._loadMapControls({ locateControl: newControl });
  }

  private _loadEmbedControls(): void {
    const zoomCtrl = new L.Control.Zoom({
      position: 'topleft',
      zoomInTitle: 'Zoom inn',
      zoomOutTitle: 'Zoom ut',
    });

    const legendCtrl = this._mapLegendService.getLegendControl({}, true, true);

    this._map.addControl(zoomCtrl);
    this._map.addControl(legendCtrl);
  }

  /**
   * Used to (re)load all controls on the Leaflet Map. Removes all controls, then adds in order.
   * This is done to ensure all controls show in the same order, even when one is replaced.
   * Solved like this since Leaflet has no ordering of controls, only add & remove (FIFO/LILO).
   */
  private _loadMapControls(replacements?: { [key: string]: L.Control }): void {
    // Remove all existing controls from the Map
    this.currentMapControlKeys = [];
    Object.keys(this.mapControls).forEach(ctrl => {
      this._map.removeControl(this.mapControls[ctrl]);
    });
    this.mapControls = this.defaultControls;

    // Define the usual controls:
    // Ruler/scale
    this.mapControls.scaleControl = L.control.scale({ imperial: false, position: 'bottomleft' });

    // Layers control widget used to toggle layers + overlays
    this.mapControls.layersControl = new L.Control.Layers(this.getBaseLayers(), this.getOverlays(), {
      collapsed: true,
    });

    // Legend control (will be reloaded on layer changes)
    this.mapControls.legendControl = this._mapLegendService.getLegendControl(
      this.getOverlays(),
      false,
      this.mapMode() !== MapModesEnum.DRAW,
    );

    // Replace specified controls with suppled ones
    if (replacements) {
      Object.keys(replacements)?.forEach(ctrlKey => {
        this.mapControls[ctrlKey] = replacements[ctrlKey];
      });
    }

    // Add the usual controls to the map in specific order:
    this._map.addControl(this.mapControls.scaleControl);
    this._map.addControl(this.mapControls.zoomControl);
    this._map.addControl(this.mapControls.locateControl);
    this._map.addControl(this.mapControls.layersControl);
    this._map.addControl(this.mapControls.legendControl);
    // this._map.addControl(this.mapControls.searchControl);
    this.currentMapControlKeys = [
      'scaleControl',
      'zoomControl',
      'locateControl',
      'layersControl',
      'legendControl',
      'searchControl',
    ];
    document.querySelector('.leaflet-control-layers-toggle')?.classList.add('bi', 'bi-layers');
    document.querySelector('.leaflet-control-layers-toggle')?.setAttribute('title', 'Kartlag');

    // Add to Map remaining MapControls still not added. For instance those added using MapService.addControl()
    Object.keys(this.mapControls).forEach(ctrlKey => {
      if (!this.currentMapControlKeys.includes(ctrlKey)) {
        this._map.addControl(this.mapControls[ctrlKey]);
        this.currentMapControlKeys.push(ctrlKey);
      }
    });
  }

  resetMapState(): void {
    this._savedMapState = undefined;
  }

  restoreMapState(): void {
    if (this._savedMapState) {
      this.getMap().setView(this._savedMapState.center, this._savedMapState.zoom);
    }
  }

  /**
   * TODO: Save per mapId (regular/embedded, or even report-map vs dictionary-map)?
   * TODO: Handle mapMode?
   */
  saveMapState(): void {
    this._savedMapState = {
      center: this.getMap()?.getCenter(),
      zoom: this.getMap()?.getZoom(),
    };
  }
}
