/// <reference types="@angular/localize" />

/* eslint-disable */

/**
 * AngularJs imports
 */
import '@ajs-npm/angular.js';
import '@ajs-npm/jquery-1.12.4.js';

declare var angular: angular.IAngularStatic;
import '@ajs-npm/angular-route.js';
import '@ajs-npm/angular-resource.js';
import '@ajs-npm/ui-bootstrap-tpls.js';

// Leaflet & plugins
// See mapController.js
// import '@ajs-npm/leaflet.js';
// import '@ajs-npm/leaflet.wms.js';
// import '@ajs-npm/leaflet.draw.js';
// import '@ajs/js/map/leaflet.draw.local.js';
// import '@ajs-npm/L.Control.Locate.min.js';
// import '@ajs-npm/Bing.js';
// import '@ajs/js/map/angular-leaflet-directive.min.js';
// import '@ajs/js/modified_extras/leaflet-pip/geojson-utils.js';
// import '@ajs/js/modified_extras/leaflet-pip/leaflet-pip.js';

// Resources, Meta, 3pd components
import '@ajs-npm/angular-sanitize.min.js';
import '@ajs-npm/angular-resource.js';
import '@ajs/js/modified_extras/angular-multi-select-2.0.2/angular-multi-select.js';
import '@ajs/js/modified_extras/typeahead.bundle.js';
import '@ajs-npm/angular-file-upload.js';

// Constants
import '@ajs/js/constants.js';
// TODO: For some reason commenting this next line causes errors, even though nothing is defined inside the file??
import '@ajs/js/constants/globalOptions.constant.js';
import '@ajs/js/constants/dateHelper.constant';
import '@ajs/js/constants/leafletStyles.constant';
import '@ajs/js/constants/mapConf.constant';

// Config
import '@ajs/js/configs.js';
import '@ajs/js/config/routes.config.js';
import '@ajs/js/config/http.config';
// import '@ajs/js/config/meta.run';

// Controllers & Services
// import '@ajs/js/animations.js'; // Not in use due to conflict in old ajs-app.
// Controllers, Filters, Services
import '@ajs/js/controllers.js';
import '@ajs/js/controllers/selectModalHost.controller.js';
import '@ajs/js/controllers/damageListController.js';
import '@ajs/js/controllers/damageDetailController.js';
import '@ajs/js/controllers/reportListController.js'
import '@ajs/js/controllers/reportDetailController.js'
import '@ajs/js/controllers/searchController.js'
import '@ajs/js/controllers/modalInstanceController.js';
import '@ajs/js/controllers/messageController.js';
import '@ajs/js/filters.js';
import '@ajs/js/services.js';
import '@ajs/js/services/formInitDataService/formInitDataService.js';
import '@ajs/js/services/legendService.js';
import '@ajs/js/services/reportFilterService.js';
import '@ajs/js/services/reportDetailService.js';
import '@ajs/js/services/reportPicturesService.js';
import '@ajs/js/services/reportListService.js';
import '@ajs/js/services/mapDataService.js';
import '@ajs/js/services/requestCounterService.js';
import '@ajs/js/services/messageService.js';
import '@ajs/js/controllers/formController.js';
import '@ajs/js/controllers/mapController.js';
import '@ajs/js/controllers/mapEditReportController.js';
import '@ajs/js/services/mapService.js';
import '@ajs/js/services/mapModeService.js';
// Directives
import '@ajs/js/directives.js';
// import '@ajs/js/directives/workSpinner.js';
import '@ajs/js/directives/searchPlace.js';
import '@ajs/js/directives/reportFilter.js';
import '@ajs/js/directives/diagnosisReports.js';
import '@ajs/js/directives/reportMap.js';
import '@ajs/js/services/interceptorService.js';


/**
 * Angular 16 imports
 */
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
// if (window['bootstrap'] === undefined) {
//  import('bootstrap/dist/js/bootstrap.min.js');
// }

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
