export class ObjectHelper {
  /**
   * Traverses through object and returns final string
   * @example
   *   Given myObject: { someSub: {someProp: string}}
   *   Call this method with ('someSub.someProp'.split('.'), myObject)
   */
  static getNestedPropertyAsString(segments: string[], stack: object, index = 0): string | false {
    if (!(segments[index] in stack)) {
      return false;
    }

    stack = stack[segments[index]];

    const nextIndex = index + 1;

    if (segments[nextIndex]) {
      return ObjectHelper.getNestedPropertyAsString(segments, stack, nextIndex);
    }

    return stack.toString();
  }

  /**
   * Use this to make sure no orphan child properties are lost
   * Object.assign does not deep clone, so properties of children only in target will be lost.
   * https://stackoverflow.com/a/56282423/504075
   */
  static nestedAssign<T extends object>(target: T, source: object): T {
    if (!target) {
      return source as T;
    }

    Object.keys(source).forEach(sourcekey => {
      if (
        Object.keys(source).find(targetkey => targetkey === sourcekey) !== undefined &&
        typeof source[sourcekey] === 'object'
      ) {
        target[sourcekey] = ObjectHelper.nestedAssign(target[sourcekey], source[sourcekey]);
      } else {
        target[sourcekey] = source[sourcekey];
      }
    });

    return target;
  }
}
