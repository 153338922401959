import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { Map as LeafletMap, Util } from 'leaflet';
import * as WMS from 'leaflet.wms';
import { appRoutes } from '../../app.routes';

/**
 * This class provides custom Skogskade-content in the popup/tooltip shown when clicking points on a
 * Skogskade-reports WMS layer. Refactored to its own class for separation of concerns.
 *
 * @usage
 * Create an instance and use its init() method to supply the redefined getFeatureInfo hook and connect to provided map
 *
 * @see
 * Leaflet include: https://leafletjs.com/examples/extending/extending-1-classes.html
 * "If a class is already defined, existing properties/methods can be redefined, or new ones can be added by using .include():"
 *
 * @see
 * Leaflet.wms GetFeatureInfo hook:
 * https://github.com/heigeo/leaflet.wms?tab=readme-ov-file#identify-getfeatureinfo
 */
export class SkogskadeReportsWmsFeatureInfo {
  popupContent!: string;
  reportIds: number[] = [];

  /**
   * Use this init method to wire up the extended getFeatureInfo hook of Leaflet.wms and connect it to provided map
   */
  init(map: LeafletMap) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const classThis = this;

    /**
     * Extends (redefines) Leaflet.WMS method
     */
    WMS.Source.include({
      getFeatureInfo: function (point, latlng, layers, callback) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const wmsThis = this;
        const params = this.getFeatureInfoParams(point, layers);
        const infoUrl = this._url + Util.getParamString(params, this._url);
        this.showWaiting();
        this.ajax(infoUrl, done);

        function done(result) {
          wmsThis.hideWaiting();
          // const text = wmsThis.parseFeatureInfo(result, url);
          // start section for new code
          let parser, xmlDoc;

          /**
           * Construct the link used in the popup.
           */
          const frontendUrl = EnvironmentHelper.fetchFrontEndURL();
          let reportsUrl = frontendUrl + '/rapporter';

          // Append actual url from any redirect in appRoutes
          const reportRoute = appRoutes.find(r => r.path === 'rapporter');
          if (reportRoute?.redirectTo?.length) {
            reportsUrl = frontendUrl + reportRoute.redirectTo;
          }
          if (reportsUrl.endsWith('/')) {
            reportsUrl.substring(0, reportsUrl.length - 1);
          }

          if (result) {
            parser = new DOMParser();
            xmlDoc = parser.parseFromString(result, 'text/xml');
            const checkXml =
              xmlDoc.documentElement.nodeName == 'parsererror'
                ? 'error while parsing'
                : xmlDoc.documentElement.nodeName;
            if (
              checkXml != 'ServiceExceptionReport' &&
              checkXml &&
              xmlDoc.getElementsByTagName(checkXml)[0].childNodes.length > 1
            ) {
              let nodes = 0;
              const nodeCountPoly = xmlDoc.getElementsByTagName('overview_polygon_feature') || [];
              const nodeCountPoint = xmlDoc.getElementsByTagName('overview_point_feature') || [];
              const nodeCountLine = xmlDoc.getElementsByTagName('overview_line_feature') || [];

              nodes += nodeCountPoly.length >= 1 ? nodeCountPoly.length : 0;
              nodes += nodeCountPoint.length >= 1 ? nodeCountPoint.length : 0;
              nodes += nodeCountLine.length >= 1 ? nodeCountLine.length : 0;
              if (nodes === 0) {
                return;
              }

              for (let i = 0; i < nodes; i++) {
                const diagnoses = xmlDoc.getElementsByTagName('diagnosis_list')[i].childNodes[0].nodeValue;
                const report_id = xmlDoc.getElementsByTagName('report_id')[i].childNodes[0].nodeValue;
                if (classThis.reportIds.indexOf(report_id) === -1) {
                  classThis.popupContent +=
                    '<p><b>' +
                    diagnoses +
                    '</b><br>' +
                    '<a href=' +
                    reportsUrl +
                    '/' +
                    report_id +
                    '>Se rapport nr. ' +
                    report_id +
                    '</a></p>';
                  classThis.reportIds.push(report_id);
                }
              }
            } else {
              if (classThis.popupContent === '') {
                classThis.popupContent = 'Ingen treff';
              }
            }
            if (classThis.popupContent.length > 20) {
              const substring = 'Ingen treff';
              if (classThis.popupContent.indexOf(substring) !== -1) {
                classThis.popupContent = classThis.popupContent.replace(substring, '');
              }
            }
          }
          // End section for new code
          callback.call(wmsThis, latlng, classThis.popupContent);
        }
      },
    });

    map.on('click', () => {
      this.popupContent = '';
      this.reportIds = [];
    });
  }
}
