import { Injectable } from '@angular/core';
import { FilterValuesType } from '@apptypes/filter-values.type';
import { DateHelper } from '@core/helpers/date.helper';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportFilterService {
  private _dateToday = new Date();
  private _filterValues: FilterValuesType = {
    diagnosisSearch: '',
    fromDate: DateHelper.subtractFromDate(new Date(), 1),
    placeSearch: '',
    toDate: this._dateToday,
  };

  private _filterValueSubject$ = new BehaviorSubject<FilterValuesType>(this._filterValues);

  filterValueChanges$ = this._filterValueSubject$.asObservable();

  get filterCurrentValues() {
    return this._filterValueSubject$.value;
  }

  updateValues(value: FilterValuesType): void {
    this._filterValues = value;
    this._filterValueSubject$.next(this._filterValues);
  }
}
