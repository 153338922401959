(function (skogskadeServices) {
  'use strict';

  skogskadeServices.factory('reportListService', reportListFn);

  reportListFn.$inject = ['$http', '$q', 'skogskadeGlobalOptions', 'dateHelper'];

  function reportListFn($http, $q, skogskadeGlobalOptions, dateHelper) {
    // variable used to cache reportList-results
    var reportList = null;
    var cachedReports = null;
    // variable to cache results from requesting reports by diagnosis
    var reportsByDiagnosis = null;

    // variable to remember from-date of reportlistResults
    var fromCached;
    // variable to remember to-date of reportlistResults
    var toCached;
    // variable to remember text-search value
    var textSearchCached;
    // variable to remember place-search value
    var placeSearchCached;

    var cachedByDiagnosis = null;

    var reportsUrl = skogskadeGlobalOptions.serviceUrl + '/skogskade_ws/rest/services/report_v2/?';
    var pagedReportsUrl = skogskadeGlobalOptions.serviceUrl + '/skogskade_ws/rest/services/report_page/?';
    var reportDownloadCSVDataUrl = skogskadeGlobalOptions.serviceUrl + '/skogskade_ws/rest/services/report_page/csv/';
    // variable to remember the date of last collected reports
    var lastDataCollect = Date.now();

    // vriable to remember
    var lastDiagnosisCollect = null;

    var filteredReports = [];

    var cachedDataAreOld = function (timestamp, from, to) {
      var maxAge = skogskadeGlobalOptions.reportListMaxAgeSec;
      var age = (timestamp - lastDataCollect) / 1000;
      //console.log("Dataage:" + age);
      //console.log("From changed:" + (from !== fromCached));
      //console.log("To changed:" +   (to !== toCached));
      var periodChanged = from !== fromCached || to !== toCached;

      return age > maxAge || periodChanged;
    };

    var reportsMustReload = function (timestamp, from, to, textSearch, placeSearch) {
      var maxAge = skogskadeGlobalOptions.reportListMaxAgeSec;
      var age = (timestamp - lastDataCollect) / 1000;
      // console.log("Dataage:" + age);
      // console.log("From changed:" + (from !== fromCached));
      // console.log("To changed:" +   (to !== toCached));
      var periodChanged = from !== fromCached || to !== toCached;

      //console.log("cachedDataAreOld returnerer:"+ (age > maxAge) ||  periodChanged);
      return age > maxAge || periodChanged || textSearch !== textSearchCached || placeSearch !== placeSearchCached;
    };

    var cachedDiagnosisAreOld = function (timestamp, diagnosis) {
      var maxAge = skogskadeGlobalOptions.reportListMaxAgeSec;
      var age = (timestamp - lastDiagnosisCollect) / 1000;
      //console.log("Dataage:" + age);
      //console.log("From changed:" + (from !== fromCached));
      //console.log("To changed:" +   (to !== toCached));
      var diagnosisChanged = diagnosis !== cachedByDiagnosis;

      //console.log("cachedDataAreOld returnerer:"+ (age > maxAge) ||  periodChanged);
      var result = age > maxAge || diagnosisChanged;
      return result;
    };

    var getReports = function (fromDate, toDate, diagnosis, place) {
      // from=&to=&diagnosisid=&diagnosis=&host=&municipality=
      var now = Date.now(); // reportsMustReload
      var from = dateHelper.dateToString(fromDate);
      var to = dateHelper.dateToString(toDate);
      /* Use this code when service is in place:*/
      if (cachedReports === null || reportsMustReload(now, from, to, diagnosis, place)) {
        lastDataCollect = now;
        fromCached = from;
        toCached = to;
        textSearchCached = diagnosis;
        placeSearchCached = place;
        var completeUrl =
          reportsUrl + 'from=' + from + '&to=' + to + '&diagnosis=' + diagnosis + '&municipality=' + place;
        return $http.get(completeUrl, { isArray: false }).then(function (response) {
          cachedReports = response.data;
          return response.data;
        });
      } else {
        return $q.when(cachedReports);
      }
    };

    var reportIDs = null; // Default mode

    var computeReportIds = function (reports) {
      var report_list = '';
      for (var i = 0; i < reports.length; i++) {
        if (i !== 0) {
          report_list = report_list + ',';
        }

        report_list = report_list + reports[i].report_id;
      }
      return report_list;
    };

    var getReportsAsString = function () {
      return computeReportIds(reportIDs);
    };

    var downloadCSV = function (config) {
      //console.log("download csv, config er:",config);
      var url = reportDownloadCSVDataUrl;
      url += '?from=';
      url += typeof config.from !== 'undefined' ? config.from : '';
      url += '&to=';
      url += typeof config.to !== 'undefined' ? config.to : '';
      url += '&diagnosis=';
      url += typeof config.diagnosis !== 'undefined' ? config.diagnosis : '';
      url += '&municipality=';
      url += typeof config.place !== 'undefined' ? config.place : '';
      url += '&diagnosisid=';
      url += typeof config.diagnosisid !== 'undefined' ? config.diagnosisid : '';
      //console.log("url er:",url);
      return $http.get(url, { isArray: false }).then(function (response) {
        return response;
      });
    };

    var getReportsByDiagnosis = function (diagnosis, fromDate, toDate) {
      var now = Date.now();
      var from = dateHelper.dateToString(fromDate);
      var to = dateHelper.dateToString(toDate);

      if (reportsByDiagnosis === null || cachedDiagnosisAreOld(now, diagnosis)) {
        lastDataCollect = now;
        cachedByDiagnosis = diagnosis;
        var completeUrl = reportsUrl + 'from=' + from + '&to=' + to + '&diagnosisid=' + diagnosis;
        return $http.get(completeUrl, { isArray: false }).then(function (response) {
          lastDiagnosisCollect = now;
          reportsByDiagnosis = response.data;
          return response.data;
        });
      } else {
        // Return data from cache
        return $q.when(reportsByDiagnosis);
      }
    };

    var getReportsPaged = function (config) {
      var url = pagedReportsUrl;
      url += 'from=';
      url += typeof config.from !== 'undefined' ? config.from : '';
      url += '&to=';
      url += typeof config.to !== 'undefined' ? config.to : '';
      url += '&diagnosis=';
      url += typeof config.diagnosis !== 'undefined' ? config.diagnosis : '';
      url += '&municipality=';
      url += typeof config.place !== 'undefined' ? config.place : '';
      url += '&diagnosisid=';
      url += typeof config.diagnosisid !== 'undefined' ? config.diagnosisid : '';
      url += '&page=';
      url += typeof config.page !== 'undefined' ? config.page : '';
      url += '&size=';
      url += typeof config.size !== 'undefined' ? config.size : '';
      url += '&sort=';
      url += typeof config.sort !== 'undefined' ? config.sort : '';
      return $http.get(url, { isArray: false }).then(function (response) {
        return response.data;
      });
    };

    var interfaces = {
      downloadCSV: downloadCSV,
      computeReportIds: computeReportIds,
      getReportsAsString: getReportsAsString,
      getReportsByDiagnosis: getReportsByDiagnosis,
      getReports: getReports,
      getReportsPaged: getReportsPaged,
    };
    return interfaces;
  }
})(angular.module('skogskadeServices'));
