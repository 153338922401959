import { FilterValuesType } from '@apptypes/filter-values.type';
import { DateHelper } from '@core/helpers/date.helper';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { Map as LeafletMap, TileLayer } from 'leaflet';
import { Source, WmsParamsOptions } from 'leaflet.wms';

/**
 * Receives values from app-report-filter, constructs a leaflet.wms layer and adds to given map.
 * The filtered values originate from ReportFilterService.
 *
 * @see ReportFilterService
 */
export class SkogskadeReportsWms {
  isWmsInitialized: boolean = false;
  skogskadeLayer!: TileLayer.WMS;

  private readonly _reportsLayerKey = 'overview_reports';

  addReportFiltersAsWmsLayer(filterValues: FilterValuesType, map: LeafletMap) {
    if (this.isWmsInitialized) {
      map.removeLayer(this.skogskadeLayer);
    }

    const fromDate = DateHelper.toShortNorString(filterValues.fromDate || DateHelper.subtractFromDate(new Date(), 1));
    const searchTermDiagnosis = filterValues.diagnosisSearch || '';
    const searchTermPlace = filterValues.placeSearch || '';
    const toDate = DateHelper.toShortNorString(filterValues.toDate || new Date());
    const wmsUrl = EnvironmentHelper.fetchAPIBase('skogskade') + EnvironmentHelper.getSkogskadePaths().wmsUrl;
    const wmsConfig: WmsParamsOptions = {
      attribution: 'NIBIO',
      format: 'image/png',
      isBack: false,
      layers: this._reportsLayerKey,
      maxZoom: 21,
      opacity: 0.75,
      transparent: true,
      version: '1.3.0',
    };
    const wmsConfigNibio: { [p: string]: any } = {
      DIAGNOSIS: searchTermDiagnosis,
      FEATURE_COUNT: 1000,
      FROMDATE: fromDate,
      KOMMUNE: searchTermPlace,
      TODATE: toDate,
      info_format: 'application/vnd.ogc.gml',
      tiled: false,
      zIndex: 10,
    };

    const reportsSource = new Source(wmsUrl, { ...wmsConfig, ...wmsConfigNibio } as any);
    const reportsLayer = reportsSource.getLayer(this._reportsLayerKey);

    reportsLayer.addTo(map);
    this.skogskadeLayer = reportsLayer;
    this.isWmsInitialized = true;
  }
}
