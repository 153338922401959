<form #form="ngForm" class="form-grid">
  <section class="filter-form-section">
    <div class="form-group">
      <div class="input-group">
        <span class="input-group-text">
          <label for="dateFrom"> Fra: </label>
        </span>
        <input
          #dpFrom="ngbDatepicker"
          (dateSelect)="datePicked()"
          (input)="manualSearch$.next(1500)"
          [class.touched]="frm.controls.dateFrom.touched"
          [formControl]="frm.controls.dateFrom"
          [maxDate]="pickerMaxDate"
          [minDate]="pickerMinDate"
          class="form-control"
          id="dateFrom"
          name="dpFrom"
          ngbDatepicker
          placeholder="Angi dato, f.eks: 23.12.2023"
          title="Nedre datogrense for rapport"
        />
        <button
          (click)="dpFrom.toggle()"
          aria-label="Åpne datovelger"
          class="btn btn-outline-secondary bi bi-calendar3"
          type="button"
        ></button>
      </div>
      <app-form-field-errors
        [control]="frm.controls.dateFrom"
        [messages]="validationMessages.dateField"
        [showErrors]="frm.controls.dateFrom.touched"
      ></app-form-field-errors>
    </div>
  </section>

  <section class="filter-form-section">
    <div class="form-group">
      <div class="input-group">
        <span class="input-group-text">
          <label for="dateTo"> Til:&nbsp; </label>
        </span>
        <input
          #dpTo="ngbDatepicker"
          (dateSelect)="datePicked()"
          (input)="manualSearch$.next(1500)"
          [class.touched]="frm.controls.dateTo.touched"
          [formControl]="frm.controls.dateTo"
          [maxDate]="pickerMaxDate"
          [minDate]="pickerMinDate"
          class="form-control"
          id="dateTo"
          name="dpTo"
          ngbDatepicker
          placeholder="Angi dato, f.eks: 23.12.2023"
          title="Øvre datogrense for rapport"
        />
        <button
          (click)="dpTo.toggle()"
          aria-label="Åpne datovelger"
          class="btn btn-outline-secondary bi bi-calendar3"
          type="button"
        ></button>
      </div>
      <app-form-field-errors
        [control]="frm.controls.dateTo"
        [messages]="validationMessages.dateField"
        [showErrors]="frm.controls.dateTo.touched"
      ></app-form-field-errors>
    </div>
  </section>

  <section class="filter-form-section">
    <div class="form-group">
      <div class="input-group">
        <input
          (input)="manualSearch$.next(250)"
          [class.touched]="frm.controls.filterDamageType.touched"
          [formControl]="frm.controls.filterDamageType"
          class="form-control"
          placeholder="Søk på skadetype"
          title="Begrens søk til skadetype"
        />
        <button
          (click)="clearFilter('filterDamageType')"
          aria-label="Nullstill filter for skadetype"
          class="btn btn-outline-secondary bi bi-x"
          type="button"
        ></button>
      </div>
      <app-form-field-errors
        [control]="frm.controls.filterDamageType"
        [showErrors]="frm.controls.filterDamageType.touched"
      ></app-form-field-errors>
    </div>
  </section>

  <section class="filter-form-section">
    <div class="form-group">
      <div class="input-group">
        <input
          (input)="manualSearch$.next(250)"
          [class.touched]="frm.controls.filterPlace.touched"
          [formControl]="frm.controls.filterPlace"
          class="form-control"
          placeholder="Fylke/kommune"
          title="Begrens søk til kommune"
        />
        <button
          (click)="clearFilter('filterPlace')"
          aria-label="Nullstill filter for fylke/kommune"
          class="btn btn-outline-secondary bi bi-x"
          type="button"
        ></button>
      </div>
      <app-form-field-errors
        [control]="frm.controls.filterPlace"
        [showErrors]="frm.controls.filterPlace.touched"
      ></app-form-field-errors>
    </div>
  </section>

  <!-- This was requested removed -->
  <!--  <section class="filter-form-section">-->
  <!--    <div *ngIf="(loading$ | async) === true" class="form-group d-flex justify-content-center">-->
  <!--      <div class="spinner-border text-primary" role="status">-->
  <!--        <span aria-live="polite" class="visually-hidden">Loading...</span>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </section>-->
</form>
