import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeNB from '@angular/common/locales/nb';
import { LocationUpgradeModule } from '@angular/common/upgrade';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterOutlet, TitleStrategy } from '@angular/router';
import { UpgradeModule } from '@angular/upgrade/static';
import { CustomTitleStrategy } from '@core/custom-title.strategy';
import { DateFormatterHelper } from '@core/helpers/date-formatter.helper';
import { ErrorInterceptor } from '@core/interceptors/error.interceptor';
import { ErrorService } from '@core/services/error.service';
import { environment } from '@environments/environment';
import { AngularJsModule } from '@features/angularjs/angular-js.module';
import { NgbActiveModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from '@shared/components/header/header.component';
import { RouteBreadcrumbsComponent } from '@shared/components/route-breadcrumbs/route-breadcrumbs.component';
import { ToastsComponent } from '@shared/components/toasts/toasts.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(localeNB);

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    AngularJsModule,
    AppRoutingModule,
    RouteBreadcrumbsComponent,
    BrowserModule,
    HeaderComponent,
    HttpClientModule,
    LocationUpgradeModule.config(),
    RouterOutlet,
    ToastsComponent,
    UpgradeModule,
  ],
  providers: [
    NgbActiveModal,
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
    },
    { provide: ErrorHandler, useClass: ErrorService },
    { provide: APP_BASE_HREF, useValue: environment.frontend.baseHref },
    { provide: NgbDateParserFormatter, useClass: DateFormatterHelper },
    { provide: TitleStrategy, useClass: CustomTitleStrategy },
  ],
})
export class AppModule {}
