import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TermsOfUseModalComponent } from '@modals/terms-of-use/terms-of-use-modal.component';
import { NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NgbModalModule],
  selector: 'app-about',
  standalone: true,
  styleUrls: ['./about.component.scss'],
  templateUrl: './about.component.html',
})
export default class AboutComponent {
  constructor(private readonly _modalService: NgbModal) {}

  openTermsModal() {
    this._modalService.open(TermsOfUseModalComponent, {
      ariaLabelledBy: 'modalHeaderTitle',
      fullscreen: 'sm',
      scrollable: true,
      size: 'lg',
    });
  }
}
