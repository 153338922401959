(function (skogskadeDirectives) {
  'use strict';

  skogskadeDirectives.directive('reportMap', reportMapFn);

  reportMapFn.$inject = ['$timeout', 'leafletStyles', 'mapConf', 'legendService'];

  function reportMapFn($timeout, leafletStyles, mapConf, legendService) {
    // var simpleMap = null;
    var defaultTemplate = '<div id="mapContainer" style="height: 100%; width:100%"><div style="height: 600px; width: calc(100% -44px);" id="{{mapid}}"></div></div>';
    return {
      restrict: 'E',
      replace: 'true',
      scope: {
        maplayers: '=',
        mapid: '@',
      },
      template: defaultTemplate,
      link: function (scope, elem, attrs) {
        // Wait for directive to render its template and add mapcontainer to DOM, then add map
        scope.mapContainer = document.getElementById('mapContainer');

        $timeout(function () {
          if(!scope.mapContainer) {
            $timeout(function() {
              scope.mapContainer = document.getElementById('mapContainer');
              if (!scope.mapContainer) {
                return false;
              }
            }, 100);
          }

          // Remove if exists
          if (scope.map) {
            scope.destroyMap();
          }

          scope.map = L.map(scope.mapid).setView([65, 10], 5);

          var config = mapConf.getSimpleMapConfig(key);
          // START: make legend
          var legend = L.control({ position: 'topright' });
          legend.onAdd = function (simplemap) {
            return legendService.getLegendElement();
          };
          legend.addTo(scope.map);
          // END: make legend
          L.tileLayer(config.layers.skmap.url, {
            attribution: 'Kartverket',
            maxZoom: 19,
            id: 'baselayer',
            accessToken: 'nothing',
          }).addTo(scope.map);

          scope.$watch('maplayers', function (newValue, oldValue) {
            if (newValue) {
              var layers = newValue;
              var bounds = null;
              for (var i = 0; i < layers.length; i++) {
                var layer = layers[i];
                layer.addTo(scope.map);
                if (typeof layer.getBounds === 'function') {
                  if (bounds === null) {
                    bounds = layer.getBounds();
                  } else {
                    bounds.extend(layer.getBounds());
                  }
                }
              }
              if (bounds !== null) {
                scope.map.fitBounds(bounds, { maxZoom: 12 });
              }
            }
          });
        }, 100);

        scope.$on('$destroy', function( event ) {
          scope.destroyMap();
        });

        scope.destroyMap = function() {
          if(scope.map) {
            scope.map = null;
          }
          if (scope.mapContainer) {
            scope.mapContainer.innerHTML = '';
          }
        }

        /*
         var config = {
         attribution: 'NIBIO',
         layers: 'diagnosis_reports',
         format: 'image/png',
         transparent: true,
         opacity: 0.7,
         diagnosisid: 754
         }
         */
      },
    };
  }
})(angular.module('skogskadeDirectives'));
