import { AppConfig } from '@core/app.config';

(function (skogskadeServices) {
  'use strict';

  skogskadeServices.factory('mapService', mapServiceFn);

  mapServiceFn.$inject = ['$http', 'skogskadeGlobalOptions'];

  function mapServiceFn($http, skogskadeGlobalOptions) {
    var activeLayers = [];
    //var geoJson = null;
    var savedObjects = {
      drawOnMap: null,
      showSkogskader: null,
      showReport: null,
      editReport: null,
    };

    var center = {
      drawOnMap: null,
      showReport: null,
      showSkogskade: null,
    };
    var mapDrawCenter = null;
    var mapReportCenter = null;
    var mapViewCenter = null;

    var searchMarker = null;

    var municipalities = null;

    var searchMarkerStyle = {
      color: '#136aec',
      fillColor: '#2a93ee',
      fillOpacity: 0.7,
      weight: 2,
      opacity: 0.9,
      radius: 5,
    };

    //Funksjon for å snu rekkefølgen på JSON
    function swapJsonKeyValues(input) {
      var one,
        output = {};
      for (one in input) {
        if (input.hasOwnProperty(one)) {
          output[input[one]] = one;
        }
      }
      return output;
    }

    $http
      .get(skogskadeGlobalOptions.serviceUrl + '/skogskade_ws/rest/services/municipalitiesAndCounties')
      .success(function (municipalitiesdata, status) {
        municipalities = swapJsonKeyValues(municipalitiesdata.municipalities);
        //counties = swapJsonKeyValues(municipalitiesdata.counties);
      });

    var searchMarkerStyle = function () {
      return searchMarkerStyle;
    };

    var getDefaultMapElement = function(elId, elClass) {
      var response = '<div ';
      if (elClass.length > 0) {
        response += ' class="' + elClass + '" ';
      }
      if (elId.length > 0) {
        response += ' id="' + elId + '" ';
      }
      response += ' style="height: 600px; width: calc(100% - 44px)"></div>';
      return response;
    };

    var getMunicipalityNR = function (fylke, kommune, aust, nord) {
      var municipalities = getMunicipalities();
      var kommuneMedFylke = null;

      var komNR = municipalities[kommune]; //For kommunenavn med riktig fylkenavn i parentes
      if (!komNR) {
        //For kommunenavn med feil fylkesnavn
        if (kommune.indexOf('(') !== -1) {
          // For Sande (Møre og R)
          var kommuneArr = kommune.split(' ');
          kommune = kommuneArr[0]; //Fjerner parentes
        }
        kommuneMedFylke = kommune + ' (' + fylke + ')';
        komNR = municipalities[kommuneMedFylke];
      }
      if (!komNR) {
        // Samiske navn
        for (var komName in municipalities) {
          if (komName.indexOf(kommune) !== -1) {
            //inneholder kommunenavn
            komNR = municipalities[komName];
          }
        }
      }
      return komNR;
    };
    var getSearchMarker = function () {
      return searchMarker;
    };
    var setSearchMarker = function (value) {
      searchMarker = value;
    };

    var getMunicipalities = function () {
      return municipalities;
    };

    var getGeoJson = function (mode) {
      mode = mode || 'drawOnMap';

      if (savedObjects[mode]) {
        return savedObjects[mode].toGeoJSON();
      } else {
        return null;
      }
    };
    var getFeatureGroup = function (mode) {
      mode = mode || 'drawOnMap';
      return savedObjects[mode];
    };
    var setFeatureGroup = function (value, mode) {
      mode = mode || 'drawOnMap';
      savedObjects[mode] = value;
    };

    var getCenterForMode = function (mode) {
      return center[mode];
    };

    var setCenterForMode = function (mode, centerpoint) {
      center[mode] = centerpoint;
    };

    var geoIsSet = function (mode) {
      mode = mode || 'drawOnMap';
      return savedObjects[mode] !== null;
    };
    var resetActiveLayerList = function () {
      while (activeLayers.length > 0) {
        activeLayers.pop();
      }
    };
    var setActiveLayer = function (activeLayer) {
      activeLayers.push(activeLayer);
    };

    var deleteActiveLayer = function (activeLayer) {
      for (var i = 0; i < activeLayers.length; i++) {
        if (activeLayers[i].name === activeLayer.name) {
          activeLayers.splice(i, 1);
          break;
        }
      }
    };

    var checkLayerIsActive = function (checklayer) {
      var status = false;
      for (var i = 0; i < activeLayers.length; i++) {
        if (activeLayers[i].name === checklayer) {
          status = true;
          break;
        }
      }
      return status;
    };

    var getMapConfig = function (customControls) {
      var cacheUrl = null;
      var cacheTopo = '&layers=topo4&zoom={z}&x={x}&y={y}';
      var cacheGrunnkart = '&layers=norges_grunnkart&zoom={z}&x={x}&y={y}';
      var imagerySet = 'AerialWithLabels'; // AerialWithLabels | Birdseye | BirdseyeWithLabels | Road
      if (key) {
        cacheUrl = 'https://gatekeeper2.geonorge.no/BaatGatekeeper/gk/gk.cache_gmaps?gkt=' + key;
      } else {
        //Begrensning på max 10 000 kart pr IP pr dag.
        cacheUrl = 'https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?';
      }

      return {
        defaults: {
          zoomControl: false, // To change mouseover
        },

        controls: {
          custom: customControls,
        },

        center: {
          lat: 65,
          lng: 10,
          zoom: 5,
        },
        layers: {
          baselayers: {
            skmap: {
              name: 'Topo4',
              url: cacheUrl + cacheTopo,
              options: {
                attribution:
                  'Maps: <a href="http://statkart.no" title="The National Mapping Authority of Norway">Kartverket</a>',
              },
              type: 'xyz',
            },
            skgrunnkart: {
              name: 'Grunnkart',
              url: cacheUrl + cacheGrunnkart,
              options: {
                attribution:
                  'Maps: <a href="http://statkart.no" title="The National Mapping Authority of Norway">Kartverket</a>',
              },
              type: 'xyz',
            },
            bingAerial: {
              name: 'Flybilder (Bing)',
              type: 'bing',
              key: 'Ahytczt-m0g_20M2RLRAPuGzfDX0XtFODW9tFp3EaGJB2Y91l3AZ2POSG0OBE_QH',
              layerOptions: {
                type: imagerySet,
              },
            },
            osm: {
              name: 'OpenStreetMap',
              url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
              type: 'xyz',
            },
          },
          overlays: {
            kommunegrense: {
              name: 'Kommunegrenser',
              type: 'wms',
              url: 'https://wms.geonorge.no/skwms1/wms.adm_enheter2',
              visible: false,
              layerOptions: {
                layers: 'kommuner',
                format: 'image/png',
                transparent: true,
                opacity: 1,
                attribution: '<a href="http://www.kartverket.no" title="Kartverket">Kartverket</a>',
              },
            },
            treslag: {
              name: 'Treslag (AR50)',
              type: 'wms',
              url: 'https://wms.nibio.no/cgi-bin/ar50_2',
              legendUrl:
                'https://wms.nibio.no/cgi-bin/ar50_2?version=1.1.1&service=WMS&request=GetLegendGraphic&format=image/png&layer=Treslag',
              visible: false,
              layerOptions: {
                layers: 'Treslag',
                format: 'image/png',
                transparent: true,
                opacity: 1,
                attribution: '<a href="https://www.nibio.no" title="NIBIO">NIBIO</a>',
              },
            },
            vern: {
              name: 'Verneområder (Mdir)',
              type: 'wms',
              url: 'http://wms.miljodirektoratet.no/arcgis/services/vern/mapserver/WMSServer',
              legendUrl:
                'http://wms.miljodirektoratet.no/arcgis/services/vern/mapserver/WMSServer?version=1.1.1&service=WMS&request=GetLegendGraphic&format=image/png&layer=naturvern_klasser_omrade',
              visible: false,
              layerOptions: {
                layers: 'naturvern_klasser_omrade',
                format: 'image/png',
                transparent: true,
                opacity: 1,
                attribution:
                  '<a href="http://wms.miljodirektoratet.no" title="Miljodirektoratet">Miljodirektoratet</a>',
              },
            },
          },
        },
      };
    };
    var getHeaderSize = function (mapMode) {
      /*Do this because delete white space in bootom on phone and avoid scrolling  */
      //      var navbarXSmallScreen = $('#navbarXSmallScreen').height();
      var headerAndNavigationHeight = $('#headerAndNavigation').height();
      /*On mode drawOnMap will we have a "Avbryt" button in bootom, height is 33,6 */
      //      if (mapMode === 'drawOnMap' && navbarXSmallScreen) {
      //        navbarXSmallScreen = navbarXSmallScreen + 34;
      //      }
      //      return navbarXSmallScreen || headerAndNavigationHeight;
      return headerAndNavigationHeight;
    };
    var getNumGeoFigures = function (mapMode) {
      //var drawnItems = getGeoJson('drawOnMap');
      var drawnItems = getFeatureGroup(mapMode);
      // getClone
      // Remove all options.addAsFindSite === false
      // return count rest
      var antall = 0;
      if (drawnItems === null) return 0;
      drawnItems.eachLayer(function (layer) {
        if (layer.options[AppConfig.MAP_FEATURE_FINDSITE] === false) {
        } else antall++;
      });
      return antall;
    };
    var getNumGeoFiguresWithText = function (mapMode) {
      var antall = getNumGeoFigures(mapMode);
      var text = antall === 1 ? ' funnsted' : ' funnsteder';
      return antall + text;
    };
    return {
      geoIsSet: geoIsSet,
      getDefaultMapElement: getDefaultMapElement,
      getGeoJson: getGeoJson,
      getFeatureGroup: getFeatureGroup,
      setFeatureGroup: setFeatureGroup,
      getSearchMarker: getSearchMarker,
      setSearchMarker: setSearchMarker,
      getMunicipalities: getMunicipalities,
      getCenterForMode: getCenterForMode,
      setCenterForMode: setCenterForMode,
      searchMarkerStyle: searchMarkerStyle,
      getMunicipalityNR: getMunicipalityNR,
      getMapConfig: getMapConfig,
      setActiveLayer: setActiveLayer,
      deleteActiveLayer: deleteActiveLayer,
      checkLayerIsActive: checkLayerIsActive,
      resetActiveLayerList: resetActiveLayerList,
      getHeaderSize: getHeaderSize,
      getNumGeoFigures: getNumGeoFigures,
      getNumGeoFiguresWithText: getNumGeoFiguresWithText,
    };
  }
})(angular.module('skogskadeServices'));
