import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SearchPlaceItemType } from '@apptypes/search-place-item.type';
import { AppConfig } from '@core/app.config';
import { MapObjectsService } from '@core/services/map/map-objects.service';
import { MapService } from '@core/services/map/map.service';
import { MunicipalityService } from '@core/services/municipality.service';
import { MapModesEnum } from '@enums/map-modes.enum';
import { MapObjectsEnum } from '@enums/map-objects.enum';
import { MarkerDrawIcon } from '@shared/constants/map/marker-draw.icon';
import { MarkerSearchIcon } from '@shared/constants/map/marker-search.icon';
import { PolylineDrawStyle } from '@shared/constants/map/polyline-draw.style';
import { PolylineSearchStyle } from '@shared/constants/map/polyline-search.style';
import { FeatureCollection } from 'geojson';
import * as L from 'leaflet';
import { FeatureGroup, Marker, Polyline } from 'leaflet';

@Injectable({
  providedIn: 'root',
})
export class MapSearchService {
  municipalityBorder = signal<FeatureCollection>({} as any);
  qtyCandidateFindSites = signal<number>(0);

  private readonly _destroyRef = inject(DestroyRef);
  private readonly _mapObjectsService = inject(MapObjectsService);
  private readonly _mapService = inject(MapService);
  private readonly _municipalityService = inject(MunicipalityService);

  private _findSites!: L.FeatureGroup;
  private _searchedItems!: L.FeatureGroup;

  private get _map() {
    return this._mapService.getMap();
  }

  constructor() {
    // Empty locally cached objects when the map is cleared
    this._mapService.mapCleared$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe({
      next: () => {
        this.clearFindSites();
        this.clearSearches();
      },
    });
  }

  /**
   * Clears the local cache of objects marked as findSites
   */
  clearFindSites() {
    this._getFindSites().clearLayers();
    this._getFindSites().removeFrom(this._map);
    this._findSites = new FeatureGroup([], { pmIgnore: false });
  }

  /**
   * Clears the local cache of objects placed by searching
   */
  clearSearches() {
    this._getSearchedItems().clearLayers();
    this._getSearchedItems().removeFrom(this._map);
    this._countFindSiteCandidates();
  }

  /**
   * Remove marker by id, using the options.customId property
   */
  removeMarkerByCustomId(id: string): void {
    if (this._map) {
      this._map.eachLayer(l => {
        if (l.options[AppConfig.MAP_KEY_CUSTOMID] === id) {
          // Also remove from locally cached searched items
          if (this._getSearchedItems().hasLayer(l)) {
            this._getSearchedItems().removeLayer(l);
          }

          this._map.removeLayer(l);
        }
      });
    }
  }

  /**
   * Remove previous search, using config ID
   */
  removeSearchMarker(): void {
    this._getSearchedItems().eachLayer(l => {
      if (l.options[AppConfig.MAP_KEY_CUSTOMID] === AppConfig.MAP_ID_SEARCHED_PLACE) {
        this._getSearchedItems().removeLayer(l);
      }
    });
  }

  /**
   * Convert the currently searched for object(s) to findSite(s).
   * Strips the SearchStyle and applies the DrawingsStyle.
   * Adds to local cache and saves in MapObjectService.
   */
  saveFindSiteCandidates() {
    this.qtyCandidateFindSites.set(0);

    const findSites = this._getFindSites();
    const searchedItems = this._getSearchedItems();
    const map = this._map;

    // Remove find-group from map so re-adding it at the end of this method actually has an effect.
    findSites.removeFrom(map);

    searchedItems.eachLayer(l => {
      l.options[AppConfig.MAP_KEY_CUSTOMID] = undefined;
      delete l.options[AppConfig.MAP_KEY_CUSTOMID];
      l.options[AppConfig.MAP_FEATURE_FINDSITE] = true;

      if (l instanceof Marker) {
        delete l.options['icon'];
        l.setIcon(MarkerDrawIcon);
      }
      if (l instanceof Polyline) {
        l.setStyle(PolylineDrawStyle);
      }
      l.addTo(findSites);
    });

    // First remove searched-group, then re-add find-group. The order is essential, else it's all removed from map.
    searchedItems.removeFrom(map);
    findSites.addTo(map);

    // Reset contents of searched-group
    this.clearSearches();
    searchedItems.clearLayers();

    this._mapObjectsService.saveObjects(findSites, MapObjectsEnum.SEARCHES, false);
    this._mapObjectsService.saveSearchesAsDrawings();
  }

  /**
   * Set a marker on the map using lat lng coordinates.
   * If "id" is supplied, will set id as options.customId
   * If "popupContent" supplied that will override the default "Coordinate: x, y" content
   *
   * @example
   * 59.66868 , 10.71871
   * 59.9281127,10.7491123
   */
  setMarker(east: number, north: number, id: string, popupContent?: string) {
    const latlng = { lat: north, lng: east };
    let marker: L.Marker;

    this.removeSearchMarker();

    if (this._mapService.mapMode() === MapModesEnum.DRAW) {
      marker = L.marker(latlng, {
        // @ts-expect-error extending marker to set custom options
        [AppConfig.MAP_KEY_CUSTOMID]: id,
        [AppConfig.MAP_FEATURE_FINDSITE]: false,
        // zIndexOffset: 2000,
        icon: MarkerSearchIcon,
      });
    } else {
      marker = L.marker(latlng, {
        // @ts-expect-error extending marker to set custom options
        [AppConfig.MAP_KEY_CUSTOMID]: id,
        icon: MarkerSearchIcon,
      });
    }

    // Add content of popup as GeoJSON property:
    const content: string = popupContent || 'Koordinat: ' + north + ', ' + east;
    this._mapService.initLayerProperties(marker);
    if (marker.feature) {
      marker.feature.properties['popupContent'] = content;
    }
    // and as Leaflet option:
    marker.options['popupContent'] = content;
    marker.bindPopup(content);

    // Add to map
    marker.addTo(this._getSearchedItems());
    this._getSearchedItems().addTo(this._map);
    if (this._mapService.mapMode() === MapModesEnum.DRAW) {
      this._countFindSiteCandidates();
    }

    // Zoom
    this._map.setView(latlng, 13, { animate: true, duration: AppConfig.MAP_ANIMATION_DURATION });
  }

  setMunicipality(place: SearchPlaceItemType): void {
    this.municipalityBorder.set({} as any);

    this._municipalityService.fetchMunicipalityFeature(parseInt(place.municipalityId)).subscribe({
      next: response => {
        if (!response.features) {
          this.setMarker(place.posEast, place.posNorth, AppConfig.MAP_ID_SEARCHED_PLACE, place.name);
        } else {
          this.municipalityBorder.set(response);
          this._drawMunicipalityBorder(response, place);
        }
      },
    });
  }

  private _countFindSiteCandidates() {
    let count: number = 0;
    this._getSearchedItems().eachLayer(l => {
      if (l.options[AppConfig.MAP_FEATURE_FINDSITE] === false) {
        count++;
      }
    });
    this.qtyCandidateFindSites.set(count);
  }

  private _drawMunicipalityBorder(geojson: FeatureCollection, place?: SearchPlaceItemType): void {
    if (!this._map) {
      return;
    }

    this.removeSearchMarker();

    if (!geojson?.features?.length) {
      this._getSearchedItems().eachLayer(l => {
        if (l.options['searchType'] === 'municipalityBorder') {
          l.remove();
        }
      });
    } else {
      L.geoJSON(geojson.features, { pmIgnore: false }).eachLayer(l => {
        l.options.pmIgnore = false;
        l.options[AppConfig.MAP_KEY_CUSTOMID] = AppConfig.MAP_ID_SEARCHED_PLACE;
        l.options[AppConfig.MAP_FEATURE_FINDSITE] = false;
        l.options['searchType'] = 'municipalityBorder';
        if (place) {
          l.bindPopup(place.name);
          l.options['popupContent'] = place.name;

          // Initialize and add popup content as feature prop
          this._mapService.initLayerProperties(l);
          if (Object.hasOwn(l, 'feature')) {
            l['feature'].properties['popupContent'] = place.name;
          }
        }
        if (l instanceof L.Polyline) {
          l.setStyle(PolylineSearchStyle);
        }
        this._getSearchedItems().addLayer(l);

        // In case we need to react to edits, we have to listen for them here:
        // Add edit listener
        // l.on('pm:edit', (e: LeafletEvent | Evented | any) => {
        //   console.log(`pm:edit inside drawMunicipalityBorder`, e);
        // });
      });

      this._countFindSiteCandidates();
      this._getSearchedItems().addTo(this._map);
      this._map.fitBounds(this._getSearchedItems().getBounds(), {
        animate: true,
        duration: AppConfig.MAP_ANIMATION_DURATION,
      });
    }
  }

  /**
   * Return reference handle for the layer with marked FindSites, create if not exists
   */
  private _getFindSites(): FeatureGroup {
    if (!this._findSites) {
      this._findSites = new FeatureGroup([], { pmIgnore: false });
    }
    return this._findSites;
  }

  /**
   * Return reference handle for the layer with Searched Items, create if not exists
   */
  private _getSearchedItems(): FeatureGroup {
    if (!this._searchedItems) {
      this._searchedItems = new FeatureGroup([], { pmIgnore: false });
    }
    return this._searchedItems;
  }
}
