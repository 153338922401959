import { environment } from '@environments/environment';

(function (skogskadeDirectives) {
  'use strict';

  skogskadeDirectives.directive('diagnosisReports', diagnosisReportsFn);

  diagnosisReportsFn.$inject = [
    '$window',
    '$location',
    'requestCounterService',
    'reportListService',
    'messageService',
    'skogskadeGlobalOptions',
    'dateHelper',
  ];

  function diagnosisReportsFn(
    $window,
    $location,
    requestCounter,
    reportListService,
    messageService,
    skogskadeGlobalOptions,
    dateHelper,
  ) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        skadeId: '=',
        skadeName: '=',
      },
      templateUrl: 'ajs/partials/report-table.html',
      link: function (scope) {
        function getPagedReports() {
          var sort = scope.sort.column + ',';
          sort += scope.sort.descending ? 'desc' : 'asc';
          var config = {
            diagnosisid: scope.diagnosisId,
            page: scope.currentPage,
            size: scope.reportsPerPage,
            sort: sort,
          };
          scope.collectingData = true;
          reportListService.getReportsPaged(config).then(
            function (callbackdata) {
              // If I have a parent scope with a recorsObject, I will update that in order to
              // reflect changes
              scope.filteredReports.filtered = callbackdata.records;
              scope.collectingData = false;
              scope.totalItems = callbackdata.metadata.totalCount;
              scope.pageCount = callbackdata.metadata.pageCount;
            },
            function (response) {
              var errorMessage = {
                title: 'Klarte ikke å hente rapporter for skadetypen ' + scope.diagnosisName,
                text: 'Vennligst prøv igjen senere.',
              };
              messageService.setMessage(errorMessage);
              messageService.showMessage(true);
              scope.filteredReports.filtered = [];
            },
          );
        }

        scope.changeSorting = function (column) {
          var sort = scope.sort;

          if (sort.column == column) {
            sort.descending = !sort.descending;
          } else {
            sort.column = column;
            sort.descending = false;
          }
          getPagedReports();
        };

        scope.go = function (path) {
          // Now uses window and baseUrl to point to Angular 16.
          // Old: $location.path(path);
          $window.location.href = environment.frontend.baseUrl + environment.frontend.baseHref + path;
        };
        scope.fromDateToStr = function () {
          return dateHelper.dateToString(scope.fromDate);
        };

        scope.toDateToStr = function () {
          return dateHelper.dateToString(scope.toDate);
        };

        scope.downloadCsv = function (id) {
          var messageText1 = 'Nedlasting av data feilet, vennligst prøv igjen.';
          var messageText2 = 'Ingen rapporter er valgt.';

          reportListService.downloadCSV({ diagnosisid: id }).then(function (callbackdata) {
            if (callbackdata.data.csvPath) {
              //$window.open(callbackdata.data.csvPath, "_blank");
              $window.location.href = skogskadeGlobalOptions.serviceUrl + '/' + callbackdata.data.csvPath;
            } else {
              var errorMessage = {
                title: 'Noe gikk galt',
                text: messageText1,
              };
              messageService.setMessage(errorMessage);
              messageService.showMessage(true);
            }
          });
        };

        scope.pageChanged = function (page) {
          if (page !== scope.currentPage) {
            scope.currentPage = page;
            getPagedReports();
          }
        };

        function init() {
          scope.filteredReports = { filtered: [] };
          scope.fromDate = dateHelper.skToDate('01.01.1900');
          scope.toDate = new Date();
          scope.sort = {
            column: 'date_observed',
            descending: true,
          };
          scope.diagnosisId = scope.skadeId;
          scope.diagnosisName = scope.skadeName;
          scope.currentPage = 1;
          scope.totalItems = 0;
          scope.pageCount = 0;
          scope.reportsPerPage = 50;
          // reportListService.getReportsByDiagnosis(scope.diagnosisId, scope.fromDate, scope.toDate)
          getPagedReports();
        }

        init();
      },
    };
  }
})(angular.module('skogskadeDirectives'));
