(function (skogskadeConstants) {
  'use strict';

  skogskadeConstants.constant('skogskadeGlobalOptions', {
    geoserverPath: 'https://kart13utv.int.nibio.no/geoserver/',
    kartverketRestUrl: 'https://ws.geonorge.no/stedsnavn/v1/sted?',
    maxFeaturesInMap: 3000,
    reportListMaxAgeSec: 1800,
    serviceUrl: 'https://kart13utv.int.nibio.no',
    serviceUrlProxy: '/getSkogskadeInfo',
    skogskadeAdminUrl: '/skogskade_adm/',
    skogskadeWsAdminUrl: '/skogskade_admin_ws/rest/services/mapdata/input',
    title: 'Skogskader',
    descriptionHelp:
      'Skogskader.no bygger på brevarkivet ved tidligere' +
      ' Norsk institutt for skogforskning (nå NIBIO) med skaderapporter tilbake til 1960-tallet.' +
      ' Skogskader.no inneholder nå flere tusen innsendte rapporter samt digitaliserte rapporter' +
      ' fra skadearkivet. Innsending av skaderapporter kan gjøres av alle naturinteresserte ' +
      'privatpersoner, skogeiere, skogbrukssjefer og Fylkesmannens landbruksavdeling.',
    descriptionMap:
      'Kart: På kartet vises alle kartfestete stedsangivelser fra innsendte' +
      ' skaderapporter som er godkjent og kvalitetssikret av NIBIO.',
    descriptionReport:
      'Rapportoversikt: I denne oversikten listes alle innsendte skaderapporter' +
      ' som er godkjent og kvalitetssikret av NIBIO. Klikk på en rapport i oversikten for å åpne den.',
    descriptionReportDetail:
      'Enkeltrapportvisningen inneholder mer detaljert informasjon om den observerte' +
      ' skaden og eventuelle bilder som er vedlagt rapporten.',
    descriptionSkadebeskrivelse:
      'Skadebeskrivelser: Her kan du lese om mer enn 200 ulike skadetyper.' +
      'Oversikten omfatter de vanligste skader forårsaket av insekter, sopper og klima,' +
      ' forurensing og næringsmangel. De fleste skadetypene er beskrevet med tekst og bilder.' +
      ' Forekomst, symptomer, biologi, årsaksforhold, skoglig betydning og mulige forvekslinger omtales.',
    descriptionSkadeDetail:
      'Skadebeskrivelser: Her kan du lese om mer enn 200 ulike skadetyper.' +
      'Oversikten omfatter de vanligste skader forårsaket av insekter, sopper og klima,' +
      ' forurensing og næringsmangel. De fleste skadetypene er beskrevet med tekst og bilder.' +
      ' Forekomst, symptomer, biologi, årsaksforhold, skoglig betydning og mulige forvekslinger omtales.',
    descriptionSkadeForm:
      'Meld skade: Skadeobservasjoner kan rapporteres inn' +
      ' og stedfestes ved å tegne inn observasjonsstedet på et digitalt kart.' +
      ' Digitale bilder kan lastes opp og lagres sammen med rapporten som separate filvedlegg.',
  });
})(angular.module('skogskadeConstants'));
