import * as L from 'leaflet';
import { LatLng, LatLngBounds } from 'leaflet';

export class CoordinateHelper {
  static readonly REGEX_COORDINATE = /^((-?|\+?)?\d+(\.\d+)?)\s?,\s*((-?|\+?)?\d+(\.\d+)?)$/i;
  static readonly REGEX_COORDINATE_LIKE = /^(-?|\+?)?\d{1,2}(\.\d)?\s?,?\s*(-?|\+?)?\d{1,2}(\.\d)?$/i;

  static isCoordinate(str: string): boolean {
    return this.REGEX_COORDINATE.test(str);
  }

  static isCoordinateLike(str: string): boolean {
    if (!str.length) {
      return false;
    }

    const segments = str.split(',');
    const isFirstSegmentNumeric = CoordinateHelper.isNumeric(segments[0]);

    // Simplified check
    if (segments.length === 1) {
      return isFirstSegmentNumeric;
    }
    if (segments.length === 2) {
      if (isFirstSegmentNumeric && CoordinateHelper.isNumeric(segments[1])) {
        return true;
      }
      // Forgiving check which yields true until more data is supplied
      if (segments[0].trim() === '' || segments[1].trim() === '') {
        return true;
      }
    }

    // Fallback to regex check
    return false;
  }

  static isNumeric(str: string): boolean {
    return !isNaN(str as any) && !isNaN(parseFloat(str));
  }

  static isWithinBounds(coord: string | LatLng, bounds: LatLngBounds): boolean {
    if (typeof coord === 'string') {
      if (!coord.length) {
        return false;
      }

      coord = CoordinateHelper.parseCoordinate(coord);
    }
    return bounds.contains(coord as LatLng);
  }

  static parseCoordinate(coord: string): LatLng {
    if (!CoordinateHelper.isCoordinate(coord)) {
      throw new Error('String passed to parseCoordinate() is not a valid candidate for Coordinate. Value: ' + coord);
    }
    const segments = coord.split(',').map(s => parseFloat(s));
    return L.latLng({ alt: segments[2] || undefined, lat: segments[0], lng: segments[1] });
  }
}
