(function (skogskadeServices) {
  'use strict';

  skogskadeServices.factory('messageService', messageServiceFn);

  messageServiceFn.$inject = ['$sce'];

  function messageServiceFn($sce) {
    var closeFunction = null;

    var message = {
      title: 'Noe gikk galt',
      text: '',
    }; // Default mode
    var showMessageFlag = false;

    var setCloseFunction = function (customCloseFunction) {
      closeFunction = customCloseFunction;
    };

    var callCloseFunction = function () {
      if (typeof closeFunction === 'function') {
        closeFunction();
      }
    };

    var getMessage = function () {
      return message;
    };
    var setMessage = function (value) {
      message.text = $sce.trustAsHtml(value.text);
      message.title = value.title;
    };
    var hasMessage = function () {
      return showMessageFlag;
    };

    var showMessage = function (value) {
      showMessageFlag = value;
    };

    var resetMessage = function () {
      closeFunction = null;
      message = {
        title: 'Noe gikk galt',
        text: '',
      }; // Default mode
    };

    var interfaces = {
      getMessage: getMessage,
      setMessage: setMessage,
      hasMessage: hasMessage,
      showMessage: showMessage,
      setCloseFunction: setCloseFunction,
      callCloseFunction: callCloseFunction,
      resetMessage: resetMessage,
    };

    return interfaces;
  }
})(angular.module('skogskadeServices'));
