import { EnvironmentTargetInterface } from '@environments/assets/environment-target.interface';

export enum GeonorgeWsEndpointsEnum {
  GET_STED = '/stedsnavn/v1/sted',
}

export const geonorgeApi: EnvironmentTargetInterface = {
  develop: {
    baseUrl: 'https://ws.geonorge.no',
  },
  production: {
    baseUrl: 'https://ws.geonorge.no',
  },
  test: {
    baseUrl: 'https://ws.geonorge.no',
  },
};
