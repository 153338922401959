import { inject, Injectable } from '@angular/core';
import { SearchPlaceResponseType } from '@apptypes/responses/search-place-response.type';
import { ApiService } from '@core/services/api.service';
import { GeonorgeWsEndpointsEnum } from '@environments/apis/geonorge.api';

@Injectable({
  providedIn: 'root',
})
export class GeonorgeService {
  private readonly _apiService = inject(ApiService);

  fetchPlaces(term: string) {
    const path = GeonorgeWsEndpointsEnum.GET_STED;
    const queryParams = {
      fuzzy: 'true',
      side: '1',
      sok: term + '*',
      treffPerSide: '25',
      utkoordsys: '4258',
    };

    return this._apiService.get<SearchPlaceResponseType>('geonorge', path, {}, queryParams);
  }
}
