(function (skogskadeServices) {
  'use strict';

  skogskadeServices.factory('reportFilterService', reportFilterServiceFn);

  reportFilterServiceFn.$inject = [];

  function reportFilterServiceFn() {
    // Initialize filter values
    var fromDate = new Date();
    fromDate.setFullYear(fromDate.getFullYear() - 1);
    var toDate = new Date();

    var filterValues = {
      changes: 0,
      fromDate: fromDate,
      toDate: toDate,
      textSearch: '',
      placeSearch: '',
    };
    var filteredReports = [];

    var getFilterValue = function (attribute) {
      return filterValues[attribute];
    };

    var setFilterValue = function (attribute, value) {
      filterValues[attribute] = value;
      filterValues.changes++;
    };

    var getFilterValues = function () {
      return filterValues;
    };

    var interfaces = {
      getFilterValue: getFilterValue,
      setFilterValue: setFilterValue,
      getFilterValues: getFilterValues,
    };

    return interfaces;
  }
})(angular.module('skogskadeServices'));
