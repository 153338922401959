<ng-container *ngIf="(reportsError$ | async) === true">
  <div>Noe gikk galt ved innlasting av rapportene.</div>
</ng-container>

<div *ngIf="(reportsError$ | async) === false" [class.dimmed]="service.loading$ | async" class="results">
  <div class="report-meta">
    <div class="report-count">{{ reportsCount() }} godkjente rapporter</div>
    <div class="report-download">
      <button
        (click)="downloadCsv()"
        (keyup.enter)="downloadCsv()"
        class="btn btn-primary btn-sm"
        title="Last ned denne listen over rapporter som en kommaseparert fil (.csv)"
      >
        Last ned
      </button>
    </div>
  </div>

  <div class="report-table">
    <table class="table table-hover table-striped reports">
      <thead>
        <tr>
          <th
            (sort)="onSort($event)"
            [class.current-sort]="filters.sort.startsWith('date_observed')"
            class="date desc"
            sortableReportList="date_observed"
          >
            <span title="Sorter på dato, klikk igjen for å snu rekkefølge">
              Dato
              <i class="bi sort"></i>
            </span>
          </th>
          <th
            (sort)="onSort($event)"
            [class.current-sort]="filters.sort.startsWith('hosts')"
            class="host d-none d-md-table-cell"
            sortableReportList="hosts"
          >
            <span title="Sorter på vertsplante, klikk igjen for å snu rekkefølge">
              Verts&shy;plante
              <i class="bi sort"></i
            ></span>
          </th>
          <th
            (sort)="onSort($event)"
            [class.current-sort]="filters.sort.startsWith('diagnosis_list')"
            class="dmg"
            sortableReportList="diagnosis_list"
          >
            <span title="Sorter på skadetype, klikk igjen for å snu rekkefølge">
              Skade&shy;type
              <i class="bi sort"></i
            ></span>
          </th>
          <th
            (sort)="onSort($event)"
            [class.current-sort]="filters.sort.startsWith('komnavn')"
            class="place"
            sortableReportList="komnavn"
          >
            <span title="Sorter på kommune, klikk igjen for å snu rekkefølge">
              Kommune
              <i class="bi sort"></i
            ></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="service.loading$ | async">
          <td class="loading" colspan="4"><h3>Laster..</h3></td>
        </tr>
        <ng-container *ngIf="(service.loading$ | async) === false">
          <tr
            *ngFor="let report of reports$ | async"
            (keyup.enter)="navigateToReport(report.report_id)"
            [routerLink]="['/rapporter/' + report.report_id]"
            tabindex="0"
          >
            <td class="date">
              <a [routerLink]="['/rapporter/' + report.report_id]">{{ report.date_observed }}</a>
            </td>
            <td class="host d-none d-md-table-cell">{{ report.hosts }}</td>
            <td class="dmg"><ngb-highlight [result]="report.diagnosis_list" [term]="service.damageType" /></td>
            <td class="place"><ngb-highlight [result]="report.komnavn" [term]="service.damagePlace" /></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="pagination">
    <ngb-pagination
      *ngIf="reportsCount() > filters.perPage"
      (pageChange)="setPage($event)"
      [(page)]="service.page"
      [boundaryLinks]="true"
      [collectionSize]="reportsCount()!"
      [pageSize]="service.perPage"
    >
      <ng-template let-page let-pages="pages" ngbPaginationPages>
        <li *ngIf="pages.length > 0" class="ngb-custom-pages-item">
          <div class="mb-3 d-flex flex-nowrap px-2">
            <label class="col-form-label me-2 ms-1" for="paginationInput" id="paginationInputLabel">Side</label>
            <input
              #i
              (blur)="userUpdatePage(i.value)"
              (input)="formatInput($any($event).target)"
              (keyup.enter)="userUpdatePage(i.value)"
              [disabled]="pages.length < 2"
              [value]="page"
              aria-labelledby="paginationInputLabel paginationDescription"
              class="form-control custom-pages-input"
              id="paginationInput"
              inputmode="numeric"
              pattern="[0-9]*"
              style="width: 2.5rem"
              type="text"
            />
            <span class="col-form-label text-nowrap px-2" id="paginationDescription"> av {{ pages.length }}</span>
          </div>
        </li>
      </ng-template>
    </ngb-pagination>

    <select
      (ngModelChange)="setPerPage($event)"
      [(ngModel)]="service.perPage"
      aria-label="Sideinndeling"
      class="form-select"
      name="pageSize"
      style="width: auto"
    >
      <option *ngFor="let val of perPage" [ngValue]="val" [selected]="service.perPage === val">
        {{ val }} per side
      </option>
    </select>
  </div>
</div>
