import { Injectable } from '@angular/core';
import { DateHelper } from '@core/helpers/date.helper';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';

/**
 * Handles how the date is rendered in input field, and parsed from keyboard input
 */
@Injectable()
export class DateFormatterHelper extends NgbDateParserFormatter {
  readonly DELIMITER = '.';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    if (!date || !DateHelper.validateNgbDateStruct(date)) {
      return '';
    }

    const structYear = date.year.toString();
    let finalYear = structYear;

    // If year given as 2-digit, we want to find the sensible century to prefix;
    //  If given 96, we probably want 1996 instead of 2096.
    if (structYear?.length === 2) {
      const intFullYear = new Date().getFullYear();
      const strShortYear = intFullYear.toString().substring(2);
      const intShortYear = parseInt(strShortYear);
      const strPrevCentury = DateHelper.subtractFromDate(new Date(), 100).getFullYear().toString();
      const yearPrefix = parseInt(structYear) > intShortYear ? strPrevCentury : intFullYear.toString();
      finalYear = structYear.padStart(4, yearPrefix);
    }

    return (
      date.day.toString(10).padStart(2, '0') +
      this.DELIMITER +
      date.month.toString(10).padStart(2, '0') +
      this.DELIMITER +
      finalYear
    );
  }

  static toNorString(date: NgbDateStruct): string {
    return new DateFormatterHelper().format(date);
  }
}
